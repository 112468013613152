import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { getDefaultValues, getDefaultValuesAllDataNew } from '../../../redux/actions';
import moment from 'moment';
import { Card, CardHeader, Grid } from '@mui/material';
import { LabelText } from '../../../components/Inputs';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 30px 0px 30px'
    },
    rootHeader: {
        paddingLeft: 0
    }
}));
const HeaderFieldsContiner = forwardRef((props, ref) => {
    const { className } = props;
    const classes = useStyles();
    const [firstTimeFlag, setFirstTimeFlag] = React.useState(true);
    const [contractSubtype, setContractSubtype] = React.useState('');
    const [target, setTarget] = React.useState('');
    const [apiResponse2, setApiResponse2] = React.useState([]);

    useEffect(() => {
        if (localStorage.getItem('mode') === 'edit' && firstTimeFlag) {
            setFirstTimeFlag(false);
        }
    });
    useEffect(() => {
        if (props.datageneral) {
            var appType = props.datageneral.application_type
            // if (appType == 'Customer Rebate')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Direct - Sales Data')
            // else if (appType == 'Supplier Rebate')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Direct - Purchase Data')
            // else if (appType == 'Royalty')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Direct - Sales Data')
            // else if (appType == 'Sales Commissions')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Direct - Sales Data')
            // else if (appType == 'Customer Chargeback')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Direct - Sales Data')
            // else if (appType == 'Supplier Chargeback')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Indirect - Sales Data')
            setTarget(props.datageneral.commitment_achievement)
            setContractSubtype(props.datageneral.calculation_schema)
        }
    }, [props.datageneral]);
    function checkLabel(name) {
        if (Object.keys(labelNewAPIData).length > 0) {
            var data = Object.entries(labelNewAPIData).filter(([key, value]) => key == name).map(([key, value]) => value.current)
            if (data && data.length > 0)
                return data[0]
            else
                return false

        }

    }
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    useEffect(() => {
        if (props.labelNewAPIData && props.tableData && props.tableData.records && Object.keys(props.labelNewAPIData).length > 0) {
            var tempArr2 = props.tableData.records;
            props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && tempArr2 && tempArr2.length > 0 &&
                tempArr2.map((item, i) => {
                    Object.entries(props.labelNewAPIData)
                        .map(([key, value]) => {
                            if (item.field_id === key) {
                                tempArr2[i].name = value['current']
                            }
                        })
                });
            setApiResponse2(tempArr2);
        }
    }, [props.tableData && props.tableData.records, props.labelNewAPIData]);

    // useEffect(() => {
    //     if (props.tableData && props.tableData.records&& props.tableData.records.length > 0) {
    //         props.tableData.records
    //         .filter((item)=>item.contract_display)
    //         .map((item) => {
    //             setContractData(prevState => ({
    //                 ...prevState,
    //                [ item.field_id]: item.field_label_name
    //             }))
    //         })
    //     }
    // }, [props.tableData && props.tableData.records, props.labelNewAPIData]);

    return (
        <Grid container className={clsx(classes.root, className)}>
            {props.contractData &&//props.fieldData&&
                <div className={classes.container}>
                    <Card>
                        <CardHeader
                            title="PRIMARY DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>{console.log(props.fieldData)}
                            <Grid container className={classes.gridContainer}>
                                {props.contractData.application_type &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData.application_type && props.fieldData.application_type.current ? props.fieldData.application_type.current : 'Application Type'} data={props.contractData.application_type} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.id &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['contract_number'] && props.fieldData['contract_number']['current'] ? props.fieldData['contract_number']['current'] : 'Contract Number'}
                                            data={props.contractData.contract_number} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.contract_type && //props.fieldData.contract_type&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['contract_type'] && props.fieldData['contract_type']['current'] ? props.fieldData['contract_type']['current'] : 'Contract Type'} data={props.contractData.contract_type} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.internal_description &&//props.fieldData.internal_description&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['internal_description'] && props.fieldData['internal_description']['current'] ? props.fieldData['internal_description']['current'] : 'Internal Description'}
                                            data={props.contractData.internal_description} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.contract_purpose &&//props.fieldData.contract_purpose&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['contract_purpose'] && props.fieldData['contract_purpose']['current'] ? props.fieldData['contract_purpose']['current'] : 'Contract Purpose'}
                                            data={props.contractData.contract_purpose} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.currency && //props.fieldData.currency&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['currency'] && props.fieldData['currency']['current'] ? props.fieldData['currency']['current'] : 'Currency'}
                                            data={props.contractData.currency} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.valid_from &&//props.fieldData.valid_from&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['valid_from'] && props.fieldData['valid_from']['current'] ? props.fieldData['valid_from']['current'] : 'Valid From'}
                                            data={moment.utc(props.contractData.valid_from).format('MM/DD/YYYY')} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.valid_to &&//props.fieldData.valid_to&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['valid_to'] && props.fieldData['valid_to']['current'] ? props.fieldData['valid_to']['current'] : 'Valid To'} data={moment.utc(props.contractData.valid_to).format('MM/DD/YYYY')} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.posting_schema && //props.fieldData.posting_schema&&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData && props.fieldData['posting_schema'] && props.fieldData['posting_schema']['current'] ? props.fieldData['posting_schema']['current'] : 'Posting Schema'}
                                            data={props.contractData.posting_schema} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.contract_group && props.fieldData.contract_group &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData['contract_group']['current']} data={props.contractData.contract_group} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.contract_sub_group && props.fieldData.contract_sub_group &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData.contract_sub_group.current} data={props.contractData.contract_sub_group} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.contract_objective && props.fieldData.contract_objective &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData.contract_objective.current} data={props.contractData.contract_objective} twoline='true' />
                                    </Grid>
                                }{props.contractData.contract_strategy && props.fieldData.contract_strategy &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData.contract_strategy.current} data={props.contractData.contract_strategy} twoline='true' />
                                    </Grid>
                                }
                                {props.contractData.workflow_name && props.fieldData.workflow_name &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <LabelText heading={props.fieldData.workflow_name.current} data={props.contractData.workflow_name} twoline='true' />
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Card>
                    <Card>
                        <CardHeader
                            title="CALCULATION DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        {props.contractData &&
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>
                                    {props.contractData.source_data_type &&//props.fieldData.source_data_type&&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData && props.fieldData.source_data_type && props.fieldData.source_data_type.current ? props.fieldData.source_data_type.current : 'Source Data Type'} data={props.contractData.source_data_type} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.calculation_method &&//props.fieldData.calculation_method&&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData && props.fieldData.calculation_method && props.fieldData.calculation_method.current ? props.fieldData.calculation_method.current : 'Calculation Method'}
                                                data={props.contractData.calculation_method} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.incentive_rate_type &&//props.fieldData.incentive_rate_type&&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData && props.fieldData.incentive_rate_type && props.fieldData.incentive_rate_type.current ? props.fieldData.incentive_rate_type.current : 'Incentive Rate Type'}
                                                data={props.contractData.incentive_rate_type} twoline='true' />
                                        </Grid>
                                    }

                                    {props.contractData.tier_type && props.fieldData.tier_type &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.tier_type.current ? props.fieldData.tier_type.current : 'Tier Type'} data={props.contractData.tier_type} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.tier_period && props.fieldData.tier_period ?
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.tier_period.current ? props.fieldData.tier_period.current : 'Tier period'}
                                                data={props.contractData.tier_period} twoline='true' />
                                        </Grid>
                                        :
                                        ''
                                    }
                                    {(props.contractData && props.contractData.calculation_method && (props.contractData.calculation_method === 'Tiered Growth' || props.contractData.calculation_method === 'Tiered Volume' || props.contractData.calculation_method === 'Multi Axis')) ?
                                        props.contractData.tier_evaluation_level && props.fieldData.tier_evaluation_level ?
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            >
                                                <LabelText classes={{ root: classes.fontSetting }} heading={props.fieldData.tier_evaluation_level.current}
                                                    data={props.contractData && props.contractData.tier_evaluation_level ? props.contractData.tier_evaluation_level.map(item => item == 'contract' ? 'Contract' : checkLabel(item) ? checkLabel(item) : item.toString().replace(/_/g, ' ')).join(",") : ''} twoline='true' capitalize={true} />
                                            </Grid>
                                            :
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            >
                                                <LabelText heading={props.fieldData.tier_evaluation_level && props.fieldData.tier_evaluation_level.current} data={'Contract'} twoline='true' capitalize={true} />
                                            </Grid>
                                        :
                                        null

                                    }
                                    {props.contractData && props.contractData.quota_level && props.contractData.calculation_method == "Quota Achievement" && props.fieldData.quota_level &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.fontSetting }} capitalize heading={props.fieldData.quota_level.current}
                                                data={props.contractData && props.contractData.quota_level ? props.contractData.quota_level.map(item => item == 'contract' ? 'Contract' : checkLabel(item) ? checkLabel(item) : item.toString().replace(/_/g, ' ')).join(",") : ''} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData && props.contractData.calculation_method && props.contractData.calculation_method != 'Price Point' && props.fieldData.commitment_achievement &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            style={{ display: props.contractData.calculation_method != 'Price Difference' && props.contractData.calculation_method != 'External Calculation' ? null : 'none' }}
                                        >
                                            <LabelText heading={props.fieldData.commitment_achievement.current} data={props.contractData.commitment_achievement} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData && props.contractData.contract_dependency && props.fieldData.contract_dependency &&
                                        props.contractData.calculation_method != 'Price Difference' && props.contractData.calculation_method != 'External Calculation' &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.contract_dependency.current} data={props.contractData.contract_dependency} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData && props.contractData.calculation_rule_modifier && props.fieldData.calculation_rule_modifier &&
                                        props.contractData.calculation_method != 'Price Difference' && props.contractData.calculation_method != 'External Calculation' &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.calculation_rule_modifier.current} data={props.contractData.calculation_rule_modifier} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.calculation_method != 'Fixed Amount' && props.contractData.calculation_method != 'Price Point' && props.contractData.calculation_method != 'Price Difference' && props.fieldData.maximum_amount_level &&
                                        props.contractData.calculation_method != 'External Calculation' &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            {props.contractData.maximum_amount_level ?

                                                <LabelText classes={{ root: classes.fontSetting }} heading={props.fieldData.maximum_amount_level.current} data={props.contractData && props.contractData.maximum_amount_level ? checkLabel(props.contractData.maximum_amount_level) ? checkLabel(props.contractData.maximum_amount_level) : props.contractData.maximum_amount_level.replace(/_/g, ' ') : ''} twoline='true' capitalize={true} />
                                                :
                                                <LabelText heading={props.fieldData.maximum_amount_level.current} data={'Contract'} twoline='true' />
                                            }
                                        </Grid>
                                    }
                                    {props.contractData.maximum_amount_period && props.fieldData.maximum_amount_period ?
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <LabelText heading={props.fieldData.maximum_amount_period.current} data={props.contractData.maximum_amount_period} twoline='true' />
                                        </Grid>
                                        :
                                        ''
                                    }
                                    {props.contractData.maximum_incentive_amount && props.fieldData.maximum_incentive_amount && props.contractData.maximum_amount_level !== 'Payment Period - Tier' && props.contractData.maximum_amount_level !== 'Payment Period - Different Amount' ?
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // style={{ display: props.contractData.calculation_method != 'Fixed Amount' || props.contractData.calculation_method != 'Price Difference' ? 'none' : null }}
                                        >
                                            <LabelText heading={props.fieldData.maximum_incentive_amount.current} data={props.contractData.maximum_incentive_amount} twoline='true' />
                                        </Grid>
                                        :
                                        ''
                                    }

                                    {
                                        props.contractData && props.contractData.calculation_method != 'Price Point' && props.fieldData.formula_exclusive &&
                                        props.contractData.calculation_method != 'External Calculation' &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            style={{ display: (props.contractData.calculation_method != 'Price Difference') ? null : 'none' }}
                                        >
                                            <LabelText heading={props.fieldData.formula_exclusive.current} data={props.contractData.formula_exclusive ? 'Yes' : 'No'} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.evaluate_sales_bundle && props.fieldData.evaluate_sales_bundle ?
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.evaluate_sales_bundle.current} data={props.contractData.evaluate_sales_bundle} twoline='true' />
                                        </Grid>
                                        :
                                        ''
                                    }

                                </Grid>
                            </div>
                        }
                    </Card>
                    {(props.contractData.external_description || props.contractData.flex_fields_1 || props.contractData.flex_fields_2 || props.contractData.flex_fields_3 ||
                        props.contractData.legacy_contract_id || (props.contractData.go_live_date && props.contractData.go_live_date != '0001-01-01T00:00:00Z')) ?
                        <Card>
                            <CardHeader
                                title="ADDITIONAL DATA"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>
                                    {props.contractData.external_description && props.fieldData.external_description &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.external_description.current} data={props.contractData.external_description} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.flex_fields_1 && props.fieldData.flex_fields_1 &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.flex_fields_1.current} data={props.contractData.flex_fields_1} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.flex_fields_2 && props.fieldData.flex_fields_2 &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.flex_fields_2.current} data={props.contractData.flex_fields_2} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.flex_fields_3 && props.fieldData.flex_fields_3 &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.flex_fields_3.current} data={props.contractData.flex_fields_3} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.legacy_contract_id && props.fieldData.legacy_contract_id &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.legacy_contract_id.current} data={props.contractData.legacy_contract_id} twoline='true' />
                                        </Grid>
                                    }
                                    {props.contractData.go_live_date && props.contractData.go_live_date != '0001-01-01T00:00:00Z' && props.fieldData.go_live_date &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <LabelText heading={props.fieldData.go_live_date.current} data={moment.utc(props.contractData.go_live_date).format('MM/DD/YYYY')} twoline='true' />
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        </Card>
                        :
                        null
                    }
                    <Card>
                        <CardHeader
                            title="ADMIN DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <Grid container className={classes.gridContainer}>
                            {props.contractData.created_by &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={'Created By'} data={props.contractData.created_by && props.contractData.created_by.username ? props.contractData.created_by.username : ''} twoline='true' />
                                </Grid>
                            }
                            {props.contractData && props.contractData.updated_by && props.contractData.updated_by.username &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={'Updated By'} data={props.contractData.updated_by && props.contractData.updated_by.username ? props.contractData.updated_by.username : ''} twoline='true' />
                                </Grid>
                            }
                            {props.contractData.created_at &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={'Created At'} data={props.contractData.created_at ? moment(props.contractData.created_at).local().format('MM/DD/YYYY HH:mm') : ''} twoline='true' />
                                </Grid>
                            }
                            {(props.contractData && props.contractData.updated_at && props.contractData.created_at && props.contractData.updated_at != props.contractData.created_at) &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={'Updated At'} data={props.contractData.updated_at ? moment(props.contractData.updated_at).local().format('MM/DD/YYYY HH:mm') : ''} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                    </Card>
                </div>
            }
        </Grid >
    );

});

const mapStateToProps = state => ({
    //contractData: state.addContractData.contractGeneralData,
    contractnumber: state.addContractData.contractNumber,
    tableData: state.addMultipleConfigurationData.multipleConfigurationData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValues()),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HeaderFieldsContiner);