import {
  ATTRIBUTE_LIST_SUCCESS,
  ATTRIBUTE_LIST_FAIL,
  FETCH_START,
  FETCH_END,
  EDIT_ATTRIBUTES_SUCCESS,
  ATTRIBUTE_NAME_SUCCESS,
  DROPDOWN_START,
  DROPDOWN_END,
  DROPDOWN_DATA_SUCCESS,
  DROPDOWN_MASTER_DATA_SUCCESS
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../library/constants";
import { history } from "../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const saveEditAttributeData = (data) => {
  return {
    type: EDIT_ATTRIBUTES_SUCCESS,
    data,
  };
};

export const getAttributeListDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_LIST_SUCCESS,
    data,
  };
};

export const getAttributeListDataFailed = (error) => {
  return {
    type: ATTRIBUTE_LIST_FAIL,
    error,
  };
};
export const getAttributeNameDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_NAME_SUCCESS,
    data,
  };
};
export const getDropdownDataSuccess = (data) => {
  return {
    type: DROPDOWN_DATA_SUCCESS,
    data,
  };
};
export const getDropdownDataMasterDataSuccess = (data) => {
  return {
    type: DROPDOWN_MASTER_DATA_SUCCESS,
    data,
  };
};
export const fetchDropdownStart = () => {
  return {
    type: DROPDOWN_START,
  };
};

export const fetchDropdownEnd = () => {
  return {
    type: DROPDOWN_END,
  };
};

export const getAttributeListData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeListDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListDataFailed(error));
        //toast.error(ServerError);
      });
  };
};

const getAttributeListDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("eligibility-attrib", { headers: headers });
  return result;
};

export const createAttributeData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAttributeDataApi({
      attribute_name: data.attribute_name,
      attribute_value: data.attribute_value,
      attribute_desc: data.attribute_desc,
    })
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          history.push("/attributes");
          toast.success("Successfully created");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const createAttributeDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("eligibility-attrib", data, { headers: headers });
  return result;
};

export const updateAttributeData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateAttributeDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          toast.success("Successfully updated");
          history.push("/attributes");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateAttributeDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("eligibility-attrib/" + id, data, { headers: headers });
  return result;
};

export const deleteAttributeData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteAttributeDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListData());
        toast.success("Successfully deleted");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteAttributeDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("eligibility-attrib/" + id, { headers: headers });
  return result;
};

// //upload File
export const uploadAttributeFile = (file) => {
  return (dispatch) => {
    uploadAttributeFileApi(file)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          dispatch(getAttributeListData());
          toast.success("File has been successfully uploaded.");
        } else {
          toast.error(ServerError);
          console.log(response);

        }
      })
      .catch((error) => {
        const errorMessages = error.response.data.split(';');
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage.trim());
        });
      });
  };
};

const uploadAttributeFileApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("eligibility-attrib-upload", file, { headers: headers });
  return result;
};

export const getAttributeNameList = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeNameApi()
      .then((response) => {
        dispatch(getAttributeNameDataSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getAttributeNameApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("/attribute-names", { headers: headers });
  return result;
};

export const uploadEDIFile = (file) => {
  return (dispatch) => {
    uploadEDIFileAPI(file)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          toast.success("File has been successfully uploaded.");
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const uploadEDIFileAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("incoming-claim-edi", file, { headers: headers });
  return result;
};
//searched dropdown
export const getDropdownList = (table, data, type) => {
  return (dispatch) => {
    dispatch(fetchDropdownStart());
    getDropdownApi(table, data, type)
      .then((response) => {
        if (type === "Master Data") {
          dispatch(getDropdownDataMasterDataSuccess(response.data.response));
        }
        else {
          dispatch(getDropdownDataSuccess(response.data.response));
        }
        dispatch(fetchDropdownEnd());
      })
      .catch((error) => {
        dispatch(fetchDropdownEnd());
      });
  };
};

const getDropdownApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("dropdown-search/" + table, data, { headers: headers });
  return result;
};