import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Breadcrumbs,
} from '@mui/material';
import { connect } from 'react-redux';
import { getContractsTableData, cloneContract, getDefaultValuesFinancialSummaryReports, getDefaultValues, getContractViewData, batchApproveFinancial, getFinacialPostingsApprovalData, runContractTrends } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { getContractDetailsData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { HashLoader } from 'react-spinners';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
var enabledFormFields = [];
const FinancialPostingReportsApproval = props => {
    useEffect(() => {
        props.getFinacialPostingsApprovalData(1, 0);
    }, []);
    var newArray = [];
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [selected, setSelected] = React.useState([]);
    const [tabHeads, setTabHeads] = React.useState([]);
    const [tabKeys, setTabKeys] = React.useState([]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([])
    const [count, setCount] = React.useState(0);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application') != 'Audit Lab' ? sessionStorage.getItem('application') : localStorage.getItem('appType'))
    const columns = [
        {
            field: 'contract_type',
            title: 'Contract Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.contract_type}</a>
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_number}</a>
        },
        {
            field: 'start_date',
            title: 'Start Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.start_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.end_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'posting_type',
            title: 'Posting Type',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.posting_type}</a>
        },
        {
            field: 'posting_number',
            title: 'Posting Number',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => handleCalculationAnalysis(rowData)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}> {rowData.posting_number}</a>

        },
        {
            field: 'posting_date',
            title: 'Posting Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.posting_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'tier_basis',
            title: 'Tier Basis',
            type: 'string',
            render: rowData => <a className={classes.hover}> {parseFloat(rowData.tier_basis).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>

        },
        {
            field: 'incentive_basis',
            title: 'Incentive Basis',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.incentive_basis).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'incentive_rate',
            title: 'Incentive Rate',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.incentive_rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'incentive_amount',
            title: 'Incentive Amount',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.incentive_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'approval_status',
            title: 'Approval Status',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.approval_status}</a>
        },
        {
            field: 'external_posting',
            title: 'External Posting',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.external_posting}</a>
        },
        {
            field: 'external_posting_document_number',
            title: 'External Posting Document Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.external_posting_document_number}</a>
        },
        {
            field: 'created_date',
            title: 'Created Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_date}</a>
        },
        {
            field: 'created_time',
            title: 'Created Time',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_time}</a>
        },
        {
            field: 'created_by',
            title: 'Created By',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_by}</a>
        },
    ];
    const approveContract = () => {
        props.batchApproveFinancial(selected, 'acc')
    };
    const rejectContract = () => {
        props.batchApproveFinancial(selected, 're')
    };
    const [searchText, setSearchText] = React.useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    };
    const contractCustomisationUpdation = useRef();
    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);

    useEffect(() => {
        props.onLoadingDefault(applicationType);
    }, [applicationType]);
    useEffect(() => {
        if (props.financialApprovalData && props.financialApprovalData.records && props.financialApprovalData.total_record) {
            setDataRows(props.financialApprovalData.records)
            setCount(props.financialApprovalData.total_record)
        }
    }, [props.financialApprovalData])
    useEffect(() => {
        var keywordsArray = ['contract_type', 'contract_number', 'start_date', 'end_date', 'posting_type', 'posting_number', 'posting_date', 'tier_basis', 'incentive_basis', 'incentive_rate', 'incentive_amount',
            'approval_status', 'created_date', 'created_time', 'created_by'
        ];

        if (!contractCustomisationUpdation.current) {
            if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
                enabledFormFields = props.tableData.records.filter(itemY => itemY.enabled != false).map(itemY => { return itemY.field_id });
            }

            /*setTabHeads(tabHeads => [...tabHeads, 'Contract Number']);
            setTabKeys(tabKeys => [...tabKeys, 'contract_number']);
            setTabHeads(tabHeads => [...tabHeads, 'Tags']);
            setTabKeys(tabKeys => [...tabKeys, 'tags']);*/
            // console.log(props.tableData)

            if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
                keywordsArray.forEach(function (elem) {
                    if (elem === 'contract_number' || elem === 'approval_status' || elem === 'created_date' || elem === 'created_by' || elem === 'created_time') {
                        setTabHeads(tabHeads => [...tabHeads, elem.replace(/_/g, ' ')]);

                        setTabKeys(tabKeys => [...tabKeys, elem]);
                    }
                    else if (elem === 'contract_internal_description') {
                        setTabHeads(tabHeads => [...tabHeads, 'Description']);
                    }
                    else {
                        props.tableData.records
                            .filter(itemY => itemY.field_id === elem)
                            .filter(itemY => itemY.enabled == true)
                            .map(itemY => {
                                setTabHeads(tabHeads => [...tabHeads, itemY.name]);
                                setTabKeys(tabKeys => [...tabKeys, itemY.field_id]);
                            });
                    }
                });
            }

        }
    }, [props.tableData]);
    useEffect(() => {
        // props.onLoadingDefault();
        setTabHeads(tabHeads.concat(newArray));
    }, []);

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleCalculationAnalysis = (item) => {
        var formData = {
            "start_date": Moment.utc(item.start_date).format('MM/DD/YYYY'),
            "end_date": Moment.utc(item.end_date).format('MM/DD/YYYY'),
            "application_type": sessionStorage.getItem('application'),
            "data_source_type": 'Direct - Sales Data',
            "contract_number": item.contract_number,
            "posting_number": item.posting_number,
            "posting_type": item.posting_type === 'Delta Payment' ? 'payment' :
                item.posting_type === 'Delta Accrual' ? 'accrual' :
                    item.posting_type === 'Accrual Reversal' ? 'accrual' :
                        item.posting_type === 'Payment Reversal' ? 'payment' :
                            item.posting_type
        };
        props.runContractTrends(formData);
        localStorage.setItem('contractTrendsQuery', JSON.stringify(formData))
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/financial-approvals-reports'
                >
                    Financial Postings Approval  Reports
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >


                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{marginTop:'-18px'}}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        contractTabledataComplete: state.addContractData.contractListDataComplete,
        financialApprovalData: state.addMultipleConfigurationData.financialApprovalData,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckContractsTable: (pagination, limit, keyword) => dispatch(getFinacialPostingsApprovalData(pagination, limit, keyword)),
        // getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        // getcontractViewdetails: (id) => dispatch(getContractViewData(id)),
        batchApproveFinancial: (items, status) => dispatch(batchApproveFinancial(items, status)),
        // approval
        getFinacialPostingsApprovalData: (pagination, limit) => dispatch(getFinacialPostingsApprovalData(pagination, limit, '')),
        onLoadingDefault: (application) => dispatch(getDefaultValues(application, 'Direct - Sales Data', null, null, 'Display Designer')),
        // onLoadingDefault: () => dispatch(getDefaultValuesFinancialSummaryReports()),
        runContractTrends: (formData) => dispatch(runContractTrends(formData, "contractV3")),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingReportsApproval);