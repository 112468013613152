import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import { history } from "../../../../../../components/Helpers";
import './BSNavbar.css';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import secureLocalStorage from "react-secure-storage";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { getProfile, getHelpDeskData, login } from "../../../../../../redux/actions";
import { connect } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from '@mui/material';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import "../../../../../../../src/library/scss/bootstrap.min.css";
import "../../../../../../../src/library/scss/rsuite-default.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { OpenInBrowser } from "@mui/icons-material";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import {
  Pricing, CustomerRebate, CustomerChargeback, Royalty,
  SalesCommission, SupplierChargeback, PromotionPlanning,
  ProfitOptimization, MasterData, TransactionData,
  Analytics, CollaborationPortal, AuditLab, DesignerStudio,
  SecurityCockpit, IntegrationStudio, PromotionSticker
} from "./components";
import AppsIcon from '@mui/icons-material/Apps';
import { useLocation } from "react-router-dom";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {
  Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  logo: {
    height: '22px'
  },
  nav: {
    //backgroundColor: theme.menutheme.menuColor.main//theme.palette.primary.main
  },
  white: {
    color: 'black #important'
  },
  navBgColor: {
    // backgroundColor: theme.menutheme.menuColor.main//theme.palette.primary.main
  },
  iconPostion: {
    textAlign: 'center',
    color: '#CEE3F6',
    cursor: 'pointer',
    padding: 0
  }
}));

const SideBarNav = (props) => {
  const theme = useTheme();
  const navbarRef = useRef(null);
  const { pages, superUserPages, className, ...rest } = props;
  const [profileDataAPI, setProfileDataAPI] = React.useState([]);
  const settings = ["New Session", "Profile", "App Launcher", "Logout"];
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState('');
  const [showUrl, setShowUrl] = React.useState(false);
  const [selectedUrl, setSelectedUrl] = React.useState(false);
  const [helpDesk, setHelpDesk] = React.useState([]);
  const location = useLocation();
  const [openModal, setOpenModal] = React.useState(false);
  const [color, setColor] = React.useState('');
  const loginData = JSON.parse(sessionStorage.getItem('loginData'));
  useEffect(() => {
    // props.getProfile();
  }, [])
  useEffect(() => {
    if (props.profileData && Object.keys(props.profileData).length > 0) {
      setProfileDataAPI(props.profileData)
      setColor(props.profileData.global_color)
    }
  }, [props.profileData]);
  useEffect(() => {
    setShowUrl(false)
    // props.getAttributeMapData();
    setCurrentPage(location.pathname);
  }, [location])
  useEffect(() => {
    if (props.helpDeskData && props.helpDeskData.records && props.helpDeskData.records && props.helpDeskData.records.length > 0) {
      setHelpDesk(props.helpDeskData.records)
    }
  }, [props.helpDeskData])
  useEffect(() => {
    helpDesk && helpDesk.map(item => {
      if ((item.application_type === sessionStorage.getItem('application')) && item.url === location.pathname) {
        setSelectedUrl(item.link);
        setShowUrl(true)
      }
    })
  }, [helpDesk])

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleNewTab = () => {
    if (loginData === null) {
      toast.info('Start the New Session from Orginal Login Session');
    } else {
      props.onLogin(loginData)
      window.open('/applauncher', "_blank", "noopener noreferrer");
      const tabsOpen = localStorage.getItem('tabsOpen')
      // if (tabsOpen == null) {
      //   localStorage.setItem('tabsOpen', 1)
      // } else {
      //   localStorage.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
      // }
    }

  }
  const handleSettings = (e) => {
    if (e === "App Launcher") {
      history.push("/applauncher");
    }
    else if (e === 'Profile') {
      history.push('/profile')
    }
    else if (e === "Logout") {
      localStorage.setItem("loggedIn", false);
      localStorage.clear();
      secureLocalStorage.clear();
      history.push("/login");
    }
    else if (e === 'New Session') {
      handleNewTab()
    }
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const AppSelector = () => {
    const app = sessionStorage.getItem('application');
    if (app === 'Customer Rebate' || app === 'Supplier Rebate' || app === 'Royalty')
      return (
        // (
        // location.pathname === '/analytics') || location.pathname === '/analytics-comparison' ||
        //     location.pathname === '/sales-payment-comparison' || location.pathname === '/geographical-chart-us' || location.pathname === '/variance-combined'
        //     || location.pathname === '/incentive-trends' || location.pathname === '/top25' ||
        //     location.pathname === '/historical-trends' || location.pathname === '/rebate-trends' ? < Analytics /> : 
        <CustomerRebate />)
    else if (app === 'Customer Chargeback')
      return <CustomerChargeback />
    else if (app === 'Sales Commission')
      return <SalesCommission />
    else if (app === 'Supplier Chargeback')
      return <SupplierChargeback />
    else if (app === 'Promotions')
      return <PromotionSticker />
    else if (app === 'Profit Optimization')
      return <ProfitOptimization />
    else if (app === 'Master Data')
      return <MasterData />
    else if (app === 'Transaction Data')
      return <TransactionData />
    // else if (app === 'Analytics')
    //   return < Analytics />
    else if (app === 'Collaboration Portal')
      return <CollaborationPortal />
    else if (app === 'Audit Lab')
      return <AuditLab />
    else if (app === 'Designer Studio')
      return < DesignerStudio />
    else if (app === 'Security Cockpit')
      return <SecurityCockpit />
    else if (app === 'Designer Studio')
      return <DesignerStudio />
    else if (app === 'Integration Studio')
      return <IntegrationStudio />
    else if (app === 'Pricing')
      return <Pricing />
  }
  const [imgHeight, setImgHeight] = React.useState('12px')
  useEffect(() => {
    if (navbarRef && navbarRef.current && navbarRef.current.offsetHeight) {
      setImgHeight(navbarRef.current.offsetHeight * .5)
    }
  }, [navbarRef])
  return <>
    <Navbar
      // bg="light"
      fixed="top"
      ref={navbarRef}
      className={classes.navBgColor}
      style={{
        height: props.page !== 'applauncher' ? 60 : 80, background: localStorage.getItem('navclr') ? localStorage.getItem('navclr') : "#32009A",//theme.menutheme.menuColor.main,
        borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px', margin: '4px 3px 3px 3px'
      }} //restore the Previous size of the Nav bar
    >
      {props.page === 'applauncher' &&

        <img
          src={require("../../../../../../library/images/new_logo.png")}
          // className={classes.logo}
          style={{ height: 60, cursor: "pointer", paddingLeft: '41%' }}
          alt=""

          onClick={() => history.push("/applauncher")}
        />
      }
      <Navbar.Brand style={{ height: '100%', display: props.page === 'applauncher' ? 'none' : 'flex', alignItems: 'center' }}>
        <img
          src={require("../../../../../../library/images/new_logo.png")}
          // className={classes.logo}
          style={{ height: imgHeight, cursor: "pointer" }}
          alt=""

          onClick={() => history.push("/applauncher")}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      {props.page !== 'applauncher' &&
        <Navbar.Collapse id="navbarScroll">
          <div></div>

          <AppSelector />
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open Control Panel" style={{ border: '2px solid white', borderRadius: '50px' }} >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="large">
                <Avatar
                  alt={secureLocalStorage.getItem("dYtz").username}
                  src={
                    profileDataAPI && profileDataAPI.profile_image_path
                  }
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={'profile'}>
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '0.8rem' }}>
                  <div>{sessionStorage.getItem('application') ? sessionStorage.getItem('application').toUpperCase() : ''}</div>
                  <div>
                    {profileDataAPI && profileDataAPI.username}
                  </div>
                </div>
              </MenuItem>
              <Dropdown.Divider />
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSettings(setting)}>

                  {setting === 'Logout' ?
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><PowerSettingsNewRoundedIcon fontSize={'small'} color={'error'} style={{ marginRight: 5 }} />{setting} </div>
                    :
                    setting === 'App Launcher' ?
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><AppsIcon fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} </div>
                      :
                      setting === 'New Session' ?
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}> <OpenInBrowser fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} /> {setting} {loginData && Object.entries(loginData).length > 0 ? <AdminPanelSettingsOutlinedIcon fontSize={'small'} color={'primary'} /> : ''} </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><AccountCircleIcon fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} </div>
                  }

                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Navbar.Collapse>
      }
      {props.page === 'applauncher' &&
        <>
          <div style={{ width: '100%' }} />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open Control Panel" style={{ border: '2px solid white', borderRadius: '50px' }} >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="large">
                <Avatar
                  alt={secureLocalStorage.getItem("dYtz").username}
                  src={
                    profileDataAPI && profileDataAPI.profile_image_path
                  }
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={'profile'}>
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '0.8rem' }}>
                  <div>{sessionStorage.getItem('application') ? sessionStorage.getItem('application').toUpperCase() : ''}</div>
                  <div>
                    {profileDataAPI && profileDataAPI.username}
                  </div>
                </div>
              </MenuItem>
              <Dropdown.Divider />
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSettings(setting)}>

                  {setting === 'Logout' ?
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><PowerSettingsNewRoundedIcon fontSize={'small'} color={'error'} style={{ marginRight: 5 }} />{setting} </div>
                    :
                    setting === 'App Launcher' ?
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><AppsIcon fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} </div>
                      :
                      setting === 'New Session' ?
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><OpenInBrowser fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} {loginData && Object.entries(loginData).length > 0 ? <AdminPanelSettingsOutlinedIcon fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} /> : ''} </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><AccountCircleIcon fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} </div>
                  }

                </MenuItem>
              ))}
            </Menu>
          </Box>
        </>
      }
    </Navbar>
    {showUrl ?
      <IconButton
        color='primary'
        className={classes.iconPostion}
        onClick={() => setOpenModal(true)}
        style={{ marginLeft: 10, position: "absolute", marginTop: 62 }}
        size="large">
        <LiveHelpIcon style={{ fontSize: 20 }} />
      </IconButton>
      :
      null
    }
    <Dialog open={openModal} fullScreen>
      <DialogContent classes={{
        root: classes.dialogContent
      }}>
        <Typography variant="h4">
          <DialogContentText>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
              {/* {currentPage === 'customer-master' ?
                                    <iframe src="https://www.loom.com/embed/73ec272073984a91b6dc826ff0251041" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                                    :
                                    <iframe src="https://www.loom.com/embed/076f1d86a11f46d2b803c0622d2da740" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>

                                } */}
              <iframe src={selectedUrl} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
            </div>
          </DialogContentText>
        </Typography>
      </DialogContent>
      <DialogActions classes={{
      }}>
        <Button onClick={() => setOpenModal(false)} color="primary" autoFocus variant="contained"  >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

SideBarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.profileData,
    helpDeskData: state.HelpDeskData.HelpDeskData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile()),
    onLogin: (userData) => dispatch(login(userData, 'New Session')),
    getAttributeMapData: () => dispatch(getHelpDeskData('noLimit'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarNav);