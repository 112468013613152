import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    Breadcrumbs,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { HashLoader } from 'react-spinners';
import MaterialTable from 'material-table';
import { runContractTrends, getAccrualVsPaymentData } from '../../../redux/actions';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const AccrualVsPaymentReportsTable = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([])
    const [count, setCount] = React.useState(0);
    const columns = [
        {
            field: 'contract_type',
            title: 'Contract Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.contract_type}</a>
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_number}</a>
        },
        {
            field: 'contract_internal_description',
            title: 'Contract Internal Description',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_internal_description}</a>
        },
        {
            field: 'start_date',
            title: 'Start Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.start_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.end_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'posting_date',
            title: 'Posting Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.posting_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'accrual_frequency',
            title: 'Accrual Frequency',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.accrual_frequency}</a>
        },
        {
            field: 'payment_frequency',
            title: 'Payment Frequency',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.payment_frequency}</a>
        },
        {
            field: 'accrual_amount',
            title: 'Accrual Amount',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => handleCalculationAnalysis(rowData, 'accrual')} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}> {parseFloat(rowData.accrual_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>

        },
        {
            field: 'payment_amount',
            title: 'Payment Amount',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleCalculationAnalysis(rowData, 'payment')} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}>{parseFloat(rowData.payment_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'approval_status',
            title: 'Approval Status',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.approval_status}</a>
        },
        {
            field: 'created_date',
            title: 'Created Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_date}</a>
        },
        {
            field: 'created_time',
            title: 'Created Time',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_time}</a>
        },
        {
            field: 'created_by',
            title: 'Created By',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_by}</a>
        },
    ];
    useEffect(() => {
        props.getAccrualVsPaymentData(JSON.parse(localStorage.getItem('accrualVsPaymentTab')))
    }, [])
    useEffect(() => {
        if (props.financialApprovalData && props.financialApprovalData.length > 0) {
            setDataRows(props.financialApprovalData)
            setCount(props.financialApprovalData.length)
        }
    }, [props.financialApprovalData])
    const handleCalculationAnalysis = (item, type) => {
        var formData = {
            "start_date": Moment.utc(item.start_date).format('MM/DD/YYYY'),
            "end_date": Moment.utc(item.end_date).format('MM/DD/YYYY'),
            "application_type": sessionStorage.getItem('application'),
            "data_source_type": 'Direct - Sales Data',
            "contract_number": item.contract_number,
            "posting_type": type
        };
        props.runContractTrends(formData);
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/accrual-vs-Payment-reports'
                >
                    Accrual Vs Payment
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >


                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{marginTop:'-18px'}}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        financialApprovalData: state.addMultipleConfigurationData.accrualVsPaymentData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAccrualVsPaymentData: (data) => dispatch(getAccrualVsPaymentData(data)),
        runContractTrends: (formData) => dispatch(runContractTrends(formData, "contractV3")),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccrualVsPaymentReportsTable);