import React, { useEffect, forwardRef, useRef, useState } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, TextField, Chip
} from '@mui/material';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import {
    getAttributeListData, getDefaultValues,
    getDefaultValuesKeyFigure
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import momentt from 'moment-timezone';
import { SearchDropdown, DropdownKeyValue } from '../../../../components/Inputs';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
var deleteFlag = false;
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    centerButton: {
        display: 'flex',
        marginTop: 50
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        // marginTop: 15,
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    },
    dropdownContainer: {
        marginTop: 15
    },
    inputTwoLineAddTiming: {
        paddingLeft: 10,
        textTransform: 'capitalize',
    }
}));

const MultiplePayment = (props) => {

    const [editableData, setEditableData] = useState('');
    const theme = useTheme();
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [editIndex, setEditIndex] = useState('');
    const [paymentPartnerType, setPaymentPartnerType] = useState('');
    const [rebateRecipient, setRebateRecipient] = useState(' ');
    const [paymentpartner, setPaymentPartner] = useState(' ');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [rebateType, setRebateType] = useState('');
    const columns = [
        {
            field: 'payment_aggregation_level',
            title: 'Payment Aggregation Level',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_aggregation_level.replace(/_/g, ' ')}</a>
        },
        {
            field: 'rebate_partner',
            title: 'Rebate Recipient',
            type: 'string',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.rebate_partner}</a>

        },
        {
            field: 'payment_partner_role',
            title: 'Payment Partner Role',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_partner_role.replace(/_/g, ' ')}</a>
        },
        {
            field: 'payment_partner',
            title: 'Payment Partner',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_partner}</a>
        },
        {
            field: 'payment_method',
            title: 'Payment Method',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_method}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editPaymentData(rowData, rowData.tableData.id)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleDeleteLocally(rowData.tableData.id)}
                        size="large">
                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                </div>

        },
    ];
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        if (props.payment_method)
            setPaymentMethod(props.payment_method)
        if (props.partnerType)
            setRebateType(props.partnerType)

    }, [props.payment_method, props.partnerType])

    const editPaymentData = (rule, index) => {
        console.log(rule)
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setRebateRecipient(rule.rebate_partner)
        setPaymentPartnerType(rule.payment_partner_role)
        setPaymentPartner(rule.payment_partner)
        setPaymentMethod(rule.payment_method)
    }

    function handleSubmit(id) {

        var data = {
            "payment_aggregation_level": props.partnerType,
            "rebate_partner": rebateRecipient,
            "payment_partner_role": paymentPartnerType,
            "payment_partner": paymentpartner,
            "payment_method": paymentMethod,

        };


        if (id || id === 0) {
            var editedArray = [...paymentData];
            editedArray[editIndex] = data;
            setPaymentData(editedArray);
            props.onChange(editedArray)
        } else {
            setPaymentData([...paymentData, data]);

            props.onChange([...paymentData, data])
        }
        handleRemove();

    }
    function handleRemove() {
        setEditMode(false);
        setRebateRecipient(' ');
        // if (paymentMethod != props.payment_method)
        setPaymentMethod(' ');
        setPaymentPartner(' ');
        setPaymentPartnerType(' ')

    }


    const handleDeleteLocally = (index) => {
        deleteFlag = true;
        setPaymentData(item => item.filter((item, i) => i !== index));
    }

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...paymentData];
            props.onChange(edited);
            deleteFlag = false;
        }
    }, [paymentData])

    function handlePaymentPartnerType(newValue) {
        setPaymentPartnerType(newValue);
    }
    function handlePaymentMethod(newValue) {
        setPaymentMethod(newValue);
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    {/* <form
                        autoComplete="off"
                        noValidate
                    > */}
                    <div className={classes.container}>

                        <Grid container spacing={1} >
                            <Grid
                                item
                                md={9}
                                xs={12}
                                container spacing={1}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Payment Aggregation
                                        </FormLabel>
                                        <OutlinedInput
                                            value={rebateType}
                                            classes={{ root: classes.inputTwoLineAddTiming }}
                                            disabled
                                        //readOnly
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required>
                                            Rebate Recipient
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <SearchDropdown id='rebate-recipient'
                                                table={props.partnerType == 'Supplier Number' ? 'supplier_master' : props.partnerType == 'Employee Number' ? 'employee_master' : 'customer_master'}
                                                keyName={props.partnerType == 'Supplier Number' ? 'supplier_number' : props.partnerType == 'Employee Number' ? 'employee_number' : 'customer_number'}
                                                description={true}
                                                onChange={(value) => setRebateRecipient(value)}
                                                prevalue={rebateRecipient}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required >
                                            Payment Partner Role
                                        </FormLabel>
                                        <div style={{ marginTop: -15 }}>
                                            <DropdownKeyValue
                                                data={props.fieldData && props.fieldData['payment_partner_role'] && props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                                onChange={handlePaymentPartnerType} labelFormat={'desc'} id="type"
                                                twoline="true" placeholder={' '} prevalue={paymentPartnerType ? paymentPartnerType : ''}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required>
                                            Payment Partner
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <SearchDropdown id='payment_partner'
                                                table={paymentPartnerType == 'Supplier Number' ? 'supplier_master' : paymentPartnerType == 'Employee Number' ? 'employee_master' : 'customer_master'}
                                                keyName={paymentPartnerType == 'Supplier Number' ? 'supplier_number' : paymentPartnerType == 'Employee Number' ? 'employee_number' : 'customer_number'}
                                                description={true}
                                                onChange={(value) => setPaymentPartner(value)}
                                                prevalue={paymentpartner}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>


                            <Grid item md={3} xs={12} container spacing={1} style={{ marginTop: '-0.6rem' }} >
                                <Grid item md={8} xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel required >
                                            {props.fieldData && props.fieldData['payment_method'] && props.fieldData['payment_method']['current']}
                                        </FormLabel>
                                        <div style={{ marginTop: -15 }}>
                                            <DropdownKeyValue
                                                data={props.fieldData && props.fieldData['payment_method'] && props.fieldData['payment_method']['drop_down_reference_value']}
                                                onChange={handlePaymentMethod} placeholder={' '} twoline='true' id={'payment_method'} labelFormat={'desc'}
                                                prevalue={paymentMethod ? paymentMethod : ''}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4} classes={{ root: classes.centerButton }} style={{ marginTop: '2.1rem' }}>
                                    <Grid container style={{ paddingBottom: 15, paddingTop: 0 }} >
                                        {editMode ?
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<EditIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                disabled={!paymentPartnerType || !paymentpartner
                                                    || !paymentMethod || !rebateRecipient ? true : false}
                                                onClick={() => handleSubmit(editIndex)}
                                            >
                                            </Button>
                                            :
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<CheckIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                disabled={!paymentPartnerType || !paymentpartner
                                                    || !paymentMethod || !rebateRecipient ? true : false}
                                                onClick={() => handleSubmit('')}
                                            >
                                            </Button>
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DeleteForeverIcon />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.deleteIconContainer,
                                            }}
                                            onClick={handleRemove}
                                            style={{ marginLeft: 10 }}
                                        >
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                    {/* </form> */}
                </Card >
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="MULTIPLE PAYMENTS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {paymentData.length > 0 ?
                                <MaterialTable
                                    components={{
                                        Toolbar: (props) => (
                                            <div
                                                style={{
                                                    height: "0px",
                                                }}
                                            >
                                            </div>
                                        ),
                                    }}
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={columns}
                                    data={paymentData}
                                    style={{ marginTop: '0px' }}
                                    options={{
                                        maxBodyHeight: '100vh',
                                        search: false,
                                        filtering: true,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                    }}
                                />
                                // <TableContainer>

                                //     <Table>
                                //         <TableHead >
                                //             <TableRow >
                                //                 <TableCell align='center' >Rebate Partner Type</TableCell>
                                //                 <TableCell align='center'>Rebate Recipient</TableCell>
                                //                 <TableCell align='center'>Payment Partner Type</TableCell>
                                //                 <TableCell align='center' >Payment Partner</TableCell>
                                //                 <TableCell align='center' >Payment Method</TableCell>
                                //                 <TableCell align='center' >Options</TableCell>
                                //             </TableRow>
                                //         </TableHead>
                                //         <TableBody classes={{ root: classes.table }} >
                                //             {paymentData
                                //                 .map((item, index) => {
                                //                     return (
                                //                         <StyledTableRow key={item.ID}>
                                //                             <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.payment_aggregation_level?.replace(/_/g, ' ')}</TableCell>
                                //                             <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.rebate_partner}</TableCell>
                                //                             <TableCell align='center' style={{ textTransform: 'capitalize' }} >{item.payment_aggregation_level?.replace(/_/g, ' ')}</TableCell>

                                //                             <TableCell align='center'>{item.payment_partner}</TableCell>
                                //                             <TableCell align='center'>{item.payment_method}</TableCell>

                                //                             <TableCell align='center'>
                                //                                 <IconButton
                                //                                     onClick={() => editPaymentData(item, index)}
                                //                                     classes={{ root: classes.IconButton }}
                                //                                     size="large">
                                //                                     <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                //                                 </IconButton>

                                //                                 <IconButton
                                //                                     onClick={() => handleDeleteLocally(index)}
                                //                                     classes={{ root: classes.IconButton }}
                                //                                     size="large">
                                //                                     <DeleteForeverIcon />
                                //                                 </IconButton>
                                //                             </TableCell>
                                //                         </StyledTableRow>
                                //                     );
                                //                 })}
                                //         </TableBody>
                                //     </Table>



                                // </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div >
    );

}

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        loading: state.dataSetupData.loading,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiplePayment);