import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button, ToggleButton, ToggleButtonGroup, Chip, Tabs, Tab
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, DetailRow } from '@syncfusion/ej2-react-grids';
import { StickerSelectedContainer, StickerTableHeader } from '../../../GlobalSetup/Components';
import { getBudgetingConfig, getMaterialFilters } from '../../../../../redux/actions';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PromoMultiSelect, NestedDropdown } from '../../../Components';
import { PromoRegion } from './Components';
import BeatLoader from "react-spinners/BeatLoader";
import { accessInnerChildren } from '../../../Components/ManipulateData';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '100%',
        overflowX: 'hidden'
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    mainHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
    },
    tableHeaderTop: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 40,
        border: '1px solid #f5f5f5',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5'
    },
    tableHeaderBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        border: '1px solid #f5f5f5',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5'
    },

    sellButton: {
        border: '1px solid #D8D8D8',
        margin: '-5px 10px',
        padding: 3,
        width: 35,
        height: 35,
        cursor: 'pointer',
    },

    refreshButton: {
        border: '1px solid #D8D8D8',
        margin: '-1px 45px',
        padding: 3,
        width: 35,
        height: 35,
        cursor: 'pointer',
    },

    span: {
        marginLeft: 5,
        fontSize: '.9rem'
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        marginTop: 19,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42,
        backgroundColor: theme.palette.white,
        marginLeft: 10,
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        display: 'flex',
        flexDirection: 'column'
    },
    gridContainer: {
        marginTop: 30,
        marginLeft: '5px',
        width: 'auto',
        overflowX: 'scroll'
    },
    chipHighlighter: {
        borderColor: theme.palette.primary.main,
        border: "2px solid"
    }

}));






const GridMain = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [modelYear, setModelYear] = React.useState('2020');
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [expandedRows, setExpandedRows] = React.useState([]);
    const [activeSticker, setActiveSticker] = React.useState([]);
    const [activeStickerID, setActiveStickerID] = React.useState([]);
    const [definedSticker, setDefinedSticker] = React.useState([]);

    const [selectedModels, setSelectedModels] = React.useState([]);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [gridPageFilter, setGridPageFilter] = React.useState([]);
    const [costData, setCostData] = React.useState({});
    const [changeStickerMode, SetChangeStickerMode] = React.useState(false);
    const [currentSticker, setCurrentSticker] = React.useState('');
    const [currentLoc, setCurrentLoc] = React.useState({});
    const [currentModel, setCurrentModel] = React.useState({});
    const [gridData, setGridData] = React.useState('');

    useEffect(() => {
        //props.getConfigData();
        setSelectedCountry(props.country)
    }, [props.country]);
    useEffect(() => {
        setCostData(props.costingData)
    }, [props.costingData]);
    useEffect(() => {
        // props.getFilters()
        if (props.modelYears && props.modelYears.length > 0)
            setModelYear(props.modelYears[0])
    }, [props.modelYears]);
    useEffect(() => {
        if (props.definedData && props.stickers) {
            var data = props.stickers && props.stickers
                .filter((item, i) => props.definedData && Object.keys(props.definedData).includes(item.ID.toString()) && item)
            setDefinedSticker(data)
        }
    }, [props.definedData, props.stickers])

    useEffect(() => {
        if (props.data)
            setGridData(props.data)
    }, [props.data]);

    function handleChange(e) {
        setProductType(e.target.value);
    }
    const handleChangeSticker = (sticker, model, loc) => {
        SetChangeStickerMode(true)
        setCurrentSticker(sticker && sticker.ID)
        setCurrentModel(model)
        setCurrentLoc(loc)
    }
    const handleOnSave = (sticker) => {
        var currentData = props.definedData && props.definedData[currentSticker] ? props.definedData[currentSticker] : {} //current sticker
        var currentDisplay = props.gridFilter && props.definedData[currentSticker] ? props.gridFilter[currentSticker] : {}
        var newData = props.definedData && props.definedData[sticker] ? props.definedData[sticker] : { 'include': [] } // changed sticker
        var newDisplay = props.gridFilter && props.definedData[sticker] ? props.gridFilter[sticker] : []
        const selected = accessInnerChildren(currentModel) // fetch all model childs
        if (currentData && currentDisplay) {
            currentData['exclude'] = {
                ...currentData['exclude'] ? currentData['exclude'] : [],
                [currentLoc.category]: {
                    'model': [...currentData['exclude'] && currentData['exclude'][[currentLoc.category]] && currentData['exclude'][[currentLoc.category]]['model'] ? currentData['exclude'][[currentLoc.category]]['model'] : [],
                    ...selected && selected.children], 'location': [currentLoc.relationship]
                }
            }
            console.log(newData)
            newData['include'] = {
                ...newData && newData['include'] ? newData['include'] : [],
                [currentLoc.category]: {
                    'model': [...newData && newData['include'] && newData['include'][[currentLoc.category]] && newData['include'][[currentLoc.category]]['model'] ? newData['include'][[currentLoc.category]]['model'] : [],
                    ...selected && selected.children], 'location': [currentLoc.relationship], 'relationship': [...newData && newData['include'] && newData['include'][[currentLoc.category]] && newData['include'][[currentLoc.category]]['relationship'] ? newData['include'][[currentLoc.category]]['relationship'] : [],
                    currentModel.relationship && Object.values(currentModel.relationship).join("_")]
                }
            }
            const display = selected && selected.relationships ? selected.relationships.map(item => newDisplay && newDisplay['model'] && newDisplay['model'].includes(item) ? !item : item) : [];
            const loc = newDisplay && newDisplay['location'] && !(newDisplay['location'].includes(Object.values(currentLoc.relationship).join("_"))) ? [Object.values(currentLoc.relationship).join("_")] : [];
            console.log(display)
            var formData = {
                grid_data: { [currentSticker]: currentData, [sticker]: newData, },
                display_data: {
                    [currentSticker]: currentDisplay,
                    [sticker]: {
                        'model': [...newDisplay && newDisplay['model'] ? newDisplay['model'] : [], ...display],
                        'location': [...newDisplay && newDisplay['location'] ? newDisplay['location'] : [], ...loc]
                    }
                }
            }
            console.log(formData)
            SetChangeStickerMode(false)
            props.handleDefine(formData, 'grid')

        }
    }
    function handleModelYear(event, value) {
        setModelYear(value)
    }

    const handleClick = (type) => {
        if (type == 'model') {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
        }
        else {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };

    const handleActiveSticker = (e, value) => {
        if (activeSticker.includes(value)) {
            setActiveSticker(activeSticker.filter(item => item != value))
            setActiveStickerID(activeStickerID.filter(item => item != value.ID))
        }
        else {
            setActiveSticker([...activeSticker, value])
            setActiveStickerID([...activeStickerID, value.ID])
        }
    }
    function handleCheckedData(selected, child, type) {
        setAnchorEl(false)
        if (type !== 'cancel') {
            //for applying grid page filter
            setGridPageFilter(prev => ({
                ...prev,
                'model': child
            }))
            setSelectedModels(child)
        }
    }
    function handleCheckedLoc(selected, child, type) {
        setAnchorloc(false)
        if (type !== 'cancel') {
            setGridPageFilter(prev => ({
                ...prev,
                'location': child
            }))
            setSelectedLoc(child)
        }
    }
    function handleReturn() {
        if (changeStickerMode)
            SetChangeStickerMode(false)
        else
            props.handleGrid('return')
    }
    function handleDeleteSticker() {

    }

    function handlePrefill(id) {
        props.prefill(id)
    }
    return (
        <>
            <div className={classes.root}>
                <StickerTableHeader data={gridData} handlePrefill={handlePrefill} productLineData={props.promoProductLines} defaultDivison={productType} onChange={handleChange} />

                <div className={classes.mainHeader}>
                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between' }}>
                        <div className={classes.row}>
                            <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={handleReturn}>
                                Return
                            </Button>
                            <Typography style={{ fontSize: 13, marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }} />
                                In Progress
                            </Typography>
                        </div>
                    </div>
                    {!changeStickerMode &&
                        <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.row}>
                                <Typography>
                                    <MonetizationOnOutlinedIcon />
                                </Typography>
                                <Typography style={{ marginRight: 80 }}>
                                    (In use) Currency Rate 0.73
                                </Typography>
                                <Typography>
                                    <SellOutlinedIcon className={classes.sellButton} />
                                </Typography>
                            </div>

                        </div>
                    }
                </div>
                {changeStickerMode ?
                    <StickerSelectedContainer page='grid' data={props.stickers} handleNext={handleOnSave}
                        onDelete={handleDeleteSticker} country={selectedCountry} onChangeCountry={handleOnSave} currentSticker={currentSticker}
                        stickerName={"Selected Stickers"} colorPicker={props.colorPicker}
                    />
                    :
                    <>
                        <div className={classes.mainHeader}>
                            <div className={classes.row}>
                                <AutorenewIcon className={classes.refreshButton} onClick={() => props.handleGrid('refresh')} />
                                <Typography style={{ margin: 5 }} >Tot. Promo Cost:</Typography>
                                <Typography style={{ margin: 5 }} >
                                    {props.loader ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loader} size={6} />
                                        :
                                        <> <span>{costData && costData.summary && costData.summary.total_cost ? costData.summary.total_cost.toLocaleString() : 0}</span> <span> {props.currency}</span></>
                                    }
                                </Typography>
                                <Typography style={{ margin: 5 }}>| Tot Vol. {costData && costData.summary && costData.summary.total_volume ? costData.summary.total_volume.toFixed(2) : 0} %
                                </Typography>
                                <Typography style={{ margin: 5 }} > Tot Avg/Unit. <> <span>{costData && costData.summary && costData.summary.avg_cost_per_volume ? parseFloat(
                                    costData.summary.avg_cost_per_volume
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) : 0}</span> <span > {" " + props.currency}</span> | </> </Typography>
                                <Typography color='grey' variant='h4' style={{ margin: 5 }}> Breakdown {" >"}</Typography>
                            </div>
                            <div className={classes.row}>
                                <Button variant="outlined">Cancel</Button>
                                <Button style={{ marginLeft: 10 }} variant="contained" onClick={() => props.handleGrid()}>
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                        :
                                        'Review Scenario'
                                    }
                                </Button>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#f5f5f5', marginLeft: 5 }}>
                            <div className={classes.tableHeaderTop}>
                                <div style={{ marginLeft: 35, marginTop: 20, color: 'grey', display: 'flex' }}>
                                    In Use Stickers
                                    <div style={{ marginLeft: 5 }}>
                                        {props.stickers && props.stickers.length > 0 && props.stickers
                                            .filter((item, i) => props.definedData && Object.keys(props.definedData).includes(item.ID.toString()) && item)
                                            .map((option, i) => (
                                                <Chip
                                                    id={option.ID}
                                                    label={(option.promo_variability ? 'PV -' : '') + (option.version > 1 ? `V${option.version} - ${option.sticker_description}` : option.sticker_description)}
                                                    variant="outlined"
                                                    onClick={(e) => handleActiveSticker(e, option)}
                                                    sx={{ backgroundColor: 'white' }}
                                                    icon={<CircleIcon style={{ color: props.colorPicker && props.colorPicker[option.ID] }} size='small' />}
                                                    className={activeStickerID.includes(option.ID) && classes.chipHighlighter}
                                                />

                                            ))

                                        }
                                    </div>
                                </div>
                                <div style={{ marginLeft: 35, marginTop: 20 }}>
                                    <MoreVertIcon className={classes.refreshButton} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 75 }}>
                                <div className={classes.tableHeaderBottom}>
                                    <Tabs
                                        value={modelYear}
                                        onChange={handleModelYear}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs"
                                        style={{ position: 'relative', marginTop: 25, zindex: -1 }}
                                    >
                                        {props.modelYears && props.modelYears.map(item =>
                                            <Tab value={item} label={item}
                                                sx={{
                                                    '&.Mui-selected': {
                                                        backgroundColor: 'white',
                                                        height: 37,
                                                        // borderRadius: 5,
                                                        //marginLeft: -1,
                                                        border: 'none',
                                                        //marginTop: 2
                                                    },
                                                    '&:not(.Mui-selected)': {
                                                        border: 'none',
                                                    },
                                                }}
                                            />
                                        )}
                                    </Tabs>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 44 }}>
                                    <div className={classes.filterBox} >
                                        <Typography variant='h4' color='black' style={{ marginRight: 12 }}>Filter</Typography>

                                        <div className={classes.filterContainer}>
                                            <div className={classes.filterItem}>
                                                <Typography variant='h4' color='grey'>Model Selection</Typography>
                                                <IconButton onClick={() => handleClick('model')}>
                                                    {anchorEl ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            </div>
                                            {anchorEl &&
                                                <NestedDropdown head='Model Selection' id={'model'} data={props.modelData} expand={expanded} onSubmit={handleCheckedData}
                                                    prevalue={selectedModels} selected={selectedModelItems} type='model_filter' />}
                                        </div>
                                        <div className={classes.filterContainer}>
                                            <div className={classes.filterItem}>
                                                <Typography variant='h4' color='grey'>Location Selection</Typography>
                                                <IconButton onClick={() => handleClick('location')}>
                                                    {anchorloc ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            </div>
                                            {anchorloc &&
                                                <NestedDropdown head='Location Selection' id={'locations'} data={props.locations} expand={expanded} onSubmit={handleCheckedLoc}
                                                    prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter' />
                                            }
                                        </div>

                                    </div>
                                    <div className={classes.filterBox} >
                                        <GroupsIcon />
                                        <Typography variant='h4' color='grey'>Custom Groups</Typography>
                                        <IconButton /*onClick={() => handleClick('model')}*/>
                                            {anchorEl ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={classes.gridContainer}>{console.log(props.definedData)}
                            <PromoRegion country={props.country} modelYear={modelYear} definedData={props.definedData} modelData={props.modelData}
                                gridFilter={props.gridFilter} pageFilter={gridPageFilter} definedSticker={definedSticker} handleDefine={props.handleDefine}
                                activeSticker={activeSticker} colorPicker={props.colorPicker} locationData={props.locations} handleChangeSticker={handleChangeSticker} />
                        </div>
                    </>
                }
            </div >
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        // getConfigData: () => dispatch(getBudgetingConfig()),
        getFilters: () => dispatch(getMaterialFilters()),
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
        loader: state.promotionData.oncostingLoad,
        costingData: state.promotionData.costingSimulationResult,
        modelData: state.promotionData.modelFilters,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GridMain);