import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    Button, IconButton, Fab, Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload } from '@mui/icons-material';
import { getPromoTerritoryData, deletePromoTerritoryData } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const PromotionTerritory = props => {
    useEffect(() => {
        props.getPromoTerritoryData();
    }, []);
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const columns = [
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country}</a>
        },
        {
            field: 'region',
            title: 'Region',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.region}</a>

        },
        {
            field: 'belt',
            title: 'Belt',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.belt}</a>
        },
        {
            field: 'state',
            title: 'State',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.state} </a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => props.deleteTerritory(rowData.id)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ];
    const [dataRows, setDataRows] = React.useState([]);
    useEffect(() => {
        var tempRows = [];
        if (props.promoTerritoryData && props.promoTerritoryData.records && props.promoTerritoryData.records.length > 0)
            props.promoTerritoryData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        region: e.region,
                        state: e.state,
                        belt: e.belt,
                        country: e.country,
                    });
                })
        setDataRows(tempRows);
    }, [props.promoTerritoryData]);

    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        localStorage.setItem('mode', 'add');
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Promotion Territory </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_territory-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/promotion-territory/promotion-territory-excel-upload'
                                onClick={addContract}
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddBoxOutlined />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                component={CustomRouterLink}
                                to='/promotion-territory/promotion-territory-excel-upload'
                                onClick={addContract}
                                style={{ marginLeft: 10 }}
                            >
                                Upload Excel
                            </Button> */}
                        </div>}
                </div>
                {props.promoTerritoryData && props.promoTerritoryData.records && props.promoTerritoryData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            // pageSize: 10,
                            // pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                            paging: false,
                            maxBodyHeight: '80vh',
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        promoTerritoryData: state.promotionData.promoTerritoryData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPromoTerritoryData: () => dispatch(getPromoTerritoryData()),
        deleteTerritory: (id) => dispatch(deletePromoTerritoryData(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionTerritory);