export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const SALES_INVOICE_QUERY_LIST = 'SALES_INVOICE_QUERY_LIST';
export const SALES_INVOICE_QUERY_FIELDS_SUCCESS = 'SALES_INVOICE_QUERY_FIELDS_SUCCESS';
export const SALES_DATA_QUERY_LABELS_SUCCESS = 'SALES_DATA_QUERY_LABELS_SUCCESS'
export const SALES_INVOICE_QUERY_SINGLE_SUCCESS = 'SALES_INVOICE_QUERY_SINGLE_SUCCESS';
export const SALES_INVOICE_UPLOAD_SUCCESS = 'SALES_INVOICE_UPLOAD_SUCCESS';
export const ALL_SALES_DATA_LIST_SUCCESS = 'ALL_SALES_DATA_LIST_SUCCESS';
export const SALES_DATA_DETAILS_SINGLE_SUCCESS = 'SALES_DATA_DETAILS_SINGLE_SUCCESS';
export const SALES_DATA_UPLOAD_SUCCESS = 'SALES_DATA_UPLOAD_SUCCESS';
export const PURCHASE_INVOICE_QUERY_LIST = 'PURCHASE_INVOICE_QUERY_LIST';
export const PURCHASE_INVOICE_QUERY_FIELDS_SUCCESS = 'PURCHASE_INVOICE_QUERY_FIELDS_SUCCESS';
export const PURCHASE_INVOICE_QUERY_SINGLE_SUCCESS = 'PURCHASE_INVOICE_QUERY_SINGLE_SUCCESS';
export const PURCHASE_INVOICE_UPLOAD_SUCCESS = 'PURCHASE_INVOICE_UPLOAD_SUCCESS'
export const INCOMING_CLAIMS_QUERY_LIST = 'INCOMING_CLAIMS_QUERY_LIST';
export const TARGET_INVOICE_LIST = 'TARGET_INVOICE_LIST';
export const COMMITMENT_DATA_SINGLE_SUCCESS = 'COMMITMENT_DATA_SINGLE_SUCCESS';
export const TARGET_INVOICE_QUERY_LIST = 'TARGET_INVOICE_QUERY_LIST';
export const TARGET_INVOICE_QUERY_FIELDS_SUCCESS = 'TARGET_INVOICE_QUERY_FIELDS_SUCCESS';
export const TARGET_INVOICE_QUERY_SINGLE_SUCCESS = 'TARGET_INVOICE_QUERY_SINGLE_SUCCESS';
export const UPLOAD_ELIGIBILITY_RULES_SUCCESS = 'UPLOAD_ELIGIBILITY_RULES_SUCCESS';
export const MATERIAL_MASTER_FIELDS_SUCCESS = 'MATERIAL_MASTER_FIELDS_SUCCESS';
export const MATERIAL_MASTER_SINGLE_FETCH_SUCCESS = 'MATERIAL_MASTER_SINGLE_FETCH_SUCCESS';
export const CUSTOMER_MASTER_SINGLE_FETCH_SUCCESS = 'CUSTOMER_MASTER_SINGLE_FETCH_SUCCESS';
export const CUSTOMER_MASTER_FIELDS_SUCCESS = 'CUSTOMER_MASTER_FIELDS_SUCCESS';
export const MATERIAL_MASTER_QUERY_LIST = 'MATERIAL_MASTER_QUERY_LIST';
export const MATERIAL_UOM_QUERY_LIST = 'MATERIAL_UOM_QUERY_LIST';
export const MATERIAL_MASTER_ORGA_DATA = 'MATERIAL_MASTER_ORGA_DATA';
export const MATERIAL_MASTER_UPLOAD_SUCCESS = 'MATERIAL_MASTER_UPLOAD_SUCCESS';
export const CUSTOMER_MASTER_LIST = 'CUSTOMER_MASTER_LIST';
export const SUPPLIER_MASTER_ORGA_DATA = 'SUPPLIER_MASTER_ORGA_DATA';
export const CUSTOMER__MASTER_SINGLE_FETCH_SUCCESS = 'CUSTOMER__MASTER_SINGLE_FETCH_SUCCESS';
export const UPLOAD_ELIGIBILITY_RULES_CLEAR = 'UPLOAD_ELIGIBILITY_RULES_CLEAR';
export const INDIRECT_SALES_INVOICE_QUERY_LIST = 'INDIRECT_SALES_INVOICE_QUERY_LIST';
export const INDIRECT_SALES_INVOICE_QUERY_FIELDS_SUCCESS = 'INDIRECT_SALES_INVOICE_QUERY_FIELDS_SUCCESS';
export const INDIRECT_SALES_INVOICE_QUERY_SINGLE_SUCCESS = 'INDIRECT_SALES_INVOICE_QUERY_SINGLE_SUCCESS';
export const OUTGOING_CLAIMS_QUERY_LIST = 'OUTGOING_CLAIMS_QUERY_LIST';
export const CUSTOMER_MASTER_ORG_LIST = 'CUSTOMER_MASTER_ORG_LIST';
export const INCOMING_CLAIM_RESPONSE_FORMAT_SUCCESS = "INCOMING_CLAIM_RESPONSE_FORMAT_SUCCESS"
export const INCOMING_CLAIM_RESPONSE_FORMAT_FIELDS = "INCOMING_CLAIM_RESPONSE_FORMAT_FIELDS"
export const OUTGOING_CLAIM_DOWNLOAD_FORMAT_SUCCESS = " OUTGOING_CLAIM_DOWNLOAD_FORMAT_SUCCESS"
export const OUTGOING_CLAIM_DOWNLOAD_FORMAT_FIELDS = " OUTGOING_CLAIM_DOWNLOAD_FORMAT_FIELDS"
export const DEMAND_SUPPLY_FORMAT_FIELDS_SUCCESS = "DEMAND_SUPPLY_FORMAT_FIELDS_SUCCESS"
export const DEMAND_FORMAT_SUCCESS = "DEMAND_FORMAT_SUCCESS"
export const DEMAND_SUPPLY_FORMAT_BY_ID_SUCCESS = "DEMAND_SUPPLY_FORMAT_BY_ID_SUCCESS"
export const DEMAND_SUPPLY_SUCCESS = "DEMAND_SUPPLY_SUCCESS"
export const DEMAND_SUPPLY_SINGLE_SUCCESS = "DEMAND_SUPPLY_SINGLE_SUCCESS"
export const INVENTORY_DATA_FORMAT_FIELDS_SUCCESS = "INVENTORY_DATA_FORMAT_FIELDS_SUCCESS"
export const INVENTORY_FORMAT_SUCCESS = "INVENTORY_FORMAT_SUCCESS"
export const INVENTORY_DATA_FORMAT_BY_ID_SUCCESS = "INVENTORY_DATA_FORMAT_BY_ID_SUCCESS"
export const INVENTORY_DATA_SUCCESS = "INVENTORY_DATA_SUCCESS"
export const INVENTORY_DATA_SINGLE_SUCCESS = "INVENTORY_DATA_SINGLE_SUCCESS"
export const UPLOAD_FAILURE = "UPLOAD_FAILURE"
export const OFF_INVOICE_COST_ERROR_LIST_SUCCESS = "OFF_INVOICE_COST_ERROR_LIST_SUCCESS"
export const OFF_INVOICE_COST_ERROR_LIST_FAILED = "OFF_INVOICE_COST_ERROR_LIST_FAILED"
export const OFFINVOICE_COST_FORMAT_SUCCESS = "OFFINVOICE_COST_FORMAT_SUCCESS"
export const OFFINVOICE_COST_FORMAT_BY_ID_SUCCESS = "OFFINVOICE_COST_FORMAT_BY_ID_SUCCESS"
export const OFFINVOICE_COST_FORMAT_FIELDS_SUCCESS = "OFFINVOICE_COST_FORMAT_FIELDS_SUCCESS"
export const OFFINVOICE_COST_UPLOAD_SUCCESS = "OFFINVOICE_COST_UPLOAD_SUCCESS"
export const SALES_FORECAST_DATA_SUCCESS = "SALES_FORECAST_DATA_SUCCESS"
export const SALES_FORECAST_SUCCESS = "SALES_FORECAST_SUCCESS"
export const COVERAGE_COST_DATA_SUCCESS = "COVERAGE_COST_DATA_SUCCESS"
export const UPLOAD_COVERAGE_COST_SUCCESS = "UPLOAD_COVERAGE_COST_SUCCESS"
export const COVERAGE_COST_FORMAT_SUCCESS = "COVERAGE_COST_FORMAT_SUCCESS"
export const COVERAGE_COST_FORMAT_BY_ID_SUCCESS = "COVERAGE_COST_FORMAT_BY_ID_SUCCESS"
export const COVERAGE_COST_FORMAT_FIELDS_SUCCESS = "COVERAGE_COST_FORMAT_FIELDS_SUCCESS"
export const COMPETITOR_DATA = 'COMPETITOR_DATA';
export const COMPETITOR_DATA_BY_ID = 'COMPETITOR_DATA_BY_ID'
export const COMPETITOR_DATA_FORMAT_SUCCESS = 'COMPETITOR_DATA_FORMAT_SUCCES'
export const COMPETITOR_DATA_FORMAT_BY_ID_SUCCESS = 'COMPETITOR_DATA_FORMAT_BY_ID_SUCCESS'
export const COMPETITOR_DATA_FORMAT_FIELDS_SUCCESS = 'COMPETITOR_DATA_FORMAT_FIELDS_SUCCESS'
export const COMPETITOR_DATA_UPLOAD_SUCCESS = 'COMPETITOR_DATA_UPLOAD_SUCCESS'
export const SALES_FORECAST_FORMAT_SUCCESS = 'SALES_FORECAST_FORMAT_SUCCESS'
export const SALES_FORECAST_FORMAT_BY_ID_SUCCESS = 'SALES_FORECAST_FORMAT_BY_ID_SUCCESS'
export const SALES_FORECAST_FORMAT_FIELDS_SUCCESS = 'SALES_FORECAST_FORMAT_FIELDS_SUCCESS'
export const MULTIPLE_CLAIMS_DETAIL_SUCCESS = "MULTIPLE_CLAIMS_DETAIL_SUCCESS"
export const TARGET_DATA_UPLOAD_SUCCESS = "TARGET_DATA_UPLOAD_SUCCESS"
export const INVENTORY_DATA_UPLOAD_SUCCESS = "INVENTORY_DATA_UPLOAD_SUCCESS"
export const DEMAND_SUPPLY_UPLOAD_SUCCESS = "DEMAND_SUPPLY_UPLOAD_SUCCESS"
export const INCOMING_CLAIMS_SUCCESS = "INCOMING_CLAIMS_SUCCESS"
export const MASTER_DATA_DASHBOARD_SUCCESS = "MASTER_DATA_DASHBOARD_SUCCESS"
export const MASTER_DATA_DASHBOARD_TRANSACTION_SUCCESS = "MASTER_DATA_DASHBOARD_TRANSACTION_SUCCESS"
export const MASTER_DATA_DASHBOARD_CHANGES_SUCCESS = "MASTER_DATA_DASHBOARD_CHANGES_SUCCESS"
export const MASTER_OPERATION_DATA_SUCCESS = "MASTER_OPERATION_DATA_SUCCESS"
export const MASTER_DATA_CHART_SUCCESS = "MASTER_DATA_CHART_SUCCESS"
export const ALL_INDIRECT_SALES_DATA_LIST_SUCCESS = 'ALL_INDIRECT_SALES_DATA_LIST_SUCCESS';
export const INDIRECT_SALES_DATA_DETAILS_SINGLE_SUCCESS = 'INDIRECT_SALES_DATA_DETAILS_SINGLE_SUCCESS';
export const ALL_PURCHASE_SALES_DATA_LIST_SUCCESS = 'ALL_PURCHASE_SALES_DATA_LIST_SUCCESS';
export const PURCHASE_SALES_DATA_DETAILS_SINGLE_SUCCESS = 'PURCHASE_SALES_DATA_DETAILS_SINGLE_SUCCESS';
export const MASTER_DATA_CONFIG_SUCCESS = "MASTER_DATA_CONFIG_SUCCESS";
export const APPLICATION_LOGS_SUCCESS = "APPLICATION_LOGS_SUCCESS";
export const CUSTOMER_MASTER_XREF_SINGLE_FETCH_SUCCESS = 'CUSTOMER_MASTER_XREF_SINGLE_FETCH_SUCCESS';
export const BUILD_SALES_DATA_LABELS = 'BUILD_SALES_DATA_LABELS'
export const OUTGOING_CLAIM_GET_BY_ID = 'OUTGOING_CLAIM_GET_BY_ID'