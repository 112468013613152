import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, TextField,
    Card, OutlinedInput, FormLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Checkbox
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addBatchJobSetup, getDefaultValues, getIntegrationSetupData, allowedApps } from '../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { ChipInput } from '../../../components/Inputs';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        marginTop: 20

    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine1: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 33
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    select1: {
        width: '100%',
        marginTop: 10,
        height: 33
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        //borderRadius: 3,
        marginTop: 2,
        height: 37,
        padding: 0,
        //marginBottom: 14,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        // border: '1px solid #E0E0E0',
        width: '100%',
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    selectChips: {
        marginTop: 2,
    }
}));

const AddBatchJobSetup = props => {
    const classes = useStyles();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [postingTypeArray, setPostingTypeArray] = React.useState([]);
    const history = useHistory();

    useEffect(() => {
        props.onLoadingDefault(sessionStorage.getItem('application'));
        // props.getIntegrationSetupData();
        props.allowedApps();
    }, []);

    useEffect(() => {
        if (props.integrationSetupData && props.integrationSetupData.records.length > 0) {
            var newArray = []
            props.integrationSetupData.records.map((item, index) => {
                newArray.push(item.name)
            })
            setIntegration(newArray)
        }
    }, [props.integrationSetupData])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])


    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'contract_type') {
                    setContractTypeArray(item)
                }
                if (item.field_id === 'calculation_method') {
                    setCalculationMethodArray(item);
                }
                if (item.field_id === 'accrual_calendar') {
                    setPlannedStartCalendarArray(item)

                }
            })

        }

    }, [props.dropdownData])


    const [integrationName, setIntegrationName] = React.useState('');
    const [integration, setIntegration] = React.useState([]);

    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [contractNumber, setContractNumber] = React.useState('');
    const [calculationStartDate, setCalculationStartDate] = React.useState(null);
    const [calculationEndDate, setCalculationEndDate] = React.useState(null);
    const [postingType, setPostingType] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [postingDate, setPostingDate] = React.useState(null);
    const [billingStartDate, setBillingStartDate] = React.useState('');
    const [billingEndDate, setBillingEndDate] = React.useState('');
    const [calculationDateType, setCalculationDateType] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [splitCriteria, setSplitCriteria] = React.useState(['supplier_number']);
    const [batchJobArray, setBatchJobArray] = React.useState([]);
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const accrualType = [
        'Accrual', 'Accrual Reversal'
    ];
    const paymentType = [
        'Payment', 'Payment Reversal'
    ]
    // const BatchJobTypesArray = [
    //     'Accrual Postings', 'Automatic PPA','Calculation Simulation', 'Payment Postings'
    // ];
    useEffect(() => {
        if (applicationType == 'Customer Rebate') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Automatic PPA', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Supplier Rebate') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Automatic PPA', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }

        if (applicationType == 'Customer Chargeback') {
            const tempBatchjobArr = [
                'Create Claims', 'Accrual Posting', 'Payment Posting'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Supplier Chargeback') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Royalty') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Sales Commission') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
    }, [applicationType, props.allowedAppsData])

    const frequencyTypesArray = [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    const postingDateArray = [
        'Last Day of Previous Month', 'First Day of Current Month', 'Current Date'
    ]
    const calculationStartDateArray = [
        'First Day of Previous Month', 'First Day of Current Month'
    ]
    const calculationEndDateArray = [
        'Last Day of Previous Month', 'Last Day of Current Month'
    ]
    const billingStartDateArray = [
        'Previous Day', 'Current Date', 'First Day of Previous Month', 'First Day of Current Month'
    ]
    const billingEndDateArray = [
        'Previous Day', 'Current Date', 'Last Day of Previous Month', 'Last Day of Current Month'
    ]

    function handleContinue() {
        setOpen(false);

        props.onLoadingDefault(applicationType)
        if (batchJobType === 'Accrual Postings') {
            setPostingTypeArray([...accrualType])
        }
        if (batchJobType === 'Payment Postings') {
            setPostingTypeArray([...paymentType]);
        }

    }

    function handleClear() {
        setContractType('')
        setContractNumber([])
        setPostingType('')
        setCalculationStartDate(null)
        setCalculationEndDate(null)
        setCalculationMethod('')
        setTags([])
        setIntegrationName('')
        setBillingEndDate('')
        setBillingStartDate('')
        setSupplierNumber('')

    }


    function handleOnSubmit() {
        let timeStamp = Moment.tz(timezoneOffset).format().toString().split("T")
        var data = {
            "app_type": applicationType,
            "batch_job_type": batchJobType,
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            // "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ'),
            // "planned_start_calendar": plannedStartCalendar,
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,
            "batch_filter_criteria": [{
                "filters": {
                    "calculation_method": calculationMethod,
                    "contract_type": contractType,
                    "contract_number": contractNumber ? contractNumber : [],
                    "calculation_date_type": calculationDateType,
                    "posting_date": postingDate ? (typeof (postingDate) === 'string' ? postingDate : Moment.utc(postingDate, 'YYYY-MM-DD').toISOString().split('T')[0]) : '',
                    "calculation_start_date": calculationStartDate ? (typeof (calculationStartDate) === 'string' ? calculationStartDate : Moment.utc(calculationStartDate, 'YYYY-MM-DD').toISOString().split('T')[0]) : '',
                    "calculation_end_date": calculationEndDate ? (typeof (calculationEndDate) === 'string' ? calculationEndDate : Moment.utc(calculationEndDate, 'YYYY-MM-DD').toISOString().split('T')[0]) : '',
                    "posting_type": postingType,
                    "billing_start_date": billingStartDate,
                    "billing_end_date": billingEndDate,
                    "tags": tags,
                    "name": integrationName,
                    "supplier_number": supplierNumber,
                    "split_criteria": splitCriteria.toString()
                }
            }]
        }
        if (plannedDateType == 'dynamic') {
            data["planned_start_calendar"] = plannedStartCalendar
        } else {
            data["planned_start_date"] = plannedStartDate ? Moment.utc(plannedStartDate).format('YYYY-MM-DDT') + timeStamp[1] : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ')
        }
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
    }

    function handleApplicationType(newValue) {
        setApplicationType(newValue)
    }
    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value)
    }
    const handleChange = (e) => {
        if (e.target.checked) {
            setSplitCriteria([...splitCriteria, e.target.name])
        }
        else {
            var pos = splitCriteria.indexOf(e.target.name)
            if (pos > -1) {
                splitCriteria.splice(pos, 1)
            }
        }

    }
    function handleTags(newValue) {
        setTags(newValue)
    }
    function handleContractNumber(newValue) {
        setContractNumber(newValue)
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'none' : 'flex') }}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <FormLabel className={classes.formLabel} required={contractNumber.length > 0 || contractType ? false : true}
                                        disabled={contractNumber.length > 0 || contractType ? true : false}>
                                        Calculation Method
                                    </FormLabel>
                                    <Select
                                        value={calculationMethod}
                                        onChange={(e) => setCalculationMethod(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        disabled={contractNumber.length > 0 || contractType ? true : false}
                                    >
                                        <MenuItem value=''> </MenuItem>
                                        {calculationMethodArray &&
                                            calculationMethodArray.drop_down_values && calculationMethodArray.drop_down_values
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <FormLabel className={classes.formLabel} required={contractNumber.length > 0 || calculationMethod ? false : true}
                                        disabled={contractNumber.length > 0 || calculationMethod ? true : false}>
                                        Contract Type
                                    </FormLabel>
                                    <Select
                                        value={contractType}
                                        onChange={(e) => setContractType(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        disabled={contractNumber.length > 0 || calculationMethod ? true : false}
                                    >
                                        <MenuItem value=''> </MenuItem>
                                        {contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.length > 0 &&
                                            contractTypeArray.drop_down_values
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required={contractType || calculationMethod ? false : true}
                                            disabled={contractType || calculationMethod ? true : false}>
                                            Contract Number
                                        </FormLabel>
                                        <div className={classes.selectChips}>
                                            <ChipInput data={contractNumber} onChange={handleContractNumber} disabled={contractType || calculationMethod ? true : false} />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                    style={{ display: (batchJobType === 'Accrual Postings' || batchJobType === 'Payment Postings' ? 'block' : 'none') }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>
                                            Posting Type
                                        </FormLabel>
                                        <Select
                                            value={postingType}
                                            onChange={(e) => setPostingType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {postingTypeArray &&
                                                postingTypeArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Calculation Date Type
                                        </FormLabel>
                                        <Select
                                            value={calculationDateType}
                                            onChange={(e) => setCalculationDateType(e.target.value)}
                                            displayEmpty
                                            disabled={!frequency ? true : false}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ marginTop: 2 }}
                                        >

                                            <MenuItem value='static'>
                                                Static
                                            </MenuItem>
                                            <MenuItem value='dynamic'>
                                                Dynamic
                                            </MenuItem>

                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Calculation Simulation' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || !calculationDateType || calculationDateType === 'static' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}


                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>
                                            Posting Date
                                        </FormLabel>
                                        <Select
                                            value={postingDate}
                                            onChange={(e) => setPostingDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {postingDateArray &&
                                                postingDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Build Indirect Sales Data' || batchJobType === 'Create Claim'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Calculation Simulation' || calculationDateType === 'dynamic' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>  Posting Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            height: 37,
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 2,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={postingDate}
                                                    onChange={(e) => setPostingDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || calculationDateType === 'dynamic' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Calculation Start Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            height: 37,
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 2,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationStartDate}
                                                    onChange={(e) => setCalculationStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || calculationDateType === 'dynamic' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Calculation End Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            height: 37,
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 2,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationEndDate}
                                                    onChange={(e) => setCalculationEndDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={calculationStartDate ? calculationStartDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>


                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || !calculationDateType || calculationDateType === 'static' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Calculation Start Date</FormLabel>
                                        <Select
                                            value={calculationStartDate}
                                            onChange={(e) => setCalculationStartDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            style={{ marginTop: 2 }}
                                        >
                                            {calculationStartDateArray &&
                                                calculationStartDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || !calculationDateType || calculationDateType === 'static' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Calculation End Date</FormLabel>
                                        <Select
                                            value={calculationEndDate}
                                            onChange={(e) => setCalculationEndDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {calculationEndDateArray &&
                                                calculationEndDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' ? 'block' : 'none')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Billing Start Date</FormLabel>
                                        <Select
                                            value={billingStartDate}
                                            onChange={(e) => setBillingStartDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {billingStartDateArray &&
                                                billingStartDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' ? 'block' : 'none')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Billing End Date</FormLabel>
                                        <Select
                                            value={billingEndDate}
                                            onChange={(e) => setBillingEndDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {billingEndDateArray &&
                                                billingEndDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Build Sales Data' || batchJobType === 'Automatic PPA' || batchJobType === 'Build Indirect Sales Data'
                                            || batchJobType === 'Build Purchase Data' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.selectRoot} style={{ width: '24rem' }}>
                                        <FormLabel className={classes.formLabel}>
                                            Tags
                                        </FormLabel>
                                        <ChipInput data={tags} onChange={handleTags} />
                                    </div>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'flex' : 'none') }}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Integration Name
                                    </FormLabel>
                                    <Select
                                        onChange={(e) => setIntegrationName(e.target.value)}
                                        value={integrationName}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {integration.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} name={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>



                            </Grid>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Create Claim' ? 'flex' : 'none') }}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Billing Start Date</FormLabel>
                                        <Select
                                            value={billingStartDate}
                                            onChange={(e) => setBillingStartDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            <MenuItem value={'Previous Day'} key={'Previous Day'}>
                                                Previous Day
                                            </MenuItem>
                                            <MenuItem value={'Current Date'} key={'Current Date'}>
                                                Current Date
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Billing End Date</FormLabel>
                                        <Select
                                            value={billingEndDate}
                                            onChange={(e) => setBillingEndDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            <MenuItem value={'Previous Day'} key={'Previous Day'}>
                                                Previous Day
                                            </MenuItem>
                                            <MenuItem value={'Current Date'} key={'Current Date'}>
                                                Current Date
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} >
                                        Supplier Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={supplierNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setSupplierNumber(e.target.value)}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} >
                                        Split Claim Criteria
                                    </FormLabel>
                                    <div style={{ display: 'flex' }}>
                                        <FormControlLabel
                                            control={<Checkbox checked color="primary" disabled />}
                                            label="Supplier Number"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox Checked={splitCriteria.includes('billing_date') > -1 ? true : false} color="primary" onChange={handleChange} name="billing_date" />}
                                            label="Billing Date"
                                        />

                                    </div>

                                </Grid>



                            </Grid>
                        </div>
                    </form>
                </Card >

                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Application Type
                                            </FormLabel>
                                            <Select
                                                value={applicationType}
                                                onChange={(e) => handleApplicationType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {allowedApps
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Type
                                            </FormLabel>
                                            <Select
                                                value={batchJobType}
                                                onChange={(e) => setBatchJobType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {batchJobArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {frequencyTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>


                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={batchJobName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine1 }}
                                                onChange={(e) => setBatchJobName(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency ? true : false}
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>

                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            height: 33,
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                    .filter(item => item != 'Manual')
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>

                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>

                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                className={classes.textField}
                                                // style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>


                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={!applicationType || !batchJobType || !batchJobName || !plannedStartTime || !frequency || !plannedDateType ? true
                                : plannedStartDate || plannedStartCalendar ? false : true
                            }
                        >
                            Continue

                        </Button>
                    </DialogActions>
                </Dialog>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>


                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}

                        disabled={
                            batchJobType === 'Accrual Postings' || batchJobType === 'Payment Postings' || batchJobType === 'Calculation Simulation' ?
                                (!calculationStartDate || !calculationEndDate || !calculationDateType || ((!postingType || !postingDate) && batchJobType != 'Calculation Simulation') ? true :
                                    (contractNumber.length > 0 || contractType || calculationMethod ? false : true)
                                ) : batchJobType === 'Automatic Integration' ? (integrationName ? false : true) : batchJobType === 'Create Claim' ? (billingStartDate && billingEndDate ? false : true)
                                    : ((!billingStartDate || !billingEndDate) && batchJobType != 'Automatic PPA') || ((!postingDate || !calculationDateType) && batchJobType != 'Build Sales Data')

                        }
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addBatchJobSetup(data)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        allowedApps: () => dispatch(allowedApps())
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        allowedAppsData: state.initialData.allowedApps,
        integrationSetupData: state.customerData.integrationSetupData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBatchJobSetup);