import React, { useEffect, forwardRef, useRef, useState } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, TextField, Chip
} from '@mui/material';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import {
    getAttributeListData, getDefaultValues,
    getDefaultValuesKeyFigure
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import momentt from 'moment-timezone';
import { SearchDropdown, DropdownKeyValue } from '../../../../components/Inputs';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
var deleteFlag = false;
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    centerButton: {
        display: 'flex',
        marginTop: 50
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    formDiv: {
        height: 41,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    dropdownContainer: {
        marginTop: 10
    }
}));

const MultiplePayment = forwardRef((props) => {
    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [keyFigureName, setKeyFigureName] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const theme = useTheme();
    const inputFile = useRef(null)
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [attributeName, setAttributeName] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);
    const [attributeValue, setAttributeValue] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    const [attributeNameEnableHelper, setAttributeNameEnableHelper] = React.useState(false);
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [paymentData, setPaymentData] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [paymentPartnerType, setPaymentPartnerType] = React.useState('');
    const [rebateRecipient, setRebateRecipient] = React.useState('');
    const [paymentpartner, setPaymentPartner] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const columns = [
        {
            field: 'payment_aggregation_level',
            title: 'Payment Aggregation Level',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_aggregation_level.replace(/_/g, ' ')}</a>
        },
        {
            field: 'rebate_partner',
            title: 'Rebate Recipient',
            type: 'string',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.rebate_partner}</a>

        },
        {
            field: 'payment_partner_role',
            title: 'Payment Partner Role',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_partner_role.replace(/_/g, ' ')}</a>
        },
        {
            field: 'payment_partner',
            title: 'Payment Partner',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_partner}</a>
        },
        {
            field: 'payment_method',
            title: 'Payment Method',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_method}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editPaymentData(rowData, rowData.tableData.id)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleDeleteLocally(rowData.tableData.id)}
                        size="large">
                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                </div>

        },
    ];
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        if (props.data) {
            if (props.data.contract_multiple_payment_rules && props.data.contract_multiple_payment_rules.length > 0)
                setPaymentData(props.data.contract_multiple_payment_rules);
        }
    }, [props.data]);
    const editPaymentData = (rule, index) => {
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setRebateRecipient(rule.rebate_partner)
        setPaymentPartnerType(rule.payment_partner_role)
        setPaymentPartner(rule.payment_partner)
        setPaymentMethod(rule.payment_method)
    }

    function handleSubmit(id) {

        var data = {
            "payment_aggregation_level": props.partnerType,
            "rebate_partner": rebateRecipient,
            "payment_partner_role": paymentPartnerType,
            "payment_partner": paymentpartner,
            "payment_method": paymentMethod,

        };


        if (id || id === 0) {
            var editedArray = [...paymentData];
            editedArray[editIndex] = data;
            setPaymentData(editedArray);
            props.onChange(editedArray)
        } else {
            setPaymentData([...paymentData, data]);

            props.onChange([...paymentData, data])
        }
        handleRemove();

    }
    function handleRemove() {
        setEditMode(false);
        setRebateRecipient('');
        if (paymentMethod != props.payment_method)
            setPaymentMethod('');

        setPaymentPartner('');
        setPaymentPartnerType('');

    }

    useEffect(() => {
        if (props.payment_method)
            setPaymentMethod(props.payment_method)

    }, [props.payment_method])

    const handleDeleteLocally = (index) => {
        deleteFlag = true;
        setPaymentData(item => item.filter((item, i) => i !== index));
    }

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...paymentData];
            props.onChange(edited);
            deleteFlag = false;
        }
    }, [paymentData])

    function handlePaymentPartnerType(newValue) {
        setPaymentPartnerType(newValue);
    }
    function handlePaymentMethod(newValue) {
        setPaymentMethod(newValue);
    }
    return (
        <div>
            {/* Eligibility Rules Add */}
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>

                            <Grid container spacing={1} >
                                <Grid
                                    item
                                    md={9}
                                    xs={12}
                                    container spacing={1}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel required className={classes.formDiv}>
                                                Payment Aggregation Level
                                            </FormLabel>
                                            <OutlinedInput
                                                value={props.partnerType}
                                                classes={{ root: classes.inputTwoLineAddTiming }}
                                                readOnly
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div className={classes.dropdownContainer}>
                                            <FormLabel required>
                                                Rebate Recipient
                                            </FormLabel>
                                            <SearchDropdown id='rebate-recipient'
                                                table={props.partnerType == 'Supplier Number' ? 'supplier_master' : props.partnerType == 'Employee Number' ? 'employee_master' : 'customer_master'}
                                                keyName={props.partnerType == 'Supplier Number' ? 'supplier_number' : props.partnerType == 'Employee Number' ? 'employee_number' : 'customer_number'}
                                                description={true}
                                                onChange={(value) => setRebateRecipient(value)}
                                                prevalue={rebateRecipient}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <DropdownKeyValue heading={'Payment Partner Role'} data={props.fieldData && props.fieldData['payment_partner_role'] && props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                            onChange={handlePaymentPartnerType} labelFormat={'desc'} id="type"
                                            required twoline="true" placeholder={' '} prevalue={paymentPartnerType ? paymentPartnerType : ''}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div className={classes.dropdownContainer}>
                                            <FormLabel required>
                                                Payment Partner
                                            </FormLabel>
                                            <SearchDropdown id='payment_partner'
                                                table={paymentPartnerType == 'supplier' ? 'supplier_master' : paymentPartnerType == 'employee' ? 'employee_master' : 'customer_master'}
                                                keyName={paymentPartnerType == 'supplier' ? 'supplier_number' : paymentPartnerType == 'employee' ? 'employee_number' : 'customer_number'}
                                                description={true}
                                                onChange={(value) => setPaymentPartner(value)}
                                                prevalue={paymentpartner}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid item md={3} xs={12} container spacing={1}>
                                    <Grid item md={8} xs={12}>
                                        <DropdownKeyValue heading={props.fieldData && props.fieldData['payment_method'] && props.fieldData['payment_method']['current']}
                                            data={props.fieldData && props.fieldData['payment_method'] && props.fieldData['payment_method']['drop_down_reference_value']}
                                            onChange={handlePaymentMethod} required placeholder={' '} twoline='true' id={'payment_method'}
                                            prevalue={paymentMethod ? paymentMethod : ''} labelFormat={'desc'}
                                        />
                                    </Grid>
                                    <Grid item md={4} classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingBottom: 15, paddingTop: 5 }} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={!paymentPartnerType || !paymentpartner
                                                        || !paymentMethod || !rebateRecipient ? true : false}
                                                    onClick={() => handleSubmit(editIndex)}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={!paymentPartnerType || !paymentpartner
                                                        || !paymentMethod || !rebateRecipient ? true : false}
                                                    onClick={() => handleSubmit('')}
                                                >
                                                </Button>
                                            }
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                                style={{ marginLeft: 10 }}
                                            >
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                        </div>
                    </form>
                </Card >
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="MULTIPLE PAYMENTS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {paymentData.length > 0 ?
                                <MaterialTable
                                    components={{
                                        Toolbar: (props) => (
                                            <div
                                                style={{
                                                    height: "0px",
                                                }}
                                            >
                                            </div>
                                        ),
                                    }}
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={columns}
                                    data={paymentData}
                                    style={{ marginTop: '0px' }}
                                    options={{
                                        maxBodyHeight: '100vh',
                                        search: false,
                                        filtering: true,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                    }}
                                />
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {/* Eligibility Rules Table Ends here */}
        </div >
    );

});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        loading: state.dataSetupData.loading,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiplePayment);