import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button, IconButton, formControlClasses } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown, ExpandMore, DeleteOutlined } from '@mui/icons-material';
import { getModels } from '../../../../../../redux/actions';
import DeleteDialog from './DeleteDialog';
import { accessInnerChildren } from '../../../../Components/ManipulateData';
const useStyles = makeStyles(theme => ({
    accordionMenu: {
        backgroundColor: '#EEEEEE',
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        fontSize: 12,
        height: 36,
        width: 250,
        paddingLeft: 10,
        "&.Mui-expanded": {
            minHeight: 48
        }
    },
    gridButton: {
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        Padding: "8px 12px 8px 12px",
        height: 36,
        width: 54,
        margin: 12,
        backgroundColor: "#E2E2E2"
    },
    locButton: {
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        Padding: "8px 12px 8px 12px",
        height: 40,
        width: '100%',
        color: 'black !important',
        margin: 2,
        backgroundColor: "#E2E2E2",
        // width: 120,
        '&:hover': {
            backgroundColor: '#E2E2E2'
        },


    },
    locMenu: {
        backgroundColor: '#EEEEEE',
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        fontSize: 12,
        height: 36,
        maxHeight: 36,
        width: '100%',
        margin: "10px 10px 0px 0px",
        // display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        webkitFlexWrap: 'nowrap',
        minWidth: 56, padding: 0,//marginBottom:2,
        "&.Mui-expanded": {
            minHeight: 48
        }
    },
    locDetail: {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        webkitFlexWrap: 'nowrap', marginTop: 2,

    },
    deleteIcon: {
        position: 'absolute',
        top: '50%',
        right: '-30px', /* Adjust as necessary */
        transform: 'translateY(-50%)',
        fontSize: '16px',
        cursor: 'pointer',
        opacity: 0,
        transition: 'opacity 0.3s',
        color: 'white'
    },

}))

const PromoRegion = (props) => {

    const classes = useStyles();
    const [activeSticker, setActiveSticker] = useState([]);
    const [data, setData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [localFilter, setLocalFilter] = React.useState([]);
    const [modelFilter, setModelFilter] = React.useState([]);
    const [onDelete, setOnDelete] = React.useState(false);
    const [selectedModel, setSelectedModel] = React.useState({});
    const [selectedLoc, setSelectedLoc] = React.useState({});
    const [selectedSticker, setSelectedSticker] = React.useState({});
    const [defaultExpanded, setDefaultExpanded] = React.useState([]);
    const [activeModel, setActiveModel] = useState([]);
    const [activeLoc, setActiveLoc] = useState([]);


    useEffect(() => {
        if (props.modelData)
            setData(props.modelData)
    }, [props.modelData])

    useEffect(() => {
        if (props.locationData)
            setLocations(props.locationData)
    }, [props.locationData])
    useEffect(() => {
        if (props.definedSticker)
            setActiveSticker(props.definedSticker)
    }, [props.definedSticker])
    useEffect(() => {
        const temp = []
        const locate = []
        if (props.pageFilter && Object.values(props.pageFilter).length > 0) {
            if (props.pageFilter['model'] &&
                (props.pageFilter['model'].filter(itemx => itemx.toString().startsWith(props.modelYear + "_"))).length > 0) {
                setModelFilter(props.pageFilter['model'])
            }
            else {
                if (props.activeSticker && props.activeSticker.length > 0)
                    setModelFilter(activeModel)
                else
                    setModelFilter([])
            }
            if (props.pageFilter && props.pageFilter['location']) {
                setLocalFilter(props.pageFilter['location'])
            }
            else {
                if (props.activeSticker && props.activeSticker.length > 0)
                    setLocalFilter(activeLoc)
                else
                    setLocalFilter([])
            }
        }
        else if (props.gridFilter && props.activeSticker && props.activeSticker.length > 0) {
            setActiveSticker(props.activeSticker)
            props.activeSticker.map(item => {
                if (props.gridFilter && props.gridFilter[item.ID] && props.gridFilter[item.ID]['model']) {
                    temp.push(...props.gridFilter[item.ID]['model'])
                }
                if (props.gridFilter && props.gridFilter[item.ID] && props.gridFilter[item.ID]['location']) {
                    locate.push(...props.gridFilter[item.ID]['location'])
                }
            })
            setModelFilter(temp)
            setActiveModel(temp)
            setActiveLoc(locate)
            setLocalFilter(locate)
        }
        else {
            if (props.gridFilter) {
                const allModels = Object.values(props.gridFilter)
                    .map(entry => entry.model)
                    .flat();
                setDefaultExpanded(allModels)
            }
            setActiveSticker(props.definedSticker)
            setModelFilter([])
            setLocalFilter([])
        }
    }, [props.gridFilter, props.pageFilter, props.activeSticker, props.modelYear])
    const handleDelete = (model, loc, sticker) => {
        if (sticker && sticker.ID) {
            setSelectedModel(model)
            setSelectedLoc(loc)
            setSelectedSticker(sticker)
            setOnDelete(true)
        }
    }
    const handleAccordionChange = (panel, type, relationship) => (event, isExpanded) => {
        if (isExpanded) {
            if (defaultExpanded.includes(Object.values(relationship).join("_")))
                setDefaultExpanded(defaultExpanded.filter(item => item != Object.values(relationship).join("_")))
            else

                setMarketExpanded([...defaultExpanded, Object.values(relationship).join("_")])
        }
        else {
            if (defaultExpanded.includes(Object.values(relationship).join("_")))
                setMarketExpanded(defaultExpanded.filter(item => item != Object.values(relationship).join("_")))
            else
                setDefaultExpanded([...defaultExpanded, Object.values(relationship).join("_")])
        }
    };
    const handleDeletedData = (value) => {
        var flag = true
        setOnDelete(false)
        if (value == 'yes') {
            var data = props.definedData && selectedSticker ? props.definedData[selectedSticker.ID] : {}
            var display = props.gridFilter && selectedSticker ? props.gridFilter[selectedSticker.ID] : {}
            if (selectedLoc && data && data['include'] && data['include'][selectedLoc.category]) { // delete from included data if any
                if (data['include'][selectedLoc.category]['model'] && data['include'][selectedLoc.category]['model'].some(filterItem => Object.values(filterItem).join("_").startsWith(Object.values(selectedModel.relationship).join("_").toString()))) {
                    data['include'] = {
                        ...data['exclude'] ? data['exclude'] : [],
                        [selectedLoc.category]: {
                            'model': data['include'][selectedLoc.category]['model'].filter(filterItem => !(Object.values(filterItem).join("_").startsWith(Object.values(selectedModel.relationship).join("_").toString()))), 'location': [selectedLoc.relationship]
                        }
                    }
                }
            }
            if (data && display) {//add deleted ones in the exclude
                const selected = accessInnerChildren(selectedModel) // fetch all childs
                data['exclude'] = {
                    ...data['exclude'] ? data['exclude'] : [],
                    [selectedLoc.category]: {
                        'model': [...data['exclude'] && data['exclude'][[selectedLoc.category]] && data['exclude'][[selectedLoc.category]]['model'] ? data['exclude'][[selectedLoc.category]]['model'] : [],
                        ...selected && selected.children], 'location': [selectedLoc.relationship]
                    }
                }
            }

            console.log(data)
            var formData = {
                grid_data: { [selectedSticker.ID]: data },
                display_data: { [selectedSticker.ID]: display }
            }
            props.handleDefine(formData, 'grid')
        }
        if (value !== 'change')
            handleReset()
        if (value == 'change')
            props.handleChangeSticker(selectedSticker, selectedModel, selectedLoc)
    }
    function handleReset() {
        setSelectedModel({})
        setSelectedLoc({})
        setSelectedSticker({})
    }
    const renderNestedItems = (items) => {
        return items && items.filter((item, index) =>
            modelFilter && modelFilter.length > 0 ? modelFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
            .map((item, index) => (
                <>

                    {item.type == 'material_group1' ?
                        renderNestedItems(item.children)
                        :
                        item.children && item.children.length > 0 ? (
                            <div key={index} style={{ flexGrow: 1, width: '100%', height: 'auto' }} >

                                < Accordion
                                    expanded={(defaultExpanded.includes(Object.values(item.relationship).join("_")) &&
                                        defaultExpanded.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString()))) ? false :
                                        (!(defaultExpanded.includes(Object.values(item.relationship).join("_"))) &&
                                            defaultExpanded.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString()))) ? true :
                                            marketExpanded.includes(Object.values(item.relationship).join("_"))}
                                    onChange={handleAccordionChange(item.category, item.type, item.relationship)}
                                    style={{ margin: 0, }}
                                    fullWidth
                                    slotProps={{ transition: { unmountOnExit: true } }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <AccordionSummary
                                            expandIcon={
                                                <ExpandMore color={(defaultExpanded.includes(Object.values(item.relationship).join("_")) &&
                                                    defaultExpanded.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString()))) ? 'black' :
                                                    (!(defaultExpanded.includes(Object.values(item.relationship).join("_"))) &&
                                                        defaultExpanded.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString()))) ? 'primary' :
                                                        marketExpanded.includes(Object.values(item.relationship).join("_")) ? 'primary' : 'black'}
                                                    onChange={handleAccordionChange(item.category, item.type, item.relationship)}
                                                />}
                                            className={classes.accordionMenu}
                                            style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10, }}

                                        >
                                            <Typography variant='h4' color={'black'} style={{ marginLeft: 10 }} >
                                                {item.category}
                                            </Typography>
                                        </AccordionSummary>
                                        {renderStates(item)}
                                    </div>
                                    <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                        className={(item.type == 'material_group3') && classes.chipContainer}
                                    >
                                        {renderNestedItems(item.children)}
                                    </AccordionDetails>
                                </Accordion>
                            </div >
                        ) :
                            (
                                <div style={{ display: 'flex', alignItems: 'flex-start', height: 48, }}>
                                    <div key={item.category} className={classes.accordionMenu} style={{ height: '100%', paddingLeft: 40,/* position: 'fixed', zIndex: 1999, left: 16 */ }}>
                                        <Typography variant='h4' color={'black'} >{item.category}</Typography>
                                    </div>
                                    {renderStates(item)}
                                </div>
                            )
                    }
                </>
            ));
    };

    const renderStates = (data, type) => {
        return type && type == 'head' ? locations && locations.filter((item, index) =>
            localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
            .map((parent, index) =>

            (
                <div key={parent.category} style={{ width: '100%', justifyContent: 'center', marginBottom: 10 }}>
                    <Accordion style={{ marginRight: 3 }} expanded>
                        <AccordionSummary expandIcon={<ExpandMore color='primary' />}
                            className={classes.locMenu}
                        >
                            <Typography variant='h4' color={'black'} >{parent.category}</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ width: '100%', padding: 0, marginTop: 2, height: 36 }}>
                            <div className={classes.locDetail} >
                                {parent.children.filter((itemx, i) => localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(itemx.relationship).join("_").toString())) : itemx)
                                    .map((child) => (
                                        <Button variant="outlined" className={classes.locButton}>{child.category}</Button>
                                    ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div >
            ))
            :
            locations && locations.filter((item, index) =>
                localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
                .map((parent, index) =>
                (<div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', webkitFlexWrap: 'nowrap', left: 250, minWidth: 120, marginRight: 3 }} >

                    {parent.children.filter((itemx, i) => localFilter && localFilter.length > 0 ?
                        localFilter.some(filterItem => filterItem.startsWith(Object.values(itemx.relationship).join("_").toString())) : itemx)
                        .map((child) => {
                            var clr;
                            var sticker = {}
                            activeSticker && activeSticker.length > 0 && activeSticker.map((item, index) => {
                                console.log(props.definedData[item.ID] && props.definedData[item.ID]['include'] && props.definedData[item.ID]['include'][child.category] && props.definedData[item.ID]['include'][child.category]['model'])

                                var includeData = props.definedData[item.ID] && props.definedData[item.ID]['include'] && props.definedData[item.ID]['include'][child.category] && props.definedData[item.ID]['include'][child.category]    // get the deleted data           
                                var excludeData = props.definedData[item.ID] && props.definedData[item.ID]['exclude'] && props.definedData[item.ID]['exclude'][child.category] && props.definedData[item.ID]['exclude'][child.category]['model']    // get changed sticker data         
                                if ((props.gridFilter && props.gridFilter[item.ID] && props.gridFilter[item.ID]['model'] && props.gridFilter[item.ID]['model'].includes(Object.values(data.relationship).join("_").toString())) || (includeData && includeData.model && includeData.model.some(filterItem => filterItem && Object.values(filterItem).toString().startsWith(Object.values(data.relationship))))) {
                                    if (props.gridFilter[item.ID]['location'] && props.gridFilter[item.ID]['location'].includes(Object.values(child.relationship).join("_").toString()) || (includeData)) {
                                        // console.log(excludeData && excludeData.some(filterItem => filterItem.model && filterItem.model.startsWith(Object.values(data.relationship))))
                                        console.log(includeData)
                                        if (props.definedData && props.definedData[item.ID] && (!(excludeData && excludeData.some(filterItem => filterItem && Object.values(filterItem).toString().startsWith(Object.values(data.relationship))))
                                            || (includeData && includeData.relationship && includeData.relationship.some(filterItem => Object.values(data.relationship).join("_").startsWith(filterItem))))) {

                                            console.log(includeData && includeData.relationship && includeData.relationship.some(filterItem => Object.values(data.relationship).join("_").startsWith(filterItem)))

                                            if (includeData && includeData.relationship && includeData.relationship.some(filterItem => Object.values(data.relationship).join("_").startsWith(filterItem))
                                                && (props.definedData[item.ID] && props.definedData[item.ID]['include'] && props.definedData[item.ID]['include'][child.category])) {
                                                clr = props.colorPicker && props.colorPicker[item.ID];
                                                sticker = item
                                                console.log("test")
                                            }
                                            else {
                                                clr = props.colorPicker && props.colorPicker[item.ID];
                                                sticker = item
                                            }
                                        }
                                    }
                                    // console.log(data)
                                    // if (props.definedData[item.ID] && props.definedData[item.ID]['model'] && props.definedData[item.ID]['model']['region'] && (props.definedData[item.ID]['model']['region'].filter(item => Object.values(item).join("_") === Object.values(child.relationship).join("_")).length == 1)) {
                                    //     clr = props.colorPicker && props.colorPicker[item.ID];
                                    //     sticker = item

                                    // }
                                }
                            });
                            return (
                                <Button className={classes.locButton} style={{ backgroundColor: clr }} onClick={(e) => handleDelete(data, child, sticker)}>
                                    {/* <DeleteOutlined /> */}
                                </Button>
                            )
                            //}
                            // <Button className={classes.locButton} style={{ backgroundColor: clr }}></Button>


                        })}
                </div>))
    }
    //     )


    // }


    return (
        <Grid container spacing={2} style={{ width: 'fit-content',/* maxHeight: 500, overflowY: 'scroll',*/display: 'flex', flexDirection: 'column', justifyContent: 'flex_start', marginTop: 0, position: 'sticky' }}>

            <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: 'white', minWidth: '250px', justifyContent: 'center' }}>
                </div>
                <div style={{ display: 'flex', width: '100%', position: '-webkit-sticky', position: 'sticky', paddingLeft: 2, top: '10px' }}>

                    {renderStates(null, 'head')}
                </div>
            </div>
            <div style={{ maxHeight: 600, overflowY: 'scroll', paddingLeft: 2, top: '10px' }}>
                {data && data.length > 0 &&
                    renderNestedItems(data.filter((item, index) => item.category == props.modelYear))
                }
            </div>{console.log(defaultExpanded)}
            {onDelete && selectedSticker &&
                <DeleteDialog onChange={handleDeletedData} color={selectedSticker && props.colorPicker ? props.colorPicker[selectedSticker['ID']] : ''}
                    sticker={selectedSticker ? (selectedSticker.promo_variability ? 'PV -' : '') + (selectedSticker.version > 1 ? `V${selectedSticker.version} - ${selectedSticker.sticker_description}` : selectedSticker.sticker_description) : ''}
                    model={selectedModel?.category} location={selectedLoc?.category} open={onDelete} />

            }

        </Grid>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        // getFilterData: (table, data) => dispatch(getModels(table, data))
    }
}

const mapStateToProps = state => {
    return {
        //modelData: state.promotionData.modelList,
        //  locationData: state.promotionData.locationFilters,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PromoRegion);
