import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField,
    OutlinedInput, Grid, Button, FormLabel, MenuItem, Select
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ArrowLeft, AddCommentOutlined, Circle, ErrorOutlineOutlined, Close } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getMaterialFilters } from '../../../../redux/actions';
import { MultiSelectDropdown } from '../../../../components/Inputs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: "1px solid #EEEEEE",
        borderRadius: 5,
        // padding: '0px 16px',
        paddingBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: 15,
        borderBottom: '1px solid #EEEEEE'
        // backgroundColor: '#E8E8E8'
    },
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    gridContainer: {
        padding: '15px 16px 0px 16px',
        justifyContent: 'flex-start'
        // display: 'flex'
    },
    subContainer: {
        // padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        marginTop: 35,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    stickerLabel: {
        textTransform: 'capitalize',
    },
    header: {
        fontSize: theme.typography.h7.fontSize,
        marginRight: 15,
        color: theme.palette.text.grey,
        paddingLeft: 0,
        whiteSpace: 'wrap'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    contentSection: {
        marginRight: -20
    },
    navStatusContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '25%'
    },
    searchBox: {
        backgroundColor: theme.palette.white
    },
    toggleButton: {
        //marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        // height: 34,
        //width: 61,
        //display: 'flex',
        alignItems: 'center',
    },
    country: {
        width: 150,
    },
}));
const StickerHeader = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([{ option: '' }]);
    const [productLine, setProductLine] = React.useState([]);
    const [country, setCountry] = React.useState('USA');
    const [enableDesc, setEnableDesc] = React.useState(false);
    const [externalDesc, setExternalDesc] = React.useState('');
    const [stickerDesc, setStickerDesc] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [status, setStatus] = React.useState('pending');
    const [promoVariability, setPromoVariability] = React.useState(false);

    useEffect(() => {
        props.getFilters()
    }, []);
    useEffect(() => {
        setStickerDesc(props.stickerName)
    }, [props.stickerName]);

    useEffect(() => {
        setChecked(props.country && props.country == 'CA' ? true : false)
        if (props.country)
            setCountry(props.country)
    }, [props.country]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);
    useEffect(() => {
        if (props.data) {
            setStickerDesc(props.data.sticker_description)
            setExternalDesc(props.data.external_description)
            setCountry(props.data.country)
            setChecked(props.data.country && props.data.country == 'CA' ? true : false)
            setProductLine(props.data.product_lines)
            setPromoVariability(props.data.promo_variability)
        }
    }, [props.data])
    useEffect(() => {
        var data = {
            "product_lines": productLine,
            "sticker_description": stickerDesc,
            "external_description": externalDesc,
            "country": country,
            "promo_variability": promoVariability
        }
        props.onChange(data)
    }, [productLine, stickerDesc, country, promoVariability])

    function handleChange(value) {
        if (value && value.length > 0)
            setProductLine(value)
        else
            setProductLine([])
    }
    function handlePromoVariablility(e) {
        setPromoVariability(e.target.checked)
    }
    function handleReturn() {
        console.log("f" + props.page)
        if (props.page == 'Scenario')
            history.push({ pathname: "/promotion-scenario-setup", data: { page: 'Scenario' } });
        else
            history.push('/promotion-sticker-setup')
    }
    function handleOnAdd() {
        setEnableDesc(true)
    }

    function handleOnDelete() {
        setEnableDesc(false);
    }
    function handleCountry(event) {
        // setChecked(event.target.checked)
        // if (event.target.checked)
        // setCountry('CA')
        // else
        setCountry(event.target.value)

    }

    function handleSave() {
        props.onSave();
    }

    function handleStatus(event) {
        setStatus(event.target.value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={classes.navStatusContainer}>
                    <Button variant="outlined" startIcon={<ArrowLeft />} onClick={handleReturn}>
                        Return
                    </Button>
                    <Typography variant='h5' color='black' style={{ marginTop: 3 }}>
                        <Circle style={{ marginLeft: 15, height: 10, color: 'red' }} />
                        In Progress
                    </Typography>
                </div>
                <div className={classes.country}>
                    <Select
                        id={'selected-country'}
                        value={country}
                        onChange={handleCountry}
                        style={{ backgroundColor: 'white', borderRadius: 5 }}
                    >
                        <MenuItem value={'USA'}>USA</MenuItem>
                        <MenuItem value={'CA'}>CANADA</MenuItem>
                    </Select>
                </div>
                {/* <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant='h4' color='black'>USA</Typography>
                    <Switch sx={{ m: 1 }} checked={checked} className={classes.toggleButton}
                        onChange={handleCountry} />
                    <Typography variant='h4' color='black'>CANADA</Typography>
                </Stack> */}
            </div>
            <Grid container spacing={1}
                style={{ padding: '15px 0px 0px 30px' }}
            >

                <Grid item md={1.7} xs={12} spacing={1} style={{ marginTop: -12 }} className={classes.subContainer}>
                    <FormLabel className={classes.header}>Product Line </FormLabel>
                    <MultiSelectDropdown btn={true} onChange={handleChange} data={productLine} listArray={productLinesList} style={{ marginTop: -18 }} />
                </Grid>

                <Grid item md={6} xs={12} spacing={1} style={{ marginTop: -12 }} className={classes.subContainer}>
                    <FormLabel className={classes.header} style={{ whiteSpace: 'wrap', display: 'flex' }}>STICKER NAME
                        <Typography variant='h5' color='grey'>( This field is automatically fill out while building the sticker )</Typography>
                    </FormLabel>
                    <OutlinedInput
                        value={stickerDesc}
                        onChange={(event) => setStickerDesc(event.target.value)}
                        style={{ width: '100%' }}
                        readOnly
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleOnAdd}
                                    edge="end"
                                >
                                    <AddCommentOutlined />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
                <Grid item md={3.8} xs={12} style={{ marginTop: 17 }} className={classes.subContainer}>
                    <div >
                        <FormControlLabel
                            //value={promoVariability}
                            control={<Checkbox checked={promoVariability}
                                onChange={handlePromoVariablility} />}
                            label="Promo Variability"
                            labelPlacement="end"
                        />
                    </div>
                </Grid>
                {enableDesc &&
                    <>
                        <Grid item md={1.7} xs={12}>
                        </Grid>
                        <Grid item md={6} xs={12} spacing={1} className={classes.subContainer}>
                            <FormLabel className={classes.header} style={{ whiteSpace: 'wrap' }}>EXTERNAL STICKER DESCRIPTION</FormLabel>
                            <OutlinedInput
                                value={externalDesc}
                                onChange={(event) => setExternalDesc(event.target.value)}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={handleOnDelete}
                                size="large">
                                <Close style={{ fontSize: 20 }} />
                            </IconButton>
                        </Grid>
                    </>
                }
                <Grid item md={11.8} xs={12} spacing={1} className={classes.subContainer}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            <ErrorOutlineOutlined /> <Typography variant='h5' color='black'>You are currently creating sticker {productLine && productLine.length > 0 ? 'for' : ''} {productLine && productLine.join(', ').replace(/,([^,]*)$/, ' and$1')} in {country && country == 'CA' ? 'CANADA' : 'USA'}</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button style={{ height: 35 }} variant="outlined" >Cancel</Button>
                            <Button style={{ marginLeft: 10, height: 35 }} variant="contained" disabled={productLine && productLine.length > 0 ? false : true}
                                onClick={handleSave}
                            >Create Sticker</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {/* </Grid> */}
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        filterData: state.promotionData.promoFilters,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerHeader);