import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    Button, OutlinedInput, Checkbox, ListItemText,
    Dialog, DialogContent, DialogContentText, DialogActions, TextField
} from '@mui/material';
import {
    getCompareRebateCalculationData, getDefaultValues, getDefaultValuesAllData, allowedApps, getQueryRefById, getAttributeListData,
    updateQueryRefData, getLblDispDesFieldValue
} from '../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../components/Inputs";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
import {
    AllOtherAttributeMasterComponent,
    MasterDataComponent, SearchDropdown
} from "../../../components/Inputs";
import momentt from "moment-timezone";
import BeatLoader from "react-spinners/BeatLoader";
import DynamicQueryFormRef from '../../DynamicQuery/DynamicQueryFormRef';
import FilterDialoge from '../../DynamicQuery/FilterDialoge';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10,
        marginTop: '-1rem'
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    leftGridContainer: {
        paddingRight: 10
    },
    calendarLeftGridContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '0px 10px 0px 10px'
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    submitContainer: {
        marginTop: 15
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dropdownSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 15
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        width: "100%"
        // marginBottom: 14
    },
    textInputHeight: {
        height: 17
    },
    error: {
        border: '1px solid red !important'
    },
    spinner: {
        height: '90vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    button: {
        marginRight: 25,
        width: 160
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    select: {
        width: "100%",
        backgroundColor: "white",
    },
    selectedItem: {
        borderRadius: 5,
        textTransform: "capitalize",
    },
    gridContainer: {
        padding: "0px 5px 10px 10px",
    },
    gridContainerOuter: {
        padding: "0px 0px 0px 0px",
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        height: '36px'
    },
}));

const CompareRebateCalculationTab = props => {
    const classes = useStyles();
    const { className } = props;
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application') != 'Audit Lab' ? sessionStorage.getItem('application') : '');
    const accrualFrequencyArray = ['Daily', 'Weekly', 'Monthly'];
    const paymentFrequencyArray = ['Weekly', 'Semi-Monthly', 'Monthly', 'Quarterly (Jan - Mar)', 'Quarterly (Feb - Apr)', 'Quarterly (Mar - May)', 'Annual (Jan - Dec)', 'Annual (Feb - Jan)', 'Annual (Mar - Feb)', 'Annual (Apr - Mar)', 'Annual (May - Apr)', 'Annual (Jun - May)', 'Annual (Jul - Jun)', 'Annual (Aug - Jul)', 'Annual (Sep - Aug)', 'Annual (Oct - Sep)', 'Annual (Nov - Oct)', 'Annual (Dec - Nov)'];
    const history = useHistory();
    const currentQueryID = "R010"
    const [filterID, setFilterID] = React.useState('');
    const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState([]);
    const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState("");
    const sortOrderObj = [
        { "label_name": "Contract Number", "sort_field": "contract_number" },
        { "label_name": "Start Date", "sort_field": "start_date" },
        { "label_name": "End Date", "sort_field": "end_date" },
        { "label_name": "Calculation Simulation Number", "sort_field": "calculation_simulation_number" },
        { "label_name": "Financial Posting Number", "sort_field": "posting_number" },

    ];
    const [numericFields, setNumericFields] = React.useState([]);
    const [dateFields, setDateFields] = React.useState(["start_date", "end_date"]);
    const [otherFields, setOtherFields] = React.useState(["calculation_simulation_number", "posting_number"]);
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [listArray, setListArray] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [SelectOpen, setSelectOpen] = useState(false);
    const [contractType, setContractType] = useState([]);
    const [postingType, setPostingType] = useState([]);
    const [postingSchema, setPostingSchema] = useState([]);
    const [contractGroup, setContractGroup] = useState([]);
    const [contractSubgroup, setContractSubgroup] = useState([]);
    const [status, setStatus] = React.useState(false);
    const [level, setLevel] = React.useState("");
    const [optionsList, setOptionsList] = React.useState([]);
    const [contractData, setContractData] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [description, setDescription] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [filterName, setFilterName] = React.useState("");
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openConfirmEmail, setOpenConfirmEmail] = React.useState(false);
    const [emailId, setEmailId] = React.useState(sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).email : '');
    const [currentPage, setCurrentPage] = React.useState("R010");
    const postingTypeArray = [
        "Accrual",
        "Accrual Reversal",
        "Delta Accrual",
    ];
    const calculationTypeArray = ["Tier", "Rebate"];
    const rangeFields = ['contract_number', 'posting_date']
    const options = ["range", "equal", "not equal", "pattern"];
    const [trigger, setTigger] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 180,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.onLoadingDefaultDropdown(applicationType);
    }, [applicationType]);
    useEffect(() => {
        props.onLoadingDefault();
        props.onLoadingDefaultDropdown();
        props.getDefaultValuesAllData();
    }, []);
    var dataType = history.location.type;
    useEffect(() => {
        if (dataType == 'email')
            setOpenConfirmEmail(true)
    }, [dataType])
    useEffect(() => {
        props.onLoadingDefault();
        props.onLoadingDefaultDropdown();
        props.getDefaultValuesAllData();
        props.getAttributeListData({ attribute_name: "" });
        props.getLblDispDesFieldValues({ "data_source": "contract_header", "application_type": sessionStorage.getItem('application') }, 'post')
    }, []);

    useEffect(() => {
        if (
            props.dropdownData && props.dropdownData.field_label_attributes) {
            setContractData(props.dropdownData.field_label_attributes);
        }
    }, [props.dropdownData]);

    useEffect(() => {
        if (props.attributeData && props.attributeData.length > 0)
            setAttributeData(props.attributeData);
    }, [props.attributeData]);
    useEffect(() => {
        var temp;
        var range
        if (props.predefinedFilter && props.predefinedFilter.filter_fields && filterName === props.predefinedFilter.filter_name) {
            setDynamicFieldValuesFormSubmit(props.predefinedFilter.filter_fields)
            setFilterName(props.predefinedFilter.filter_name)
            Object.values(props.predefinedFilter.filter_fields).map((item, i) => {
                temp = { ...temp, [item.filter_name]: item.filter_value && item.filter_value.from ? item.filter_value.from : item.filter_value }
                if (item.filter_value && item.filter_value.to)
                    range = { ...range, [item.filter_name]: item.filter_value.to }
            })
            setDynamicFieldValues(temp)
            setDynamicFieldValuesRange(range)
        }
        else {
            setDynamicFieldValues({})
            setDynamicFieldValuesFormSubmit({})
            setDynamicFieldValuesRange({})
        }
    }, [props.predefinedFilter])
    const handleFilter = (item) => {
        setFilterID(item.id)
        setFilterName(item.filter_name)
        props.getPredefinedFilter(item.id)
    }
    const handleOpen = (item, type) => {
        if (type == "select_box") {
            setSelectOpen(true);
            setLevel(item);
            if (item === "contract_type") {
                const contractTypeObj = contractData.contract_type;
                const dropDownValues = contractTypeObj.drop_down_values || [];
                setListArray(dropDownValues);

                setSelected(contractType);
                setAllSelect(
                    contractData.hasOwnProperty("contract_type") &&
                    contractType.length === dropDownValues.length
                );
            }
            else if (item === "posting_schema") {
                const contractTypeObj = contractData.posting_schema;
                // const dropDownValues = contractTypeObj.drop_down_values || [];
                const dropDownValues = contractTypeObj.drop_down_reference_value.map(option => option.key) || [];
                setListArray(dropDownValues);

                setSelected(postingSchema);
                setAllSelect(
                    contractData.hasOwnProperty("posting_schema") &&
                    postingSchema.length === dropDownValues.length
                );
            }
            else if (item === "contract_group") {
                const contractTypeObj = contractData.contract_group;
                const dropDownValues = contractTypeObj.drop_down_values || [];
                setListArray(dropDownValues);

                setSelected(contractGroup);
                setAllSelect(
                    contractData.hasOwnProperty("contract_group") &&
                    contractGroup.length === dropDownValues.length
                );
            }
            else if (item === "contract_sub_group") {
                const contractTypeObj = contractData.contract_sub_group;
                const dropDownValues = contractTypeObj.drop_down_values || [];
                setListArray(dropDownValues);

                setSelected(contractSubgroup);
                setAllSelect(
                    contractData.hasOwnProperty("contract_sub_group") &&
                    contractSubgroup.length === dropDownValues.length
                );
            }
            // setSelected(contractType);
            // setListArray(
            //   contractData.filter((item) => item.field_id == "contract_type")[0][
            //   "drop_down_value_keys"
            //   ]
            // );
            // setAllSelect(
            //   contractData.filter((item) => item.field_id == "contract_type") &&
            //   contractData
            //     .filter((item) => item.field_id == "contract_type")
            //     .map((e) => e.drop_down_value_keys).length > 0 &&
            //   contractType.length ===
            //   contractData
            //     .filter((item) => item.field_id == "contract_type")
            //     .map((e) => e.drop_down_value_keys).length
            // );

            else if (item === "posting_type") {
                setListArray(postingTypeArray);
                setSelected(postingType);
                setAllSelect(
                    contractType.length === postingTypeArray.length
                );
            }
        } else {
            if (item === "contract_number") {
                setOptionsList(contractNumbersList);
                setInternalDescriptionArray(description);
            }
        }
    };
    // console.log("123", listArray)
    const handleBillingDate = (e, type, fieldName) => {
        if (type == "start") {
            setBillingDateStart(e);
            setDynamicFieldValues({
                ...dynamicFieldValues,
                [fieldName]: Moment(e).format("YYYY-MM-DD"),
            });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = Moment(e).format("YYYY-MM-DD")
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: Moment(e).format("YYYY-MM-DD")
                    }
                });
            }
        } else {
            setBillingDateEnd(e);
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: dynamicFieldValues[fieldName], to: Moment(e).format("YYYY-MM-DD") },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        }
    };
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        if (value === "all") {
            if (level === "posting_type") {
                setSelected(
                    selected.length === postingTypeArray.length ? [] : postingTypeArray
                );
                setAllSelect(
                    selected.length === postingTypeArray.length ? false : true
                );
            } else if (level === "calculation_type") {
                setSelected(
                    selected.length === calculationTypeArray.length
                        ? []
                        : calculationTypeArray
                );
                setAllSelect(
                    selected.length === calculationTypeArray.length ? false : true
                );
            } else {
                setSelected(selected.length === listArray.length ? [] : listArray);
                setAllSelect(selected.length === listArray.length ? false : true);
            }
        } else {
            setSelected(newSelected);
            setAllSelect(newSelected.length === listArray.length ? true : false);
        }
    };
    function onAdd() {
        setSelectOpen(false);
        if (level == "contract_type") {
            setContractType(selected);
        }
        else if (level == "posting_type") {
            setPostingType(selected);
        }
        else if (level == "posting_schema") {
            setPostingSchema(selected);
        }
        else if (level == "contract_group") {
            setContractGroup(selected);
        }
        else if (level == "contract_sub_group") {
            setContractSubgroup(selected);
        }
        setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            //[level]: selected,
            [level]: {
                ...dynamicFieldValuesFormSubmit[level],
                filter_name: level,
                filter_value: selected
            }
        });
    }
    function onCancel() {
        setSelectOpen(false);
        setSelected([]);
        setLevel("");
    }
    const handleDynamicFieldValues = (e, fieldName, type, page) => {
        if (!dynamicFieldValuesFormSubmit[fieldName]) {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: { filter_name: fieldName },
            });
        }
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    var value = page == 'master' ? e.key : e
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: value });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: {
                                from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName]) : dynamicFieldValues[fieldName],
                                to: numericFields.includes(fieldName) ? Number(value) : value.toString()
                            },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: '' });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                // setDynamicFieldValuesRange("");
            }
        } else if (type === "option") {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    filter_name: fieldName,
                    filter_option: e,
                },
            });
        } else {
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(e) : e
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? e.map(Number) : e,
                    },
                });
            }
        }
    };

    const handleDynamicFieldValuesAdd = (e, fieldName, type, ix) => {
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_name: fieldName,
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        } else {
            var temp = dynamicFieldValues[fieldName]
                ? dynamicFieldValues[fieldName]
                : [];
            if (type === "pastedItem") {
                e.map((arg) => {
                    if (arg) temp.push(arg.trim());
                });
            } else {
                // temp.push(e);
                temp = e;
            }
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(temp) : temp
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
                    },
                });
            }
        }
    };
    const handleDynamicFieldValuesDelete = (e, fieldName, type) => {
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        } else {
            var temp = dynamicFieldValues[fieldName].filter((item) => item !== e);
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
                },
            });
        }
    };
    const handleFilterdata = () => {
        if (dynamicFieldValuesFormSubmit) {
            for (var propName in dynamicFieldValuesFormSubmit) {
                if (!dynamicFieldValuesFormSubmit[propName]['filter_value'] || dynamicFieldValuesFormSubmit[propName]['filter_value'] == '' ||
                    dynamicFieldValuesFormSubmit[propName]['filter_value'].length == 0 || !dynamicFieldValuesFormSubmit[propName]['filter_option'] ||
                    dynamicFieldValuesFormSubmit[propName]['filter_option' == ''])
                    delete dynamicFieldValuesFormSubmit[propName]
            }
        }
    }
    const handleSearch = (type) => {
        if (type === 'Save Search') {
            setOpenFilter(true)
        }
        if (type === 'Save Search & Run') {
            setOpenFilter(true)
            setTigger(true)
        }
    }
    const handleSaveAndRun = (name, type) => {

        setOpenFilter(false)
        if (dynamicFieldValuesFormSubmit) {
            handleFilterdata()
            var formData = {
                query_id: "R010",
                filter_name: name,
                filter_fields: dynamicFieldValuesFormSubmit,
            };
            if (type == 'edit')
                formData['ID'] = filterID
            props.saveAndRun(type, formData, currentPage, dataType);
            if (trigger) {
                props.onSubmit({ "filter_fields": dynamicFieldValuesFormSubmit });
            }
            localStorage.setItem('accrualVsPaymentTab', JSON.stringify({ "filter_fields": dynamicFieldValuesFormSubmit }))
        }

    }
    const onSubmitHandler = () => {
        const filterFieldsIMA = { ...dynamicFieldValuesFormSubmit };
        const filterFieldsExternal = { ...dynamicFieldValuesFormSubmit };
        delete filterFieldsIMA.posting_number;
        delete filterFieldsExternal.calculation_simulation_number;

        localStorage.setItem('compareRebateCalculationDatafilter', JSON.stringify({ "filter_fields_ima": filterFieldsIMA, "filter_fields_external": filterFieldsExternal }));
        props.onSubmit({ "filter_fields_ima": filterFieldsIMA, "filter_fields_external": filterFieldsExternal });
    }

    const handleClear = () => {
        setDynamicFieldValuesFormSubmit([])
        setDynamicFieldValues([])

    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ textTransform: 'capitalize', marginLeft: '1rem' }}>Compare Rebate Calculation</Typography>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.container} style={{ width: '80%' }}>

                            <Grid container>
                                {sortOrderObj &&
                                    sortOrderObj.length > 0 &&
                                    sortOrderObj
                                        .map((item, ix) => {
                                            return <>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainerOuter }}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >

                                                        <FormLabel classes={{ root: classes.fontSetting }} required={ix === 0 ? false : ix === 3 ? false : true}
                                                            style={{
                                                                // position: 'absolute',
                                                                // top: '50%',
                                                                // left: '25%',
                                                                // transform: 'translate(-50%, -50%)'
                                                                marginTop: ix === 0 ? '0.7rem' : '-1.3rem'
                                                            }}>
                                                            <Typography variant="h3" color='primary' className={ix == 0 && classes.fontSetup} style={{ marginTop: '-0.6rem', width: '14.5rem' }}>{ix == 0 && "Filter Name"}</Typography>
                                                            {
                                                                ix == 0 ? <div style={{ marginTop: '10px' }} > {item.label_name} <span style={{ color: 'red' }}>*</span> </div> : ix == 3 ? <div > {item.label_name} <span style={{ color: 'red' }}>*</span> </div> : item.label_name
                                                            }
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <FormLabel
                                                            classes={{ root: classes.fontSetting }}
                                                        >
                                                            <Typography variant="h3" color='primary' style={{ width: '10.5rem' }} className={ix == 0 && classes.fontSetup}>{ix === 0 && "Option"}</Typography>
                                                        </FormLabel>
                                                        <Select
                                                            value={
                                                                dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                ] &&
                                                                    dynamicFieldValuesFormSubmit[item.sort_field][
                                                                    "filter_option"
                                                                    ]
                                                                    ? dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"]
                                                                    : ""
                                                            }
                                                            onChange={(e) =>
                                                                handleDynamicFieldValues(
                                                                    e.target.value,
                                                                    item.sort_field,
                                                                    "option"
                                                                )
                                                            }
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            style={{ marginTop: ix === 0 ? '0px' : '-21px', textTransform: 'capitalize' }}
                                                            className={clsx({
                                                                [classes.select]: true,
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem,
                                                            }}
                                                            disabled={(item.sort_field == "customer_number" || item.sort_field == "supplier_number" || item.sort_field == "employee_number") ? status : false}
                                                        >
                                                            <MenuItem value=""></MenuItem>
                                                            {options &&
                                                                options
                                                                    .filter(itemX => (numericFields.includes(item.sort_field) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                                        ((!numericFields.includes(item.sort_field) && !rangeFields.includes(item.sort_field)) ? itemX != 'range' : itemX)
                                                                        && (dateFields.includes(item.sort_field) ? (itemX != 'pattern' && itemX != 'not equal') : itemX) && (otherFields.includes(item.sort_field) ? (itemX != 'pattern' && itemX != 'not equal') : itemX))
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={item}
                                                                                key={item}
                                                                                style={{ textTransform: "capitalize" }}
                                                                            >
                                                                                {item}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                        </Select>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={7}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <FormLabel
                                                            classes={{ root: classes.fontSetting }}
                                                        >
                                                            <Typography variant="h3" color='primary' style={{ width: '24.5rem' }} className={ix == 0 && classes.fontSetup}>{ix == 0 && "Filter Value"}</Typography>
                                                        </FormLabel>
                                                        {dateFields.includes(item.sort_field) ? (
                                                            <div style={{ display: "flex", marginTop: "-1.4rem" }}>
                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        // padding: '0px 11px 0px 11px',
                                                                                        alignSelf: "center",
                                                                                        alignItems: "center",
                                                                                        border: "1px solid #E0E0E0",
                                                                                        width: "100%",
                                                                                        height: 36,
                                                                                        // borderRadius: 5,
                                                                                        // color: "#1675e0",
                                                                                    },
                                                                                }}
                                                                                value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : null}
                                                                                onChange={(e) =>
                                                                                    handleBillingDate(e, "start", item.sort_field)
                                                                                }
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                                {dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                ] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"] == "range" &&
                                                                    <div style={{ width: "40%", marginLeft: 5 }}>
                                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                            <Grid container justifyContent="space-around">
                                                                                <DatePicker
                                                                                    disableToolbar
                                                                                    clearable
                                                                                    InputProps={{
                                                                                        padding: 0,
                                                                                        disableUnderline: true,
                                                                                        style: {
                                                                                            // padding: '0px 11px 0px 11px',
                                                                                            alignSelf: "center",
                                                                                            alignItems: "center",
                                                                                            border: "1px solid #E0E0E0",
                                                                                            width: "100%",
                                                                                            height: 36,
                                                                                            // borderRadius: 5,
                                                                                            //color: "#1675e0",
                                                                                        },
                                                                                    }}
                                                                                    value={dynamicFieldValuesFormSubmit[item.sort_field] && dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']
                                                                                        && dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']['to'] ? dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']['to'] : null}
                                                                                    onChange={(e) =>
                                                                                        handleBillingDate(e, "end", item.sort_field)
                                                                                    }
                                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                    format="MM/DD/YYYY"
                                                                                    disabled={
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] == "range"
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </LocalizationProvider>
                                                                    </div>}
                                                            </div>
                                                        ) :
                                                            item.sort_field === 'posting_type' || item.sort_field === 'posting_schema' || item.sort_field === 'calculation_type' || item.sort_field === 'contract_type' || item.sort_field === 'contract_group' || item.sort_field === 'contract_sub_group' ?
                                                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                    {dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                    ] &&
                                                                        dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                        ]["filter_option"] &&
                                                                        dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                        ]["filter_option"] == "pattern" ?
                                                                        <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                            <ChipInput data={dynamicFieldValues[item.sort_field]
                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />

                                                                        </div>
                                                                        : <Select
                                                                            labelId="demo-mutiple-name-label"
                                                                            id="demo-mutiple-name"
                                                                            multiple
                                                                            value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                            style={{ maxHeight: "50px", width: '60%', height: 36, textTransform: 'capitalize' }}
                                                                            input={<OutlinedInput />}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            onOpen={() => handleOpen(item.sort_field, 'select_box')}
                                                                            onClose={() => setSelectOpen(false)}
                                                                            open={SelectOpen && level == item.sort_field}
                                                                            renderValue={(appType) => appType.join(", ")}
                                                                            className={classes.multiSelect}

                                                                        >
                                                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                                                <Checkbox
                                                                                    color='primary'
                                                                                    checked={allSelect} />
                                                                                <ListItemText primary='Select All' />
                                                                            </MenuItem>
                                                                            {listArray && listArray.length > 0 && listArray
                                                                                .map(item => {
                                                                                    return (
                                                                                        <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                                            <Checkbox
                                                                                                color='primary'
                                                                                                checked={selected.indexOf(item) > -1} />
                                                                                            <ListItemText primary={item} />
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}

                                                                            <div className={classes.dropdownAction}>
                                                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                                                    Cancel
                                                                                </Button>

                                                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </Select>}
                                                                </div>
                                                                :
                                                                item.sort_field === "class_of_trade" ||
                                                                    item.sort_field === "customer_group" ||
                                                                    item.sort_field === "customer_chain" ||
                                                                    item.sort_field === "material_group" ||
                                                                    item.sort_field === "product_hierarchy" ||
                                                                    item.sort_field === "membership_owner_id" ? (
                                                                    <div style={{ display: "flex", marginTop: '-10px' }}>
                                                                        <div style={{ width: "60%", marginRight: 5 }}>
                                                                            <AllOtherAttributeMasterComponent
                                                                                attributeName={item.sort_field}
                                                                                multiple
                                                                                withOutLabel
                                                                                prevalue={
                                                                                    dynamicFieldValues[item.sort_field]
                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                        : []
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleDynamicFieldValues(
                                                                                        e,
                                                                                        item.sort_field
                                                                                    )
                                                                                }
                                                                                id={item.sort_field}
                                                                            />
                                                                        </div>
                                                                        {dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                        ] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                            ]["filter_option"] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                            ]["filter_option"] == "range" &&
                                                                            <div style={{ width: "40%" }}>
                                                                                <AllOtherAttributeMasterComponent
                                                                                    attributeName={item.sort_field}
                                                                                    withOutLabel
                                                                                    prevalue={
                                                                                        dynamicFieldValues[item.sort_field]
                                                                                            ? dynamicFieldValues[item.sort_field]
                                                                                            : []
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDynamicFieldValues(
                                                                                            e,
                                                                                            item.sort_field,
                                                                                            "range"
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] == "range"
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                    id={item.sort_field}
                                                                                />
                                                                            </div>}
                                                                    </div>
                                                                )
                                                                    :
                                                                    item.sort_field === "contract_number" ||
                                                                        (item.sort_field === "material_number" ||
                                                                            item.sort_field === "customer_number" ||
                                                                            item.sort_field === "employee_number" ||
                                                                            item.sort_field === "supplier_number") &&
                                                                        activeDropdown &&
                                                                        activeDropdown[item.sort_field] ? (
                                                                        <div style={{ display: "flex", marginTop: '-0.2rem' }}>
                                                                            <div style={{ width: "60%", marginRight: 5, height: '1rem' }}>
                                                                                {dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] == "range" ?
                                                                                    <SearchDropdown id='contract_number_multiple' //multiple
                                                                                        table='contract_header' keyName='contract_number'
                                                                                        prevalue={
                                                                                            dynamicFieldValues[item.sort_field]
                                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                                : ''
                                                                                        }
                                                                                        description={true} //heading='Contract Number'
                                                                                        onChange={(e, newValue) =>
                                                                                            handleDynamicFieldValues(
                                                                                                e,
                                                                                                item.sort_field
                                                                                            )
                                                                                        }
                                                                                        applicationType={sessionStorage.getItem("application")}
                                                                                    />
                                                                                    : dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] == "pattern" ?
                                                                                        <div style={{ marginRight: 5, marginTop: "3px", width: "100%" }}>
                                                                                            <ChipInput data={dynamicFieldValues[item.sort_field]
                                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                                : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />

                                                                                        </div>

                                                                                        :
                                                                                        <SearchDropdown id='contract_number_multiple' multiple
                                                                                            table='contract_header' keyName='contract_number'
                                                                                            prevalue={
                                                                                                dynamicFieldValues[item.sort_field]
                                                                                                    ? dynamicFieldValues[item.sort_field]
                                                                                                    : []
                                                                                            }
                                                                                            description={true} //heading='Contract Number'
                                                                                            onChange={(e, newValue) =>
                                                                                                handleDynamicFieldValues(
                                                                                                    e,
                                                                                                    item.sort_field
                                                                                                )
                                                                                            }
                                                                                            applicationType={sessionStorage.getItem("application")}
                                                                                        />}
                                                                            </div>
                                                                            {dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                            ] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                                ]["filter_option"] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                                ]["filter_option"] == "range" ?
                                                                                <div style={{ width: "40%", marginRight: 5, marginTop: '6px' }}>
                                                                                    {item.sort_field == 'contract_number' ?
                                                                                        <div style={{ width: "100%", marginTop: '-6px' }}>
                                                                                            <SearchDropdown id='contract_number_multiple'
                                                                                                prevalue={dynamicFieldValuesRange &&
                                                                                                    dynamicFieldValuesRange[item.sort_field]
                                                                                                    ? dynamicFieldValuesRange[item.sort_field]
                                                                                                    : ''
                                                                                                }
                                                                                                table='contract_header' keyName='contract_number'
                                                                                                description={true} //heading='Contract Number'
                                                                                                onChange={(e) =>
                                                                                                    handleDynamicFieldValues(e, item.sort_field, 'range')
                                                                                                }
                                                                                                applicationType={sessionStorage.getItem("application")}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <div style={{ width: "100%" }}>
                                                                                            <MasterDataComponent attributeName={item.sort_field} id={item.sort_field} withOutLabel
                                                                                                onChange={(e) =>
                                                                                                    handleDynamicFieldValues(e, item.sort_field, 'range', 'master')
                                                                                                }
                                                                                                prevalue={{
                                                                                                    'key': dynamicFieldValuesRange[item.sort_field]
                                                                                                        ? dynamicFieldValuesRange[item.sort_field]
                                                                                                        : '', 'desc': ''
                                                                                                }}
                                                                                                disabled={
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                        item.sort_field
                                                                                                    ] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.sort_field
                                                                                                        ]["filter_option"] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.sort_field
                                                                                                        ]["filter_option"] == "range"
                                                                                                        ? false
                                                                                                        : true
                                                                                                }
                                                                                                style={{ paddingTop: -10 }}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </div> : ''}
                                                                        </div>
                                                                    )
                                                                        : item.sort_field === "accrual_frequency" ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                                {dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] == "pattern" ?
                                                                                    <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                        <ChipInput data={dynamicFieldValues[item.sort_field]
                                                                                            ? dynamicFieldValues[item.sort_field]
                                                                                            : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />

                                                                                    </div>
                                                                                    : <Select
                                                                                        value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                                        style={{ maxHeight: "50px", width: '60%', textTransform: 'capitalize' }}
                                                                                        onChange={(e, newValue) =>
                                                                                            handleDynamicFieldValues(
                                                                                                [e.target.value],
                                                                                                item.sort_field
                                                                                            )
                                                                                        }
                                                                                        displayEmpty
                                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                        className={classes.multiSelect}

                                                                                    >
                                                                                        {accrualFrequencyArray && accrualFrequencyArray.length > 0 && accrualFrequencyArray
                                                                                            .map(item => {
                                                                                                return (
                                                                                                    <MenuItem
                                                                                                        value={item}
                                                                                                        key={item}
                                                                                                        style={{ textTransform: "capitalize" }}
                                                                                                    >
                                                                                                        {item}
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            })}
                                                                                    </Select>}
                                                                            </div>
                                                                            :
                                                                            item.sort_field === "payment_frequency" ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                                    {dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] == "pattern" ?
                                                                                        <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                            <ChipInput data={dynamicFieldValues[item.sort_field]
                                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                                : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />

                                                                                        </div>
                                                                                        :
                                                                                        <Select
                                                                                            value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                                            style={{ maxHeight: "50px", height: 36, width: '60%', textTransform: 'capitalize' }}
                                                                                            onChange={(e, newValue) =>
                                                                                                handleDynamicFieldValues(
                                                                                                    [e.target.value],
                                                                                                    item.sort_field
                                                                                                )
                                                                                            }
                                                                                            displayEmpty
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                            className={classes.multiSelect}

                                                                                        >
                                                                                            {paymentFrequencyArray && paymentFrequencyArray.length > 0 && paymentFrequencyArray
                                                                                                .map(item => {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            value={item}
                                                                                                            key={item}
                                                                                                            style={{ textTransform: "capitalize" }}
                                                                                                        >
                                                                                                            {item}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                        </Select>}
                                                                                </div>
                                                                                :
                                                                                item.sort_field === "posting_number" || item.sort_field === 'calculation_simulation_number' ?
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                                        {dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] == "pattern" ?
                                                                                            <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                                <ChipInput data={dynamicFieldValues[item.sort_field]
                                                                                                    ? dynamicFieldValues[item.sort_field]
                                                                                                    : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />

                                                                                            </div>
                                                                                            :
                                                                                            <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                                <OutlinedInput
                                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                                    style={{ width: "100%", height: "36px" }}
                                                                                                    type={props.type}
                                                                                                    value={
                                                                                                        dynamicFieldValues[item.sort_field] &&
                                                                                                            dynamicFieldValues[item.sort_field]
                                                                                                                .length > 0
                                                                                                            ? dynamicFieldValuesRange[
                                                                                                            item.sort_field
                                                                                                            ]
                                                                                                            : ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleDynamicFieldValues(
                                                                                                            e.target.value,
                                                                                                            item.sort_field,
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    : (
                                                                                        <div style={{ display: "flex", marginTop: '-10px' }}>
                                                                                            <div style={{ width: "60%", marginRight: 5 }}>
                                                                                                <ChipInput data={dynamicFieldValues[item.sort_field]
                                                                                                    ? dynamicFieldValues[item.sort_field]
                                                                                                    : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} disabled={item.sort_field !== "material_number" || item.sort_field !== "contract_number" ? status : false} />
                                                                                                {/* <ChipInput
                                                classes={{
                                                  root: classes.rootContainer,
                                                  chip: classes.chip,
                                                  input: classes.input,
                                                  inputRoot: classes.inputRoot,
                                                  label: classes.chipLabel,
                                                  chipContainer: classes.chipContainer,
                                                }}
                                                disabled={item.sort_field !== "material_number" || item.sort_field !== "contract_number" ? status : false}
                                                value={
                                                  dynamicFieldValues[item.sort_field]
                                                    ? dynamicFieldValues[item.sort_field]
                                                    : []
                                                }
                                                onAdd={(e) =>
                                                  handleDynamicFieldValuesAdd(
                                                    e,
                                                    item.sort_field,
                                                    null,
                                                    ix
                                                  )
                                                }
                                                onDelete={(e) =>
                                                  handleDynamicFieldValuesDelete(
                                                    e,
                                                    item.sort_field,
                                                    null,
                                                    ix
                                                  )
                                                }
                                                onPaste={(event) => {
                                                  const clipboardText = event.clipboardData
                                                    .getData("Text")
                                                    .split("\n");

                                                  event.preventDefault();
                                                  handleDynamicFieldValuesAdd(
                                                    clipboardText,
                                                    item.sort_field,
                                                    "pastedItem",
                                                    ix
                                                  );
                                                  if (props.onPaste) {
                                                    props.onPaste(event);
                                                  }
                                                }}
                                                disableUnderline={true}
                                                blurBehavior="add"
                                              /> */}
                                                                                            </div>
                                                                                            {dynamicFieldValuesFormSubmit[
                                                                                                item.sort_field
                                                                                            ] &&
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                item.sort_field
                                                                                                ]["filter_option"] &&
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                item.sort_field
                                                                                                ]["filter_option"] == "range" &&
                                                                                                <div style={{ width: "40%", marginRight: 5 }}>
                                                                                                    <OutlinedInput
                                                                                                        classes={{ root: classes.inputTwoLine }}
                                                                                                        type={props.type}
                                                                                                        value={
                                                                                                            dynamicFieldValues[item.sort_field] &&
                                                                                                                dynamicFieldValues[item.sort_field]
                                                                                                                    .length > 0
                                                                                                                ? dynamicFieldValuesRange[
                                                                                                                item.sort_field
                                                                                                                ]
                                                                                                                : ""
                                                                                                        }
                                                                                                        onChange={(e) =>
                                                                                                            handleDynamicFieldValues(
                                                                                                                e.target.value,
                                                                                                                item.sort_field,
                                                                                                                "range"
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={
                                                                                                            dynamicFieldValuesFormSubmit[
                                                                                                                item.sort_field
                                                                                                            ] &&
                                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                                item.sort_field
                                                                                                                ]["filter_option"] &&
                                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                                item.sort_field
                                                                                                                ]["filter_option"] == "range"
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                    />
                                                                                                </div>}
                                                                                        </div>
                                                                                    )}
                                                    </Grid>

                                                </Grid >

                                            </>;
                                        })}
                            </Grid>
                            <div className={classes.buttonRoot}>
                                <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                    Clear
                                </Button>
                                <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler}
                                    disabled={dynamicFieldValuesFormSubmit['contract_number'] && dynamicFieldValuesFormSubmit['posting_number'] && dynamicFieldValuesFormSubmit['start_date'] && dynamicFieldValuesFormSubmit['end_date'] && dynamicFieldValuesFormSubmit['calculation_simulation_number'] ? false : true}>
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Run'
                                    }
                                </Button>
                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleSearch('Save Search')}
                                    twoline="true"
                                    disabled={
                                        !Object.values(dynamicFieldValuesFormSubmit).some(obj =>
                                            Object.keys(obj).length > 0 &&
                                            (
                                                Array.isArray(obj.filter_value) ?
                                                    obj.filter_value.length > 0 :
                                                    obj.filter_value
                                            ) &&
                                            (
                                                obj.filter_option !== "range" ||
                                                (obj.filter_option === "range" && obj.filter_value.from && obj.filter_value.to)
                                            )
                                        )
                                    }
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search'
                                    }
                                </Button> */}
                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleSearch('Save Search & Run')}
                                    twoline="true"
                                    disabled={
                                        !Object.values(dynamicFieldValuesFormSubmit).some(obj =>
                                            Object.keys(obj).length > 0 &&
                                            (
                                                Array.isArray(obj.filter_value) ?
                                                    obj.filter_value.length > 0 :
                                                    obj.filter_value
                                            ) &&
                                            (
                                                obj.filter_option !== "range" ||
                                                (obj.filter_option === "range" && obj.filter_value.from && obj.filter_value.to)
                                            )
                                        )
                                    }
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search & Run'
                                    }
                                </Button> */}
                            </div>
                        </div>
                        {/* <div style={{ width: '20%' }} className={classes.container}>
                            <DynamicQueryFormRef id={currentQueryID} onChange={handleFilter} />
                        </div> */}
                    </div>
                </div>

            </div >
            {
                openFilter &&
                <FilterDialoge open={openFilter} filterName={filterName}
                    action={filterID ? 'edit' : 'create'}
                    onChange={handleSaveAndRun}
                    onCancel={() => setOpenFilter(false)}
                />

            }
            <Dialog open={openConfirmEmail}>
                <DialogContent
                    classes={{
                        root: classes.dialogContent,
                    }}
                >
                    <Typography variant="h4">
                        <DialogContentText>
                            <FormLabel classes={{ root: classes.fontSetting }}>Email Id</FormLabel>
                            <OutlinedInput
                                classes={{ root: classes.inputTwoLine }}
                                type={'email'}
                                value={emailId}
                                onChange={(e) => setEmailId(e.target.value)}
                            />
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialog,
                    }}
                >
                    <Button
                        onClick={() => history.push('/accrual-vs-Payment-reports')}
                        autoFocus
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => setOpenConfirmEmail(false)}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getCompareRebateCalculationData(data)),
        onLoadingDefaultDropdown: (applicationType) => dispatch(getDefaultValues(applicationType, 'Direct - Sales Data', null, null, 'Display Designer')),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        allowedApps: () => dispatch(allowedApps()),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        getPredefinedFilter: (id) => dispatch(getQueryRefById(id)),
        saveAndRun: (type, data, page, dataType) => dispatch(updateQueryRefData(type, data, null, page, dataType, 'true')),
        getLblDispDesFieldValues: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        allowedAppsData: state.initialData.allowedApps,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        attributeData: state.attributeListData.AttributeData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareRebateCalculationTab);