import {
    ADD_MULTIPLE_CONFIGURATION,
    ADD_MULTIPLE_CONFIGURATION_KF,
    ADD_MULTIPLE_CONFIGURATION_AV,
    GET_SALES_BASIS_SUCCESS, ACCESS_DATA_SUCCESS, SECOND_API_CONFIG_SUCCESS, HISTORY_SUCCESS,
    ORGANIZATION_ACCESS_SUCCESS, USER_ROLE_ACCESS_SUCCESS, DEFAULT_VALUES_CALC_SIM,
    DEFAULT_VALUES_FINANCIAL,
    APPROVAL_GROUP_SUCCESS,
    COLLABORATION_PORTAL_SUCCESS,
    GET_FINANCIAL_APPROVAL_SUCCESS, FETCH_START, FETCH_END, FORM_FIELDS_ALL_DATA, FORM_FIELDS_ALL_DATA_NEW, FORM_FIELDS_ALL_DATA_ER,
    TARGET_SOURCE_ATTRIBUTE_ALL_DATA_SA,
    TARGET_SOURCE_ATTRIBUTE_ALL_DATA,
    FORM_FIELDS_ALL_DATA_KF,
    LOAD_FIELDVALUES_ON_APP_TYPE,
    USER_ROLE_SINGLE, ATTRIBUTE_NAME,
    FETCH_START_ORG, FETCH_END_ORG, GET_USER_EMAIL_LINK,
    WORKFLOW_REQUEST_ACCRUAL, WORKFLOW_REQUEST_PAYMENT, ACCESS_DATA_NEW_SUCCESS, KEY_FIGURE_NAME, CONTRACT_TRENDS, CALCULATION_SIMULATION_NUMBERS,
    QUOTA_LEVEL, ORG_DEFAULTS, WORKFLOW_REQUEST_PROMOTION, CLAIM_ERROR_COUNT, APP_RIGHTS, GET_CALCULATION_SUMMARY_SUCCESS, GET_ACC_VS_PAY_SUCCESS, ORG_APP_TITLE_SUCCESS,
    UPLOAD_MASS_FILE, TIER_EVALUATION_LEVEL, TABLES_NAMES_SUCCESS, DEAFULT_VALUES_LBL_DISP, CONTRACT_TRENDS_V2, CONTRACT_DISPLAY, COLLABORATED_DATA, DELETE_MASTER_DATA_SUCCESS, DELETE_MASTER_DATA_FIELD_SUCCESS, DELETE_CALCULATION_SIMULATION_DATA_SUCCESS, CONFIGURATION_DESIGNER_SUCCESS, REPORT_TYPE_FILTER_SUCCESS, REPORT_TYPE_DATA_ALL, REPORT_TYPE_DATA_SINGLE, REPORT_ON_ROLL_ALL, GET_EXTERNAL_ACCURAL_DATA_SUCCESS, GET_EXTERNAL_PAYMENT_DATA_SUCCESS, GET_COMPARE_REBATE_CALCULATION_DATA_SUCCESS,
} from '../../../actions/Configuration/MultipleConfiguration/ActionTypes';

const initialState = {
    multipleConfigurationData: [],
    multipleConfigurationDataKF: [],
    multipleConfigurationDataAV: [],
    salesBasisData: [],
    accessData: [],
    accessDataNew: [],
    orgAppTitleData: [],
    secondApiData: [],
    historyData: [],
    organizationAccessData: [],
    calcSimulationDefaultData: [],
    financialSimulationDefaultData: [],
    approvalGroupData: [],
    collaborationPortalData: [],
    financialApprovalData: [],
    accrualVsPaymentData: [],
    externalAccuralPostingData: [],
    externalPaymentPostingData: [],
    compareRebateCalculationData: [],
    calculationSummaryData: [],
    formFieldsAllData: [],
    formFieldsAllDataER: [],
    sourceAttributeAllDataSa: [],
    sourceAttributeAllData: [],
    formFieldsAllDataCR: [],
    formFieldsAllDataNew: {},
    loadFieldValuesOnAppType: [],
    userSingleRole: [],
    attributeNameData: [],
    keyFigureNameData: [],
    workflowRequestsAccural: [],
    workflowRequestsPayment: [],
    loading: false,
    loadingOrg: false,
    contractTrendsResult: {},
    quotaLevelData: [],
    orgDefaults: {},
    workflowRequestsPromotion: [],
    claimErrorCount: {},
    appRights: {},
    massUploadMessage: [],
    tierEvaluationData: {},
    tableName: [],
    defaultValueLblDisp: [],
    contractTrendsV2Result: {},
    contractDesignerData: [],
    collaboratedData: [],
    deletemasterdata: [],
    deletemasterdatafield: [],
    configdesigner: [],
    reportypefilter: [],
    reportypedataAll: [],
    reportypedataSingle: [],
    reportonrolldataAll: [],

};

const AddMultipleConfigurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MULTIPLE_CONFIGURATION:
            return {
                ...state,
                multipleConfigurationData: action.data
            };
        case APP_RIGHTS:
            return {
                ...state,
                appRights: action.data
            };
        case ADD_MULTIPLE_CONFIGURATION_KF:
            return {
                ...state,
                multipleConfigurationDataKF: action.data
            };
        case ADD_MULTIPLE_CONFIGURATION_AV:
            return {
                ...state,
                multipleConfigurationDataAV: action.data
            };
        case GET_SALES_BASIS_SUCCESS:
            return {
                ...state,
                salesBasisData: action.data
            };
        case ACCESS_DATA_SUCCESS:
            return {
                ...state,
                accessData: action.data
            };
        case ACCESS_DATA_NEW_SUCCESS:
            return {
                ...state,
                accessDataNew: action.data
            };
        case ORG_APP_TITLE_SUCCESS:
            return {
                ...state,
                orgAppTitleData: action.data
            }
        case SECOND_API_CONFIG_SUCCESS:
            return {
                ...state,
                secondApiData: action.data
            };
        case HISTORY_SUCCESS:
            return {
                ...state,
                historyData: action.data
            };
        case ORGANIZATION_ACCESS_SUCCESS:
            return {
                ...state,
                organizationAccessData: action.data
            };
        case USER_ROLE_ACCESS_SUCCESS:
            return {
                ...state,
                userRoleAccessData: action.data
            };
        case DEFAULT_VALUES_CALC_SIM:
            return {
                ...state,
                calcSimulationDefaultData: action.data
            };
        case DEFAULT_VALUES_FINANCIAL:
            return {
                ...state,
                financialSimulationDefaultData: action.data
            };
        case APPROVAL_GROUP_SUCCESS:
            return {
                ...state,
                approvalGroupData: action.data
            };
        case COLLABORATION_PORTAL_SUCCESS:
            return {
                ...state,
                collaborationPortalData: action.data
            };
        case GET_FINANCIAL_APPROVAL_SUCCESS:
            return {
                ...state,
                financialApprovalData: action.data
            };
        case GET_CALCULATION_SUMMARY_SUCCESS:
            return {
                ...state,
                calculationSummaryData: action.data
            };
        case GET_ACC_VS_PAY_SUCCESS:
            return {
                ...state,
                accrualVsPaymentData: action.data
            }
        case GET_EXTERNAL_ACCURAL_DATA_SUCCESS:
            return {
                ...state,
                externalAccuralPostingData: action.data
            }
        case GET_EXTERNAL_PAYMENT_DATA_SUCCESS:
            return {
                ...state,
                externalPaymentPostingData: action.data
            }
        case GET_COMPARE_REBATE_CALCULATION_DATA_SUCCESS:
            return {
                ...state,
                compareRebateCalculationData: action.data
            }
        case FETCH_START:
            return {
                loading: true
            }
        case FETCH_END:
            return {
                loading: false
            }
        case FORM_FIELDS_ALL_DATA:
            return {
                ...state,
                formFieldsAllData: action.data
            };
        case FORM_FIELDS_ALL_DATA_NEW:
            return {
                ...state,
                formFieldsAllDataNew: action.data
            };
        case TABLES_NAMES_SUCCESS:
            return {
                ...state,
                tableName: action.data
            }
        case FORM_FIELDS_ALL_DATA_ER:
            return {
                ...state,
                formFieldsAllDataER: action.data
            };
        case TARGET_SOURCE_ATTRIBUTE_ALL_DATA_SA:
            return {
                ...state,
                sourceAttributeAllDataSa: action.data
            };
        case TARGET_SOURCE_ATTRIBUTE_ALL_DATA:
            return {
                ...state,
                sourceAttributeAllData: action.data
            };
        case FORM_FIELDS_ALL_DATA_KF:
            return {
                ...state,
                formFieldsAllDataKF: action.data
            };
        case LOAD_FIELDVALUES_ON_APP_TYPE:
            return {
                ...state,
                loadFieldValuesOnAppType: action.data
            };
        case USER_ROLE_SINGLE:
            return {
                ...state,
                userRoleSingle: action.data
            };
        case ATTRIBUTE_NAME:
            return {
                ...state,
                attributeNameData: action.data
            };
        case KEY_FIGURE_NAME:
            return {
                ...state,
                keyFigureNameData: action.data
            };
        case FETCH_START_ORG:
            return {
                loadingOrg: true
            }
        case FETCH_END_ORG:
            return {
                ...state,
                loadingOrg: false,
                organisationPostResponse: action.data
            }
        case GET_USER_EMAIL_LINK:
            return {
                ...state,
                emailLinkResponse: action.data
            }
        case WORKFLOW_REQUEST_ACCRUAL:
            return {
                ...state,
                workflowRequestsAccural: action.data
            }
        case WORKFLOW_REQUEST_PAYMENT:
            return {
                ...state,
                workflowRequestsPayment: action.data
            }
        case CONTRACT_TRENDS:
            return {
                ...state,
                contractTrendsResult: action.data
            }
        case CALCULATION_SIMULATION_NUMBERS:
            return {
                ...state,
                calculationSimulationNumbers: action.data
            }
        case QUOTA_LEVEL:
            return {
                ...state,
                quotaLevelData: action.data
            }
        case ORG_DEFAULTS:
            return {
                ...state,
                orgDefaults: action.data
            }
        case WORKFLOW_REQUEST_PROMOTION:
            return {
                ...state,
                workflowRequestsPromotion: action.data
            }
        case CLAIM_ERROR_COUNT:
            return {
                ...state,
                claimErrorCount: action.data
            }
        case UPLOAD_MASS_FILE:
            return {
                ...state,
                massUploadMessage: action.data
            }
        case TIER_EVALUATION_LEVEL:
            return {
                ...state,
                tierEvaluationData: action.data
            }
        case DEAFULT_VALUES_LBL_DISP:
            return {
                ...state,
                defaultValueLblDisp: action.data
            }
        case DELETE_MASTER_DATA_SUCCESS:
            return {
                ...state,
                deletemasterdata: action.data
            }
        case DELETE_MASTER_DATA_FIELD_SUCCESS:
            return {
                ...state,
                deletemasterdatafield: action.data
            }
        case CONTRACT_TRENDS_V2:
            return {
                ...state,
                contractTrendsV2Result: action.data
            }
        case CONTRACT_DISPLAY:
            return {
                ...state,
                contractDesignerData: action.data
            }
        case COLLABORATED_DATA:
            return {
                ...state,
                collaboratedData: action.data
            }
        case CONFIGURATION_DESIGNER_SUCCESS:
            return {
                ...state,
                configdesigner: action.data
            }
        case REPORT_TYPE_FILTER_SUCCESS:
            return {
                ...state,
                reportypefilter: action.data
            }
        case REPORT_TYPE_DATA_ALL:
            return {
                ...state,
                reportypedataAll: action.data
            }
        case REPORT_ON_ROLL_ALL:
            return {
                ...state,
                reportonrolldataAll: action.data
            }
        case REPORT_TYPE_DATA_SINGLE:
            return {
                ...state,
                reportypedataSingle: action.data
            }
        default:
            return state;
    }
};

export default AddMultipleConfigurationReducer;