import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Paper,
  AppBar,
  IconButton,
  Button,
  Switch,
} from "@mui/material";
import { Avatar, Tooltip, Box } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Menu from "@mui/material/Menu";
import { CustomThemeContext } from "../../../theme/CustomThemeProvider";
import { PowerSettingsNewRounded, Apps, AccountCircle } from '@mui/icons-material';
import Dropdown from 'react-bootstrap/Dropdown';
import MenuItem from "@mui/material/MenuItem";
import { history } from "../../../components/Helpers";
import { connect } from "react-redux";
import {
  onLoadingSingleUser,
  getAccessData,
  getProfile,
  currentApplication, dashboardDashBoardNames
} from "../../../redux/actions";
import secureLocalStorage from "react-secure-storage";
import "./components/Launcherstyle.css";
import { BSNavbar } from "../../../layouts/Main/components/SideBar/components/SidebarNav";


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: theme.palette.gradient.appLauncher,
  },
  innerContainer: {
    [theme.breakpoints.down('xl')]: {
      minWidth: 0,
      // marginTop: 10,
      marginRight: 15,
      fontSize: 10,
      alignItems: 'center'
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  vectorParent: {
    borderRadius: "var(--br-smi)",
    width: "220px",
    fontSize: "16px",
    fontFamily: "Poppins",
    borderRadius: "13px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "column",
    cursor: "pointer",
    margin: "8px",

  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 84,
    width: 210,
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "0.6s",
      cursor: "pointer",
      animationDuration: "4s",
      animationDelay: "2s",
    },
    color: "white",
    margin: "8px 16px 8px 8px",
    borderRadius: 9,

    backgroundColor: theme.palette.appLauncher.paper,
  },

  disabled: {
    pointerEvents: "none",
    opacity: 0.4,
  },
  mgBottom10: {
    marginBottom: 10,
    color: "#A9B3E4",
  },
  h4: {
    marginTop: 30,
    paddingLeft: 15,
  },
  tabStyle: {
    backgroundColor: theme.palette.appLauncher.tabStyle,
    padding: 12,
    borderRadius: "10px 10px 0 0",
    color: theme.palette.appLauncher.tabStyleFont,
    width: 170,
    fontSize: "0.76rem",
  },
  tabContainer: {
    padding: '8px 10px 8px 15px',
    borderRadius: "8px 8px 8px 8px",
    marginLeft: 20,
    marginRight: 20,
    //width:'100%'
  },
  button: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: theme.palette.primary.main,
  },
  switchTheme: {
    color: "black",
  },
  subContainer: {
    // display: "flex",
    // flexDirection: "row",
    // backgroundImage: theme.palette.appLauncher.subContainer,
    // borderRadius: "0px 20px 20px 20px",
    // padding: 15,
  },
  adminContainer: {
    padding: '30px 10px 8px 15px',
    borderRadius: "8px 8px 8px 8px",
    marginLeft: 20,
    marginRight: 20,
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    height: window.screen.height,
  },

}));
const triggerLogout = () => {
  localStorage.setItem("loggedIn", false);

  localStorage.clear();
  secureLocalStorage.clear();
  history.push("/login");
};
const AppLauncher = (props) => {
  const classes = useStyles();
  const { currentTheme, setTheme } = useContext(CustomThemeContext)
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [profileDataAPI, setProfileDataAPI] = React.useState([]);


  const settings = ["Profile", "App Launcher", "Logout"];
  const colorCode = [{ key: 'violet', value: '#8F00FF' }, { key: 'green', value: '#264F1B' }, { key: 'light', value: '#32009A' }, { key: 'gray', value: '#4F4F61' }, { key: 'dark', value: '#0B3662' }]

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleSettings = (e) => {
    if (e === "App Launcher") {
      history.push("/applauncher");
    }
    else if (e === 'Profile') {
      history.push('/profile')
    }
    else if (e === "Logout") {
      localStorage.setItem("loggedIn", false);
      localStorage.clear();
      secureLocalStorage.clear();
      history.push("/login");
    }
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleBoxClick = (value) => {
    props.currentApplication(value);
    localStorage.setItem("application", value);
    sessionStorage.setItem("application", value);
    if (value === "Designer Studio") {
      history.push("default-page");
    } else if (value == "Master Data") {
      history.push("default-page");
    } else if (value == "Transaction Data") {
      history.push("default-page");
    } else if (value === "Security Cockpit") {
      if (userDetails.role.name === "admin") {
        history.push("organisation-setup");
      } else {
        history.push("default-page");
      }
    } else if (value === "Integration Studio") {
      history.push("default-page");
    } else if (value === "Collaboration Portal") {
      history.push("default-page");
    } else if (value === "Analytics") {
      history.push("default-page");
    } else if (value === "Audit Lab") {
      history.push("default-page");
    } else if (value === "Pricing") {
      history.push("pricing-dashboard");
    } else if (value === "Promotions") {
      history.push("promotion-scenario-sand-box");
      // history.push("promo-plan-analytics");
    } else {
      history.push("dashboard");
    }
  };

  const handleBoxClickCR = (event) => {
    event.preventDefault();
    props.currentApplication("Customer Rebate");
    localStorage.setItem("application", "Customer Rebate");
    sessionStorage.setItem("application", "Customer Rebate");
    history.push("dashboard");
  };

  const [userDetails, setUserDetails] = React.useState(
    secureLocalStorage.getItem("dYtz")
  );
  useEffect(() => {
    props.getAccessData(userDetails.role["name"]);
    props.getProfile();
    props.allowedDashBoardApps("Master Data");
  }, []);
  useEffect(() => {
    if (props.userRoleSingle && props.userRoleSingle.role) {
      props.getAccessData(props.userRoleSingle.role);
    }
  }, [props.userRoleSingle]);

  useEffect(() => {
    if (props.userRoleSingle) {
    }
  }, [props.userRoleSingle]);

  useEffect(() => {
    if (props.userData) {
    }
  }, [props.userData]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();

    setAnchorEl(null);
  };

  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      // Calculate the adjusted window height based on the zoom level
      const adjustedHeight = window.innerHeight / window.devicePixelRatio;
      setWindowHeight(adjustedHeight);
      const adjustedWidth = window.innerWidth / window.devicePixelRatio;
      setWindowWidth(adjustedWidth);
    }

    // Add a listener for window resize events
    window.addEventListener("resize", handleResize);

    // Call the resize handler once to set the initial height
    handleResize();

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="desktop1">
      <BSNavbar page={'applauncher'} pages={[]} />
      {/* <div className="rectangleDiv">
        <div></div>
        <img className="finalWhite1Icon" src={require("../../../library/images/new_logo.png")}
        // src={"/Desktop1_finalwhite1.png"} 
        />
        <div className="boxContainer">
          <Box sx={{ flexGrow: 0 }} >
            <Tooltip title="Open Control Panel" style={{ border: '2px solid white', borderRadius: '50px' }} >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={secureLocalStorage.getItem("dYtz").username}
                  src={
                    profileDataAPI && profileDataAPI.profile_image_path
                  }
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={'profile'}>
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '0.8rem' }}>
                  <div>
                    {profileDataAPI && profileDataAPI.username}
                  </div>
                </div>
              </MenuItem>
              <Dropdown.Divider />
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSettings(setting)}>

                  {setting === 'Logout' ?
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><PowerSettingsNewRounded fontSize={'small'} color={'error'} style={{ marginRight: 5 }} />{setting} </div>
                    :
                    setting === 'App Launcher' ?
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><Apps fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} </div>
                      :
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}><AccountCircle fontSize={'small'} color={'primary'} style={{ marginRight: 5 }} />{setting} </div>
                  }

                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </div> */}

      <div className="mainContainer">
        <div className={classes.innerContainer}>


          {/*<Button
            variant="contained"
            className={classes.button}
            size="small"
            onClick={triggerLogout}
            style={{
              fontSize: "0.74rem",
              padding: 10,
              borderRadius: 10,
              color: "white",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <PowerSettingsNewIcon />
          </Button>*/}

          {userDetails.role.name != "admin" &&
            userDetails.role != "external" &&
            (userDetails.role.authorized_apps.includes("Customer Rebate") ||
              userDetails.role.authorized_apps.includes(
                "Customer Chargeback"
              ) ||
              userDetails.role.authorized_apps.includes("Royalty") ||
              userDetails.role.authorized_apps.includes("Promotions") ||
              userDetails.role.authorized_apps.includes("Supplier Rebate") ||
              userDetails.role.authorized_apps.includes(
                "Supplier Chargeback"
              ) ||
              userDetails.role.authorized_apps.includes("Sales Commission") ||
              userDetails.role.authorized_apps.includes("Pricing") ||
              userDetails.role.authorized_apps.includes(
                "Profit Optimization"
              )) && (
              <div className={classes.tabContainer}>
                <div className="subContainer">
                  {/* <div className="subContainer"> */}
                  <div className="functionalApps">Business Process Apps</div>
                  <div
                    style={{
                      width: "auto",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Customer Rebate"
                      ) && (
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #BF10E2 0%, #E85EFB 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={handleBoxClickCR}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Customer</p>
                              <p className="customer">Rebate</p>
                            </b>
                            <img
                              className="bgLayer"
                              alt=""
                              src="/cr_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />

                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{

                                  background:
                                    "linear-gradient(180deg, #DF3AFF 0%, #B800D6 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/layer-1.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <div
                                  style={{
                                    width: "10px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  onClick={handleClick}
                                  id="basic-button"
                                  aria-controls={open ? "basic-menu" : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <img
                                    className="frame-item"
                                    alt=""
                                    src="/group-10.svg"
                                    onClick={handleClick}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => history.push("/contract-setup")}
                            >
                              Contract Setup
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push("/calculation-simulation")
                              }
                            >
                              Calculation Simulation
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                history.push("/financial-postings")
                              }
                            >
                              Accrual Postings
                            </MenuItem>
                            <MenuItem
                              onClick={() => history.push("/payment-postings")}
                            >
                              Payment Postings
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Customer Chargeback"
                      ) && (
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #E1950F 0%, #F3CE2A 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Customer Chargeback")}
                        >
                          <div className="samelinediv">
                            <img
                              className="bgLayer"
                              alt=""
                              src="/cc-bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <b className="customer-rebate">
                              <p className="customer">Customer</p>
                              <p className="customer">Chargeback</p>
                            </b>

                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #FFBB3F 0%, #E29810 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/layer-11.svg"

                                />
                              </div>
                              <div className="drop-icon">
                                <div
                                  style={{
                                    width: "10px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  onClick={handleClick}
                                  id="basic-button"
                                  aria-controls={open ? "basic-menu" : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <img
                                    className="frame-item"
                                    alt=""
                                    src="/group-10.svg"
                                  //onClick={handleClick}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes("Pricing") && (
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #2C78E5 0%, #62B1F5 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Pricing")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Pricing</p>
                            </b>
                            <img
                              alt=""
                              src="/price_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #559DFF 0%, #317DE6 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/vector10.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <div
                                  style={{
                                    width: "10px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  // onClick={handleClick}
                                  id="basic-button"
                                  aria-controls={open ? "basic-menu" : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <img
                                    className="frame-item"
                                    alt=""
                                    src="/group-10.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        //</div>
                      )}
                    {/* {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes("Royalty") && (

                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #1eb4c9, #3fe8ff)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Royalty")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Incoming</p>
                              <p className="customer">Royalty</p>
                            </b>
                            <img
                              alt=""
                              src="/ir_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #39e1f9, #20b7cc)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/vector8.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                // onClick={() => handleBoxClick("Customer Rebate")}
                                />
                              </div>
                            </div>
                          </div>

                        </div>

                      )} */}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Sales Commission"
                      ) && (
                        //<div
                        // item
                        //xs={12}
                        //md={3}
                        // onClick={() => handleBoxClick("Sales Commission")}
                        // >
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #1CA155 0%, #4CD887 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Sales Commission")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Sales</p>
                              <p className="customer">Commission</p>
                            </b>
                            <img
                              alt=""
                              src="/sc_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(90deg, #1CA155 0%, #4CD887 104.79%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/Vector_sc.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        //</div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Profit Optimization"
                      ) && (
                        //<div
                        //   item
                        //  xs={12}
                        //  md={3}
                        //  onClick={() => handleBoxClick("Profit Optimization")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #EACDA3 0%, #D6AE7B 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Profit Optimization")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Profit</p>
                              <p className="customer">Optimization</p>
                            </b>
                            <img
                              alt="bgLayer"
                              src="/profit_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #FFD292 0%, #BE8F49 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/Vector_profit.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Supplier Rebate"
                      ) && (
                        //<div
                        //  item
                        // xs={12}
                        //  md={3}
                        //  onClick={() => handleBoxClick("Supplier Rebate")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #F28396 0%, #FFA9A9 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Supplier Rebate")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Supplier</p>
                              <p className="customer">Rebate</p>
                            </b>
                            <img
                              alt=""
                              src="/sr_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #FFB9C5 0%, #F38497 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/Vector_sr.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        //</div>
                      )}

                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Supplier Chargeback"
                      ) && (
                        //<div
                        //  item
                        //  xs={12}
                        //  md={3}
                        //  onClick={() => handleBoxClick("Supplier Chargeback")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #00BFB7 0%, #00FFE0 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Supplier Chargeback")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Supplier</p>
                              <p className="customer">Chargeback</p>
                            </b>
                            <img
                              alt=""
                              src="/scharge_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(90deg, #00BFB7 0%, #00FFE0 104.79%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/scb.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>

                            </div>
                          </div>

                        </div>
                        //</div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Promotions"
                      ) && (

                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #887BD6 0%, #AFB7FF 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Promotions")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Promotions</p>
                              {/* <p className="customer">Planning</p> */}
                            </b>
                            <img
                              alt="bgLayer"
                              src="/promo_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(90deg, #887BD6 0%, #AFB7FF 104.79%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/Vector_promo.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes("Royalty") && (

                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #56AB2F 0%, #A8E063 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Royalty")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              {/* <p className="customer">Outgoing</p> */}
                              <p className="customer">Royalty</p>
                            </b>
                            <img
                              alt=""
                              src="/or_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(90deg, #56AB2F 0%, #A8E063 104.79%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/or.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      )}


                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
          {userDetails.role.name != "admin" &&
            userDetails.role.authorized_apps &&
            (userDetails.role.authorized_apps.includes("Master Data") ||
              userDetails.role.authorized_apps.includes("Transaction Data") ||
              userDetails.role.authorized_apps.includes("Analytics") ||
              userDetails.role.authorized_apps.includes(
                "Collaboration Portal"
              )) && (
              <div className={classes.tabContainer}>
                <div className="subContainer">
                  {/* <div className="subContainer"> */}
                  <div className="functionalApps">Business Support Apps</div>
                  <div
                    style={{
                      width: "auto",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Master Data"
                      ) && (
                        //<div
                        //  item
                        // xs={12}
                        // md={3}
                        // onClick={() => handleBoxClick("Master Data")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #6048AB 0%, #AD90FF 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Master Data")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Master</p>
                              <p className="customer">Data</p>
                            </b>
                            <img
                              alt="bgLayer"
                              src="/md_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #A284FF 0%, #6149AC 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/Vector_master.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Transaction Data"
                      ) && (
                        // <div
                        //  item
                        //  xs={12}
                        //  md={3}
                        //  onClick={() => handleBoxClick("Transaction Data")}
                        // >
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #A5834D 0%, #FFD9B6 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Transaction Data")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Transaction</p>
                              <p className="customer">Data</p>
                            </b>
                            <img
                              alt="bgLayer"
                              src="/td_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #F0CE98 0%, #A98652 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/Vector_tr.svg"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                    {/* {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Analytics"
                      ) && (
                        // <div
                        //  item
                        //  xs={12}
                        //  md={3}
                        //  onClick={() => handleBoxClick("Analytics")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #4CA1AF 0%, #C4E0E5 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Analytics")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Analytics</p>
                            </b>
                            <img
                              alt="bgLayer"
                              src="/analytics_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #7DEAFB 0%, #55A2AE 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/analtics_icon.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        //</div>
                      )} */}
                    {userDetails.role &&
                      userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Collaboration Portal"
                      ) && userDetails.collaboration_id != 0 ? handleBoxClick("Collaboration Portal") : (
                      //<div
                      //  item
                      //  xs={12}
                      //  md={3}
                      // onClick={() => handleBoxClick("Collaboration Portal")}
                      //>
                      <div
                        className={classes.vectorParent}
                        style={{
                          background:
                            "linear-gradient(90deg, #B8C14D 0%, #F2FF59 104.79%)",
                          height: windowHeight * 0.15 + "px",
                          width: windowWidth / 5 + "px",
                        }}
                        onClick={() => handleBoxClick("Collaboration Portal")}
                      >
                        <div className="samelinediv">
                          <b className="customer-rebate">
                            <p className="customer">Collaboration</p>
                            <p className="customer">Portal</p>
                          </b>
                          <img
                            alt="bgLayer"
                            src="/cp_bg.svg"
                            style={{ height: windowHeight * 0.14 + "px", }}
                          />
                          <div className="flexEnd">
                            <div
                              className="frame-child"
                              style={{
                                background:
                                  "linear-gradient(180deg, #F4FF69 0%, #BAC34D 100%)",
                                height: windowHeight * 0.067 + "px",
                                width: windowHeight * 0.067 + "px",
                              }}
                            >
                              <img
                                className="layer-1-icon"
                                alt=""
                                src="/cp.png"
                              />
                            </div>
                            <div className="drop-icon">
                              <img
                                className="frame-item"
                                alt=""
                                src="/group-10.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      //</div>
                    )}
                    {userDetails.role &&
                      userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Audit Lab"
                      ) && (
                        // <div
                        //   item
                        //   xs={12}
                        //   md={3}
                        //   onClick={() => handleBoxClick("Audit Lab")}
                        // >
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #CF8F12 0%, #FFC24B 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Audit Lab")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Audit</p>
                              <p className="customer">Lab</p>
                            </b>
                            <img
                              alt="bgLayer"
                              src="/al_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #FFD37D 0%, #D09014 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/al.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
          {userDetails.role.name != "admin" &&
            (userDetails.role.authorized_apps.includes("Designer Studio") ||
              userDetails.role.authorized_apps.includes("Security Cockpit") ||
              userDetails.role.authorized_apps.includes(
                "Integration Studio"
              )) && (
              <div className={classes.tabContainer}>
                <div className="subContainer">
                  {/* <div className="subContainer"> */}
                  <div className="functionalApps">Technical Apps</div>
                  <div
                    style={{
                      width: "auto",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Designer Studio"
                      ) && (
                        //<div
                        //  item
                        //  xs={12}
                        //  md={3}
                        //  onClick={() => handleBoxClick("Designer Studio")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #2D79E5 0%, #5DACF4 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Designer Studio")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Designer</p>
                              <p className="customer">Studio</p>
                            </b>
                            <img
                              alt=""
                              src="/ds_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #549BFE 0%, #337FE7 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/ds.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Security Cockpit"
                      ) && (
                        // <div
                        //  item
                        //    xs={12}
                        //   md={3}
                        //   onClick={() => handleBoxClick("Security Cockpit")}
                        // >
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #F28396 0%, #FFD9B6 104.79%, #FEA7A8 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Security Cockpit")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Security</p>
                              <p className="customer">Cockpit</p>
                            </b>
                            <img
                              alt=""
                              src="/security_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #FFB9C5 0%, #F4899B 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/sc.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        //</div>
                      )}
                    {userDetails.role.authorized_apps &&
                      userDetails.role.authorized_apps.includes(
                        "Integration Studio"
                      ) && (
                        // <div
                        //   item
                        //   xs={12}
                        //   md={3}
                        //   onClick={() => handleBoxClick("Integration Studio")}
                        //>
                        <div
                          className={classes.vectorParent}
                          style={{
                            background:
                              "linear-gradient(90deg, #BF10E2 0%, #E85EFB 104.79%)",
                            height: windowHeight * 0.15 + "px",
                            width: windowWidth / 5 + "px",
                          }}
                          onClick={() => handleBoxClick("Integration Studio")}
                        >
                          <div className="samelinediv">
                            <b className="customer-rebate">
                              <p className="customer">Integration</p>
                              <p className="customer">Studio</p>
                            </b>
                            <img
                              alt=""
                              src="/is_bg.svg"
                              style={{ height: windowHeight * 0.14 + "px", }}
                            />
                            <div className="flexEnd">
                              <div
                                className="frame-child"
                                style={{
                                  background:
                                    "linear-gradient(180deg, #E76DFF 0%, #B902D7 100%)",
                                  height: windowHeight * 0.067 + "px",
                                  width: windowHeight * 0.067 + "px",
                                }}
                              >
                                <img
                                  className="layer-1-icon"
                                  alt=""
                                  src="/is.png"
                                />
                              </div>
                              <div className="drop-icon">
                                <img
                                  className="frame-item"
                                  alt=""
                                  src="/group-10.svg"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        // </div>
                      )}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
          {/*  */}

          {userDetails.role.name === "admin" && (
            <div className={classes.adminContainer}>
              <div className="adminSubContainer">
                <div
                  style={{
                    width: "auto",
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 30,
                    marginBottom: 30
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  /* onClick={() => handleBoxClick("Security Cockpit")}
                   className={classes.vectorParent}
                   style={{
                     background:
                       "linear-gradient(90deg, #00BFB7 0%, #00FFE0 104.79%)",
                     height: windowHeight * 0.15 + "px",
                     width: windowWidth / 5 + "px",
                   }}*/
                  >
                    <div
                      className={classes.vectorParent}
                      style={{
                        background:
                          "linear-gradient(90deg, #F28396 0%, #FFD9B6 104.79%, #FEA7A8 104.79%)",
                        height: windowHeight * 0.15 + "px",
                        width: windowWidth / 5 + "px",
                      }}
                      onClick={() => handleBoxClick("Security Cockpit")}
                    >
                      <div className="samelinediv">
                        <b className="customer-rebate">
                          <p className="customer">Security</p>
                          <p className="customer">Cockpit</p>
                        </b>
                        <img
                          alt=""
                          src="/security_bg.svg"
                          style={{ height: windowHeight * 0.14 + "px", }}
                        />
                        <div className="flexEnd">
                          <div
                            className="frame-child"
                            style={{
                              background:
                                "linear-gradient(180deg, #FFB9C5 0%, #F4899B 100%)",
                              height: windowHeight * 0.067 + "px",
                              width: windowHeight * 0.067 + "px",
                            }}
                          >
                            <img
                              className="layer-1-icon"
                              alt=""
                              src="/sc.png"
                            />
                          </div>
                          <div className="drop-icon">
                            <img
                              className="frame-item"
                              alt=""
                              src="/group-10.svg"
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadingSingleUser: (id) => dispatch(onLoadingSingleUser(id)),
    getAccessData: (userRole) => dispatch(getAccessData(userRole)),
    getProfile: () => dispatch(getProfile()),
    currentApplication: (app) => dispatch(currentApplication(app)),
    allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
  };
};

const mapStateToProps = (state) => {
  return {
    userRoleSingle: state.addMultipleConfigurationData.userRoleSingle,
    accessData: state.addMultipleConfigurationData.accessData,
    profileData: state.profileData.profileData,
    userData: state.loginData.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLauncher);