import {
    FETCH_START, FETCH_END,
    PROMOTION_DATA_SUCCESS,
    PROMO_FINANCE_COST_DATA_SUCCESS,
    PROMO_TERRITORY_DATA_SUCCESS,
    PROMO_TERRITORY_FORMAT_SUCCESS,
    PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS,
    PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS,
    PROMO_TERRITORY_UPLOAD_SUCCESS,
    PROMOTION_SETUP_BY_ID,
    PROMOTION_SETUP_SUCCESS,
    PROMOTION_MATERIAL_SUCCESS,
    PROMO_FINANCE_COST_DETAIL_SUCCESS,
    FINANCE_COST_FORMAT_SUCCESS,
    FINANCE_COST_FORMAT_BY_ID_SUCCESS,
    FINANCE_COST_FORMAT_FIELDS_SUCCESS,
    PROMO_FINANCE_COST_UPLOAD_SUCCESS,
    COUNTRY_SUCCESS,
    POSTING_FAILED,
    GLOBAL_SETUP_BY_ID,
    GLOBAL_SETUP_SUCCESS,
    PROMO_MAP_SUCCESS,
    PROMO_MAP_SINGLE_SUCCESS,
    PROMO_MAPPING_FORMAT_SUCCESS,
    PROMO_MAP_FORMAT_BY_ID,
    PROMO_MAP_FORMAT_FIELDS,
    PROMO_SIM_FAILURE,
    PROMO_TYPES,
    PROMO_STICKERS,
    PROMO_STICKER_BY_ID,
    MARKET_FILTERS,
    PROMO_FILTERS,
    MODEL_GROUP,
    LOCATION_GROUP,
    MODEL_LIST_GROUP,
    PROMO_CONFIG_UPLOAD_SUCCESS,
    PROMO_CONFIG_FORMAT_FIELDS_SUCCESS,
    PROMO_CONFIG_FORMAT_SUCCESS,
    PROMO_CONFIG_DATA_SUCCESS,
    MATERIAL_PRICING_UPLOAD_SUCCESS,
    MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS,
    MATERIAL_PRICING_FORMAT_SUCCESS,
    MATERIAL_PRICING_DATA_SUCCESS,
    MATERIAL_PRICING_SINGLE_DATA_SUCCESS,
    PROMO_CONFIG_SINGLE_DATA_SUCCESS,
    SCENARIO_LIST_DATA_SUCCESS,
    HISTORY_DATA_SUCCESS,
    SAND_BOX_DATA_SUCCESS,
    PROMOTIONS_SEARCH_SUCCESS,
    COSTING_SIMULATION_SUCCESS,
    COSTING_START,
    COSTING_END, PREFILL_SCENARIO_SUCCESS, SCENARIO_BY_ID,
    COVERAGE_MAPPING_UPLOAD_SUCCESS,
    COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS,
    COVERAGE_MAPPING_FORMAT_SUCCESS,
    STANDARD_FINANCE_UPLOAD_SUCCESS,
    STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS,
    STANDARD_FINANCE_FORMAT_SUCCESS,
    STANDARD_FINANCE_DATA_SUCCESS,
} from '../../actions/Promotion/ActionTypes';

const initialState = {
    loading: false,
    promotionData: [],
    promoFinanceCostData: [],
    promoTerritoryData: [],
    promoTerritorySingleFormatData: [],
    promoTerritoryFormatFieldData: [],
    promoTerritoryFormatData: [],
    promoTerritory: [],
    promoTerritoryErrorList: [],
    promotionSetupData: [],
    promotionSetupSingleData: [],
    promotionMaterialData: [],
    promoFinanceCostDetailsData: [],
    promoFinanceCostSingleFormatData: [],
    promoFinanceCostFormatFieldData: [],
    promoFinanceCostFormatData: [],
    promoFinanceCost: [],
    promoFinanceCostErrorList: [],
    countryData: [],
    postingFailedList: [],
    globalSetupData: [],
    globalSetupList: [],
    promoMapData: [],
    promoMapSingleData: [],
    promoMapFormatData: [],
    promoMapFormatFields: [],
    promoMapFormatSingleData: [],
    promoSimulationFailure: [],
    promoTypes: {},
    promoStickers: [],
    stickerData: {},
    marketFilters: [],
    promoFilters: [],
    modelFilters: [],
    locationFilters: [],
    modelList: [],
    promoConfigErrorList: [],
    promoConfigField: [],
    promoConfigFormatData: [],
    promoConfigData: [],
    promoConfigDataSingle: [],
    materialPricingErrorList: [],
    materialPricingField: [],
    materialPricingFormatData: [],
    materialPricingData: [],
    materialPricingDataSingle: [],
    promotionsSearchData: [],
    costingSimulationResult: [],
    oncostingLoad: false,
    prefillScenarioList: [],
    //scenarioPayload: [],
    scenarioData: [],
    scenarioListData: [],
    sandBoxListData: [],
    historyListData: [],
    coverageMappingFormatData: [],
    coverageMappingErrorList: [],
    coverageMappingField: [],
    standardFinanceFormatData: [],
    standardFinanceErrorList: [],
    standardFinanceField: [],
    standardFinanceListData: [],
};

const PromotionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                ...state,
                loading: true
            };
        case FETCH_END:
            return {
                ...state,
                loading: false
            };
        case PROMOTION_DATA_SUCCESS:
            return {
                ...state,
                promotionData: action.data,
            };
        case PROMO_FINANCE_COST_DATA_SUCCESS:
            return {
                ...state,
                promoFinanceCostData: action.data,
            };
        case PROMO_TERRITORY_DATA_SUCCESS:
            return {
                ...state,
                promoTerritoryData: action.data,
            };
        case PROMO_TERRITORY_FORMAT_SUCCESS:
            return {
                ...state,
                promoTerritoryFormatData: action.data
            };
        case PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                promoTerritoryFormatFieldData: action.data
            };
        case PROMO_TERRITORY_UPLOAD_SUCCESS:
            return {
                ...state,
                promoTerritoryErrorList: action.data
            };
        case PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                promoTerritorySingleFormatData: action.data
            };
        case PROMOTION_SETUP_BY_ID:
            return {
                ...state,
                promotionSetupSingleData: action.data,
            };

        case PROMOTION_SETUP_SUCCESS:
            return {
                ...state,
                promotionSetupData: action.data,
            };
        case PROMOTION_MATERIAL_SUCCESS:
            return {
                ...state,
                promotionMaterialData: action.data,
            };
        case PROMO_FINANCE_COST_DETAIL_SUCCESS:
            return {
                ...state,
                promoFinanceCostDetailsData: action.data
            };
        case FINANCE_COST_FORMAT_SUCCESS:
            return {
                ...state,
                promoFinanceCostFormatData: action.data
            };
        case FINANCE_COST_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                promoFinanceCostFormatFieldData: action.data
            };
        case FINANCE_COST_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                promoFinanceCostSingleFormatData: action.data
            };
        case PROMO_FINANCE_COST_UPLOAD_SUCCESS:
            return {
                ...state,
                promoFinanceCostErrorList: action.data
            };
        case COUNTRY_SUCCESS:
            return {
                ...state,
                countryData: action.data
            };
        case POSTING_FAILED:
            return {
                ...state,
                postingFailedList: action.data
            };
        case GLOBAL_SETUP_SUCCESS:
            return {
                ...state,
                globalSetupData: action.data
            };
        case GLOBAL_SETUP_BY_ID:
            return {
                ...state,
                globalSetupList: action.data
            };
        case PROMO_MAP_SUCCESS:
            return {
                ...state,
                promoMapData: action.data
            };
        case PROMO_MAP_SINGLE_SUCCESS:
            return {
                ...state,
                promoMapSingleData: action.data
            };
        case PROMO_MAPPING_FORMAT_SUCCESS:
            return {
                ...state,
                promoMapFormatData: action.data
            };
        case PROMO_MAP_FORMAT_BY_ID:
            return {
                ...state,
                promoMapFormatSingleData: action.data
            };
        case PROMO_MAP_FORMAT_FIELDS:
            return {
                ...state,
                promoMapFormatFields: action.data
            };
        case PROMO_SIM_FAILURE:
            return {
                ...state,
                promoSimulationFailure: action.data
            };
        case PROMO_TYPES:
            return {
                ...state,
                promoTypes: action.data
            };
        case PROMO_STICKERS:
            return {
                ...state,
                promoStickers: action.data
            };
        case PROMO_STICKER_BY_ID:
            return {
                ...state,
                stickerData: action.data
            };
        case PROMO_FILTERS:
            return {
                ...state,
                promoFilters: action.data
            };
        case MARKET_FILTERS:
            return {
                ...state,
                marketFilters: action.data
            };
        case MODEL_GROUP:
            return {
                ...state,
                modelFilters: action.data
            };
        case LOCATION_GROUP:
            return {
                ...state,
                locationFilters: action.data
            };
        case MODEL_LIST_GROUP:
            return {
                ...state,
                modelList: action.data
            };
        case PROMO_CONFIG_UPLOAD_SUCCESS:
            return {
                ...state,
                promoConfigErrorList: action.data
            };
        case PROMO_CONFIG_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                promoConfigField: action.data
            };
        case PROMO_CONFIG_FORMAT_SUCCESS:
            return {
                ...state,
                promoConfigFormatData: action.data
            };
        case PROMO_CONFIG_DATA_SUCCESS:
            return {
                ...state,
                promoConfigData: action.data
            };
        case PROMO_CONFIG_SINGLE_DATA_SUCCESS:
            return {
                ...state,
                promoConfigDataSingle: action.data
            };
        case MATERIAL_PRICING_UPLOAD_SUCCESS:
            return {
                ...state,
                materialPricingErrorList: action.data
            };
        case MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                materialPricingField: action.data
            };
        case MATERIAL_PRICING_FORMAT_SUCCESS:
            return {
                ...state,
                materialPricingFormatData: action.data
            };
        case MATERIAL_PRICING_DATA_SUCCESS:
            return {
                ...state,
                materialPricingData: action.data
            };
        case MATERIAL_PRICING_SINGLE_DATA_SUCCESS:
            return {
                ...state,
                materialPricingDataSingle: action.data
            };
        case PROMOTIONS_SEARCH_SUCCESS:
            return {
                ...state,
                promotionsSearchData: action.data
            };
        case COSTING_SIMULATION_SUCCESS:
            return {
                ...state,
                costingSimulationResult: action.data,
            };
        case COSTING_START:
            return {
                ...state,
                oncostingLoad: true
            };
        case COSTING_END:
            return {
                ...state,
                oncostingLoad: false
            };
        case PREFILL_SCENARIO_SUCCESS:
            return {
                ...state,
                prefillScenarioList: action.data,
                // scenarioPayload: action.payload
            };
        case SCENARIO_BY_ID:
            console.log(action.data)
            return {
                ...state,
                scenarioData: action.data
            };
        case SCENARIO_LIST_DATA_SUCCESS:
            return {
                ...state,
                scenarioListData: action.data
            };
        case SAND_BOX_DATA_SUCCESS:
            return {
                ...state,
                sandBoxListData: action.data
            };
        case HISTORY_DATA_SUCCESS:
            return {
                ...state,
                historyListData: action.data
            };

        case COVERAGE_MAPPING_UPLOAD_SUCCESS:
            return {
                ...state,
                coverageMappingErrorList: action.data
            };
        case COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                coverageMappingField: action.data
            };
        case COVERAGE_MAPPING_FORMAT_SUCCESS:
            return {
                ...state,
                coverageMappingFormatData: action.data
            };
        case STANDARD_FINANCE_UPLOAD_SUCCESS:
            return {
                ...state,
                standardFinanceErrorList: action.data
            };
        case STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                standardFinanceField: action.data
            };
        case STANDARD_FINANCE_FORMAT_SUCCESS:
            return {
                ...state,
                standardFinanceFormatData: action.data
            };
        case STANDARD_FINANCE_DATA_SUCCESS:
            return {
                ...state,
                standardFinanceListData: action.data
            };
        default:
            return state;
    }
};

export default PromotionReducer;