import React, { useEffect } from 'react';
import {
    Typography, Card, Table, TableCell,
    TableHead, TableRow, TableContainer, TableBody, IconButton, InputAdornment,
    OutlinedInput, Grid, Button, Select, MenuItem, FormLabel, ToggleButton, ToggleButtonGroup,
    Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Checkbox,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { AddBoxOutlined, Close, AddCommentOutlined, Percent, AttachMoney, ErrorOutlineOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getPromoTypes, getLblDispDesFieldValue } from '../../../../redux/actions';
import OverWrite from './OverWrite';
import { MultiSelectDropdown } from '../../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // borderRadius: 10,
        // padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: '0px'
    },
    gridContainer: {
        // padding: '15px 0px 0px 0px',
        justifyContent: 'flex-start',
        //marginLeft: 20,
        width: '100%',
        borderBottom: 'none',
        marginBottom: 15

    },
    subContainer: {
        padding: '10px 16px 0px 16px',
        marginTop: 2
    },
    iconContainer: {
        // marginTop: 35
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    IconAddButton: {
        marginTop: 4,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    stickerLabel: {
        textTransform: 'capitalize',
        fontSize: theme.typography.h4.fontSize,
        // height: 41,
        marginTop: 9
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.text.grey
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },
    inputTwoLine: {
        // width: '91%'
        textAlign: 'right'
    },
    togglebBtn: {
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        '&.MuiToggleButton-root': {
            color: 'white !important',
        }

    },
    overwriteBtn: {
        marginLeft: 20,
        padding: 3,
    },
    input: {
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
    },
    promoContainer: {
        width: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 16px 0px 16px',
        //marginLeft: 20,
        marginBottom: 50,
        // height: 'max-content',
        //borderBottom


    },
    promoSubContainer: {
        width: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        //flexWrap: 'wrap'
        // padding: '0px 16px 0px 16px',
        //marginLeft: 20

    },
    fieldContainer: {
        // border: '0.8px solid #E2E2E2',
        height: 450, marginBottom: 65, width: '100%',
        paddingLeft: 15,
        backgroundColor: 'white'
    },
    commonContainer: {
        backgroundColor: 'white',
        border: '0.8px solid #E2E2E2',
        marginTop: -20,
        padding: '15px 16px 15px 16px',
        display: 'flex',
        flexDirection: 'column'
    }
}));

const StickerContainer = (props) => {

    const classes = useStyles();
    const { className } = props;
    const format = { 'options': [{ promotion_type: '' }] }
    const [promoFields, setPromoFields] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([format]);
    const [promoTypes, setPromoTypes] = React.useState({});
    const [promoTypeData, setPromoTypeData] = React.useState({});
    const [stickerDesc, setStickerDesc] = React.useState([]);
    const [columnIndex, setColumnIndex] = React.useState('');
    const [overWriteEnabled, setOverWriteEnabled] = React.useState(false);
    const [openedItem, setOpenedItem] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(-1);
    const [total, setTotal] = React.useState(1);
    const [description, setDescription] = React.useState({});
    const [takeRate, setTakeRate] = React.useState({});
    const [takeRateName, setTakeRateName] = React.useState('');
    const [currentIndex, setCurrentIndex] = React.useState('');
    const [optIndex, setOptIndex] = React.useState('');
    const [enable, setEnable] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    useEffect(() => {
        props.getPromotionTypes();
        var data = {
            "data_source": "Promotion Scenarios",
        }
        props.fieldDesigner(data, 'post');

    }, []);

    useEffect(() => {
        if (props.isCopy)
            setOpen(true);
    }, [props.isCopy]);

    useEffect(() => {
        if (props.promotionTypes && Object.keys(props.promotionTypes).length > 0) {
            setPromoTypeData(props.promotionTypes)
            setPromoTypes(Object.keys(props.promotionTypes))
        }

    }, [props.promotionTypes]);
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setPromoFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])

    useEffect(() => {
        if (stickerData, takeRateName)
            props.onChange(stickerData, takeRateName)
    }, [stickerData, takeRateName])

    useEffect(() => {
        if (props.data && props.data.sticker_type) {
            setStickerData(props.data.sticker_type)
            setTakeRateName(props.data.takerate_name)
            setEnable(true)

        }
    }, [props.data])
    useEffect(() => {
        if (enable && stickerData.length > 0) {
            ManipulateDescription(0, stickerData.length - 1)
            setEnable(false)
        }
    }, [stickerData, enable])
    useEffect(() => {
        if (update) {
            if (description) {
                const stickername = handleDesc(Object.values(description).flatMap(item => item))
                props.getSticker(stickername)
            }
            if (takeRate) {
                const takerateName = handleDesc(Object.values(takeRate).flatMap(item => item))
                setTakeRateName(takerateName)
            }
            setUpdate(false)
        }
    }, [update])

    const handleDesc = (desc) => {
        if (desc && desc.length > 0) {
            const inputString = desc.join("")
            const splitArray = inputString.split("OR");
            const resultArray = splitArray
                .filter(item => item !== null)
                .map((item) => {
                    const trimmedItem = item.trim();
                    return trimmedItem.length > 0 ? `(${trimmedItem})` : null;
                });
            const filteredResultArray = resultArray.filter(item => item !== null);
            const finalString = filteredResultArray.join("OR");
            if (desc && desc[desc.length - 1] == 'OR')
                finalString.concat("OR")
            return finalString
            // props.getSticker(finalString)
        }
    }
    const ManipulateDescription = (index, len) => {
        var length = len ? len : index

        var desc = description
        var take_rate = takeRate
        for (var key = index; key <= length; key++) {
            var sticker = []
            var takerate = ["("]
            stickerData && stickerData[key] && stickerData[key].options?.map((item, i) => {

                if (item['benefit_coverage_length']) {
                    sticker.push(item['benefit_coverage_length'] ? (Number(item['benefit_coverage_length']) % 12) == 0 ? (Number(item['benefit_coverage_length']) / 12) + 'Y' : (Number(item['benefit_coverage_length']) / 12).toFixed(1) + 'Y' : '0Y')
                    takerate.push(item['benefit_coverage_length'] ? (Number(item['benefit_coverage_length']) % 12) == 0 ? (Number(item['benefit_coverage_length']) / 12) + 'Y' : (Number(item['benefit_coverage_length']) / 12).toFixed(1) + 'Y' : '0Y')
                }
                if (item['benefit_promo_finance_interest'] || item['benefit_promo_finance_duration']) {
                    var duration = item['benefit_promo_finance_duration'] ? item['benefit_promo_finance_duration'] + 'Months' : '0 Months'
                    var interest = item['benefit_promo_finance_interest'] ? item['benefit_promo_finance_interest'] + ' %' : '0 %'

                    sticker.push(interest + " for " + duration)
                    takerate.push(interest + " for " + duration)
                }
                if (item['benefit_rebate']) {
                    sticker.push('$' + item['benefit_rebate'])
                    takerate.push('$' + item['benefit_rebate'])
                }
                if (item['benefit_spiff']) {
                    sticker.push('$' + item['benefit_spiff'])
                    takerate.push('$' + item['benefit_spiff'])
                }
                if (item['benefit_extra_rebate']) {
                    sticker.push('$' + item['benefit_extra_rebate'])
                    takerate.push('$' + item['benefit_extra_rebate'])
                }
                if (stickerData[key].options.length == 1) {
                    if (item['takerate_rebate']) {
                        takerate.push(')-' + item['takerate_rebate'] + '%')
                    }
                    if (item['takerate_coverage']) {
                        takerate.push(')-' + item['takerate_coverage'] + '%')
                    }
                    if (item['takerate_spiff']) {
                        takerate.push(')-' + item['takerate_spiff'] + '%')
                    }
                    if (item['takerate_extra_rebate']) {
                        takerate.push(')-' + item['takerate_extra_rebate'] + '%')
                    }
                    if (item['takerate_promo_finance']) {
                        takerate.push(')-' + item['takerate_promo_finance'] + '%')
                    }
                }
                if (item['operation'] && i != stickerData[key].options.length - 1) {
                    sticker.push(item['operation'])
                    takerate.push(item['operation'])
                }
                // }
            })
            if (stickerData && stickerData[key] && stickerData[key].common_takerate && stickerData[key].options.length > 1) {
                takerate.push(")-" + stickerData[key].common_takerate + '%')
            }
            if (stickerData && stickerData[key] && stickerData[key].operation) {
                sticker.push(stickerData[key].operation)
                takerate.push(stickerData[key].operation)
            }
            desc = { ...desc, [key]: sticker }
            take_rate = { ...take_rate, [key]: takerate }
        }
        setDescription(desc)
        setTakeRate(take_rate)
        setUpdate(true)
    }
    function handleChange(event, index, item, optIndex) {
        if (stickerData && stickerData[index]) {
            if (item) {
                if (item == 'common_takerate')
                    stickerData[index]['common_takerate'] = event.target.value ? parseInt(event.target.value) : ''
                else {
                    var promoType = stickerData[index]['options'][optIndex]['promotion_type']
                    // if (item == 'common_takerate')
                    //     stickerData[index]['common_takerate'] = event.target.value ? parseInt(event.target.value) : ''
                    // else
                    stickerData[index]['options'][optIndex][item] = promoFields && promoFields[item] && promoFields[item]['data_type'] == 'numeric' ? parseFloat(event.target.value) : event.target.value;

                    if (promoType == 'cash_rebate' && (item == 'contribution_rebate' || item == 'benefit_rebate')) {
                        var cost = (stickerData[index]['options'][optIndex]['contribution_rebate'] / 100) * stickerData[index]['options'][optIndex]['benefit_rebate']
                        if (cost > 0)
                            stickerData[index]['options'][optIndex]['costing_rebate'] = parseInt(cost)
                    }
                    if (promoType == 'x_cash_offers' && (item == 'contribution_x_cash' || item == 'benefit_extra_rebate')) {
                        var cost = (stickerData[index]['options'][optIndex]['contribution_x_cash'] / 100) * stickerData[index]['options'][optIndex]['benefit_extra_rebate']
                        if (cost > 0)
                            stickerData[index]['options'][optIndex]['costing_x_cash'] = parseInt(cost)
                    }
                    if (promoType == 'spiff' && (item == 'contribution_spiff' || item == 'benefit_spiff')) {
                        var cost = (stickerData[index]['options'][optIndex]['contribution_spiff'] / 100) * stickerData[index]['options'][optIndex]['benefit_spiff']
                        if (cost > 0)
                            stickerData[index]['options'][optIndex]['costing_spiff'] = parseInt(cost)
                    }
                }

                ManipulateDescription(index)
            }
            else {
                stickerData[index]['options'][optIndex]['promotion_type'] = event.target.value;
                if (promoTypeData && promoTypeData[event.target.value]) {
                    var field = promoTypeData[event.target.value].filter(itemx => itemx.includes('contribution_'))
                    if (field && field[0])
                        stickerData[index]['options'][optIndex][field[0]] = parseInt(100)

                }

            }
            setStickerData(stickerData)
        }

    }
    const handleonOpen = (index, item, optIndex) => {
        setOverWriteEnabled(true)
        setColumnIndex(index)
        setOptIndex(optIndex)
        var label = "Option " + (index + 1) + ":" + (promoFields && promoFields['x_cash_offers'] ? promoFields['x_cash_offers']['current'] : 'X Cash Offers') + " $" +
            (stickerData && stickerData[index] && stickerData[index]['benefit_extra_rebate'] ? stickerData[index]['benefit_extra_rebate'] : 0);

        setOpenedItem(label)
    }
    function handleOverWrite(value) {
        setOverWriteEnabled(false)
        if (value != 'cancel') {
            if (stickerData[columnIndex] && stickerData[columnIndex]['options'])
                stickerData[columnIndex]['options'][optIndex]['overwriting'] = value

            setStickerData(stickerData)
        }
        setOptIndex('')
        setColumnIndex('')
    }
    function handleOnAdd(index) {
        stickerData[index]['operation'] = 'OR'
        setStickerData([...stickerData, format]);
        if (description[index]) {
            var arrIndx = stickerData[index] && stickerData[index].options ? ((stickerData[index].options.length) * 2) - 1 : 0
            description[index][arrIndx] = 'OR'
            takeRate[index][arrIndx + 2] = 'OR'
        }
        else {
            description[index] = ['OR']
            takeRate[index] = ['OR']
        }
        // setTotal(total + 1)
    }
    const handleOperation = (value, index) => {
        stickerData[index]['operation'] = value
        if (description[index]) {
            var arrIndx = stickerData[index] && stickerData[index].options ? ((stickerData[index].options.length) * 2) - 1 : 0
            description[index][arrIndx] = value
            takeRate[index][arrIndx + 2] = value
        }
        else {
            description[index] = [value]
            takeRate[index] = [value]
        }
        setDescription(description)
        setTakeRate(takeRate)
        setStickerData([...stickerData])
        setUpdate(true)
    };

    function handleDelete(index) {
        var newData = stickerData.filter((item, i) => i != index)
        delete description[index]
        delete takeRate[index]
        setStickerData(newData)
        setDescription(description)
        setTakeRate(takeRate)
        setUpdate(true)
    }
    function handleDeleteOption(index, optIndex) {
        var newData = stickerData && stickerData[index] && stickerData[index]['options']
            && stickerData[index]['options'].filter((item, i) => i != optIndex)
        stickerData[index]['options'] = newData
        setStickerData(stickerData)
        ManipulateDescription(index)
    }

    function handleClose() {
        setOpen(false);
        props.onCopy(false);
        setIsChecked(-1);
    }

    function handleCopy() {
        setOpen(false);
        props.onCopy(false);
        var len = stickerData.length - 1
        stickerData[len]['operation'] = 'OR'
        var copiedData = JSON.parse(JSON.stringify({ ...stickerData[isChecked], operation: '' }));
        var data = [...stickerData]
        data.push(copiedData)
        setStickerData(data);
        if (description[len]) {
            var arrIndx = stickerData[len] && stickerData[len].options ? ((stickerData[len].options.length) * 2) - 1 : 0
            description[len][arrIndx] = 'OR'
            takeRate[len][arrIndx + 2] = 'OR'
        }
        else {
            description[len] = ['OR']
            takeRate[len] = ['OR']
        }
        setCurrentIndex(len + 1)
        setIsChecked(-1);
    }

    useEffect(() => {
        if (stickerData && stickerData[currentIndex]) {
            ManipulateDescription(currentIndex)
            setCurrentIndex('')
        }
    }, [stickerData && stickerData[currentIndex]])
    function handleCheck(e, index) {
        if (e.target.checked)
            setIsChecked(index);
        else
            setIsChecked(-1);
    }

    function handleChangeSelect(event, index, optIndex) {
        //for (var i = 1; i < event.length; i++) {
        // setStickerData([...stickerData, { promotion_type: '' }]);
        var optArray = []
        var count = total
        event && event.length > 0 && event.filter(element => element !== '').forEach((element, i) => {
            if (stickerData[index] && stickerData[index]['options']) {
                if (!stickerData[index]['options'][i] || (stickerData[index]['options'][i] && !stickerData[index]['options'][i]['promotion_type'] || stickerData[index]['options'][i]['promotion_type'] !== element)) {
                    var field;
                    if (promoTypeData && promoTypeData[element]) {
                        field = promoTypeData[element].filter(itemx => itemx.includes('contribution_'))
                        if (field && field[0])
                            optArray.push({
                                'promotion_type': element, 'option': count,
                                'operation': '+', [field[0]]: parseInt(100)
                            })
                        else
                            optArray.push({
                                'promotion_type': element, 'option': count,
                                'operation': (i !== event.length - 1) ? '+' : ''
                            })
                        //stickerData[index]['options'][i][field[0]] = parseInt(100)

                    }
                    count = count + 1
                }

            }
        });
        setTotal(count)
        if (event && event.length > 0) {
            if (stickerData[index] && stickerData[index]['options'] && stickerData[index]['options'].length == 1) {
                if (stickerData[index]['options'][0] && stickerData[index]['options'][0]['promotion_type'] == '') {
                    stickerData[index]['options'] = optArray
                    stickerData[index]['common_takerate'] = ''
                }
                else {
                    stickerData[index]['options'] = [...stickerData[index]['options'], ...optArray]
                    stickerData[index]['common_takerate'] = ''
                }
            }
            else {
                stickerData[index]['options'] = [...stickerData[index]['options'], ...optArray]
                stickerData[index]['common_takerate'] = ''
            }
        }

        setStickerData([...stickerData])
        // }
    }

    return (
        <div className={classes.root}>
            <Grid item
                container spacing={1}
                style={{ display: 'flex', flexDirection: 'row', }}
            >
                {stickerData && stickerData.map((itemX, index) => (
                    <>
                        <div className={classes.promoContainer}
                        //style={{ marginLeft: stickerData[index - 1] && stickerData[index - 1]['operation'] == '+' && -95 }}
                        >
                            <div style={{ width: '100%', height: '100%', backgroundColor: 'white', border: '0.8px solid #E2E2E2' }}>
                                <div className={classes.promoSubContainer}>
                                    {itemX && itemX.options && itemX.options.map((item, i) => (
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            // spacing={1}
                                            container
                                            className={classes.gridContainer}
                                            style={{
                                                width: 320, height: '100%', borderRight: i < itemX.options.length - 1 && '0.8px solid #E2E2E2',
                                            }}
                                        >
                                            <Grid
                                                item
                                                container
                                                md={12}
                                                xs={12}
                                                style={{ border: '1px solid #eeeeee', marginTop: 0, backgroundColor: '#e8e8e8', width: '100%' }}
                                            >

                                                <Grid
                                                    item
                                                    md={4.5}
                                                    xs={12}
                                                    className={classes.subContainer}
                                                >
                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                        <FormLabel className={classes.formLabel} >OPTION {item.option ? parseInt(item.option) : index + 1}</FormLabel>
                                                    </div>
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={6.5}
                                                    xs={12}
                                                    style={{ marginTop: '-15px' }}
                                                >
                                                    <MultiSelectDropdown
                                                        capitalize
                                                        bgColor
                                                        id={'promotion_type' + index}
                                                        btn={true}
                                                        disabled={i > 0}
                                                        onChange={(e) => handleChangeSelect(e, index, i)}
                                                        data={stickerData && stickerData[index] && stickerData[index]['options'] && stickerData[index]['options'][i] &&
                                                            stickerData[index]['options'][i]['promotion_type'] ? [stickerData[index]['options'][i]['promotion_type']] : []}
                                                        listArray={promoTypes}
                                                    />

                                                </Grid>
                                                {itemX.options.length > 1 &&
                                                    <Grid
                                                        item
                                                        md={0.5}
                                                        xs={12}
                                                        style={{ padding: '2px' }}
                                                    >
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => handleDeleteOption(index, i)}
                                                            size="medium"
                                                            style={{ display: itemX.options.length > 1 ? 'block' : 'none' }}
                                                        >
                                                            <Close style={{ fontSize: 16 }} />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>

                                            <div className={classes.fieldContainer} >
                                                {//stickerData[index] && stickerData[index]['promotion_type'] &&
                                                    itemX && itemX['options'] && itemX['options'][i] && itemX['options'][i]['promotion_type'] &&
                                                    promoTypeData && promoTypeData[itemX['options'][i]['promotion_type']] &&
                                                    promoTypeData[itemX['options'][i]['promotion_type']]
                                                        .filter(itemx => promoFields ? (promoFields[itemx] && itemx) : itemx)
                                                        .map(itemx => (
                                                            <Grid
                                                                item
                                                                md={12}
                                                                xs={12}
                                                                container
                                                                style={{ alignContent: 'flex-start' }}
                                                                className={classes.subContfainer}

                                                            >
                                                                <FormLabel className={classes.stickerLabel} style={{ marginTop: itemx == "overwriting" && 20 }}>{promoFields && promoFields[itemx] ? promoFields[itemx]['current'] : itemx.replace(/_/g, ' ')}</FormLabel>

                                                                <Grid
                                                                    item
                                                                    md={12}
                                                                    xs={12}
                                                                    container
                                                                    style={{ display: 'flex' }}
                                                                >
                                                                    {itemx !== "overwriting" ?
                                                                        <OutlinedInput
                                                                            key={itemx + "-" + index + "-" + i}
                                                                            value={itemX['options'][i][itemx]}
                                                                            type={promoFields && promoFields[itemx] && promoFields[itemx]['data_type'] == 'numeric' ? 'number' : 'string'}
                                                                            //inputProps={{ min: "0", step: "1" }}
                                                                            classes={{ root: classes.input, input: classes.inputTwoLine }}
                                                                            onChange={(e) => handleChange(e, index, itemx, i)}
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    {itemx && (itemx.toLowerCase().includes('duration') || itemx.toLowerCase().includes('length')) ?
                                                                                        <IconButton edge="end">
                                                                                            <span style={{ fontSize: '1rem' }}>M</span>
                                                                                        </IconButton> :
                                                                                        (itemx && (itemx.toLowerCase().includes('interest') || itemx.toLowerCase().includes('takerate') || itemx.toLowerCase().includes('contribution')) ?
                                                                                            <IconButton edge="end">
                                                                                                <Percent fontSize="small" />
                                                                                            </IconButton> :
                                                                                            (itemx && (itemx.toLowerCase().includes('benefit') || itemx.toLowerCase().includes('costing')) ?
                                                                                                <IconButton edge="end">
                                                                                                    <AttachMoney fontSize="small" />
                                                                                                </IconButton> : ""))
                                                                                    }
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        :
                                                                        <>
                                                                            <Typography variant='h5' color='grey'> Write how it will appear to dealers</Typography>
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={() => handleonOpen(index, itemx, i)}
                                                                                edge="end"
                                                                                className={classes.overwriteBtn}
                                                                            >
                                                                                <AddCommentOutlined />
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                </Grid>

                                                            </Grid>
                                                        ))
                                                }
                                            </div>
                                        </Grid >

                                    ))}

                                </div>
                                {(itemX && itemX.options && itemX.options.length > 1) /*|| (stickerData[index] && stickerData[index]['operation'] == '+'))*/ &&
                                    <div className={classes.commonContainer}
                                    >
                                        <FormLabel className={classes.stickerLabel}>Common TakeRate</FormLabel>
                                        <OutlinedInput
                                            key={'common_takerate' + index}
                                            value={itemX['common_takerate']}
                                            type={'number'}
                                            style={{ width: '45%' }}
                                            classes={{ root: classes.input, input: classes.inputTwoLine }}
                                            onChange={(e) => handleChange(e, index, 'common_takerate')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        <Percent fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                }
                            </div>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                className={classes.iconContainer}
                            >
                                {(stickerData.length > index + 1) ?
                                    <div style={{ marginTop: -40 }}>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={stickerData[index] ? stickerData[index]['operation'] : ''}
                                            exclusive
                                            onChange={(e, value) => handleOperation(value, index)}
                                            aria-label="Platform"
                                            id={'operation' + index}
                                            size='small'
                                            style={{ margin: '0px 0px 0px 0px' }}
                                        >
                                            <ToggleButton value="+"
                                                style={{ color: stickerData[index] && stickerData[index]['operation'] && stickerData[index]['operation'] == '+' ? 'white !important' : 'initial' }}
                                                className={stickerData[index] && stickerData[index]['operation'] && stickerData[index]['operation'] == '+' && classes.togglebBtn}>
                                                + </ToggleButton>
                                            <ToggleButton value="OR" className={stickerData[index] && stickerData[index]['operation'] && stickerData[index]['operation'] == 'OR' && classes.togglebBtn}>
                                                OR </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    : <div style={{ display: "flex", marginTop: -40 }}>
                                        <IconButton
                                            classes={{ root: classes.IconAddButton }}
                                            onClick={() => handleOnAdd(index)}
                                            size="large">
                                            <AddBoxOutlined />
                                        </IconButton>
                                        {index != 0 &&
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => handleDelete(index)}
                                                size="large">
                                                <Close style={{ fontSize: 20 }} />
                                            </IconButton>
                                        }
                                    </div>}
                            </Grid>
                        </div>

                    </>
                ))}
            </Grid >
            {overWriteEnabled &&
                <OverWrite label={openedItem} open={overWriteEnabled} onChange={handleOverWrite} data={stickerData[columnIndex]?.['options']?.[optIndex]?.['overwriting']} />
            }
            {
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                        {'COPY ANY PREVIOUS OPTIONS?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div style={{ display: 'flex' }}>
                                <ErrorOutlineOutlined /> <Typography variant='h5' color='black'>This action will pre-fill your next selection</Typography>
                            </div>
                            <hr></hr>
                            {stickerData.map((item, index) => (
                                <>
                                    <div key={index} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            checked={isChecked == index}
                                            onChange={(e) => handleCheck(e, index)}
                                            color='primary'
                                            inputProps={{ 'aria-label': 'primary checkbox' }}

                                        />
                                        {item.options && item.options.length > 1 ? 'BOTH :' + item.options.map(item => item.promotion_type.replace(/_/g, ' ')).join("+")
                                            : 'OPTION' + (item.options && item.options[0] ? item.options[0]['option'] + 1 + " : " + item.options[0]['promotion_type'].replace(/_/g, ' ') : '')}
                                    </div>
                                    <hr></hr>
                                </>
                            ))}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
                        <Button autoFocus onClick={handleClose}>
                            Skip
                        </Button>
                        <Button variant='contained' onClick={handleCopy} disabled={isChecked != -1 ? false : true}>Copy</Button>
                    </DialogActions>
                </Dialog>
            }
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type) => dispatch(createGlobalSetup(data, null, type)),
        getPromotionTypes: () => dispatch(getPromoTypes()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerContainer);


