import React, { useEffect } from 'react';
import {
    Typography, FormControl,
    OutlinedInput, Button, Select, MenuItem, InputAdornment
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ErrorOutlineOutlined, NavigateNext, SaveOutlined, SellOutlined, Troubleshoot } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createStickers, getBudgetingConfig, getModels } from '../../../../../redux/actions';
import { Header, DefineMarket } from './Components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        //paddingTop: -10
        paddingTop: "-35px !important"
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    },
    container: {
        marginTop: 20,
        height: 44,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E2E2',
        paddingBottom: 10,
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        cursor: 'pointer'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    subContainer: {
        // backgroundColor: '#F8F8F8',
        display: 'flex',
        marginLeft: 21,

    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const MarketDefinition = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [modelYear, setModelYear] = React.useState([]);
    const [stickerName, setStickerName] = React.useState('');
    const [sticker, setSticker] = React.useState('');
    const [headerData, setHeaderData] = React.useState({});
    const [defineMarket, setDefineMarket] = React.useState({});
    const [defineMarketChild, setDefineMarketChild] = React.useState({});
    const [saved, setSaved] = React.useState(false);

    useEffect(() => {
        if (sticker) {
            setDefineMarket(props.defineMarket ? props.defineMarket[sticker] : '')
            setDefineMarketChild(props.defineMarketChild ? props.defineMarketChild[sticker] : '')
        }
    }, [props.defineMarket, props.defineMarketChild, sticker]);

    useEffect(() => {
        if (props.sticker) {
            setSticker(props.sticker)
        }

    }, [props.sticker]);
    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData[0].promotion_division)
        }
    }, [props.configData]);
    function handleHeaderData(value) {
        if (value == 'return')
            props.onChange('return')
        else
            setHeaderData(value)

    }
    function handleStickerData(value, type) {
        if (type == 'material_group1') {
            setModelYear(value)
            setDefineMarketChild(prev => ({
                ...prev,
                'model_year': value
            }))
        }
        else
            setStickerData(value)
    }
    function handleSticker(value) {
        setSticker(value)
        setSaved(false)
    }
    function handleApplyGrid(type) {
        var division = props.stickers && props.stickers.filter(item => item.ID == sticker).map(e => e.product_lines)
        var productLine = division ? division[0] && division[0].length > 1 ? division[0].filter(item => props.productLines.includes(item)) : division[0] : []

        var data = {
            grid_data: { [sticker]: { ...defineMarket, 'product_lines': productLine } },
            display_data: { [sticker]: defineMarketChild }
        }
        props.onChange(data, type, sticker)
        setSaved(true)

    }
    function handleDefineMarket(value, child, type) {
        setDefineMarket(prev => ({
            ...prev,
            [type]: value
        }))
        setDefineMarketChild(prev => ({
            ...prev,
            [type]: child
        }))
        setSaved(false)
    }
    return (
        <div className={classes.root}>
            <div>
                <Header configData={productLinesList} defaultDivison={productType} onChange={handleHeaderData} stickerName={stickerName} country={props.country}
                    handleBack={props.handleBack}
                />
            </div>
            <div className={classes.container} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.subContainer}>
                    <FormControl variant="outlined" fullWidth>{console.log(sticker)}
                        <Select
                            value={sticker}
                            onChange={(e) => handleSticker(e.target.value)}
                            label="Select"
                            input={<OutlinedInput startAdornment={<InputAdornment position="start"><SellOutlined /></InputAdornment>} />}
                            style={{ width: '300px' }}
                        >
                            <MenuItem value={''}></MenuItem>
                            {props.stickers && props.stickers.map((option) => (
                                <MenuItem key={option.sticker_description} value={option.ID} style={{ display: 'flex' }}>
                                    <CircleIcon style={{ color: props.colorPicker && props.colorPicker[option.ID] }} fontSize='12px' />
                                    {(option.promo_variability ? 'PV -' : '') + (option.version > 1 ? `V${option.version} - ${option.sticker_description}` : option.sticker_description)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ display: 'flex', marginLeft: 46 }}>
                        <ErrorOutlineOutlined /> <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>You are currently defining market for a {props.country && props.country == 'CA' ? 'CANADA' : 'USA'} sticker</Typography>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button variant='Outlined' color='primary' style={{ marginRight: 5 }}> Cancel</Button>
                    <Button variant={saved ? 'contained' : 'outlined'} color='primary' onClick={() => handleApplyGrid('save')} disabled={!sticker ? true : false} style={{ marginRight: 5 }}>
                        {saved ? <><DoneOutlineIcon style={{ marginRight: 2 }} /> Saved</> : 'Save Market'}
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => handleApplyGrid('market')} disabled={!sticker ? true : false}> Apply Market</Button>
                </div>

            </div>
            <DefineMarket onChange={handleStickerData} filterData={props.filters}
                defineMarket={sticker && props.defineMarket ? props.defineMarket[sticker] : ''} modelData={props.modelData}
                defineMarketChild={sticker && props.defineMarketChild ? props.defineMarketChild[sticker] : ''}
                handleDefineMarket={handleDefineMarket} locationData={props.locations}
                modelYears={props.modelYears}
            />

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        createSticker: (data) => dispatch(createStickers(data, 'create')),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketDefinition);


// ['160_2020_Spyde', '160_2020_Spyde_spyde1', '160_2020_Spyde_mat1', '160_2022_Spyde', '160_2022_Spyde_nkm', '160_2020_def',
//     '161_2020_Spyde', '161_2020_Spyde_spyde1', '162_2020_Spyde_mat1', '163_2022_Spyde', '164_2022_Spyde_nkm', '161_2020_def'
// ]