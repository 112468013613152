import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select, TextField, Checkbox, ListItemText, Input
} from '@mui/material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { allowedApps, addExceptionManagement, getContractsTableDataList, getMaterialMasterData, getExceptionManagementAll, getCustomerMasterData, getSupplierMasterData, getEmployeeData, getExceptionTierEvals, fetchDropdownConfig, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import MomentUtils from "@date-io/moment";
import { useHistory } from 'react-router-dom';
import { AllOtherAttributeMasterComponent, ContractNumberAC, MasterDataComponent, SearchDropdown } from '../../components/Inputs';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown } from '../../components/Inputs';
import { isEmpty } from 'validate.js';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 35
    },
    inputTwoLine: {
        marginTop: 0,
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectedItem: {
        color: theme.palette.text.grey
    },

    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));
const EditExceptionManagement = props => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [exceptionValue, setExceptionValue] = React.useState();
    const [overrideReason, setOverrideReason] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [contractNumber, setContractNumber] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [allowedApps, setAllowedApps] = React.useState([sessionStorage.getItem('application')]);
    const [exceptionIdentifier, setExceptionIdentifier] = React.useState([]);
    const [exceptionIdentifierApi, setExceptionIdentifierApi] = React.useState([]);
    const [calculationRules, setCalculationRules] = React.useState([]);
    const [indentifierValue, setIdentifierValue] = React.useState('');
    const [exceptionType, setExceptionType] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [contractNumbersList2, setContractNumbersList2] = React.useState([]);
    const [internalDescriptionArray2, setInternalDescriptionArray2] = React.useState([]);
    const [contractNumberMultiple, setContractNumberMultiple] = React.useState([]);
    const [exceptionIdentifierArray, setExceptionIdentifierArray] = React.useState([]);
    const [attributeValues, setAttributeValues] = React.useState({});
    const [attributeValues2, setAttributeValues2] = React.useState({});
    const [selected3, setSelected3] = React.useState([]);
    const [open3, setOpen3] = React.useState('');
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [materialNumberListArray, setMaterialNumberListArray] = React.useState([]);
    const [materialNumberList, setMaterialNumberList] = React.useState([]);
    const [supplierNumberListArray, setSupplierNumberListArray] = React.useState([]);
    const [supplierNumberList, setSupplierNumberList] = React.useState([]);
    const [employeeNumberListArray, setEmployeeNumberListArray] = React.useState([]);
    const [employeeNumberList, setEmployeeNumberList] = React.useState([]);
    const [dynamicFields, setDynamicFields] = React.useState([])
    const [calcExceptionValue, setCalcExceptionValue] = React.useState([])
    const [type, setType] = React.useState('')
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState({});
    const [externalFields, setExternalFields] = React.useState([]);
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    const isAllSelected3 =
        exceptionIdentifierArray && exceptionIdentifierArray.length > 0 && selected3.length === exceptionIdentifierArray.length;
    const isAllExceptionValue =
        calculationRules && calculationRules.length > 0 && selected3.length === calculationRules.length;

    useEffect(() => {
        props.onCheckContractsTable(localStorage.getItem('application_type'))
        props.getdropdownConfig()
    }, []);

    useEffect(() => {
        var pathname = window.location.pathname;
        var ID = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(ID);
        if (ID)
            props.getExceptionManagementAll(ID);

        var data = { "data_source": "incentive_overrides" };
        props.getLblDispDesFieldValue(data, 'post');
    }, []);

    useEffect(() => {
        if (props.tableData) {
            setFieldLabel(props.tableData.field_label_attributes);
        }
    }, [props.tableData])

    useEffect(() => {
        if (props.dropdownConfig && props.dropdownConfig[0] && props.dropdownConfig[0].default_configuration)
            setActiveDropdown(props.dropdownConfig[0].default_configuration)

    }, [props.dropdownConfig]);

    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    newArray.push(item)
                    if (item.mandatory || item.sort_details) {
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])



    // useEffect(() => {
    //     if (props.exceptionManagementTierEvals) {
    //         Object.entries(props.exceptionManagementTierEvals).map(([key, value]) => {
    //             if (value.name === 'Customer Number' && activeDropdown && activeDropdown['customer_number']) {
    //                 props.getCustomerMasterData(1, 0);
    //             }
    //             if (value.name === 'Material Number' && activeDropdown && activeDropdown['customer_number']) {
    //                 props.getMaterialMasterData()
    //             }
    //             if (value.name === 'Supplier Number' && activeDropdown && activeDropdown['supplier_number']) {
    //                 props.getMaterialMasterData()
    //             }
    //             if (value.name === 'Employee Number' && activeDropdown && activeDropdown['employee_number']) {
    //                 props.getMaterialMasterData()
    //             }
    //         }
    //         )
    //     }
    // }, [props.exceptionManagementTierEvals])
    // useEffect(() => {
    //     if ((exceptionIdentifier && exceptionIdentifier.length > 0 || calcExceptionValue && calcExceptionValue.length > 0) && activeDropdown) {
    //         if ((exceptionIdentifier.includes('customer_number') || calcExceptionValue.includes('customer_number')) &&
    //             activeDropdown['customer_number'] && customerNumberList.length == 0) {
    //             props.getCustomerMasterData(1, 0);
    //         }
    //         if ((exceptionIdentifier.includes('material_number') || calcExceptionValue.includes('material_number')) &&
    //             activeDropdown['material_number'] && materialNumberList.length == 0) {
    //             props.getMaterialMasterData()
    //         }
    //         if ((exceptionIdentifier.includes('supplier_number') || calcExceptionValue.includes('supplier_number')) &&
    //             activeDropdown['supplier_number'] && supplierNumberList.length == 0) {
    //             props.getSupplierMasterData()
    //         }
    //         if ((exceptionIdentifier.includes('employee_number') || calcExceptionValue.includes('employee_number')) &&
    //             activeDropdown['employee_number'] && employeeNumberList.length == 0) {
    //             props.getEmployeeData()
    //         }
    //     }
    // }, exceptionIdentifier, calcExceptionValue, activeDropdown)
    // useEffect(() => {
    //     if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
    //         var tempArray = [];
    //         var tempNameArray = [];
    //         props.customerMasterData.records.map((item) => {
    //             tempArray.push(item.customer_number ? item.customer_number : '');
    //             tempNameArray.push(item.customer_name ? item.customer_name : '');
    //         })
    //         setCustomerNumberList(tempArray)
    //         setCustomerNameListArray(tempNameArray);
    //     }
    // }, [props.customerMasterData])
    // useEffect(() => {
    //     if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
    //         var tempArray = []
    //         var tempNameArray = []
    //         props.materialMasterData.records.map((item) => {
    //             tempArray.push(item.material_number ? item.material_number : '');
    //             tempNameArray.push(item.material_description ? item.material_description : '');
    //         })
    //         setMaterialNumberList(tempArray)
    //         setMaterialNumberListArray(tempNameArray);
    //     }
    // }, [props.materialMasterData])
    // useEffect(() => {
    //     var newArray = [];
    //     var tempNameArray = [];
    //     if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0)
    //         props.supplierMasterData.records
    //             .map(e => {
    //                 newArray.push(e.supplier_number ? e.supplier_number : '');
    //                 tempNameArray.push(e.supplier_name ? e.supplier_name : '');
    //             })
    //     // setDropDownValue(newArray.sort((a, b) => a - b));
    //     setSupplierNumberList(newArray);
    //     setSupplierNumberListArray(tempNameArray);
    // }, [props.supplierMasterData])

    // useEffect(() => {
    //     var newArray = []
    //     var tempNameArray = [];
    //     if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0)
    //         props.employeeMasterData.records
    //             .map(e => {
    //                 newArray.push(e.employee_number ? e.employee_number : '');
    //                 tempNameArray.push(e.employee_name ? e.employee_name : '');
    //             })
    //     // setDropDownValue(newArray.sort((a, b) => a - b));
    //     setEmployeeNumberList(newArray);
    //     setEmployeeNumberListArray(tempNameArray);
    // }, [props.employeeMasterData]);
    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.contractTabledataComplete) {
            props.contractTabledataComplete.map(item => {
                contracts.push(item.contract_number ? item.contract_number.toString() : '')
                tempNameArray.push(item.internal_description ? item.internal_description : '');
            })
            setContractNumbersList2(contracts);
            setInternalDescriptionArray2(tempNameArray);
        }
    }, [props.contractTabledataComplete])
    useEffect(() => {
        var newArray = []
        if (props.exceptionManagementSingle) {
            setApplicationType(props.exceptionManagementSingle.application_type ? props.exceptionManagementSingle.application_type : '');
            setContractNumberMultiple(props.exceptionManagementSingle.contract_number && props.exceptionManagementSingle.contract_number);
            props.getExceptionTierEvals(props.exceptionManagementSingle.application_type, props.exceptionManagementSingle.contract_number)
            setStartDate(props.exceptionManagementSingle.start_date ? Moment(props.exceptionManagementSingle.start_date) : Moment());
            setEndDate(props.exceptionManagementSingle.end_date ? Moment(props.exceptionManagementSingle.end_date) : Moment());
            setExceptionValue(props.exceptionManagementSingle.exception_value && props.exceptionManagementSingle.exception_value);
            setOverrideReason(props.exceptionManagementSingle.exception_reason && props.exceptionManagementSingle.exception_reason);
            setComments(props.exceptionManagementSingle.comments && props.exceptionManagementSingle.comments);
            setExceptionType(props.exceptionManagementSingle.exception_type && props.exceptionManagementSingle.exception_type);
            if (props.exceptionManagementSingle.exception_mapping !== null) {
                setExceptionIdentifier(Object.keys(props.exceptionManagementSingle.exception_mapping).filter(item => item != 'rule_exception'));
                setAttributeValues(Object.entries(props.exceptionManagementSingle.exception_mapping).filter(([k, v]) => k != 'rule_exception').reduce((r, [k, v]) => ({ ...r, [k]: v }), {}))
                //setExceptionIdentifierApi(Object.keys(props.exceptionManagementSingle.exception_mapping));
                if (props.exceptionManagementSingle.exception_mapping.rule_exception) {
                    {
                        setCalcExceptionValue(Object.keys(props.exceptionManagementSingle.exception_mapping.rule_exception))
                        setAttributeValues2(props.exceptionManagementSingle.exception_mapping.rule_exception)
                    }
                }

            }

        }
    }, [props.exceptionManagementSingle])
    useEffect(() => {
        var newArray = []
        var newArray2 = []
        if (props.exceptionManagementTierEvals && Object.entries(props.exceptionManagementTierEvals).length > 0) {
            Object.entries(props.exceptionManagementTierEvals).map(([key, value]) => {
                if (!value['is_key_figure'])
                    newArray.push({ 'key': key, ...value })
                if (value['is_key_figure'])
                    newArray2.push({ 'key': key, ...value })
            })
            setExceptionIdentifierArray(newArray)
            setCalculationRules(newArray2)
        }

    }, [props.exceptionManagementTierEvals])
    useEffect(() => {
        var newArray = []
        if (exceptionIdentifier && exceptionIdentifier.length > 0 && exceptionIdentifierArray && exceptionIdentifierArray.length > 0) {
            exceptionIdentifierArray.map(item => {
                if (exceptionIdentifier.includes(item.key)) {
                    newArray.push(item)
                }
            })
            setDynamicFields(newArray)
        }

    }, [exceptionIdentifier, exceptionIdentifierArray])
    useEffect(() => {
        var newArray = []
        if (exceptionIdentifierApi && exceptionIdentifierApi.length > 0 && props.exceptionManagementTierEvals && props.exceptionManagementTierEvals) {

            Object.entries(props.exceptionManagementTierEvals).map(item => {
                // newArray.push(item)
                // newArray.push(Object.values(item)[1].name)
                exceptionIdentifierApi.map(e => {
                    if (Object.values(item)[0] === e) {
                        newArray.push(item[1].name)
                    }
                })
            })
            // setDynamicFields(newArray)
            setExceptionIdentifier(newArray)
        }

    }, [exceptionIdentifierApi, props.exceptionManagementTierEvals])
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    const filterOptions2 = createFilterOptions({
        stringify: (option) => internalDescriptionArray2[contractNumbersList2.indexOf(option)] + option
    });
    const handleIdentifierValue = (e, value) => {
        let newSelected = value;
        if (value.length > 0) {
            setSelected2(newSelected)
            setIdentifierValue(newSelected);
        }
        else {
            setSelected2([])
            setIdentifierValue([]);
        }
        // props.onChange(value)
    };
    function handleExceptionReason(newValue) {
        if (newValue) {
            setOverrideReason(newValue);
        }
        else
            setOverrideReason('')
        // props.onChange(formData);
    }
    const handleApplicationtype = (e) => {
        if (e && e.target.value)
            setApplicationType(e.target.value);
    }
    const handleExceptionIdentifier = (e) => {
        setExceptionIdentifier(e.target.value)

        if (e.target.value === 'customer_number') {
            props.getCustomerMasterData(1, 0);
        }
        if (e.target.value === 'supplier_number') {
            props.getSupplierMasterData(1, 0);
        }
        if (e.target.value === 'employee_number') {
            props.getEmployeeData(1, 0)
        }
    }


    const handleExceptionType = (e) => {
        setExceptionType(e.target.value)
    }
    const handleContractNumberMultiple = (newValue) => {
        let newSelected = newValue;
        if (newValue.length > 0) {
            setSelected(newSelected);
            setContractNumberMultiple(newSelected);
            setExceptionIdentifierArray([])
            setCalculationRules([])
            props.getExceptionTierEvals(applicationType, newSelected)
        } else {
            setSelected([]);
            setContractNumberMultiple([]);
            setExceptionIdentifierArray([])
            setCalculationRules([])
        }
    };
    function handleOnSubmit() {
        var tempData = {}
        tempData[exceptionIdentifier] = indentifierValue;

        var data = {
            "application_type": applicationType,
            "contract_number": parseInt(contractNumberMultiple),
            "start_date": Moment(startDate).format('YYYY-MM-DD'),
            "end_date": Moment(endDate).format('YYYY-MM-DD'),
            "exception_value": parseFloat(exceptionValue),
            "exception_reason": overrideReason,
            "comments": comments,
            "exception_type": exceptionType,
        };
        data["exception_mapping"] = attributeValues
        if (exceptionType.includes('calculation_rule')) {
            attributeValues["rule_exception"] = attributeValues2;
        }
        var pathname = window.location.pathname;
        var ID = pathname.substring(pathname.lastIndexOf('/') + 1);
        props.onSubmit(data, 'put', ID);
    }
    function handleClearAll() {
        // setApplicationType('')
        setContractNumber('')
        setStartDate(null)
        setEndDate(null)
        setExceptionValue('')
        setOverrideReason('')
        setComments('')
        setExceptionType('')
        setIdentifierValue('')
        setExceptionIdentifier([])
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected3.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected3, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected3.slice(1));
        } else if (selectedIndex === selected3.length - 1) {
            newSelected = newSelected.concat(selected3.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected3.slice(0, selectedIndex),
                selected3.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (type == 'exception_value')
                setSelected3(selected3.length === calculationRules.map(item => item.key).length ? [] : calculationRules.map(item => item.key));

            else
                setSelected3(selected3.length === exceptionIdentifierArray.length ? [] : exceptionIdentifierArray.map(item => item.key));
        }
        else
            setSelected3(newSelected)
    }
    function handleOpen(item) {
        setType(item)
        if (item == 'exception_value')
            setSelected3(calcExceptionValue)
        else
            setSelected3(exceptionIdentifier)
        setOpen3(true)
    }
    function onCancel() {
        setOpen3(false)
        setSelected3([])
    }
    function onAdd(type) {
        if (type == 'exception_value')
            setCalcExceptionValue(selected3)
        else
            setExceptionIdentifier(selected3)
        setOpen3(false)
        setSelected3([])
    }
    function handleAttributeValues(newValue, item, key) {
        setAttributeValues({ ...attributeValues, [item]: key ? Number(newValue) : newValue })
    }
    function handleAttributeValues2(newValue, item, key) {
        setAttributeValues2({ ...attributeValues2, [item]: key ? Number(newValue) : newValue })
    }
    const filterOptionsCustomer = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    const filterOptionsMaterial = createFilterOptions({
        stringify: (option) => materialNumberListArray[materialNumberList.indexOf(option)] + option
    });
    function handleClose() {
        setOpen3(false)
        setType([])
    }
    function handleStartDate(newValue) {
        if (newValue && newValue.isValid()) {
            setStartDate(newValue);
        } else {
            setStartDate(newValue);
        }
    }
    function handleEndDate(newValue) {
        if (newValue && newValue.isValid()) {
            setEndDate(newValue);
        } else {
            setEndDate(newValue);
        }
    }

    return (
        <div className={classes.root}>{console.log(activeDropdown['supplier_number'])}
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/exception-management'
                >
                    Exception Management
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Exception</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['application_type'] ? fieldLabel['application_type']['current'] : 'Application Type'}
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    disabled
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >   {allowedApps && allowedApps.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['contract_number'] ? fieldLabel['contract_number']['current'] : 'Contract Number'}
                                </FormLabel>
                                <div className={classes.dropdownColumn} style={{ marginTop: -3 }} >
                                    <SearchDropdown id='contract_number_multiple'
                                        table='contract_header' keyName='contract_number'
                                        description={true}
                                        onChange={handleContractNumberMultiple}
                                        applicationType={sessionStorage.getItem("application")}
                                        prevalue={contractNumberMultiple}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                {exceptionIdentifierArray && exceptionIdentifierArray.length > 0 ?
                                    <>
                                        <FormLabel
                                            classes={{ root: classes.formLabel }}
                                        >
                                            {fieldLabel['exception_mapping'] ? fieldLabel['exception_mapping']['current'] : 'Exception Identifier'}
                                        </FormLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={exceptionIdentifier}
                                            style={{ maxHeight: "50px", width: '100%', marginTop: 0, textTransform: 'capitalize' }}
                                            input={<OutlinedInput sx={{ padding: '4px' }} />}
                                            onOpen={() => handleOpen('identifier')}
                                            onClose={handleClose}
                                            open={open3 && type == 'identifier'}
                                            renderValue={(appType) => {
                                                var priceType = []
                                                priceType = exceptionIdentifierArray.filter(item => appType.includes(item.key)).map(item => item.name)
                                                return priceType.join(", ")
                                            }}
                                            className={classes.multiSelect}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all', 'identifier')}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isAllSelected3} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {exceptionIdentifierArray && exceptionIdentifierArray.length > 0 && exceptionIdentifierArray
                                                .map(item => {
                                                    return (
                                                        <MenuItem onClick={(event) => handleSelectValues(event, item.key, 'identifier')} value={item.key} key={item.key} style={{ textTransform: 'capitalize' }} >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected3.indexOf(item.key) > -1} />
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            <div className={classes.dropdownbottom}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('identifier')} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </>
                                    :
                                    <LabelText style={{ marginTop: '0.6rem' }} heading={fieldLabel['exception_mapping'] ? fieldLabel['exception_mapping']['current'] : 'Exception Identifier'} data={'Contract Number'} twoline='true' border />
                                }
                            </Grid>
                            {dynamicFields && dynamicFields.length > 0 && dynamicFields.map(item => {
                                return (

                                    (item.key == 'supplier_number' || item.key == 'employee_number' || item.key == 'customer_number' || item.key == 'material_number') && activeDropdown && activeDropdown[item.key] ?
                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                            style={{ marginTop: '-1px' }}
                                        >
                                            <FormLabel className={classes.formLabel}>
                                                {item.name}
                                            </FormLabel>
                                            <div style={{ marginTop: -3 }}>
                                                <SearchDropdown id='exception_single'
                                                    table={item.key == 'supplier_number' ? 'supplier_master' : item.key == 'employee_number' ? 'employee_master'
                                                        : item.key == 'material_number' ? 'material_master' : 'customer_master'}
                                                    keyName={item.key}
                                                    description={true}
                                                    prevalue={attributeValues[item.key]}
                                                    onChange={(value) => handleAttributeValues(value, item.key, item.is_key_figure)}
                                                />
                                            </div>
                                        </Grid>

                                        :
                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}

                                        >
                                            {props.viewMode ?
                                                <LabelText heading={item.name} data={attributeValues && attributeValues[item.key] && attributeValues[item.key]} twoline='true' required />
                                                :
                                                <>
                                                    <FormLabel className={classes.formLabel}>
                                                        {item.name}
                                                    </FormLabel>
                                                    {/* Object.values(item)[1].name */}
                                                    <OutlinedInput
                                                        type={item.is_key_figure ? 'Number' : 'string'}
                                                        value={attributeValues && attributeValues[item.key]}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={(e) => handleAttributeValues(e.target.value ? e.target.value : 0, item.key, item.is_key_figure)}
                                                    />
                                                </>
                                            }
                                        </Grid>
                                )
                            })}

                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: '-0.3rem' }}
                            >   <FormLabel
                                classes={{ root: classes.formLabel }}
                                required
                            >
                                    {fieldLabel['start_date'] ? fieldLabel['start_date']['current'] : 'Start Date'}
                                </FormLabel>
                                <Datepicker
                                    twoline='true' {...props} onChange={handleStartDate} error={typeof startDate === 'boolean' && !startDate ? true : false}
                                    prevalue={startDate} />

                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: '-0.3rem' }}
                            >   <FormLabel
                                classes={{ root: classes.formLabel }}
                                required
                            >
                                    {fieldLabel['end_date'] ? fieldLabel['end_date']['current'] : 'End Date'}
                                </FormLabel>
                                <div>
                                    <Datepicker
                                        twoline='true'{...props} onChange={handleEndDate} error={typeof endDate === 'boolean' && !endDate ? true : false}
                                        prevalue={endDate} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: "-2px" }}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['exception_type'] ? fieldLabel['exception_type']['current'] : 'Exception Type'}
                                </FormLabel>
                                <Select
                                    value={exceptionType}
                                    onChange={handleExceptionType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    style={{ height: 35 }}
                                ><MenuItem value={'calculation_rule'} key={'calculation_rule'} style={{ textTransform: 'capitalize' }}> Calculation Rule </MenuItem>
                                    <MenuItem value={'incentive_amount'} key={'incentive_amount'} style={{ textTransform: 'capitalize' }}> Incentive Amount </MenuItem>
                                    <MenuItem value={'tier_rate'} key={'tier_rate'} style={{ textTransform: 'capitalize' }}> Incentive Rate </MenuItem>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['exception_value'] ? fieldLabel['exception_value']['current'] : 'Exception Value'}
                                </FormLabel>
                                {exceptionType === 'calculation_rule' ?
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={calcExceptionValue}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 0 }}
                                        input={<OutlinedInput sx={{ padding: '4px' }} />}
                                        onOpen={() => handleOpen('exception_value')}
                                        onClose={handleClose}
                                        open={open3 && type == 'exception_value'}
                                        renderValue={(appType) => {
                                            var priceType = []
                                            priceType = calculationRules.filter(item => appType.includes(item.key)).map(item => item.name)
                                            return priceType.join(", ")
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all', 'exception_value')}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllExceptionValue} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {calculationRules && calculationRules.length > 0 && calculationRules
                                            .map(item => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.key, 'exception_value')} value={item.key} key={item.key}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected3.indexOf(item.key) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownbottom}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('exception_value')} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                    :
                                    <OutlinedInput
                                        value={exceptionValue}
                                        inputProps={{ min: "1", step: "1" }}
                                        type={'number'}
                                        classes={{ root: classes.inputTwoLine }}
                                        style={{ height: 36 }}
                                        onChange={(e) => setExceptionValue(e.target.value)} />
                                }
                            </Grid>

                            {calcExceptionValue && calcExceptionValue.length > 0 && calculationRules && calculationRules.length > 0 &&
                                calculationRules.filter(item => calcExceptionValue.includes(item.key)).map(item => {
                                    return (
                                        // item.key == 'customer_number' && customerNumberList.length>0 ?
                                        //     <Grid
                                        //         item
                                        //         md={2}
                                        //         xs={12}
                                        //         style={{ marginTop: '0.5rem' }}
                                        //     >

                                        //                 <FormLabel className={classes.formLabel}>
                                        //                     {item.name}
                                        //                 </FormLabel>
                                        //                 <Autocomplete
                                        //                     //multiple
                                        //                     //limitTags={2}
                                        //                     options={customerNumberList}
                                        //                     getOptionLabel={(option) => option}
                                        //                     renderInput={(params) => (
                                        //                         <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                        //                     )}
                                        //                     filterOptions={filterOptionsCustomer}
                                        //                     value={attributeValues2 && attributeValues2[item.key]}
                                        //                     style={{ marginBottom: 10, marginTop: 10 }}
                                        //                     onChange={(event, newValue) => handleAttributeValues2(newValue, item.key, item.is_key_figure)}
                                        //                     renderOption={(option, index) => {
                                        //                         return <>{customerNameListArray[customerNumberList.indexOf(option)] ? customerNameListArray[customerNumberList.indexOf(option)] : ''} - ({option})  </>;
                                        //                     }}
                                        //                     classes={{
                                        //                         option: {
                                        //                             borderBottom: `1px solid red`,
                                        //                             // Hover
                                        //                             '&[data-focus="true"]': {
                                        //                                 backgroundColor: 'red',
                                        //                                 borderColor: 'transparent',
                                        //                             },
                                        //                             // Selected
                                        //                             '&[aria-selected="true"]': {
                                        //                                 backgroundColor: 'red',
                                        //                                 borderColor: 'transparent',
                                        //                             },
                                        //                         },
                                        //                         listbox: {
                                        //                             padding: 0,
                                        //                         },
                                        //                         inputRoot: {
                                        //                             borderRadius: '0px !important'
                                        //                         }
                                        //                     }}
                                        //                 />
                                        //     </Grid>
                                        //     :
                                        //     item.key == 'material_number' && materialNumberList.length>0 ?
                                        //         <Grid
                                        //             item
                                        //             md={2}
                                        //             xs={12}
                                        //             style={{ marginTop: '0.5rem' }}
                                        //         >
                                        //                     <FormLabel className={classes.formLabel}>
                                        //                         {item.name}
                                        //                     </FormLabel>
                                        //                     <Autocomplete
                                        //                         // multiple
                                        //                         // limitTags={2}
                                        //                         options={materialNumberList}
                                        //                         getOptionLabel={(option) => option}
                                        //                         renderInput={(params) => (
                                        //                             <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                        //                         )}
                                        //                         filterOptions={filterOptionsMaterial}
                                        //                         value={attributeValues2 && attributeValues2[item.key]}
                                        //                         style={{ marginBottom: 10, marginTop: 10 }}
                                        //                         onChange={(event, newValue) => handleAttributeValues2(newValue, item.key, item.is_key_figure)}
                                        //                         renderOption={(option, index) => {
                                        //                             return <>{materialNumberListArray[materialNumberList.indexOf(option)] ? materialNumberListArray[materialNumberList.indexOf(option)] : ''} - ({option})  </>;
                                        //                         }}
                                        //                         classes={{
                                        //                             option: {
                                        //                                 borderBottom: `1px solid red`,
                                        //                                 // Hover
                                        //                                 '&[data-focus="true"]': {
                                        //                                     backgroundColor: 'red',
                                        //                                     borderColor: 'transparent',
                                        //                                 },
                                        //                                 // Selected
                                        //                                 '&[aria-selected="true"]': {
                                        //                                     backgroundColor: 'red',
                                        //                                     borderColor: 'transparent',
                                        //                                 },
                                        //                             },
                                        //                             listbox: {
                                        //                                 padding: 0,
                                        //                             },
                                        //                             inputRoot: {
                                        //                                 borderRadius: '0px !important'
                                        //                             }
                                        //                         }}
                                        //                     />
                                        //         </Grid>
                                        //         :
                                        //         (item.key == 'supplier_number'||item.key == 'employee_number') &&activeDropdown&&activeDropdown[item.key]?
                                        //         <Grid
                                        //             item
                                        //             md={2}
                                        //             xs={12}
                                        //             style={{ marginTop: '0.5rem' }}
                                        //         >
                                        //                     <FormLabel className={classes.formLabel}>
                                        //                         {item.name}
                                        //                     </FormLabel>
                                        //                     <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key}  onChange={(value) => handleAttributeValues2(value, item.key, item.is_key_figure)} />

                                        //           </Grid>   
                                        (item.key == 'supplier_number' || item.key == 'employee_number' || item.key == 'customer_number' || item.key == 'material_number') && activeDropdown && activeDropdown[item.key] ?
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            // style={{ marginTop: '0.5rem' }}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    {item.name}
                                                </FormLabel>
                                                <div style={{ marginTop: -3 }}>
                                                    <SearchDropdown id='rule_single'
                                                        table={item.key == 'supplier_number' ? 'supplier_master' : item.key == 'employee_number' ? 'employee_master'
                                                            : item.key == 'material_number' ? 'material_master' : 'customer_master'}
                                                        keyName={item.key}
                                                        description={true}
                                                        prevalue={attributeValues2[item.key]}
                                                        onChange={(value) => handleAttributeValues2(value, item.key, item.is_key_figure)}
                                                    />
                                                </div>
                                            </Grid>

                                            :
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            // classes={{ root: classes.gridContainer }}

                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    {item.name}
                                                </FormLabel>
                                                <OutlinedInput
                                                    type={item.is_key_figure ? 'Number' : 'string'}
                                                    value={attributeValues2 && attributeValues2[item.key]}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => handleAttributeValues2(e.target.value, item.key, item.is_key_figure)}
                                                />

                                            </Grid>
                                    )
                                })}

                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: "-2px" }}

                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                >
                                    {fieldLabel['exception_reason'] ? fieldLabel['exception_reason']['current'] : 'Exception Reason'}
                                </FormLabel>

                                {externalFields.map((currentObject, index) => {
                                    if (currentObject.default === 'Exception Reason') {
                                        return currentObject.drop_down_reference_value && currentObject.drop_down_reference_value.length > 0 ? (
                                            <Select
                                                value={overrideReason}
                                                onChange={(e) => handleExceptionReason(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ height: 37 }}
                                            >   {currentObject.drop_down_reference_value && currentObject.drop_down_reference_value.length > 0 && currentObject.drop_down_reference_value.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        {item.key}
                                                    </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        ) : (
                                            <OutlinedInput
                                                key={index}
                                                value={overrideReason}
                                                classes={{ root: classes.inputTwoLine }}
                                                style={{ marginTop: "2px" }}
                                                onChange={(e) => handleExceptionReason(e.target.value)}
                                            />
                                        );
                                    }
                                })}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel
                                        classes={{ root: classes.formLabel }}
                                    >
                                        {fieldLabel['comments'] ? fieldLabel['comments']['current'] : 'Comments'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={comments}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setComments(e.target.value ? e.target.value : '')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit}
                        disabled={!applicationType || !contractNumberMultiple || !startDate || !endDate || !exceptionType ||
                            (exceptionType == 'calculation_rule' ? (!exceptionValue && Object.entries(attributeValues2).length == 0) : !exceptionValue) ? true : false}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div >

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        loadingDropdownValues: state.addMultipleConfigurationData.loading,
        exceptionManagementSingle: state.customerData.exceptionManagementSingle,
        allowedAppsData: state.initialData.allowedApps,
        customerMasterData: state.customerData.customerMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
        employeeMasterData: state.customerData.employeeMasterData,
        contractTabledataComplete: state.addContractData.contractAllListData,
        materialMasterData: state.customerData.materialMasterData,
        exceptionManagementTierEvals: state.customerData.exceptionManagementTierEvals,
        dropdownConfig: state.financialCloseData.dropdownConfig
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(addExceptionManagement(data, type, id)),
        getExceptionManagementAll: (ID) => dispatch(getExceptionManagementAll(ID)),
        allowedApps: () => dispatch(allowedApps()),
        getCustomerMasterData: (pagination, limit) => dispatch(getCustomerMasterData(pagination, limit)),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit, '')),
        onCheckContractsTable: (applicationType) => dispatch(getContractsTableDataList(applicationType)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
        getExceptionTierEvals: (app, data) => dispatch(getExceptionTierEvals(app, data)),
        getdropdownConfig: () => dispatch(fetchDropdownConfig()),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditExceptionManagement);