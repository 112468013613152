import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell, InputLabel,
    TableHead, TableRow, TableBody, TextField, FormControl,
    InputAdornment, IconButton, Button, TablePagination, Select, MenuItem,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Add, Delete, Edit, ContentCopy } from '@mui/icons-material';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../../redux/actions';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    divContainerLeft: {
        position: 'relative',
        width: '80%',
        height: '80vh',
        marginLeft: 5,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        // backgroundColor: '#E8E8E8',
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    headerLeft: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    toggleButton: {
        marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },

    icons: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    name: {
        display: 'flex',
        cursor: 'pointer',
    },

    filter: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
        border: '1px solid #EEEEEE',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 15,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        // bottom: '20px',
        marginTop: 2,
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
        zIndex: 1
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '122px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    historyButton: {
        border: '1px solid #D8D8D8',
        margin: '6px 10px',
        padding: 3,
        width: 35,
        height: 35,
    },

    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },

    actionIcon: {
        cursor: 'pointer',
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
    },

}));


const StickerBankContainer = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const [productType, setProductType] = React.useState('');
    const [stickersBankData, setStickersBankData] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [stickerName, setStickerName] = React.useState('');
    const [stickersAPIData, setStickersAPIData] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10]);
    const [iconClicked, setIconClicked] = React.useState({});
    const [dataRows, setDataRows] = React.useState([]);

    const columns = [
        {
            field: 'sticker_description',
            title: 'Stickers Name',
            type: 'string',
            render: (rowData, index) =>
                <div className={classes.name} onClick={() => handleTableData(rowData, index)} >
                    <LibraryAddIcon style={{ color: iconClicked[rowData.ID] ? '#ffdd42' : 'grey', marginRight: 4 }}></LibraryAddIcon>
                    {(rowData.promo_variability ? 'PV -' : '') + (rowData.version > 1 ? `V${rowData.version} - ${rowData.sticker_description}` : rowData.sticker_description)}
                </div>,
            customFilterAndSearch: (term, rowData) => {
                const displayedText = (rowData.promo_variability ? 'PV -' : '') +
                    (rowData.version > 1 ? `V${rowData.version} - ${rowData.sticker_description}` : rowData.sticker_description);
                return displayedText.toLowerCase().includes(term.toLowerCase());
            },
            cellStyle: { minWidth: 350 },
        },
        {
            field: 'takerate_name',
            title: 'Take Rate',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > <Tooltip title={rowData.takerate_name}>{rowData.takerate_name}</Tooltip></a>,
            cellStyle: { minWidth: 400 },
        },
        {
            field: 'user_details.created_user.name',
            title: 'Creator',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.user_details && rowData.user_details.created_user.name}</a>

        },
        {
            field: 'product_lines',
            title: 'Product Line',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.product_lines && rowData.product_lines.join(', ')}</a>
        },
        {
            field: 'UpdatedAt',
            title: 'Last Update',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {moment.utc(rowData.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = moment.utc(rowData.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'status',
            title: 'Status',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > <CheckCircleOutlineIcon style={{ color: 'green' }}></CheckCircleOutlineIcon></a>,
            //cellStyle: { maxWidth: 50 },
            headerStyle: { width: 50 },
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div className={classes.actionContainer}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <ContentCopy style={{ fontSize: 20 }} onClick={() => handleCopy(rowData)} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <Edit style={{ fontSize: 20 }} onClick={() => handleEdit(rowData.ID)} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <Delete style={{ fontSize: 20 }} onClick={() => handleDelete(rowData)} />
                    </IconButton>
                </div>
        },
    ];


    useEffect(() => {
        if (props.stickersData && props.stickersData.records) {
            setStickersBankData(props.stickersData.records);
            setStickersAPIData(props.stickersData);
            setDataRows(props.stickersData.records)
        }
    }, [props.stickersData]);

    useEffect(() => {
        if (props.country) {
            // if (props.country === 'CA')
            // setChecked(true);
            setSelectedCountry(props.country);
        }
    }, [props.country]);

    useEffect(() => {
        if (props.stickerName) {
            setStickerName(props.stickerName);
        }
    }, [props.stickerName]);
    useEffect(() => {
        if (props.productType) {
            setProductType(props.productType);
        }
    }, [props.productType]);

    useEffect(() => {
        if (props.iconClicked) {
            setIconClicked(props.iconClicked);
        }
    }, [props.iconClicked]);

    function handleAdd() {
        history.push({
            pathname: '/promotion-sticker-setup/add',
            data: { variable: selectedCountry, page: props.page }
        });
    }


    function handleToggle(e) {
        setChecked(e.target.checked)
        if (e.target.checked)
            setSelectedCountry('CA')
        else
            setSelectedCountry('USA')
        props.onChange(e.target.checked ? 'CA' : 'USA');
    };

    function handleEdit(id) {
        props.getStickers(productType, selectedCountry, id, null, null, props.page)
    }

    function handleDelete(item) {
        var param = {
            ID: item.ID,
            product_line: productType,
            country: item.country
        }
        if (item && item.product_lines.length > 1) {
            var data = {
                product_lines: productType
            };

            props.onAction(data, 'delete', param, props.page);
        }
        else {
            props.onAction(null, 'delete', param, props.page);
        }

    }
    function handleCopy(item) {
        var param = {
            ID: item.ID,
            product_line: productType,
            country: item.country
        }
        props.onAction(null, 'clone', param, props.page)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getStickers(productType, selectedCountry, null, newPage + 1, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getStickers(productType, selectedCountry, null, 1, event.target.value);
    };

    function handleTableData(item) {
        const newIconClicked = { ...iconClicked, [item.ID]: true };
        setIconClicked(newIconClicked);
        var selectedData = {
            'item': item,
            'iconClicked': newIconClicked,
        }
        props.data(selectedData);
    }

    function handleCountry(e) {
        setSelectedCountry(e.target.value)
        props.onChange(e.target.value);
    }

    return (
        <>
            <div className={classes.root}>
                {/* <div style={{ display: 'flex' }}>
                    <div className={classes.divContainerLeft}> */}
                {props.page == "Scenario" &&
                    <div className={classes.headerLeft}>
                        <div></div>
                        {/* <div style={{ marginTop: 17, marginLeft: 25, width: 130 }}>
                        <Select
                            id={'selected-country'}
                            value={selectedCountry}
                            onChange={handleCountry}
                            style={{ marginBottom: 10, backgroundColor: 'white', borderRadius: 5 }}
                            disabled={props.page === "Scenario" ? true : false}

                        >
                            <MenuItem value={'USA'}>USA</MenuItem>
                            <MenuItem value={'CA'}>CANADA</MenuItem>
                        </Select>
                    </div> */}
                        {/* <div style={{ display: 'flex' }}>
                        <Typography style={{ marginLeft: 5, marginTop: 23 }}>USA</Typography>
                        <span className={classes.toggleButton} >
                            <Switch
                                checked={checked}
                                onClick={handleToggle}
                                disabled={props.page === "Scenario" && props.promoCountry && props.promoCountry.length == 1 ? true : false} />
                        </span>
                        <Typography style={{ marginTop: 23 }}>CANADA</Typography>
                    </div> */}
                        <div>
                            <Typography style={{ color: 'rgb(182 175 175)', marginTop: 22, marginRight: 35 }}>{stickerName}</Typography>
                        </div>
                    </div>
                }

                <MaterialTable
                    components={{
                        Toolbar: (props) => (
                            <div
                                style={{
                                    height: "0px",
                                }}
                            >
                            </div>
                        ),
                    }}
                    title={' '}
                    editable={true}
                    icons={tableIcons}
                    columns={columns}
                    data={dataRows}
                    style={{ marginLeft: '6px' }}
                    options={{
                        columnResizable: true,
                        fixedColumns: true,
                        maxBodyHeight: '100vh',
                        search: false,
                        filtering: true,
                        headerStyle: theme.mixins.MaterialHeader,
                        cellStyle: theme.mixins.MaterialCell,
                        pageSize: 10,
                        pageSizeOptions: [10, 15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                    }}
                />

                {/* <Table style={{ marginTop: 5 }}>
                    <TableHead style={{ backgroundColor: "#E8E8E8", position: "sticky", top: 0, zIndex: 1 }}>
                        <TableRow>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 200 }}  >Stickers Name</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Take Rate</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Creator</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Related PL</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >Last update</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Status</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 120 }}  >Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <div className={classes.filter}>
                        <Typography style={{ marginLeft: -8, marginTop: 7 }}>Filter</Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Promotion Type <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Status <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Version <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Take Rate <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                    </div>
                    {stickersBankData && stickersBankData.length > 0 ? (
                        <TableBody>
                            {stickersBankData && stickersBankData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 200 }}  >
                                        <div className={classes.name} onClick={() => handleTableData(item, index)}>
                                            <LibraryAddIcon style={{ color: iconClicked[item.ID] ? '#ffdd42' : 'grey', marginRight: 4 }}></LibraryAddIcon>
                                            {(item.promo_variability ? 'PV -' : '') + (item.version > 1 ? `V${item.version} - ${item.sticker_description}` : item.sticker_description)}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >X%</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >{item.user_details && item.user_details.created_user.name}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >{item.product_lines && item.product_lines.join(', ')}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >{moment.utc(item.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >
                                        <CheckCircleOutlineIcon style={{ color: 'green' }}></CheckCircleOutlineIcon>
                                    </TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >
                                        <div className={classes.icons}>
                                            <ContentCopyIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleCopy(item)}
                                            >
                                            </ContentCopyIcon>
                                            <EditIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleEdit(item.ID)}>
                                            </EditIcon>
                                            <DeleteIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleDelete(item)}>
                                            </DeleteIcon>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} align='center'>No data available</TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table> */}
                {/* <TablePagination
                    component="div"
                    count={props.stickersData && props.stickersData.total_record ? props.stickersData.total_record : 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        caption: classes.caption,
                        root: classes.paginationRoot
                    }}
                /> */}
                <Add onClick={handleAdd}
                    className={classes.addIcon}>
                </Add>
                {/* </div >
                </div> */}
            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getStickers: (productType, country, id, pagination, limit, page) => dispatch(getPromoStickers(productType, country, id, pagination, limit, page)),
        onAction: (data, type, id, page) => dispatch(createStickers(data, type, id, page)),
    }
}

const mapStateToProps = state => {
    return {
        stickersData: state.promotionData.promoStickers,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerBankContainer);