import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Button, FormLabel, Select, MenuItem, Breadcrumbs, Link, Typography,
    Dialog, OutlinedInput, DialogActions, Grid,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import {
    getDefaultValuesKeyFigure,
    submitFormula,
    loadValuesBasedOnAppTypeAndField, getFormulaDetails
} from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    select: {
        margin: 10,
        fontSize: 13
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 33,
        padding: 0,
        margin: 10,
        backgroundColor: 'white',
        width: '80%'
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        textAlign: 'center'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    table: {
        border: 'none'
    },
    centerText: {
        textAlign: 'center',
        border: '1px solid #e2e2e2',
        cursor: 'pointer',
        padding: '7px 0px',
    },
    centerTextAlign: {
        textAlign: 'center'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    fontHead: {
        fontSize: theme.typography.h3.fontSize
    },
    gloabalColor: {
        color: theme.palette.primary.main,
    }
}));
const operationData = [
    { key: '(', value: '(' },
    { key: ')', value: ')' },
    { key: '+', value: '+' },
    { key: '-', value: '-' },
    { key: '/', value: '/' },
    { key: '*', value: '*' },
];

const EditFormula = props => {
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [mappingValuesSet, setMappingValuesSet] = React.useState([]);
    const [constant, setConstant] = React.useState('');
    const [decimal, setDecimal] = React.useState('');
    const [formulaName, setFormulaName] = React.useState('');
    const [newArrayOfKeys, setNewArrayOfKeys] = React.useState([]);
    const [newArrayOfValues, setNewArrayOfValues] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('Customer Rebate');
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState(['External'])
    const [open, setOpen] = useState(true);
    const [formula, setFormula] = React.useState([]);
    const [formulaAPI, setFormulaAPI] = React.useState([]);
    const operationDataAr = ['(', ')', '+', '-', '/', '*']
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getFormulaDetails(appId)
    }, []);
    useEffect(() => {
        if (props.formulaDetailsData && props.formulaDetailsData && Object.keys(props.formulaDetailsData).length > 0) {
            var formulaDetails = props.formulaDetailsData;
            setFormulaName(props.formulaDetailsData.formula_name);
            setDecimal(props.formulaDetailsData.decimal_points);
            setFormula(props.formulaDetailsData.formula_labels)
            setFormulaAPI(props.formulaDetailsData.formula)
            setApplicationType(props.formulaDetailsData.application_type);
            setSourceDataType(props.formulaDetailsData.source_data_type);
            props.loadSourceDataType(props.formulaDetailsData.application_type, 'source_data_type');
            if (props.formulaDetailsData.source_data_type && props.formulaDetailsData.source_data_type !== 'External') {
                props.onLoadingDefault(props.formulaDetailsData.application_type, props.formulaDetailsData.source_data_type);
            }
        }
    }, [props.formulaDetailsData]);
    useEffect(() => {
        if (props.sourceDataTypeValue && props.sourceDataTypeValue['drop_down_value_keys'] && props.sourceDataTypeValue['drop_down_value_keys'].length > 0) {
            setSourceDataTypeDataFromAPI([...props.sourceDataTypeValue['drop_down_value_keys'], 'External']);
        }
    }, [props.sourceDataTypeValue])

    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
            setMappingValuesSet(props.tableData.records[0]['label_names'])
        }
    }, [props.tableData]);


    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }
    const handleSourceDataType = (e) => {
        setMappingValuesSet([]);
        setSourceDataType(e.target.value);
        setFormulaAPI([]);
        setFormula([]);
    }
    const handleAdd = (e, k) => {
        setFormula([...formula, e])
        setFormulaAPI([...formulaAPI, k])
    };
    const handleFormula = (newValue) => {
        setFormula([...formula, newValue]);
        setFormulaAPI([...formulaAPI, newValue]);
    }
    const handleFormulaAdd = (newValue) => {
        setFormula(newValue);
        setFormulaAPI(newValue);
    }
    const handleFormulaDelete = (index) => {
        setFormula(formula.filter((item, i) => i !== index));
        setFormulaAPI(formulaAPI.filter((item, i) => i !== index));
    }
    const handleConstant = (e) => {
        setConstant(e.target.value);
    }
    const handeSubmitConstant = () => {
        setFormula([...formula, constant]);
        setFormulaAPI([...formulaAPI, constant]);
        setConstant('');
    }
    const handleDecimal = (e) => {
        setDecimal(e.target.value);
    }
    const handleSubmitFormula = () => {
        var data = {
            "formula_name": formulaName,
            "formula": formulaAPI,
            "application_type": applicationType,
            "source_data_type": sourceDataType,
            "decimal_points": parseInt(decimal)
        }
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        props.submitFormula(data, appId, 'put');
    }

    const handleClear = () => {
        setFormula([]);
    }
    const continueDialog = () => {
        setOpen(false)
        // var temp = [];
        // if (formula && mappingValuesSet && Object.entries(mappingValuesSet).length > 0) {
        //     var temp = [];
        //     formula.map(item2 => {
        //         if (operationDataAr.includes(item2)) {
        //             temp.push(item2);
        //         } else if (!isNaN(item2)) {
        //             temp.push(item2);
        //         } else {
        //             temp.push(mappingValuesSet[item2])

        //         }
        //     })
        //     setFormula(temp)
        // }

    }
    return (
        <div className={clsx(classes.root, className)}>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel classes={{ root: classes.fonthead }} style={{ marginRight: 10 }}>
                        Formula Name
                    </FormLabel>
                    <OutlinedInput
                        inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        value={formulaName}
                        onChange={(e) => setFormulaName(e.target.value.toUpperCase())}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={() => history.push('/formula-lab')}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={continueDialog}
                        disabled={formulaName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ padding: 29 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: 33 }}>
                    <div style={{ marginLeft: 10 }}>
                        <FormLabel classes={{ root: classes.fonthead }} className={classes.gloabalColor} style={{ height: 34, paddingTop: 23, paddingRight: 10 }}>
                            Application Type
                        </FormLabel>
                        <Select
                            value={applicationType}
                            onChange={handleApplicationtype}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                            })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                            disabled
                        >   <MenuItem value={applicationType} key={1}>
                                {applicationType}
                            </MenuItem>
                        </Select>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <FormLabel classes={{ root: classes.fonthead }} className={classes.gloabalColor} style={{ height: 34, paddingTop: 23, paddingRight: 10 }}>
                            Source Data Type
                        </FormLabel>
                        <Select
                            value={sourceDataType}
                            onChange={handleSourceDataType}
                            displayEmpty
                            disabled
                            className={clsx({
                                [classes.select]: true
                            })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >
                            {sourceDataTypeDataFromAPI
                                .map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </div>
                </div>
                <div style={{ marginTop: 29 }}>

                    <div style={{
                        backgroundColor: '#FAFAFA', alignItems: 'center',
                        color: 'white', justifyContent: 'center', flexDirection: 'row', display: 'flex',
                        borderRadius: 5, padding: "24px 0px 8px 0px"
                    }}>
                        <div style={{ color: '#0B3662', width: "10%", marginTop: "-10px" }} classes={{ root: classes.fonthead }} >{formulaName}</div>
                        <div style={{ color: '#0B3662', width: "80%", marginTop: "-26px" }}>
                            <ChipInput data={formula} onChange={handleFormulaAdd}
                                disabled={sourceDataType !== 'External'} />
                            {/* <ChipInput
                            classes={{
                                root: classes.rootContainer,
                                chip: classes.chip,
                                input: classes.input,
                                inputRoot: classes.inputRoot,
                                label: classes.chipLabel
                            }}
                            allowDuplicates
                            value={formula}
                            onAdd={(chips) => sourceDataType === 'External' && handleFormulaAdd(chips)}
                            onDelete={(chip, index) => handleFormulaDelete(index)}
                            disableUnderline={true}
                            className={clsx({
                                [classes.textInput]: true
                            })}
                        /> */}
                        </div>
                    </div>
                    <Grid container style={{ marginTop: 12 }}>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table" classes={{
                                    root: classes.table
                                }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', backgroundColor: '#FAFAFA' }}>
                                                Key Figure Name
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ marginTop: 10 }}>
                                        {Object.entries(mappingValuesSet).length > 0 && sourceDataType !== 'External' ?
                                            Object.entries(mappingValuesSet).map(([k, v]) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell classes={{ root: classes.fontSetting }} key={k} style={{ textAlign: 'center', cursor: 'pointer', backgroundColor: 'white' }} onClick={() => handleAdd(v, k)}>
                                                            {v}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <Typography classes={{ root: classes.fontSetting }} style={{ textAlign: 'center' }}>No data to Show</Typography>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <Table stickyHeader aria-label="sticky table" classes={{
                                root: classes.table
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', backgroundColor: '#FAFAFA' }}>
                                            Formula Element
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Grid container spacing={1}
                                style={{ display: 'flex', justifyContent: 'center', paddingLeft: 19, paddingRight: 8 }}
                            >
                                <Grid container item xs={12} spacing={3} style={{ paddingTop: 25, paddingLeft: 20 }}>
                                    {operationData.map((item) => {
                                        return (
                                            <Grid item xs={4} style={{ paddingTop: 7, height: 37 }} className={classes.centerText} onClick={() => handleAdd(item.value, item.key)}>
                                                {item.value}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <Table stickyHeader aria-label="sticky table" classes={{
                                root: classes.table
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', backgroundColor: '#FAFAFA' }}>
                                            Constants
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Grid container spacing={1}
                                style={{ padding: '10px 20px 10px 20px' }}
                            //spacing={3}
                            >
                                <OutlinedInput
                                    value={constant}
                                    style={{ width: '100%', height: 55, textAlign: 'center' }}
                                    classes={{ input: classes.centerTextAlign }}
                                    onChange={handleConstant}
                                />
                                <Button variant="contained" color="primary" disableElevation fullWidth
                                    style={{ marginTop: 10 }}
                                    onClick={handeSubmitConstant}
                                    disabled={!constant ? true : false}
                                >
                                    Add Constant
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <Table stickyHeader aria-label="sticky table" classes={{
                                root: classes.table
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', backgroundColor: '#FAFAFA' }}>
                                            Decimal
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Grid container spacing={1}
                                style={{ padding: '10px 20px 10px 20px' }}
                            >
                                <OutlinedInput
                                    value={decimal}
                                    style={{ width: '100%', height: 55, textAlign: 'center' }}
                                    classes={{ input: classes.centerTextAlign }}
                                    onChange={handleDecimal}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button}
                    disabled={formula.length === 0 ? true : false}
                    onClick={handleSubmitFormula}>
                    Submit
                </Button>
            </div>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        tableData: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        formulaDetailsData: state.FormulaLabData.formulaDetailsData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'FormulaLab')),
        submitFormula: (data, id, type) => dispatch(submitFormula(data, id, type)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getFormulaDetails: (id) => dispatch(getFormulaDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFormula);