export const accessInnerChildren = (obj, childArray = [], relationshipArray = []) => {
    // Add the current relationship to the relationship array

    relationshipArray.push(Object.values(obj.relationship).join("_"));
    // If the object has no children, return the accumulated arrays
    if (!obj.children || obj.children.length === 0) {
        childArray.push(obj.relationship);
        return { children: [...childArray], relationships: [...relationshipArray] };
    }

    // Use reduce to accumulate results from recursive calls
    return obj.children.reduce((acc, child) => {
        const result = accessInnerChildren(child, acc.children, acc.relationships);
        return {
            children: result.children,
            relationships: result.relationships
        };
    }, { children: childArray, relationships: relationshipArray });
};