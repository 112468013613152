import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    IconButton,
    Dialog, DialogActions, DialogContent, DialogContentText,
    Grid,
    FormLabel,
    OutlinedInput,
    Select,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getIntegrationSetupData, editIntegrationSetup, deleteIntegrationSetup, getFilterNameList, getIntegrationFieldValues } from '../../../redux/actions';
import { IntegrationSetupToolbar } from '.';
import { useHistory } from "react-router-dom";
import { DeleteForever, Clear } from '@mui/icons-material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel, DropdownArray } from '../../../components/Inputs';
import secureLocalStorage from 'react-secure-storage';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 9,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        padding: 10
    },
    select: {
        width: '161%',
        marginTop: 16
    },
    select1: {
        width: '100%',
        marginTop: 16
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    tabHead1: {
        color: 'black',
        fontSize: 16,
        border: '1px solid #E0E0E0',
        padding: '5px'
    }
}));
const IntegrationSetupUserTable = props => {
    useEffect(() => {
        props.getIntegrationSetupData(1, 10);
        props.getIntegraionFieldValues('activityList')
        props.getIntegraionFieldValues('dataSource')
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableId, setCurrentEditableId] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [integrationData, setIntegrationData] = React.useState([]);
    const [activity, setActivity] = React.useState('');
    const [dataSource, setDataSource] = React.useState('');
    const [filterValue, setFiltervalue] = React.useState([]);
    const [filterName, setFilterName] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [filterValueType, setFilterValueType] = React.useState([]);
    const [filterValueTypeArray, setFilterValueTypeArray] = React.useState(['Static', 'Dynamic']);
    const [url, setUrl] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [integrationName, setIntegrationName] = React.useState('');
    const [topics, setTopics] = React.useState('');
    const [groupId, setGroupId] = React.useState('');
    const [securityProtocol, setSecurityProtocol] = React.useState('');
    const [mechanism, setMechanism] = React.useState('PLAIN');
    const [sslKey, setSslKey] = React.useState('');
    const [sslCa, setSslCa] = React.useState('');
    const [sslClientCertificate, setSslClientCertificate] = React.useState('');
    const securityProtocolArray = ['SASL', 'SSL', 'None']
    const [disabled, setDisabled] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [batchSize, setBatchSize] = React.useState(5000);

    useEffect(() => {
        if (props.activityList) {
            setActivityArray(props.activityList)
        }
        if (props.dataSourceList) {
            setDataSourceArray(props.dataSourceList)
        }
    }, [props.activityList, props.dataSourceList]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getIntegrationSetupData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getIntegrationSetupData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.integrationSetupData) {
            setContractsTableComplete(props.integrationSetupData);
            setIntegrationData(props.integrationSetupData)
        }
    }, [props.integrationSetupData]);

    useEffect(() => {
        if (props.filterNameList) {
            setFilterNameArray(props.filterNameList);
        }
    }, [props.filterNameList]);
    function editIntegration(item) {
        setOpen(true);
        setDisabled(false);
        setCurrentEditableId(item.ID);
        setActivity(item.activity);
        setCurrentEditableId(item.ID);
        setDataSource(item.datasource);
        setUrl(item.url);
        setUserId(item.user_id);
        setPassword(item.password)
        setIntegrationName(item.name)
        setFilterName(item.filter_name)
        setFilterValueType(item.filter_value_type)
        setFiltervalue(item.filter_value)
        setTopics(item.topic)
        setGroupId(item.group_id)
        setSecurityProtocol(item.security_protocol)
        setMechanism(item.mechanisms)
        setSslCa(item.ssl_ca)
        setSslKey(item.ssl_key)
        setSslClientCertificate(item.ssl_client_certificate)
        setBatchSize(item.batch_size)

    }
    function viewIntegration(item) {
        setOpen(true);
        setDisabled(true);
        setCurrentEditableId(item.ID);
        setActivity(item.activity);
        setCurrentEditableId(item.ID);
        setDataSource(item.datasource);
        setUrl(item.url);
        setUserId(item.user_id);
        setPassword(item.password)
        setIntegrationName(item.name)
        setFilterName(item.filter_name)
        setFilterValueType(item.filter_value_type)
        setFiltervalue(item.filter_value)
        setTopics(item.topic)
        setGroupId(item.group_id)
        setSecurityProtocol(item.security_protocol)
        setMechanism(item.mechanisms)
        setSslCa(item.ssl_ca)
        setSslKey(item.ssl_key)
        setSslClientCertificate(item.ssl_client_certificate)
        setBatchSize(item.batch_size)

    }
    function handleItemClick(id) {
        localStorage.setItem('currentAddContractID', id);
        props.getcontractViewdetails(id);
    }
    const [open, setOpen] = React.useState(false);
    const [activityArray, setActivityArray] = React.useState([]);
    const [dataSourceArray, setDataSourceArray] = React.useState([]);
    function handleDataSource(e) {
        setDataSource(e.target.value);
    }
    function handleSecurityProtocol(e) {
        setSecurityProtocol(e.target.value);
    }
    function handleUrl(e) {
        setUrl(e.target.value);
    }
    function handleUserId(e) {
        setUserId(e.target.value);
    }
    function handleBatchSize(e) {
        setBatchSize(e.target.value);
    }
    function handlePassword(e) {
        setPassword(e.target.value);
    }
    function handleSubmit(event) {
        setOpen(false);
    }
    function handleSubmit() {
        var data = {
            "name": integrationName,
            "activity": activity,
            "datasource": dataSource,
            "url": url,
            "user_id": userId,
            "password": password,
            // "filter_name": filterName,
            // "filter_value_type": filterValueType,
            // "filter_value": filterValue,
            "topic": topics,
            "group_id": groupId,
            "security_protocol": securityProtocol,
            "mechanisms": mechanism,
            "ssl_ca": sslCa,
            "ssl_key": sslKey,
            "ssl_client_certificate": sslClientCertificate,
            "batch_size": batchSize ? Number(batchSize) : 5000
        };
        handleClear();
        props.onEdit(currentEditableId, data);
        setOpen(false);
    }
    function handleClear() {
        setActivity('')
        setCurrentEditableId('');
        setDataSource('');
        setUrl('');
        setUserId('');
        setPassword('');
        setIntegrationName('')
        setTopics('')
        setGroupId('')
        setSecurityProtocol('')
        setMechanism('')
        setSslCa('')
        setSslKey('')
        setSslClientCertificate('')
    }
    function handleDelete(id) {
        props.onDelete(id);
    }
    function handleFilterName(newValue, index) {
        filterName[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterValue[index] = newValue;
    }
    function handleFilterValueType(newValue, index) {
        filterValueType[index] = newValue;
    }
    function handleOnAdd() {
        filterName[filterName.length] = ''
        filterValueType[filterValueType.length] = ''
        filterValue[filterValue.length] = ''
        setAddMember((filterName.length || filterValueType.length) + 1)
    }
    useEffect(() => {
        if (activity) {
            props.getFilterNameList(activity);
        }
    }, [activity]);
    function handleActivity(value) {
        setActivity(value)
        setFilterName([])
    }
    const handleDeleteLocally = (e, index) => {
        setFilterName(item => item.filter((item, i) => i !== index));
        setFilterValueType(item => item.filter((item, i) => i !== index));
        setFiltervalue(item => item.filter((item, i) => i != index));
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>Integration Activity</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_integration_setup-' + sessionStorage.getItem('application')) &&
                    <IntegrationSetupToolbar />}
            </div>
            {integrationData && integrationData.records && integrationData.records.length > 0 ?
                <TableContainer style={{ marginTop: -20 }}>
                    <Table stickyHeader style={{ padding: 0 }}>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Name</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Activity</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Data Source</TableCell>
                                <TableCell align='center' className={classes.tabHead}>URL</TableCell>
                                <TableCell align='center' className={classes.tabHead}>User ID</TableCell>
                                <TableCell align='center' className={classes.tabHead} >Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {integrationData.records.map(item => {
                                return (
                                    <StyledTableRow key={item.ID} >
                                        <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewIntegration(item)} style={{ cursor: 'pointer' }}>{item.name}</TableCell>
                                        <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewIntegration(item)} style={{ cursor: 'pointer' }}>{item.activity}</TableCell>
                                        <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewIntegration(item)} style={{ cursor: 'pointer' }}>{item.datasource}</TableCell>
                                        <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewIntegration(item)} style={{ cursor: 'pointer' }}>{item.url}</TableCell>
                                        <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewIntegration(item)} style={{ cursor: 'pointer' }}>{item.user_id}</TableCell>
                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_setup-' + sessionStorage.getItem('application')) &&
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => editIntegration(item)}
                                                    size="large">
                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>}
                                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_setup-' + sessionStorage.getItem('application')) &&
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => props.onDelete(item.ID)}
                                                    size="large">
                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                </IconButton>}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={props.integrationSetupData && props.integrationSetupData.total_record ? props.integrationSetupData.total_record : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    />
                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
            <Dialog open={open}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >  <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Name
                                        </FormLabel>
                                        {
                                            disabled ? <p className={classes.tabHead1} style={{ marginTop: 8 }}>{integrationName}</p> :
                                                <OutlinedInput
                                                    value={integrationName}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setIntegrationName(e.target.value)} />
                                        }

                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >   <FormLabel className={classes.formLabel}>
                                        Activity*
                                    </FormLabel>
                                    <>
                                        {disabled ? <p className={classes.tabHead1} style={{ width: '278px' }}>{activity}</p> :
                                            <Select
                                                onChange={(e) => handleActivity(e.target.value)}
                                                value={activity}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >  <MenuItem value="">
                                                    {props.placeholder}
                                                </MenuItem>
                                                {activityArray && activityArray.length > 0 && activityArray.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} name={item}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        }
                                    </>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ marginLeft: 105 }}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        Data Source*
                                    </FormLabel>
                                    <>
                                        {disabled ? <p className={classes.tabHead1} style={{ width: '278px' }}>{dataSource}</p> :
                                            <Select
                                                onChange={handleDataSource}
                                                value={dataSource}
                                                className={classes.select}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value="">
                                                    {props.placeholder}
                                                </MenuItem>
                                                {dataSourceArray && dataSourceArray.length > 0 && dataSourceArray.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} name={item}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        }
                                    </>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}

                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} style={{ marginLeft: 100 }}>
                                            URL*
                                        </FormLabel>
                                        <>
                                            {disabled ? <p className={classes.tabHead1} style={{ marginLeft: 100, marginTop: 8, whiteSpace: 'nowrap', width: '261px', overflowX: 'auto' }} >{url}</p> :
                                                <OutlinedInput
                                                    style={{ height: 36, marginTop: 8, marginLeft: 100 }}
                                                    value={url}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleUrl} />
                                            }
                                        </>
                                    </div>
                                </Grid>
                                {dataSource === 'Kafka Consumer' || dataSource === 'Kafka Producer' ? (
                                    <>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel} required>
                                                    Topic
                                                </FormLabel>
                                                <>
                                                    {disabled ? (
                                                        <p className={classes.tabHead1}>{topics !== '' ? topics : '\u00A0'}</p>
                                                    ) : (
                                                        <OutlinedInput
                                                            value={topics}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(e) => setTopics(e.target.value)} />
                                                    )}

                                                </>
                                            </div>

                                        </Grid>
                                        {dataSource !== 'Kafka Producer' &&
                                            < Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        GroupId
                                                    </FormLabel>
                                                    <>
                                                        {disabled ? (
                                                            <p className={classes.tabHead1}>{groupId !== '' ? groupId : '\u00A0'}</p>
                                                        ) : (
                                                            <OutlinedInput
                                                                value={groupId}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => setGroupId(e.target.value)} />
                                                        )}
                                                    </>
                                                </div>

                                            </Grid>
                                        }
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >   <FormLabel className={classes.formLabel} >
                                                Security Protocol
                                            </FormLabel>
                                            <>
                                                {disabled ? <p className={classes.tabHead1} style={{ marginTop: 23 }} >{securityProtocol}</p> :
                                                    <Select
                                                        onChange={handleSecurityProtocol}
                                                        value={securityProtocol}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        className={clsx({
                                                            [classes.select1]: true,
                                                        })}
                                                    >
                                                        <MenuItem value="">
                                                            {props.placeholder}
                                                        </MenuItem>

                                                        {securityProtocolArray && securityProtocolArray.length > 0 && securityProtocolArray.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index} name={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}

                                                    </Select>
                                                }
                                            </>
                                        </Grid>
                                        {securityProtocol === 'SASL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Mechanisms
                                                    </FormLabel>
                                                    <>
                                                        {disabled ? (
                                                            <p className={classes.tabHead1}>{mechanism !== '' ? mechanism : '\u00A0'}</p>
                                                        ) : (
                                                            <OutlinedInput
                                                                value={mechanism}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => setMechanism(e.target.value)} />
                                                        )}
                                                    </>
                                                </div>
                                            </Grid>

                                        ) : null}

                                        {securityProtocol === 'SASL' || securityProtocol === 'None' ? null : (
                                            <Grid item md={3} xs={12}>
                                                <div className={classes.selectRoot}>

                                                    <FormLabel className={classes.formLabel} >
                                                        Ssl Ca
                                                    </FormLabel>
                                                    <>
                                                        {disabled ? (
                                                            <p className={classes.tabHead1}>{sslCa !== '' ? sslCa : '\u00A0'}</p>
                                                        ) : (
                                                            <OutlinedInput
                                                                value={sslCa}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => setSslCa(e.target.value)}
                                                            />
                                                        )}
                                                    </>
                                                </div>
                                            </Grid>
                                        )}
                                        {securityProtocol === 'SSL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Ssl Key
                                                    </FormLabel>
                                                    {disabled ? (
                                                        <p className={classes.tabHead1}>{sslKey !== '' ? sslKey : '\u00A0'}</p>
                                                    ) : (
                                                        <OutlinedInput
                                                            value={sslKey}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(e) => setSslKey(e.target.value)} />
                                                    )}
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {securityProtocol === 'SSL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Ssl Client Certificate
                                                    </FormLabel>
                                                    {disabled ? (
                                                        <p className={classes.tabHead1}>{sslClientCertificate !== '' ? sslClientCertificate : '\u00A0'}</p>
                                                    ) : (
                                                        <OutlinedInput
                                                            value={sslClientCertificate}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(e) => setSslClientCertificate(e.target.value)} />
                                                    )}
                                                </div>
                                            </Grid>
                                        ) : null}
                                    </>
                                ) : null}

                                {dataSource === 'Kafka Consumer' ? (
                                    <>
                                        {/* Render fields based on security protocol only for Kafka Consumer */}
                                        {securityProtocol !== 'SSL' && securityProtocol !== 'None' && (
                                            <>

                                                <Grid item md={2} xs={12} style={{ marginTop: 0 }}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formLabel}>
                                                            User ID*
                                                        </FormLabel>
                                                        <>
                                                            {disabled ? (
                                                                <p className={classes.tabHead1} style={{ width: '278px' }}>
                                                                    {userId}
                                                                </p>
                                                            ) : (
                                                                <OutlinedInput
                                                                    style={{ width: '277px' }}
                                                                    value={userId}
                                                                    inputProps={{ min: "0", step: "1" }}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={handleUserId}
                                                                />
                                                            )}
                                                        </>
                                                    </div>
                                                </Grid>

                                                <Grid item md={2} xs={12} style={{ marginLeft: 99 }}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formLabel}>
                                                            Password*
                                                        </FormLabel>
                                                        <>
                                                            {disabled ? (
                                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                    <p className={classes.tabHead1} style={{ width: '278px', overflowX: 'auto' }}>
                                                                        {showPassword ? password : '********'}
                                                                    </p>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', left: '250px' }}
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </div>
                                                            ) : (
                                                                <OutlinedInput
                                                                    style={{ width: '286px' }}
                                                                    value={password}
                                                                    inputProps={{ min: "0", step: "1" }}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={handlePassword}
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={() => setShowPassword(!showPassword)}
                                                                                edge="end"
                                                                            >
                                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    </div>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {/* Always render User ID and Password fields for non-Kafka Consumer data sources */}
                                        <Grid item md={2} xs={12} style={{ marginTop: 0 }}>
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel}>
                                                    User ID*
                                                </FormLabel>
                                                <>
                                                    {disabled ? (
                                                        <p className={classes.tabHead1} style={{ width: '278px' }}>
                                                            {userId}
                                                        </p>
                                                    ) : (
                                                        <OutlinedInput
                                                            style={{ width: '277px' }}
                                                            value={userId}
                                                            inputProps={{ min: "0", step: "1" }}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={handleUserId}
                                                        />
                                                    )}
                                                </>
                                            </div>
                                        </Grid>
                                        {/* Password field */}
                                        <Grid item md={2} xs={12} style={{ marginLeft: dataSource === 'Kafka Producer' ? 0 : 99 }}>
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel}>
                                                    Password*
                                                </FormLabel>
                                                <>
                                                    {disabled ? (
                                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                                            <p className={classes.tabHead1} style={{ width: '278px', overflowX: 'auto' }}>
                                                                {showPassword ? password : '********'}
                                                            </p>
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', left: '250px' }}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </div>
                                                    ) : (
                                                        <OutlinedInput
                                                            style={{ width: '286px' }}
                                                            value={password}
                                                            inputProps={{ min: "0", step: "1" }}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={handlePassword}
                                                            type={showPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    )}
                                                </>
                                            </div>
                                        </Grid>
                                    </>
                                )}
                                <Grid item md={2} xs={12} style={{ marginLeft: 99 }}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Batch Size
                                        </FormLabel>
                                        <>
                                            {disabled ? (
                                                <p className={classes.tabHead1} style={{ width: '278px' }}>
                                                    {batchSize}
                                                </p>
                                            ) : (
                                                <OutlinedInput
                                                    style={{ width: '277px' }}
                                                    type='number'
                                                    value={batchSize}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleBatchSize}
                                                />
                                            )}
                                        </>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </Typography>
                </DialogContent>


                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpen(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    {
                        disabled ? null :

                            <Button onClick={() => handleSubmit()} color="primary" autoFocus variant="contained"
                                disabled={activity && dataSource && url && integrationName && userId && password ? false : true} >
                                Edit
                            </Button>
                    }
                </DialogActions>

            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        integrationSetupData: state.customerData.integrationSetupData,
        filterNameList: state.customerData.filterNameList,
        activityList: state.customerData.activityList,
        dataSourceList: state.customerData.dataSourceList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        getFilterNameList: (activity) => dispatch(getFilterNameList(activity)),
        onDelete: (id) => dispatch(deleteIntegrationSetup(id)),
        onEdit: (id, data) => dispatch(editIntegrationSetup(id, data)),
        getIntegraionFieldValues: (option) => dispatch(getIntegrationFieldValues(option))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSetupUserTable);