import React, { useEffect, forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Typography, FormLabel, TextField, Grid
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { Delete, Edit, FileDownload, ContentCopy, ExitToApp, Add, ErrorOutlineOutlined, } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import Moment from 'moment';
import { StickerTableHeader } from '../GlobalSetup/Components';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { createPromotionScenario, createScenarioList, getMaterialFilters, getScenarioList } from '../../../redux/actions';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // height: '110vh'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    table: {
        marginLeft: 6,
        marginTop: 5,
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        // bottom: '20px',
        marginTop: 2,
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
        zIndex: 1
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const HistoryListScreen = props => {
    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [extendedData, setExtendedData] = React.useState({});
    const [extendedEndDate, setExtendedEndDate] = React.useState(null);
    const [extendedStartDate, setExtendedStartDate] = React.useState(null);

    const columns = [
        {
            field: 'scenario_name',
            title: 'Scenario Name',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.scenario_name}</a>,
            cellStyle: { minWidth: 320 },
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country}</a>,
        },
        {
            field: 'product_lines',
            title: 'Product Lines',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.product_lines.join(', ')}</a>

        },
        {
            field: 'costing',
            title: 'Costing',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.costing}</a>
        },
        {
            field: 'promotion_type',
            title: 'Promotion Type',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.promotion_type}</a>
        },
        {
            field: 'promotion_start_date',
            title: 'Promotion Start Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.promotion_start_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.promotion_start_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'promotion_end_date',
            title: 'Promotion End Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.promotion_end_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.promotion_end_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'creation_date',
            title: 'Creation Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.creation_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.creation_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'update_date',
            title: 'Update Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.update_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.update_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'month',
            title: 'Month',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.month}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {/* <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <Edit color="disabled" style={{ fontSize: 20 }} onClick={() => handleEdit(rowData)} />
                    </IconButton> */}
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <ContentCopy color="disabled" style={{ fontSize: 20 }} onClick={() => handleClone(rowData)} />
                    </IconButton>
                    {/* <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <Delete color="disabled" style={{ fontSize: 20 }} onClick={() => handleDelete(rowData)} />
                    </IconButton> */}
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <FileDownload color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <ExitToApp color="disabled" style={{ fontSize: 20 }} onClick={() => handleExtend(rowData)} />
                    </IconButton>
                </div>
        },
    ];

    useEffect(() => {
        props.getFilters()
    }, []);

    useEffect(() => {
        if (productType)
            props.getScenarioListData(productType);
    }, [productType]);

    useEffect(() => {
        var tempRows = [];
        if (props.historyListData && props.historyListData.length > 0) {
            props.historyListData
                .map(e => {
                    tempRows.push({
                        id: e.id,
                        scenario_name: e.scenario_name,
                        country: e.country,
                        product_lines: e.product_lines,
                        promotion_type: e.promotion_type,
                        costing: e.costing,
                        promotion_start_date: e.promotion_start_date,
                        promotion_end_date: e.promotion_end_date,
                        creation_date: e.creation_date,
                        update_date: e.update_date,
                        month: e.month,
                    });
                })
            setDataRows(tempRows);
        }
        else
            setDataRows([])
    }, [props.historyListData]);


    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group']);
            if (props.filterData['material_master'][0]['material_group'])
                setProductType(props.filterData['material_master'][0]['material_group'][0])
        }
    }, [props.filterData]);

    function handleChange(e) {
        setProductType(e.target.value);
    }

    function handleAdd() {
        history.push('/promotion-scenario-setup')
    }

    function handleEdit(item) {
        props.fetchScenario(item.id, 'edit')
    }

    function handleClone(e) {
        var formData = {
            'ID': e.id,
            'product_line': e.product_lines
        }
        props.sceanrioList(null, 'clone', formData)
    }

    function handleDelete(e) {
        var formData = {
            'ID': e.id,
            'product_line': e.product_lines
        }
        props.sceanrioList(null, 'delete', formData)
    }

    function handleExtend(e) {
        setExtendedData(e)
        setOpen(true)
        setExtendedStartDate(Moment.utc(e.promotion_end_date).add(1, 'days').format('MMMM DD, YYYY'))
    }

    function handleClose() {
        setOpen(false)
        setExtendedEndDate(null)
    }

    function handleSubmit() {
        setOpen(false);
        var formData = {
            'ID': extendedData.id,
            'product_line': extendedData.product_lines,
            'extended_scenario': true,
            'promotion_start_date': Moment.utc(extendedStartDate).format('MM/DD/YYYY'),
            'promotion_end_date': Moment.utc(extendedEndDate).format('MM/DD/YYYY'),
        }
        props.sceanrioList(null, 'extended', formData)
    }

    function handleExtendedEndDate(e) {
        setExtendedEndDate(e);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >
                <StickerTableHeader productLineData={productLinesList} defaultDivison={productType} onChange={handleChange} stickerName={"History"} />

                {dataRows ?
                    <>
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            style={{ marginLeft: '6px' }}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        />
                        <Add onClick={handleAdd}
                            className={classes.addIcon}>
                        </Add>
                    </>
                    :
                    <div className={classes.noDataContainer}>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                fullWidth
                maxWidth={'xs'}
            >
                <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                    {'EXTEND SCENARIO ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ display: 'flex' }}>
                            <ErrorOutlineOutlined /> <Typography variant='h5' color='black'>This action will create a new scenario with similar settings and a different end-date</Typography>
                        </div>
                        <Typography style={{ margin: 15 }} variant='h6' color='black'>{extendedData && extendedData.scenario_name}</Typography>
                        <hr></hr>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>REGIONS</Typography>
                            <Typography color='black'>{extendedData && extendedData.country ? extendedData.country : 'NA'}</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>PRODUCT LINES</Typography>
                            <Typography color='black'>{extendedData && extendedData.product_lines ? extendedData.product_lines.join(', ') : "NA"}</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>STARTING DATE</Typography>
                            <Typography color='black'>{extendedData && extendedData.promotion_end_date ? Moment.utc(extendedData.promotion_end_date).add(1, 'days').format('MMMM DD, YYYY') : "NA"}</Typography>
                        </div>
                        <FormLabel style={{ margin: '5px 5px 12px' }}>END DATE</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container justifyContent="space-around">
                                <DatePicker
                                    disableToolbar
                                    clearable
                                    InputProps={{
                                        padding: 0,
                                        disableUnderline: true,
                                        style: {
                                            padding: '0px 11px',
                                            height: 37,
                                            alignSelf: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #E0E0E0',
                                            width: '97%',
                                        }
                                    }}
                                    value={extendedEndDate}
                                    onChange={handleExtendedEndDate}
                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                    format="MM/DD/YYYY"
                                />
                            </Grid>
                        </LocalizationProvider>
                        <hr></hr>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained' disabled={!extendedEndDate} onClick={handleSubmit}>Extend Scenario</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        historyListData: state.promotionData.historyListData,
        filterData: state.promotionData.promoFilters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getScenarioListData: (productLine) => dispatch(getScenarioList(productLine, 'history')),
        fetchScenario: (id, page) => dispatch(createPromotionScenario(null, id, 'fetch_id', page)),
        sceanrioList: (data, type, value) => dispatch(createScenarioList(data, type, value, 'history')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryListScreen);