import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Dialog,
    DialogActions,
    Switch,
    Paper,
    IconButton,
    Fab
} from '@mui/material';
import { LibraryAdd, Delete, Edit } from '@mui/icons-material';
import { postAccessData, addUser, getAccessDataNew, appRights, getOrganizationAppTitle } from '../../redux/actions';
import { connect } from 'react-redux';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { TextInputWithLabel } from '../../components/Inputs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import secureLocalStorage from 'react-secure-storage';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '0 0 1px 0',
        margin: '30px 0px 30px'
    },
    container: {
        paddingTop: 18,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    IconButton: {
        padding: 0
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        height: '100vh'
    },
}));
const AccessNew = props => {
    const theme = useTheme();
    const [apps, setApps] = React.useState([]);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.getAccessData();
        props.getOrganizationAppTitle()
    }, []);
    useEffect(() => {
        // console.log('te', props.appRightsData)
    }, [props.appRightsData])
    useEffect(() => {
        var newArray = ['Role Name', 'Action']
        if (props.orgAppTitleData && props.orgAppTitleData.application_type) {
            props.orgAppTitleData.application_type.map(item => {
                newArray.push(item)
            })
            setApps(newArray)
        }
    }, [props.orgAppTitleData])
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const classes = useStyles();
    const { className } = props;
    const [functionalSectionnEnabled, setFunctionalSectionnEnabled] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [editID, setEditID] = React.useState(0);
    const handleChange = (event, item, type, type2, authorizedApps) => {
        var accessData = item.rights;
        var accessDataAppplication = item.authorized_apps ? item.authorized_apps : [];
        if (authorizedApps === 'authorized_apps') {
            if (accessDataAppplication.includes(type)) {
                if (type2) {
                    accessDataAppplication = accessDataAppplication.filter(function (item) {
                        return item !== type
                    })
                    accessDataAppplication = accessDataAppplication.filter(function (item) {
                        return item !== type2
                    })
                } else {
                    accessDataAppplication = accessDataAppplication.filter(function (item) {
                        return item !== type
                    })
                }
            } else {
                if (type2) {
                    accessDataAppplication.push(type)
                    accessDataAppplication.push(type2)
                } else {
                    accessDataAppplication.push(type)
                }
            }
            var formData = {
                "name": item.name,
                "rights": [...accessData],
                "authorized_apps": [...accessDataAppplication],
            }
            props.postAccessData(formData, item.ID);
        } else {
            if (accessData.includes(type)) {
                if (type2) {
                    accessData = accessData.filter(function (item) {
                        return item !== type
                    })
                    accessData = accessData.filter(function (item) {
                        return item !== type2
                    })
                } else {
                    accessData = accessData.filter(function (item) {
                        return item !== type
                    })
                }
            } else {
                if (type2) {
                    accessData.push(type)
                    accessData.push(type2)
                } else {
                    accessData.push(type)
                }
            }
            var formData = {
                "name": item.name,
                "rights": [...accessData],
            }
            props.postAccessData(formData, item.ID);
        }
    };
    const addRole = () => {
        setOpen(true);
    }
    const handleUserName = (value) => {
        setUserName(value);
    }
    const handleAddUser = () => {
        // props.addUser(userName);
        setOpen(false);
        if (editID == '0') {
            props.addUser(userName);
            handleCancel()
        } else {
            props.addUser(userName, 'Edit', editID);
            handleCancel()
        }

    }
    const handleEditUser = (item) => {
        // props.addUser(userName);
        setOpen(true);
        setUserName(item.name)
        setEditID(item.ID)
    }
    const [accessData, setAccessData] = React.useState([]);
    useEffect(() => {
        if (props.accessData)
            setAccessData(props.accessData)
    }, [props.accessData])
    const handleAppSelect = (item) => {
        setFunctionalSectionnEnabled(true);
        props.appRights(item);
    }
    const handleCancel = () => {
        setOpen(false)
        setEditID(0)
        setUserName('')
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div>

                {accessData && accessData.length > 0 ?
                    <div
                        className={clsx(classes.root, className)}>
                        <div style={{ marginLeft: '15px' }}>
                            <Typography variant="h1" color='primary' style={{ marginLeft: 5, marginTop: 10 }}> Security Role Designer </Typography>
                        </div>
                        <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
                            <div style={{ padding: 20 }}>
                                <TextInputWithLabel heading={'Enter role name'} twoline='true' prevalue={userName} onChange={handleUserName} required />
                            </div>
                            <DialogActions>
                                <Button onClick={handleCancel} color="primary">
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={handleAddUser}>
                                    {editID == '0' ? 'Add' : 'Edit'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                // component={CustomRouterLink}
                                onClick={addRole}
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add Role</Typography>
                            </Fab>
                            {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlinedIcon />}
                            onClick={addRole}
                        >
                            {isDesktop ? 'Add Role' : ''}
                        </Button> */}
                        </div>
                        {functionalSectionnEnabled ?
                            <div style={{ margin: 15 }}>
                                <Typography variant="h3" style={{ paddingTop: 10 }}>
                                    <IconButton
                                        onClick={() => setFunctionalSectionnEnabled(false)}
                                        classes={{ root: classes.IconButton }}
                                        size="large">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &nbsp; Functional Access </Typography>
                                <Paper style={{ overflowY: 'auto !important' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell>Role</TableCell>
                                                    {
                                                        props.appRightsData && props.appRightsData.rights &&
                                                        props.appRightsData.rights.map(app => {
                                                            return <TableCell align='center' style={{ textTransform: 'capitalize' }}>{app.replace(/_/g, ' ')}</TableCell>;
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {accessData && accessData
                                                    .filter(item => item.name != 'external')
                                                    .map(item => {
                                                        return (
                                                            <StyledTableRow>
                                                                <TableCell>{item.name}</TableCell>
                                                                {
                                                                    props.appRightsData && props.appRightsData.keys &&
                                                                    props.appRightsData.keys.map(key => {
                                                                        return (
                                                                            <TableCell align='center'>
                                                                                <Switch
                                                                                    checked={item.rights.includes(key)}
                                                                                    color='primary'
                                                                                    onChange={(e) => handleChange(e, item, key)}
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                />
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                            :
                            <div style={{ margin: 15, marginTop: "0px" }}>
                                <Typography variant="h3" style={{ color: 'black' }}>
                                    Application Access </Typography>
                                <Paper style={{ overflowY: 'auto !important' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow>
                                                    {apps.map(item => {
                                                        return <TableCell align='center' className={classes.tabHead}
                                                        >{item}</TableCell>
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {accessData && accessData
                                                    .filter(item => item.name != 'external')
                                                    .map(item => {
                                                        return (
                                                            <StyledTableRow>
                                                                <TableCell classes={{ root: classes.fontSetting }}>{item.name} </TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }}>
                                                                    {item.name != 'org_admin' ?
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            < IconButton
                                                                                classes={{ root: classes.IconButton }}
                                                                                onClick={() => handleEditUser(item)}
                                                                                size="large">
                                                                                <Edit color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                classes={{ root: classes.IconButton }}
                                                                                onClick={() => props.deleteUser(item.ID)}
                                                                                size="large">
                                                                                <Delete color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </TableCell>
                                                                {
                                                                    apps
                                                                        .filter(appItem => appItem != 'Role Name' && appItem != 'Action')
                                                                        .map(appItem => {
                                                                            return (
                                                                                <TableCell>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        <Switch
                                                                                            checked={item.authorized_apps && item.authorized_apps.includes(appItem)}
                                                                                            color='primary'
                                                                                            onChange={(e) => handleChange(e, item, appItem, null, 'authorized_apps')}
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                        />
                                                                                        <IconButton
                                                                                            onClick={() => handleAppSelect(appItem)}
                                                                                            classes={{ root: classes.IconButton }}
                                                                                            size="large">
                                                                                            <SettingsIcon />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </TableCell>
                                                                            );
                                                                        })
                                                                }
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        }
                    </div>
                    :
                    <Typography variant='h4'>
                        No roles defined
                    </Typography>
                }
            </div >
        </LoadingOverlay >
    );

};

const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        accessData: state.addMultipleConfigurationData.accessDataNew,
        appRightsData: state.addMultipleConfigurationData.appRights,
        orgAppTitleData: state.addMultipleConfigurationData.orgAppTitleData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAccessData: () => dispatch(getAccessDataNew()),
        postAccessData: (formData, id) => dispatch(postAccessData(formData, id)),
        addUser: (name, fn, id) => dispatch(addUser(name, fn, id)),
        deleteUser: (id) => dispatch(addUser(id, 'delete')),
        appRights: (app) => dispatch(appRights(app)),
        getOrganizationAppTitle: () => dispatch(getOrganizationAppTitle())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessNew);