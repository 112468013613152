import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
    TextField,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Checkbox, ListItemText
} from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { connect } from 'react-redux';
import { getSearchDocumentResult, getLblDispDesFieldValue, getQueryRefById, updateQueryRefDataMaster } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink } from 'react-router-dom';
import SimpleDialog from '../../components/Dialog';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import MomentUtils from '@date-io/moment';
import { ChipInput } from "../../components/Inputs";
import RingLoader from "react-spinners/RingLoader";
import DynamicQueryFormRef from '../DynamicQuery/DynamicQueryFormRef';
import FilterDialoge from '../DynamicQuery/FilterDialoge';
import momentt from "moment-timezone";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            overflowY: 'scroll',
            scrollBehaviour: ' smooth',
            MarginTop: 0,
            menuPlacement: "top"
        }
    }, getContentAnchorEl: null,
}

const useStyles = makeStyles(theme => ({
    bodyContainer: {
        backgroundColor: theme.palette.white,
        //border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    container2: {
        paddingTop: 18,
        width: '100%'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 160
    },
    select: {
        width: '100%',
        marginTop: 10,
        textTransform: "capitalize",
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: "capitalize",
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    spinner: {
        height: '100vh'
    },
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10,
        marginTop: '-1rem'
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    multiSelect: {
        marginTop: 10
    },
    spinner: {
        height: '90vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30,
    },
    select: {
        width: "100%",
        // color: "#1675e0",
        backgroundColor: "white",
    },
    selectedItem: {
        borderRadius: 5,
        textTransform: "capitalize",
    },
    button: {
        marginRight: 25,
        width: 160
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    gridContainer: {
        padding: "0px 15px 10px 10px",
    },
    gridContainerOuter: {
        padding: "0px 0px 0px 0px",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },

}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);
const DocumentManagementSearch = props => {
    const classes = useStyles();
    const [membershipFields, setMembershipFields] = React.useState([]);
    const [membershipData, setMembershipData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);
    const [numericFields, setNumericFields] = React.useState(['duration']);
    const [rangeOption, setRangeOption] = React.useState(['status']);
    const [dateFields, setDateFields] = React.useState(["valid_from", "valid_to"]);
    const rangeFields = ["valid_from", "valid_to", "duration", 'status'];
    const [open, setOpen] = useState(false);
    const [trigger, setTigger] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [filterID, setFilterID] = React.useState('');
    const [filterName, setFilterName] = React.useState("");
    const currentQueryID = "document_header";
    const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState([]);
    const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState("");
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [status, setStatus] = React.useState(false);
    const options = ["range", "equal", "not equal", 'greater than', 'less than', 'greater than equal to', 'less than equal to', "pattern"];
    const [validItem, setValidItem] = React.useState(true)
    const [formData, setFormData] = React.useState([])
    const [attributeNameArray, setAttributeNameArray] = React.useState([])
    const [attributes, setAttributes] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedDetail, setSelectedDetail] = useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [dropDownData, setDropDownData] = React.useState([]);
    useEffect(() => {
        props.getLblDispDesFieldValue({ "data_source": "document_header", "application_type": "Customer Rebate" }, 'post');
    }, []);

    useEffect(() => {
        var newArray = []
        var notContainSortList = []
        var mandatory = []
        var requiredFields = []
        let newArray2 = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)

                .map((item) => {
                    item.sort_list = item.sort_list ? item.sort_list : 999

                    if ((item.sort_list > 0 && item.sort_list < 999)) {
                        newArray.push(item)
                        // arrange item according to sortlist
                        newArray.sort((a, b) => {
                            return a.sort_list - b.sort_list;
                        })
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                    if (item.key == 'document_status') {
                        newArray2.push({
                            current: item.current,
                            data_type: item.data_type,
                            default: item.default,
                            display: item.display,
                            drop_down_reference: item.drop_down_reference,
                            drop_down_reference_value: item.drop_down_reference_value,
                            drop_down_table: item.drop_down_table,
                            ignore_dropdown: item.ignore_dropdown,
                            ignore_fields: item.ignore_fields,
                            key: "status",
                            mandatory: item.mandatory,
                            required: item.required,
                            sort_details: item.sort_details,
                            sort_list: item.sort_list,
                            type: item.type,
                        })

                    }
                    if (item.sort_list == 999) {
                        notContainSortList.push(item)
                    }
                })
            newArray2.push({
                "current": "Duration Hr",
                "data_type": "string",
                "default": "Duration",
                "display": true,
                "drop_down_reference": "",
                "drop_down_reference_value": [],
                "drop_down_table": "",
                "ignore_dropdown": false,
                "ignore_fields": false,
                "key": "duration",
                "mandatory": false,
                "required": false,
                "sort_details": 11,
                "type": "Number"
            })

            setMembershipFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
            setAttributeNameArray(newArray2)
            setDropDownData(notContainSortList)
        }
    }, [props.tableData]);
    //do array banegi ek jisme sort list waali elements jpo neeche display honge ,doosri array mien bina sortlist waale honge jo neeche dropdown mein aayenge 

    useEffect(() => {
        var temp;
        var range
        if (props.predefinedFilter && props.predefinedFilter.filter_fields && filterName === props.predefinedFilter.filter_name) {
            setDynamicFieldValuesFormSubmit(props.predefinedFilter.filter_fields)
            setFilterName(props.predefinedFilter.filter_name)
            Object.values(props.predefinedFilter.filter_fields).map((item, i) => {
                temp = { ...temp, [item.filter_name]: item.filter_value && item.filter_value.from ? item.filter_value.from : item.filter_value }
                if (item.filter_value && item.filter_value.to)
                    range = { ...range, [item.filter_name]: item.filter_value.to }
            })
            setDynamicFieldValues(temp)
            setDynamicFieldValuesRange(range)
        }
        else {
            setDynamicFieldValues({})
            setDynamicFieldValuesFormSubmit({})
            setDynamicFieldValuesRange({})
        }
    }, [props.predefinedFilter])
    useEffect(() => {
        dynamicFieldValuesFormSubmit && Object.entries(dynamicFieldValuesFormSubmit).length > 0 && Object.entries(dynamicFieldValuesFormSubmit).map(([item, index]) => {
            if (index.filter_name == 'status') {
                if (index.filter_option == 'range') {
                    if (index.filter_value && index.filter_value.length > 0) {
                        setValidItem(false)
                    }
                }
            }
            if (index.filter_name == 'duration') {
                if (index.filter_option !== '') {
                    if (index.filter_value && index.filter_value !== 0) {
                        setValidItem(false)
                    }
                }
            }
            else {
                if (props.predefinedFilter && props.predefinedFilter.filter_fields && 'duration' in props.predefinedFilter.filter_fields && 'status' in props.predefinedFilter.filter_fields) {
                    setValidItem(false)
                }
                else
                    setValidItem(true)
            }

        })
    }, [validItem, dynamicFieldValuesFormSubmit, props.predefinedFilter])
    function handleClearAll() {
        // setMembershipData({})
        setValidItem(true)
        setDynamicFieldValues({})
        setDynamicFieldValuesRange("")
        setDynamicFieldValuesFormSubmit({})
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData2);
            handleClearAll();
        } else {
            setOpen(false);
        }
    }
    const formData2 = {
        "filter_fields": {
            ...dynamicFieldValuesFormSubmit
        }
    };

    const handleSearch = (type) => {
        if (type === 'Save Search') {
            setOpenFilter(true)
        }
        if (type === 'Save Search & Run') {
            setOpenFilter(true)
            setTigger(true)
        }
    }

    const handleFilter = (item) => {
        setFilterID(item.id)
        setFilterName(item.filter_name)
        props.getPredefinedFilter(item.id)
    }

    const handleSaveAndRun = (name, type) => {
        setOpenFilter(false)
        if (dynamicFieldValuesFormSubmit) {
            var formData1 = {
                query_id: "document_header",
                filter_name: name,
                filter_fields: dynamicFieldValuesFormSubmit,
            };
            if (type == 'edit')
                formData1['ID'] = filterID
            props.saveAndRun(type, formData1);
            if (trigger) {
                props.onSubmit(formData2);
            }
        }
    }
    const handleBillingDate = (e, type, fieldName) => {
        if (type == "start") {
            setBillingDateStart(e);
            setDynamicFieldValues({
                ...dynamicFieldValues,
                [fieldName]: Moment(e).format("YYYY-MM-DD"),
            });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = Moment(e).format("YYYY-MM-DD")
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: Moment(e).format("YYYY-MM-DD")
                    }
                });
            }
        } else {
            setBillingDateEnd(e);
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: dynamicFieldValues[fieldName], to: Moment(e).format("YYYY-MM-DD") },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        }
    };
    const handleDynamicFieldValues = (e, fieldName, type, page) => {
        if (!dynamicFieldValuesFormSubmit[fieldName]) {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: { filter_name: fieldName },
            });
        }
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    var value = page == 'master' ? e.key : e
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: value });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: {
                                from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName]) : dynamicFieldValues[fieldName],
                                to: numericFields.includes(fieldName) ? Number(value) : value.toString()
                            },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: '' });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                // setDynamicFieldValuesRange("");
            }
        } else if (type === "option") {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    filter_name: fieldName,
                    filter_option: e,
                },
            });
        } else {
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(e) : e
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? Number(e) : e,
                    },
                });
            }
        }
    };

    const handleDynamicFieldValuesAdd = (e, fieldName, type, ix) => {
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_name: fieldName,
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        } else {
            var temp = dynamicFieldValues[fieldName]
                ? dynamicFieldValues[fieldName]
                : [];
            if (type === "pastedItem") {
                e.map((arg) => {
                    if (arg) temp.push(arg.trim());
                });
            } else {
                // temp.push(e);
                temp = e;
            }
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(temp) : temp
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
                    },
                });
            }
        }
    };
    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === dropDownData.length ? [] : dropDownData.map((item) => item.key));
            setAllSelect(selected.length === dropDownData.length ? false : true)
            setSelectedDetail(selected.length === dropDownData.length ? [] : dropDownData)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === dropDownData.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else { setSelectedDetail(detail) }
        }
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(dropDownData && dropDownData.length > 0 && dropDownData.length === membershipData.length)
        setSelectedDetail(attributesData)
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
        // setMembershipFields([...membershipFields, ...selectedDetail])
    }

    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setAttributes([])
        setAttributesData([])
        setSelectedDetail([])
    }
    const combinedData = [...membershipFields, ...attributesData];


    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >

            <div className={clsx(classes.root)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ textTransform: 'capitalize', marginLeft: '1rem' }}> Search Document</Typography>
                    <Grid container style={{ marginBottom: 20, marginTop: '2rem' }}>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ display: 'flex' }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginRight: 20, paddingTop: 5 }}>
                                Select Required Filters
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-name-label"
                                id={'filter-list'}
                                multiple
                                value={attributes ? attributes : []}
                                style={{ maxHeight: "50px", width: '67.5%', color: 'grey' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen()}
                                onClose={() => setSelectOpen(false)}
                                open={selectOpen}
                                renderValue={(filter) => {
                                    let type = []
                                    filter.map(itemX => {
                                        attributesData.filter(item => item.key == itemX).map((item) => {
                                            if (item.current)
                                                type.push(item.current)
                                        })

                                    })
                                    return type.join(',')
                                }}
                                className={classes.multiSelect1}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={allSelect} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {dropDownData && dropDownData.length > 0 && dropDownData

                                    .map((item) => {
                                        return (
                                            <MenuItem onClick={(event) => handleSelectValues(event, item.key, item)} value={item.key} key={item.key} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected && selected.indexOf(item.key) > -1}
                                                />
                                                <ListItemText primary={item.current} />
                                            </MenuItem>
                                        )
                                    })}
                                <MenuItem value='' ></MenuItem>

                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.container} style={{ width: '80%' }}>

                            <Grid container>
                                {combinedData &&
                                    combinedData.length > 0 &&
                                    combinedData
                                        .map((item, ix) => {
                                            return <>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainerOuter }}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >

                                                        <FormLabel classes={{ root: classes.fontSetting }}
                                                            style={{
                                                                // position: 'absolute',
                                                                // top: '50%',
                                                                // left: '25%',
                                                                // transform: 'translate(-50%, -50%)'
                                                                marginTop: ix === 0 ? '0.7rem' : '-1.3rem'
                                                            }}>
                                                            <Typography variant="h3" color='primary' style={{
                                                                marginTop: '-0.6rem',
                                                                position: 'absolute'
                                                            }}>{ix == 0 && "Filter Name"}</Typography>
                                                            {
                                                                ix == 0 ? <div style={{ marginTop: '26px' }} > {item.current} </div> : item.current
                                                            }
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <FormLabel
                                                            classes={{ root: classes.fontSetting }}
                                                        >
                                                            <Typography variant="h3" color='primary'>{ix === 0 && "Option"}</Typography>
                                                        </FormLabel>
                                                        <Select
                                                            value={
                                                                dynamicFieldValuesFormSubmit[
                                                                    item.key
                                                                ] &&
                                                                    dynamicFieldValuesFormSubmit[item.key][
                                                                    "filter_option"
                                                                    ]
                                                                    ? dynamicFieldValuesFormSubmit[
                                                                    item.key
                                                                    ]["filter_option"]
                                                                    : ""
                                                            }
                                                            onChange={(e) =>
                                                                handleDynamicFieldValues(
                                                                    e.target.value,
                                                                    item.key,
                                                                    "option"
                                                                )
                                                            }
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            style={{ marginTop: ix === 0 ? '0px' : '-21px', textTransform: 'capitalize' }}
                                                            className={clsx({
                                                                [classes.select]: true,
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem,
                                                            }}
                                                            disabled={(item.key == "customer_number" || item.key == "supplier_number" || item.key == "employee_number") ? status : false}
                                                        >
                                                            <MenuItem value=""></MenuItem>
                                                            {options &&
                                                                options
                                                                    .filter(itemX => (numericFields.includes(item.key) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                                        ((!numericFields.includes(item.key) && !dateFields.includes(item.key) && !rangeFields.includes(item.key)) ? itemX != 'range' : itemX)
                                                                        && (dateFields.includes(item.key) ? itemX != 'pattern' : itemX) && (rangeOption.includes(item.key) ? itemX != 'not equal' && itemX != 'equal' && itemX != 'pattern' : itemX))
                                                                    .filter(itemX => (item.key == 'document_manager' || item.key == 'document_analyst' || item.key == 'document_id') ? itemX !== 'pattern' : itemX)
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={item}
                                                                                key={item}
                                                                                style={{ textTransform: "capitalize" }}
                                                                            >
                                                                                {item}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                        </Select>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={7}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <FormLabel
                                                            classes={{ root: classes.fontSetting }}
                                                        >
                                                            <Typography variant="h3" color='primary'>{ix == 0 && "Value"}</Typography>
                                                        </FormLabel>
                                                        {dateFields.includes(item.key) ? (
                                                            <div style={{ display: "flex", marginTop: "-1.4rem" }}>
                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                    <LocalizationProvider
                                                                        libInstance={momentt}
                                                                        dateAdapter={AdapterMoment}
                                                                    >
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        // padding: "0px 11px 0px 11px",
                                                                                        alignSelf: "center",
                                                                                        alignItems: "center",
                                                                                        border: "1px solid #E0E0E0",
                                                                                        width: "100%",
                                                                                        height: 36,
                                                                                        // borderRadius: 5,
                                                                                        // color: "#1675e0",
                                                                                    },
                                                                                }}
                                                                                value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : null}
                                                                                onChange={(e) =>
                                                                                    handleBillingDate(e, "start", item.key)
                                                                                }
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider >
                                                                </div >
                                                                {
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.key
                                                                    ] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.key
                                                                    ]["filter_option"] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.key
                                                                    ]["filter_option"] == "range" &&
                                                                    <div style={{ width: "40%", marginLeft: 5 }}>
                                                                        < LocalizationProvider
                                                                            libInstance={momentt}
                                                                            dateAdapter={AdapterMoment}
                                                                        >
                                                                            <Grid container justifyContent="space-around">
                                                                                <DatePicker
                                                                                    disableToolbar
                                                                                    clearable
                                                                                    InputProps={{
                                                                                        padding: 0,
                                                                                        disableUnderline: true,
                                                                                        style: {
                                                                                            // padding: "0px 11px 0px 11px",
                                                                                            alignSelf: "center",
                                                                                            alignItems: "center",
                                                                                            border: "1px solid #E0E0E0",
                                                                                            width: "100%",
                                                                                            height: 36,
                                                                                            // borderRadius: 5,
                                                                                            //color: "#1675e0",
                                                                                        },
                                                                                    }}
                                                                                    value={dynamicFieldValuesFormSubmit[item.key] && dynamicFieldValuesFormSubmit[item.key]['filter_value']
                                                                                        && dynamicFieldValuesFormSubmit[item.key]['filter_value']['to'] ? dynamicFieldValuesFormSubmit[item.key]['filter_value']['to'] : null}
                                                                                    onChange={(e) =>
                                                                                        handleBillingDate(e, "end", item.key)
                                                                                    }
                                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                    format="MM/DD/YYYY"
                                                                                    disabled={
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                            item.key
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.key
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.key
                                                                                            ]["filter_option"] == "range"
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </LocalizationProvider >
                                                                    </div >}
                                                            </div >
                                                        ) :

                                                            dynamicFieldValuesFormSubmit[
                                                                item.key
                                                            ] &&
                                                                dynamicFieldValuesFormSubmit[
                                                                item.key
                                                                ]["filter_option"] &&
                                                                dynamicFieldValuesFormSubmit[
                                                                item.key
                                                                ]["filter_option"] == "pattern" ?
                                                                <div style={{ marginRight: 5, marginTop: ix == 0 ? '0rem' : "-1.3rem", width: "60%" }}>
                                                                    <ChipInput data={dynamicFieldValues[item.key]
                                                                        ? dynamicFieldValues[item.key]
                                                                        : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.key, null, ix)} />
                                                                </div>
                                                                :

                                                                item.key === 'duration'
                                                                    //  || item.key === 'calculation_type' || item.key === 'contract_type' || item.key === 'contract_group' || item.key === 'contract_sub_group' 
                                                                    ?
                                                                    <div style={{ display: "flex", marginTop: '-1.9rem' }}>
                                                                        <div style={{ width: "60%", marginRight: 5 }}>
                                                                            <OutlinedInput
                                                                                type={props.type}
                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                // onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.key, null, ix)} 
                                                                                onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)}
                                                                            />
                                                                        </div>
                                                                        {dynamicFieldValuesFormSubmit[
                                                                            item.key
                                                                        ] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.key
                                                                            ]["filter_option"] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.key
                                                                            ]["filter_option"] == "range" &&
                                                                            <div style={{ width: "40%", marginRight: 5 }}>
                                                                                <OutlinedInput
                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                    type={props.type}
                                                                                    value={
                                                                                        dynamicFieldValues[item.key] &&
                                                                                            dynamicFieldValues[item.key]
                                                                                                .length > 0
                                                                                            ? dynamicFieldValuesRange[
                                                                                            item.key
                                                                                            ]
                                                                                            : ""
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDynamicFieldValues(
                                                                                            e.target.value,
                                                                                            item.key,
                                                                                            "range"
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>}
                                                                    </div>
                                                                    :
                                                                    item.key == 'document_manager' || item.key == 'document_analyst' ?
                                                                        (
                                                                            <div style={{ display: "flex", marginTop: '-1.9rem' }}>
                                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                                    <Select
                                                                                        value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                        style={{ marginTop: "10px", maxHeight: "50px", width: '100%' }}
                                                                                        onChange={(e) => handleDynamicFieldValues((e.target.value).toString(), item.key)}
                                                                                        displayEmpty
                                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                        className={classes.multiSelect}

                                                                                    >
                                                                                        {item.drop_down_reference_value.map((item, index) => {
                                                                                            return (
                                                                                                <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.desc}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                    </Select>
                                                                                </div>
                                                                                {dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                    ]["filter_option"] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                    ]["filter_option"] == "range" &&
                                                                                    <div style={{ width: "40%" }}>
                                                                                        <Select
                                                                                            value={
                                                                                                dynamicFieldValues[item.key] &&
                                                                                                    dynamicFieldValues[item.key]
                                                                                                        .length > 0
                                                                                                    ? dynamicFieldValuesRange[
                                                                                                    item.key
                                                                                                    ]
                                                                                                    : ""
                                                                                            }
                                                                                            style={{ marginTop: "10px", maxHeight: "50px", width: '100%' }}
                                                                                            onChange={(e) => handleDynamicFieldValues(e.target.value.toString(), item.key, "range")}
                                                                                            displayEmpty
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                            className={classes.multiSelect}

                                                                                        >
                                                                                            {item.drop_down_reference_value.map((item, index) => {
                                                                                                return (
                                                                                                    <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                        {item.desc}
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    </div>}
                                                                            </div>
                                                                        ) :

                                                                        item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                            (
                                                                                <div style={{
                                                                                    display: "flex", marginTop: ix == 0 ? '-0.6rem' : '-1.9rem'
                                                                                }}>
                                                                                    <div style={{ width: "60%", marginRight: 5 }}>
                                                                                        <Select
                                                                                            value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                            style={{ marginTop: "10px", maxHeight: "50px", width: '100%' }}
                                                                                            onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)}
                                                                                            displayEmpty
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                            className={classes.multiSelect}

                                                                                        >
                                                                                            {item.drop_down_reference_value.map((item, index) => {
                                                                                                return (
                                                                                                    <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                        {item.desc}
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    </div>
                                                                                    {dynamicFieldValuesFormSubmit[
                                                                                        item.key
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.key
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.key
                                                                                        ]["filter_option"] == "range" &&
                                                                                        <div style={{ width: "40%" }}>
                                                                                            <Select
                                                                                                value={
                                                                                                    dynamicFieldValues[item.key] &&
                                                                                                        dynamicFieldValues[item.key]
                                                                                                            .length > 0
                                                                                                        ? dynamicFieldValuesRange[
                                                                                                        item.key
                                                                                                        ]
                                                                                                        : ""
                                                                                                }
                                                                                                style={{ marginTop: "10px", maxHeight: "50px", width: '100%' }}
                                                                                                onChange={(e) => handleDynamicFieldValues(e.target.value, item.key, "range")}
                                                                                                displayEmpty
                                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                                className={classes.multiSelect}

                                                                                            >
                                                                                                {item.drop_down_reference_value.map((item, index) => {
                                                                                                    return (
                                                                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                            {item.desc}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                            </Select>
                                                                                        </div>}
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                <div style={{ display: "flex", marginTop: ix == 0 ? '0' : '-1.2rem' }}>
                                                                                    <div style={{ width: "60%", marginRight: 5 }}>
                                                                                        <ChipInput
                                                                                            data={dynamicFieldValues[item.key]
                                                                                                ? dynamicFieldValues[item.key]
                                                                                                : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.key, null, ix)} />
                                                                                    </div>
                                                                                    {dynamicFieldValuesFormSubmit[
                                                                                        item.key
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.key
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.key
                                                                                        ]["filter_option"] == "range" &&
                                                                                        <div style={{ width: "40%", marginRight: 5 }}>
                                                                                            <OutlinedInput
                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                type={props.type}
                                                                                                value={
                                                                                                    dynamicFieldValues[item.key] &&
                                                                                                        dynamicFieldValues[item.key]
                                                                                                            .length > 0
                                                                                                        ? dynamicFieldValuesRange[
                                                                                                        item.key
                                                                                                        ]
                                                                                                        : ""
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    handleDynamicFieldValues(
                                                                                                        e.target.value,
                                                                                                        item.key,
                                                                                                        "range"
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                        item.key
                                                                                                    ] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.key
                                                                                                        ]["filter_option"] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.key
                                                                                                        ]["filter_option"] == "range"
                                                                                                        ? false
                                                                                                        : true
                                                                                                }
                                                                                            />
                                                                                        </div>}
                                                                                </div>
                                                                            )}
                                                    </Grid >

                                                </Grid >

                                            </>;
                                        })}
                                <div className={classes.container2} style={{ paddingRight: '1rem' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell align='center'>Attribute Name <span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                                    <TableCell align='center'>Option<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                                    <TableCell align='center'>Attribute Value<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                                    {/* <TableCell align='center'>Range<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell> */}
                                                    {/* <TableCell align='center' style={{ width: '100px' }}>Actions</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {(() => {
                                                    let td = [];
                                                    attributeNameArray.map((item, ix) => {
                                                        td.push(<>
                                                            <StyledTableRow>
                                                                <TableCell>
                                                                    <div>{item.current}</div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div style={{ marginTop: '0rem' }} >
                                                                        <Select
                                                                            id={ix}
                                                                            value={
                                                                                dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[item.key][
                                                                                    "filter_option"
                                                                                    ]
                                                                                    ? dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                    ]["filter_option"]
                                                                                    : ""
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleDynamicFieldValues(
                                                                                    e.target.value,
                                                                                    item.key,
                                                                                    "option"
                                                                                )
                                                                            }
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            displayEmpty
                                                                            style={{ textTransform: 'capitalize' }}
                                                                            className={clsx({
                                                                                [classes.select]: true,
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem,
                                                                            }}
                                                                            disabled={(item.key == "customer_number" || item.key == "supplier_number" || item.key == "employee_number") ? status : false}
                                                                        >
                                                                            <MenuItem value=""></MenuItem>
                                                                            {options &&
                                                                                options
                                                                                    .filter(itemX => (numericFields.includes(item.key) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                                                        ((!numericFields.includes(item.key) && !dateFields.includes(item.key) && !rangeFields.includes(item.key)) ? itemX != 'range' : itemX)
                                                                                        && (dateFields.includes(item.key) ? itemX != 'pattern' : itemX) && (rangeOption.includes(item.key) ? itemX != 'not equal' && itemX != 'equal' && itemX != 'pattern' : itemX))
                                                                                    .map((item, index) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                value={item}
                                                                                                key={item}
                                                                                                style={{ textTransform: "capitalize" }}
                                                                                            >
                                                                                                {item}
                                                                                            </MenuItem>
                                                                                        );
                                                                                    })}
                                                                        </Select>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                        (
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                                    <Select
                                                                                        id={ix}
                                                                                        value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                        style={{ marginTop: "0px", maxHeight: "50px", width: '100%' }}
                                                                                        onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)}
                                                                                        displayEmpty
                                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                        className={classes.multiSelect}

                                                                                    >
                                                                                        {item.drop_down_reference_value.map((item, index) => {
                                                                                            return (
                                                                                                <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.desc}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                    </Select>
                                                                                </div>
                                                                                {dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                    ]["filter_option"] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.key
                                                                                    ]["filter_option"] == "range" &&
                                                                                    <div style={{ width: "40%" }}>
                                                                                        <Select
                                                                                            id={ix}
                                                                                            value={
                                                                                                dynamicFieldValues[item.key] &&
                                                                                                    dynamicFieldValues[item.key]
                                                                                                        .length > 0
                                                                                                    ? dynamicFieldValuesRange[
                                                                                                    item.key
                                                                                                    ]
                                                                                                    : ""
                                                                                            }
                                                                                            style={{ marginTop: "0px", maxHeight: "50px", width: '166%' }}
                                                                                            onChange={(e) => handleDynamicFieldValues(e.target.value, item.key, "range")}
                                                                                            displayEmpty
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                            className={classes.multiSelect}

                                                                                        >
                                                                                            {item.drop_down_reference_value.map((item, index) => {
                                                                                                return (
                                                                                                    <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                        {item.desc}
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        ) :
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div style={{ width: "166%", marginRight: 5 }}>
                                                                                <OutlinedInput
                                                                                    id={ix}
                                                                                    type={props.type}
                                                                                    style={{ marginTop: '0px' }}
                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                    value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                    // onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.key, null, ix)} 
                                                                                    onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)}
                                                                                />
                                                                            </div>
                                                                            {dynamicFieldValuesFormSubmit[
                                                                                'duration'
                                                                            ] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                'duration'
                                                                                ]["filter_option"] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                'duration'
                                                                                ]["filter_option"] == "range" &&
                                                                                <div style={{ width: "40%", marginRight: 5 }}>
                                                                                    <OutlinedInput
                                                                                        classes={{ root: classes.inputTwoLine }}
                                                                                        type={props.type}
                                                                                        style={{ marginTop: '0px' }}
                                                                                        id={ix}
                                                                                        value={
                                                                                            dynamicFieldValues[item.key] &&
                                                                                                dynamicFieldValues[item.key]
                                                                                                    .length > 0
                                                                                                ? dynamicFieldValuesRange[
                                                                                                item.key
                                                                                                ]
                                                                                                : ""
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleDynamicFieldValues(
                                                                                                e.target.value,
                                                                                                item.key,
                                                                                                "range"
                                                                                            )
                                                                                        }
                                                                                        disabled={
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                                item.key
                                                                                            ] &&
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                item.key
                                                                                                ]["filter_option"] &&
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                item.key
                                                                                                ]["filter_option"] == "range"
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </TableCell >
                                                            </StyledTableRow >

                                                        </>)
                                                    })
                                                    return td;
                                                })()}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid >
                            <div className={classes.buttonRoot}>
                                <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClearAll} >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Clear'
                                    }
                                </Button>
                                <Button variant="contained" color="primary" className={classes.button} onClick={() => props.onSubmit(formData2)}
                                    disabled={('duration' in dynamicFieldValuesFormSubmit || 'status' in dynamicFieldValuesFormSubmit) && validItem ? true : false}
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Run'
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleSearch('Save Search')}
                                    twoline="true"
                                    disabled={('duration' in dynamicFieldValuesFormSubmit || 'status' in dynamicFieldValuesFormSubmit) && validItem ? true : false}
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search'
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleSearch('Save Search & Run')}
                                    twoline="true"
                                    disabled={('duration' in dynamicFieldValuesFormSubmit || 'status' in dynamicFieldValuesFormSubmit) && validItem ? true : false}
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search & Run'
                                    }
                                </Button>
                            </div>
                        </div >
                        <div style={{ width: '20%' }} className={classes.container}>
                            <DynamicQueryFormRef id={currentQueryID} onChange={handleFilter} />
                        </div>
                    </div >
                </div >
            </div >
            <SimpleDialog open={open} content='Do you want to search document search data?' handleDialog={handleDialog} />
            {
                openFilter &&
                <FilterDialoge open={openFilter} filterName={filterName}
                    action={filterID ? 'edit' : 'create'}
                    onChange={handleSaveAndRun}
                    onCancel={() => setOpenFilter(false)}
                />
            }
        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getSearchDocumentResult(data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getPredefinedFilter: (id) => dispatch(getQueryRefById(id)),
        saveAndRun: (type, data) => dispatch(updateQueryRefDataMaster(type, data, null, 'document_header')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagementSearch);