import React, { useEffect } from 'react';
import {
    Typography, IconButton, Button, Tooltip
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Delete } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import LastPageIcon from '@mui/icons-material/LastPage';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../../redux/actions';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    divContainerLeft: {
        position: 'relative',
        width: '80%',
        height: '80vh',
        marginLeft: 5,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        // backgroundColor: '#E8E8E8',
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    headerLeft: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    toggleButton: {
        marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },

    icons: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    name: {
        display: 'flex',
    },

    filter: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
        border: '1px solid #EEEEEE',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 15,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '122px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    historyButton: {
        border: '1px solid #D8D8D8',
        margin: '6px 10px',
        padding: 3,
        width: 35,
        height: 35,
    },

    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },

    actionIcon: {
        cursor: 'pointer',
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1,
        marginTop: 50
    },
    SelectBtn: {
        marginRight: 5
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: 10
    },
    actionLabel: {
        padding: '6px 10px',
        marginRight: 5
    }


}));


const StickerSelectedContainer = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const [productType, setProductType] = React.useState('');
    const [stickersBankData, setStickersBankData] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [stickerName, setStickerName] = React.useState('');
    const [dataRows, setDataRows] = React.useState([]);

    const columns = [
        {
            field: 'sticker_description',
            title: 'Stickers Name',
            type: 'string',
            render: (rowData, index) =>
                <div className={classes.name}>
                    <CircleIcon style={{ marginLeft: 15, marginTop: 2, height: 16, color: props.colorPicker && props.colorPicker[rowData.ID] }} />
                    {(rowData.promo_variability ? 'PV -' : '') + (rowData.version > 1 ? `V${rowData.version} - ${rowData.sticker_description}` : rowData.sticker_description)}
                </div>,
            cellStyle: { minWidth: 350 },
        },
        {
            field: 'take_rate',
            title: 'Take Rate',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > <Tooltip title={rowData.takerate_name}>{rowData.takerate_name}</Tooltip></a>,
            cellStyle: { minWidth: 400 },
        },
        {
            field: 'user_details.created_user.name',
            title: 'Creator',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.user_details && rowData.user_details.created_user.name}</a>

        },
        {
            field: 'product_lines',
            title: 'Product Line',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.product_lines && rowData.product_lines.join(', ')}</a>
        },
        {
            field: 'UpdatedAt',
            title: 'Last Update',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {moment.utc(rowData.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</a>
        },
        {
            field: 'status',
            title: 'Status',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > <CheckCircleOutlineIcon style={{ color: 'green' }}></CheckCircleOutlineIcon></a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div className={classes.actionContainer}>
                    {props.page == 'grid' ?
                        <>
                            {props.currentSticker == rowData.ID ?
                                <Typography varaint='h4' color='primary' className={classes.actionLabel}>Currently Used</Typography>
                                :
                                <Button variant={selectSticker == rowData.ID ? 'contained' : 'outlined'} onClick={() => handleSelect(rowData.ID)} className={classes.SelectBtn}>
                                    Select Sticker
                                </Button>
                            }
                        </>
                        :
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Button onClick={() => handleDefineMarket(rowData.ID)}>Define Market</Button>
                        </IconButton>
                    }
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large">
                        <Delete style={{ fontSize: 20 }} onClick={() => handleDelete(rowData)} />
                    </IconButton>
                </div>
        },
    ];
    const [selectSticker, setSelectSticker] = React.useState('');

    useEffect(() => {
        if (props.data) {
            setStickersBankData(props.data);
            setDataRows(props.data)
        }
    }, [props.data]);

    useEffect(() => {
        if (props.country) {
            // if (props.country === 'CA')
            //     setChecked(true);
            // else
            //     setChecked(false);
            setSelectedCountry(props.country);
        }
    }, [props.country]);

    useEffect(() => {
        if (props.stickerName) {
            setStickerName(props.stickerName);
        }
    }, [props.stickerName]);

    function handleAdd() {
        history.push('/promotion-sticker-setup/add');
    }

    function handleCountry(e) {
        setSelectedCountry(e.target.value)
        props.onChangeCountry(e.target.value);
    }

    function handleToggle(e) {
        setChecked(e.target.checked)
        if (e.target.checked)
            setSelectedCountry('CA')
        else
            setSelectedCountry('USA')
        props.onChangeCountry(selectedCountry);
    };

    function handleEdit(id) {
        props.getStickers(productType, selectedCountry, id)
    }

    function handleDelete(item) {
        props.onDelete(item);
    }

    function handleDefineMarket(value) {
        props.onChange(value)
    }

    function handleExpand() {
        props.onExpand(false);
    }
    function handleSelect(sticker) {
        setSelectSticker(sticker)
    }
    function handleApply() {
        if (props.page == 'grid')
            props.handleNext(selectSticker)
        else
            props.handleNext()
    }
    // function getRandomColor() {
    //     const letters = '0123456789ABCDEF';
    //     let color = '#';
    //     for (let i = 0; i < 6; i++) {
    //         color += letters[Math.floor(Math.random() * 16)];
    //     }
    //     return color;
    // }
    console.log(selectedCountry)
    return (
        <>
            <div className={classes.root}>
                {/* <div style={{ display: 'flex' }}>
                    <div className={classes.divContainerLeft}> */}
                <div className={classes.headerLeft}>
                    {/* <div style={{ display: 'flex' }}>
                        <LastPageIcon style={{ marginLeft: 10, marginTop: 14, border: '1px solid #D6D6D6', cursor: 'pointer' }} onClick={handleExpand}></LastPageIcon>
                        <Typography style={{ marginLeft: 5, marginTop: 23 }}>USA</Typography>
                        <span className={classes.toggleButton} >
                            <Switch
                                checked={checked}
                                onClick={handleToggle}
                                disabled={props.promoCountry && props.promoCountry.length == 1} />
                        </span>
                        <Typography style={{ marginTop: 23 }}>CANADA</Typography>
                    </div> */}
                    <div style={{ display: 'flex' }}>
                        <LastPageIcon style={{ marginLeft: 10, marginTop: 25, border: '1px solid #D6D6D6', cursor: 'pointer' }} onClick={handleExpand}></LastPageIcon>
                        <Typography style={{ color: 'rgb(182 175 175)', marginTop: 21, marginLeft: 5 }}>{stickerName}</Typography>
                    </div>
                    {/* <div style={{ marginTop: 18, marginRight: 20, width: 130 }}>
                        <Select
                            id={'sticker-selected-country'}
                            value={selectedCountry}
                            onChange={handleCountry}
                            style={{ marginBottom: 10, backgroundColor: 'white', borderRadius: 5 }}
                            disabled

                        >
                            <MenuItem value={'USA'}>USA</MenuItem>
                            <MenuItem value={'CA'}>CANADA</MenuItem>
                        </Select>
                    </div> */}
                </div>
                <MaterialTable
                    components={{
                        Toolbar: (props) => (
                            <div
                                style={{
                                    height: "0px",
                                }}
                            >
                            </div>
                        ),
                    }}
                    title={' '}
                    editable={true}
                    icons={tableIcons}
                    columns={columns}
                    data={dataRows}
                    style={{ marginLeft: '6px' }}
                    options={{
                        maxBodyHeight: '100vh',
                        search: false,
                        filtering: true,
                        headerStyle: theme.mixins.MaterialHeader,
                        cellStyle: theme.mixins.MaterialCell,
                        pageSize: 10,
                        pageSizeOptions: [10, 15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                    }}
                />
                {/* <Table style={{ marginTop: 5 }}>
                    <TableHead style={{ backgroundColor: "#E8E8E8", position: "sticky", top: 0, zIndex: 1 }}>
                        <TableRow>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 200 }}  >Stickers Name</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Take Rate</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Creator</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Related PL</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >Last update</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Status</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >Actions</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  ></TableCell>
                        </TableRow>
                    </TableHead>
                    <div className={classes.filter}>
                        <Typography style={{ marginTop: 7 }}>Filter</Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Promotion Type <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Status <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Version <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Take Rate <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                    </div>
                    {stickersBankData && stickersBankData.length > 0 ? (
                        <TableBody>
                            {stickersBankData && stickersBankData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 200 }}  >
                                        <div className={classes.name}>
                                            <CircleIcon style={{ marginLeft: 15, marginTop: 2, height: 16, color: props.colorPicker && props.colorPicker[item.ID] }} />
                                            {(item.promo_variability ? 'PV -' : '') + (item.version > 1 ? `V${item.version} - ${item.sticker_description}` : item.sticker_description)}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >X%</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >{item.user_details && item.user_details.created_user.name}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >{item.product_lines && item.product_lines.join(', ')}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >{moment.utc(item.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >
                                        <CheckCircleOutlineIcon style={{ color: 'green' }}></CheckCircleOutlineIcon>
                                    </TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >
                                        <div className={classes.icons}>
                                            <ContentCopyIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleCopy(item)}
                                            >
                                            </ContentCopyIcon>
                                            <EditIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleEdit(item.ID)}>
                                            </EditIcon>
                                            <DeleteIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleDelete(item)}>
                                            </DeleteIcon>
                                        </div>
                                    </TableCell>
                                    {props.page == 'grid' ?
                                        <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}>
                                            {props.currentSticker == item.ID ?
                                                <Typography varaint='h4' color='primary'>Currently Used</Typography>
                                                :
                                                <Button variant={selectSticker == item.ID ? 'contained' : 'outlined'} onClick={() => handleSelect(item.ID)} >
                                                    Select Sticker
                                                </Button>
                                            }
                                        </TableCell>
                                        : <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}>
                                            <Button onClick={() => handleDefineMarket(item.ID)}>Define Market</Button>
                                        </TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={7} align='center'>No data available</TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table> */}
                <div className={classes.buttonContainer}>
                    {props.page == 'grid' &&
                        <Button variant='text' color='primary' onClick={props.handleNext} style={{ marginRight: 5 }}>Cancel</Button>
                    }
                    <Button variant='contained' color='primary' onClick={handleApply}>{props.page == 'grid' ? 'Apply Sticker' : 'Apply Stickers'}</Button>
                </div>
            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getStickers: (productType, country, id) => dispatch(getPromoStickers(productType, country, id)),
        onAction: (data, type, id) => dispatch(createStickers(data, type, id)),
    }
}

const mapStateToProps = state => {
    return {
        stickersData: state.promotionData.promoStickers,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerSelectedContainer);