import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card, OutlinedInput,
    CardHeader, Input, Checkbox, ListItemText, Button,
    Grid, FormLabel, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Dropdown, Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown, DropdownKeyValue } from '../../../../components/Inputs';
import { connect } from 'react-redux';
import { getDefaultValues, getDefaultValuesAllDataNew } from '../../../../redux/actions';
import { AdminDataContainer } from '../../containers';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // border: '1px solid #EEEEEE',
        borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 15,
        height: 75,
        maxHeight: 75
    },
    error: {
        color: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 150
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        border: '1px solid #E0E0E0',
        display: 'flex',
        paddingLeft: 10
    },
    boxRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    boxGrid: {
        width: '100%'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        // marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'

    },
    formlabelContainer: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        marginBottom: 0

    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        // paddingBottom: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    selectedItem: {
        // color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        // marginTop: 25
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));
const HeaderFieldsContiner = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    useEffect(() => {
        if (props.datageneral && props.datageneral.application_type && props.datageneral.source_data_type) {
            // props.onLoadingLabelDesignerNew('field-label-designer', props.datageneral.application_type, props.datageneral.source_data_type)
            props.onLoadingDefaultTier(props.datageneral.application_type, props.datageneral.source_data_type);

        }
    }, [props.datageneral])

    const [applicationType, setApplicationType] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [firstTimeFlag, setFirstTimeFlag] = React.useState(true);
    const [contractGroup, setContractGroup] = React.useState('');
    const [internalDescription, setInternalDescription] = React.useState('');
    const [contractObjective, setContractObjective] = React.useState('');
    const [contractStrategy, setContractStrategy] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [internalReferance, setInternalReferance] = React.useState('');
    const [validFrom, setValidFrom] = React.useState('');
    const [contractSubtype, setContractSubtype] = React.useState('');
    const [contractSubgroup, setContractSubgroup] = React.useState('');
    const [externalDescription, setExternalDescription] = React.useState('');
    const [approvedStatus, setApprovedStatus] = React.useState('');
    const [externalReferance, setExternalReferance] = React.useState('');
    const [validTo, setValidTo] = React.useState('');
    const applicationRef = useRef(null);
    const contractTypeRef = useRef(null);
    const contractGroupRef = useRef(null);
    const internalDescriptionRef = useRef(null);
    const currencyRef = useRef(null);
    const internalReferanceRef = useRef(null);
    const validFromRef = useRef(null);
    const contractSubtypeRef = useRef(null);
    const contractSubgroupRef = useRef(null);
    const externalDescriptionRef = useRef(null);
    // const approvedStatusRef = useRef(null);
    // const externalReferanceRef = useRef(null);
    const validToRef = useRef(null);
    const [sourceDataTypeDataArray, setourceDataTypeDataArray] = React.useState(['Direct - Sales Data', 'Indirect – Channel Partner Sales']);
    //new values
    const contractPurposeRef = useRef(null);
    const [contractPurpose, setContractPurpose] = React.useState('');
    const [contractPurposeArray, setContractPurposeArray] = React.useState([]);
    const sourceDataTypeRef = useRef(null);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    const postingSchemaRef = useRef(null);
    const [postingSchema, setPostingSchema] = React.useState('');
    const [postingSchemaArray, setPostingSchemaArray] = React.useState([]);
    const postingApprovalRef = useRef(null);
    const [postingApproval, setPostingApproval] = React.useState('');
    const [postingApprovalArray, setPostingApprovalArray] = React.useState([]);
    const legacyContractIdRef = useRef(null);
    const [legacyContractId, setLegacyContractId] = React.useState('');
    const [legacyContractIdArray, setLegacyContractIdArray] = React.useState([]);
    const goLiveDateRef = useRef(null);
    const [goLiveDate, setGoLiveDate] = React.useState('');
    const [goLiveDateArray, setGoLiveDateArray] = React.useState([]);
    const [flexFields1Array, setFlexFields1Array] = React.useState([]);
    const [flexFields1, setFlexFields1] = React.useState('');
    const [flexFields2, setFlexFields2] = React.useState('');
    const [flexFields2Array, setFlexFields2Array] = React.useState([]);
    const [flexFields3, setFlexFields3] = React.useState('');
    const [flexFields3Array, setFlexFields3Array] = React.useState([]);
    const [flexFields4, setFlexFields4] = React.useState('');
    const [flexFields4Array, setFlexFields4Array] = React.useState([]);
    const [pricingStratergyArray, setPricingStratergyArray] = React.useState([]);
    const [pricingStratergy, setPricingStratergy] = React.useState('');
    //constants
    const [contractSubtypeData, setContractSubtypeData] = React.useState([]);
    const [contractSubtypeName, setContractSubtypeName] = React.useState('');
    const [applicationTypeData, setApplicationTypeData] = React.useState([]);
    const [applicationTypeName, setApplicationTypeName] = React.useState('');
    const [contractTypeData, setContractTypeData] = React.useState([]);
    const [contractTypeName, setContractTypeName] = React.useState('');
    const [contractGroupData, setContractGroupData] = React.useState([]);
    const [contractGroupName, setContractGroupName] = React.useState('');
    const [contractSubGroupData, setContractSubGroupData] = React.useState([]);
    const [contractSubGroupName, setContractSubGroupName] = React.useState('');
    const [currencyData, setCurrencyData] = React.useState([]);
    const [approvedStatusData, setApprovedStatusData] = React.useState([]);
    //arrays
    const [applicationTypeArray, setApplicationTypeArray] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [contractSubtypeArray, setContractSubtypeArray] = React.useState([]);
    const [contractGroupArray, setContractGroupArray] = React.useState([]);
    const [contractSubGroupArray, setContractSubGroupArray] = React.useState([]);
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [externalDescriptionArray, setExternalDescriptionArray] = React.useState([]);
    const [currencyArray, setCurrencyArray] = React.useState([]);
    const [approvalStatusArray, setApprovalStatusArray] = React.useState([]);
    const [internalReferenceArray, setInternalReferenceArray] = React.useState([]);
    const [externalReferenceArray, setExternalReferenceArray] = React.useState([]);
    const [validFromArray, setValidFromArray] = React.useState([]);
    const [validToArray, setValidToArray] = React.useState([]);
    const tierPeriodArray = ['Current Period', 'Previous Period', 'Previous 12 months'];
    const tierGrowthPeriodArray = ['Previous Year Same Period', 'Previous Period'];
    const [tierPeriod, setTierPeriod] = React.useState('');
    const [target, setTarget] = React.useState('No');
    const [incentiveAmount, setIncentiveAmount] = React.useState('');
    const [apiResponse2, setApiResponse2] = React.useState([]);
    //new fields
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [incentiveRateTypeArray, setIncentiveRateTypeArray] = React.useState([]);
    const [incentiveRateType, setIncentiveRateType] = React.useState('');
    const [tierTypeArray, setTierTypeArray] = React.useState('');
    const [tierType, setTierType] = React.useState('');
    const [tierEvaluationLevel, setTierEvaluationLevel] = React.useState([]);
    const [tierEvaluationLevelArray, setTierEvaluationLevelArray] = React.useState(['contract']);
    const [tierEvaluationLevelLabel, setTierEvaluationLevelLabel] = React.useState({ 'contract': 'Contract' });
    const [maximumAmountLevel, setMaximumAmountLevel] = React.useState('');
    const [maximumAmountLevelArray, setMaximumAmountLevelArray] = React.useState([]);
    const [evaluateSalesBundle, setEvaluateSalesBundle] = React.useState();
    const [quotaLevel, setQuotaLevel] = React.useState([]);
    const [quotaLevelArray, setQuotaLevelArray] = React.useState([]);
    const [targetAchievementArray, setTargetAchievementArray] = React.useState({});
    const [generalData, setGeneralData] = React.useState({});
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [maximumAmountPeriod, setMaximumAmountPeriod] = React.useState('');
    const [maximumAmountPeriodArray, setMaximumAmountPeriodArray] = React.useState({});
    const [contractDependency, setContractDependency] = React.useState('');
    const [calcRuleModifier, setCalcRuleModifier] = React.useState('');
    const [contractDependencyArray, setContractDependencyArray] = React.useState({});
    const [contractData, setContractData] = React.useState({});
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    useEffect(() => {
        // props.onChange(headerValues, headerValuesRequired);
        if (localStorage.getItem('mode') === 'edit' && firstTimeFlag) {
            setFirstTimeFlag(false);
        }
    });
    useEffect(() => {
        if (props.datageneral && Object.entries(props.datageneral).length > 0) {
            setGeneralData(props.datageneral)
            setFlexFields1(props.datageneral.flex_fields_1)
            setFlexFields2(props.datageneral.flex_fields_2)
            setFlexFields3(props.datageneral.flex_fields_3)
            setContractObjective(props.datageneral.contract_objective)
            setContractStrategy(props.datageneral.contract_strategy)
            setTarget(props.datageneral.commitment_achievement)
            setContractSubtype(props.datageneral.calculation_schema)
            setCalculationMethod(props.datageneral.calculation_method);
            setIncentiveRateType(props.datageneral.incentive_rate_type)
            setTierType(props.datageneral.tier_type)
            setTierEvaluationLevel(props.datageneral.tier_evaluation_level ? props.datageneral.tier_evaluation_level : [])
            setExclusiveFormula(props.datageneral.formula_exclusive)
            setMaximumAmountLevel(props.datageneral.maximum_amount_level)
            setPostingSchema(props.datageneral.posting_schema);
            setPostingApproval(props.datageneral.posting_approval);
            setEvaluateSalesBundle(props.datageneral.evaluate_sales_bundle);
            if (props.datageneral && props.datageneral.quota_level ? props.datageneral.quota_level : [])
                setQuotaLevel(props.datageneral.quota_level);
            setValidFrom(props.datageneral.valid_from);
            setValidTo(props.datageneral.valid_to);
            setMaximumAmountPeriod(props.datageneral.maximum_amount_period)
            if (props.datageneral.calculation_schema === 'Price Difference' && sessionStorage.getItem('application') == 'Supplier Chargeback')
                setContractSubtype('Price Difference');
            setContractDependency(props.datageneral.contract_dependency)
        }
    }, [props.datageneral]);

    // useEffect(() => {
    //     props.onLoadingDefault();
    // }, []);

    const [exclusiveFormula, setExclusiveFormula] = React.useState(false);
    var headerValues = {
        application_type: applicationType,
        contract_type: contractType,
        calculation_schema: contractType,
        contract_group: contractGroup,
        internal_description: internalDescription,
        contract_objective: contractObjective,
        contract_strategy: contractStrategy,
        currency: currency,
        valid_from: validFrom,
        contract_sub_type: contractSubtype,
        contract_sub_group: contractSubgroup,
        external_description: externalDescription,
        approval_status: approvedStatus,
        external_reference: externalReferance,
        valid_to: validTo,
        contract_purpose: contractPurpose,
        source_data_type: sourceDataType,
        posting_schema: postingSchema,
        posting_approval: postingApproval,
        legacy_contract_id: legacyContractId,
        go_live_date: goLiveDate,
        tier_period: tierPeriod,
        commitment_achievement: target,
        maximum_incentive_amount: incentiveAmount ? parseFloat(incentiveAmount) : 0,
        //new fields
        calculation_method: calculationMethod,
        incentive_rate_type: incentiveRateType,
        tier_type: tierType,
        tier_evaluation_level: tierEvaluationLevel,
        formula_exclusive: exclusiveFormula,
        maximum_amount_level: maximumAmountLevel,
        evaluate_sales_bundle: evaluateSalesBundle,
        quota_level: quotaLevel,
        maximum_amount_period: maximumAmountPeriod
    };
    var headerValuesRequired = {
        applicationType: applicationType,
        contractType: contractType,
        internalDescription: internalDescription,
        currency: currency,
        validFrom: validFrom,
        contractSubtype: contractSubtype,
        validTo: validTo,
    };
    const reset = () => {
        applicationRef.current.reset();
        postingSchemaRef.current.reset();
        postingApprovalRef.current.reset();
        legacyContractIdRef.current.reset();
        contractPurposeRef.current.reset();
        contractTypeRef.current.reset();
        contractGroupRef.current.reset();
        internalDescriptionRef.current.reset();
        currencyRef.current.reset();
        // internalReferanceRef.current.reset();
        validFromRef.current.reset();
        goLiveDateRef.current.reset();
        contractSubtypeRef.current.reset();
        contractSubgroupRef.current.reset();
        // externalDescriptionRef.current.reset();
        // approvedStatusRef.current.reset();
        // externalReferanceRef.current.reset();
        validToRef.current.reset();
        setApplicationType('');
        setContractType('');
        setContractGroup('');
        setInternalDescription('');
        setCurrency('');
        setInternalReferance('');
        setValidFrom('');
    };

    //const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    // useEffect(() => {
    //     if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
    //         setLabelNewAPIData(props.labelNewAPIData)
    //     }
    // }, [props.labelNewAPIData])

    // function checkLabel(name) {
    //     return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
    //         .map(([key, value]) => {
    //             if (key == name) {
    //                 return labelNewAPIData[name]['current']
    //             }
    //         })

    // }

    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (!applicationRef.current.value || applicationRef.current.value === '')
                setApplicationType(false);
            if (!contractTypeRef.current.value || contractTypeRef.current.value === '')
                setContractType(false);
            if (!internalDescriptionRef.current.value || contractTypeRef.current.value === '')
                setInternalDescription(false);
            if (!currencyRef.current.value || contractTypeRef.current.value === '')
                setCurrency(false);
            if (!validFromRef.current.value || contractTypeRef.current.value === '')
                setValidFrom(false);
            if (!contractSubtypeRef.current.value || contractTypeRef.current.value === '')
                setContractSubtype(false);
            if (!validToRef.current.value || contractTypeRef.current.value === '')
                setValidTo(false);
            //new fields
            if (!contractPurposeRef.current.value || contractPurposeRef.current.value === '')
                setContractPurpose(false);
            if (!sourceDataTypeRef.current.value || sourceDataTypeRef.current.value === '')
                setSourceDataType(false);
            if (!postingSchemaRef.current.value || postingSchemaRef.current.value === '')
                setPostingSchema(false);
            if (!postingApprovalRef.current.value || postingApprovalRef.current.value === '')
                setPostingApproval(false);
            if (!legacyContractIdRef.current.value || legacyContractIdRef.current.value === '')
                setLegacyContractId(false);
            if (!goLiveDateRef.current.value || goLiveDateRef.current.value === '')
                setGoLiveDate(false);
        },
        getHeaderValues() {
            headerValues = {
                application_type: applicationRef.current.value,
                contract_type: contractTypeRef.current.value,
                contract_sub_type: contractSubtypeRef.current.value,
                valid_from: validFromRef.current.value,
                valid_to: validToRef.current.value,
                currency: currencyRef.current.value,
                contract_group: contractGroupRef.current.value,
                contract_sub_group: contractSubgroupRef.current.value,
                contract_purpose: contractPurposeRef.current.value,
                source_data_type: sourceDataTypeRef.current.value,
                posting_schema: postingSchemaRef.current.value,
                posting_approval: postingApprovalRef.current.value,
                legacy_contract_id: legacyContractIdRef.current.value,
                go_live_date: goLiveDateRef.current.value,
                internal_description: internalDescriptionRef.current.value
            };
            return headerValues;
        },
        getHeaderValuesRequired() {
            headerValuesRequired = {
                applicationType: applicationRef.current.value,
                contractType: contractTypeRef.current.value,
                internalDescription: internalDescriptionRef.current.value,
                currency: currencyRef.current.value,
                validFrom: validFromRef.current.value,
                contractSubtype: contractSubtypeRef.current.value,
                validTo: validToRef.current.value
            };
            return headerValuesRequired;
        }
    }));

    function handleContractType(newValue) {
        setContractType(newValue);
        props.onChange('contract_type', newValue);
    }
    function handleContractGroup(newValue) {
        setContractGroup(newValue);
        props.onChange('contract_group', newValue);
    }
    function handleInternalDescription(newValue) {
        setInternalDescription(newValue);
        props.onChange('internal_description', newValue);
    }
    function handleContractObjective(newValue) {
        setContractObjective(newValue);
        props.onChange('contract_objective', newValue);
    }
    function handleConractStrategy(newValue) {
        setContractStrategy(newValue);
        props.onChange('contract_strategy', newValue);
    }
    function handleCurrency(newValue) {
        setCurrency(newValue);
        props.onChange('currency', newValue);
    }
    function handleValidFrom(newValue) {
        setValidFrom(newValue);
        props.onChange('valid_from', newValue);
    }
    function handleContractSubgroup(newValue) {
        setContractSubgroup(newValue);
        props.onChange('contract_sub_group', newValue);
    }
    function handleExternalDescription(newValue) {
        setExternalDescription(newValue);
        props.onChange('external_description', newValue);
    }
    function handleMaximumAmountLevel(e) {
        setMaximumAmountLevel(e.target.value);
        setMaximumAmountPeriod('')
        props.onChange('maximum_amount_level', e.target.value);
    }
    function handleIncentiveAmount(newValue) {
        setIncentiveAmount(newValue);
        if (newValue == '') {
            props.onChange('maximum_incentive_amount', 0);
        } else {
            props.onChange('maximum_incentive_amount', parseFloat(newValue));
        }
    }

    function handleValidTo(newValue) {
        setValidTo(newValue);
        props.onChange('valid_to', newValue);
    }
    //new fields
    function handleContractPurpose(newValue) {
        setContractPurpose(newValue);
        props.onChange('contract_purpose', newValue);
    }
    function handleSourceDataType(newValue) {
        setSourceDataType(newValue);
        props.onChange('source_data_type', newValue);
    }
    function handlePostingSchema(e) {
        setPostingSchema(e.target.value);
        props.onChange('posting_schema', e.target.value);
    }

    function handleLegacyContractId(newValue) {
        setLegacyContractId(newValue);
        props.onChange('legacy_contract_id', newValue);
    }
    function handleGoLiveDate(newValue) {
        setGoLiveDate(newValue);
        props.onChange('go_live_date', newValue);
    }
    //flex fields
    function handleFlexFields1(newValue) {
        setFlexFields1(newValue);
        props.onChange('flex_fields_1', newValue);
    }
    function handleFlexFields2(newValue) {
        setFlexFields2(newValue);
        props.onChange('flex_fields_2', newValue);
    }
    function handleFlexFields3(newValue) {
        setFlexFields3(newValue);
        props.onChange('flex_fields_3', newValue);
    }

    function handleTarget(newValue) {
        setTarget(newValue.target.value);
        props.onChange('commitment_achievement', newValue.target.value);
    }
    function handleTierPeriod(newValue) {
        props.onChange('tier_period', newValue);
        setTierPeriod(newValue);
    }
    function handleMaximumAmountPeriod(value) {
        setMaximumAmountPeriod(value)
        props.onChange('maximum_amount_period', value);
    }
    function handleContractDependency(value) {
        setContractDependency(value)
        props.onChange('contract_dependency', value);
    }
    function handleCalcRuleModifier(value) {
        setCalcRuleModifier(value)
        props.onChange('calculation_rule_modifier', value);
    }
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse(props.queryListData.records);
        }
    }, [props.queryListData]);


    const [apiResponse, setApiResponse] = React.useState([]);
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse(props.queryListData.records);
        }
    }, [props.queryListData]);

    useEffect(() => {
        if (props.dropdownTierData && props.dropdownTierData.records && props.dropdownTierData.records.length > 0) {
            if (props.dropdownTierData.records[0] && props.dropdownTierData.records[0].field_id === 'tier_evaluation_level') {
                if (props.dropdownTierData.records[0].drop_down_value_keys)
                    setTierEvaluationLevelArray(props.dropdownTierData.records[0].drop_down_value_keys);
                if (props.dropdownTierData.records[0].label_names)
                    setTierEvaluationLevelLabel(props.dropdownTierData.records[0].label_names);
            }

        }
    }, [props.dropdownTierData]);

    const handleTierType = (e) => {
        props.onChange('tier_type', e.target.value);
        setTierType(e.target.value)
        if (calculationMethod === 'Tiered Growth' && e.target.value === 'Simple') {
            setContractSubtype('Tiered Growth - Simple')
        }
        if (calculationMethod === 'Tiered Growth' && e.target.value === 'Graduated') {
            setContractSubtype('Tiered Growth - Graduated')
        }
        if (calculationMethod === 'Tiered Volume' && e.target.value === 'Simple') {
            setContractSubtype('Tiered Growth - Simple')
        }
        if (calculationMethod === 'Tiered Volume' && e.target.value === 'Graduated') {
            setContractSubtype('Tiered Growth - Graduated')
        }
    }
    const handleCalculationMethod = (e) => {
        setCalculationMethod(e.target.value);
        if (e.target.value === 'Price Difference') {
            setEvaluateSalesBundle('');
            setTarget('');
            setMaximumAmountLevel('');
            setExclusiveFormula('');
            setExternalDescription('');
            setFlexFields1('');
            setFlexFields2('');
            setFlexFields3('');
            setLegacyContractId('');
            setGoLiveDate('');
        }
    }
    const handleIncentiveRateType = (e) => {
        props.onChange('incentive_rate_type', e.target.value);
        setIncentiveRateType(e.target.value)
        if (calculationMethod === 'Flat Rate' && e.target.value === 'Contract') {
            setContractSubtype('Flat % - One Rate per Contract')
        }
        if (calculationMethod === 'Flat Rate' && e.target.value === 'Attribute') {
            setContractSubtype('Flat % - Multiple Rates per Contract')
        }
        if (calculationMethod === 'Fixed Amount' && e.target.value === 'Contract') {
            setContractSubtype('Flat $ - One lumpsum Amount per Contract')
        }
        if (calculationMethod === 'Fixed Amount' && e.target.value === 'Attribute') {
            setContractSubtype('Flat $ - One lumpsum Amount per Contract')
        }
        if (calculationMethod === 'Price Difference' && e.target.value === 'Attribute') {
            setContractSubtype('Price Difference')
        }
        if (calculationMethod === 'Tiered Growth' && (e.target.value === 'Contract' || e.target.value === 'Attribute')) {
            setContractSubtype('Price Difference')
        }
    }


    const handleExclusiveFormula = (e) => {
        setExclusiveFormula(e.target.value);
        props.onChange('formula_exclusive', e.target.value);
    }

    const handleEvaluateSalesBundle = (e) => {
        setEvaluateSalesBundle(e.target.value);
        props.onChange('evaluate_sales_bundle', e.target.value);
    }

    const handleQuotaLevel = (data) => {
        if (data && data.length > 0) {
            setQuotaLevel(data)
            props.onChange('quota_level', data);
        }
        else {
            setQuotaLevel(quotaLevel)
            props.onChange('quota_level', quotaLevel);
        }

    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === tierEvaluationLevelArray.length ? [] : tierEvaluationLevelArray);
            setAllSelect(selected.length === tierEvaluationLevelArray.length ? false : true)

        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === tierEvaluationLevelArray.length ? true : false)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setTierEvaluationLevel(selected)
        props.onChange('tier_evaluation_level', selected)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(tierEvaluationLevel)
        setAllSelect(tierEvaluationLevelArray && tierEvaluationLevelArray.length > 0 && tierEvaluationLevel.length === tierEvaluationLevelArray.length)

    }
    return (
        <div className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-1.5rem' }}
                >
                    <CardHeader
                        title="PRIMARY DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <LabelText classes={{ root: classes.fontSetting }}
                                    heading={props.fieldData && props.fieldData['application_type'] && props.fieldData['application_type']['current'] ?
                                        props.fieldData['application_type']['current'] : 'Application Type'}
                                    data={sessionStorage.getItem('application')} twoline='true' border />
                            </Grid>

                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <LabelText classes={{ root: classes.fontSetting }} heading={'Contract Number'} data={generalData && generalData.contract_number && generalData.contract_number ? generalData.contract_number : ''} twoline='true' border required />
                            </Grid>

                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <DropdownArray classes={{ root: classes.fontSetting }}
                                    heading={props.fieldData && props.fieldData['contract_type'] && props.fieldData['contract_type']['current'] ? props.fieldData['contract_type']['current'] : 'Contract Type'}
                                    placeholder={' '} twoline='true' {...props} onChange={handleContractType}
                                    data={props.fieldData && props.fieldData['contract_type'] && props.fieldData['contract_type']['drop_down_values']}
                                    error={typeof contractType === 'boolean' && !contractType ? true : false} ref={contractTypeRef} prevalue={generalData ? generalData.contract_type : ''} disabled />
                            </Grid>

                            {props.fieldData && props.fieldData['internal_description'] && //internalDescriptionArray.contract_display &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} mandatory
                                        heading={props.fieldData['internal_description']['current'] ? props.fieldData && props.fieldData['internal_description']['current'] : 'Internal Description'}
                                        placeholder=' ' twoline='true' {...props} onChange={handleInternalDescription} error={typeof internalDescription === 'boolean' && !internalDescription ? true : false} ref={internalDescriptionRef} prevalue={generalData ? generalData.internal_description : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_purpose'] &&//contractPurposeArray.contract_display &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }} required
                                        heading={props.fieldData['contract_purpose']['current'] ? props.fieldData['contract_purpose']['current'] : 'Contract Purpose'}
                                        placeholder={' '} twoline='true' onChange={handleContractPurpose} labelFormat={'desc'}
                                        data={props.fieldData['contract_purpose']['drop_down_reference_value']} ref={contractPurposeRef} prevalue={generalData ? generalData.contract_purpose : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['currency'] &&//currencyArray.contract_display &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['currency']['current'] ? props.fieldData['currency']['current'] : 'Currency'}
                                        placeholder={' '} twoline='true' {...props} data={props.fieldData['currency']['drop_down_reference_value']}
                                        onChange={handleCurrency} error={typeof currency === 'boolean' && !currency ? true : false} ref={currencyRef}
                                        prevalue={generalData ? generalData.currency : ''} labelFormat={'desc'} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['valid_from'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['valid_from']['current'] ? props.fieldData['valid_from']['current'] : 'Valid From'}
                                        twoline='true'{...props} onChange={handleValidFrom} error={typeof validFrom === 'boolean' && !validFrom ? true : false} ref={validFromRef}
                                        // prevalue={generalData && generalData.valid_from != '0001-01-01T00:00:00Z' ? Moment(generalData.valid_from) : ''}
                                        prevalue={validFrom} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['valid_to'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading={props.fieldData['valid_to']['current'] ? props.fieldData['valid_to']['current'] : 'Valid To'}
                                        twoline='true' {...props} onChange={handleValidTo} error={typeof validTo === 'boolean' && !validTo ? true : false} minimum={validFrom} ref={validToRef}
                                        // prevalue={generalData && generalData.valid_from != '0001-01-01T00:00:00Z' ? Moment(generalData.valid_to) : ''}
                                        prevalue={validTo} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['posting_schema'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {/* <div style={{
                                        padding: '20px 0px 5px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}> */}
                                    <FormLabel classes={{ root: classes.fontSetting }} required>
                                        {props.fieldData['posting_schema']['current'] ? props.fieldData['posting_schema']['current'] : 'Posting Schema'}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={postingSchema}
                                        onChange={handlePostingSchema}
                                        displayEmpty
                                        disabled={generalData && generalData.accrual_amount && (generalData.accrual_amount || generalData.payment_amount
                                        ) || sessionStorage.getItem('application') == 'Pricing' ? true : false}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData['posting_schema']['drop_down_reference_value'] && props.fieldData['posting_schema']['drop_down_reference_value'] &&
                                            props.fieldData['posting_schema']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} >
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                    {/* </div> */}

                                </Grid>
                            }


                            {props.fieldData && props.fieldData['contract_group'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['contract_group']['current']} placeholder={' '}
                                        data={props.fieldData['contract_group']['drop_down_values'] ? props.fieldData['contract_group']['drop_down_values'] : []}
                                        twoline='true' onChange={handleContractGroup} ref={contractGroupRef}
                                        prevalue={generalData ? generalData.contract_group : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_sub_group'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['contract_sub_group']['current']} placeholder={' '}
                                        twoline='true' onChange={handleContractSubgroup}
                                        data={props.fieldData['contract_sub_group']['drop_down_values'] ? props.fieldData['contract_sub_group']['drop_down_values'] : []}
                                        ref={contractSubgroupRef} prevalue={generalData ? generalData.contract_sub_group : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_objective'] &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['contract_objective']['drop_down_reference_value'] && props.fieldData['contract_objective']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                {props.fieldData['contract_objective']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={contractObjective}
                                                onChange={(e) => handleContractObjective(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value="" > </MenuItem>
                                                {props.fieldData['contract_objective']['drop_down_reference_value'] && props.fieldData['contract_objective']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['contract_objective']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['contract_objective']['current'] ? props.fieldData && props.fieldData['contract_objective']['current'] : 'Contract Objective'}
                                            placeholder=' ' twoline='true' {...props} onChange={handleContractObjective} prevalue={generalData ? generalData.contract_objective : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_strategy'] &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['contract_strategy']['drop_down_reference_value'] && props.fieldData['contract_strategy']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                {props.fieldData['contract_strategy']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={contractStrategy}
                                                onChange={(e) => handleConractStrategy(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value="" > </MenuItem>
                                                {props.fieldData['contract_strategy']['drop_down_reference_value'] && props.fieldData['contract_strategy']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['contract_strategy']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['contract_strategy']['current'] ? props.fieldData && props.fieldData['contract_strategy']['current'] : 'Contract Strategy'}
                                            placeholder=' ' twoline='true' {...props} onChange={handleConractStrategy} prevalue={generalData ? generalData.contract_strategy : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['workflow_name'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }} required
                                        heading={props.fieldData['workflow_name']['current'] ? props.fieldData['workflow_name']['current'] : 'Workflow Name'}
                                        placeholder={' '} twoline='true' disabled labelFormat={'desc'}
                                        data={props.fieldData['workflow_name']['drop_down_reference_value']} prevalue={generalData ? generalData.workflow_name : ''} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>

            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-1.5rem' }}
                >
                    <CardHeader
                        title="CALCULATION DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container}>
                        <Grid container
                        // style={{ marginBottom: 16 }}
                        >

                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <DropdownKeyValue classes={{ root: classes.fontSetting }} required
                                    heading={props.fieldData && props.fieldData['source_data_type'] && props.fieldData['source_data_type']['current'] ?
                                        props.fieldData['source_data_type']['current'] : 'Source Data Type'}
                                    placeholder={' '} twoline='true' onChange={handleSourceDataType} labelFormat={'desc'}
                                    data={props.fieldData && props.fieldData['source_data_type'] && props.fieldData['source_data_type']['drop_down_reference_value']}
                                    ref={sourceDataTypeRef} prevalue={generalData ? generalData.source_data_type : ''} disabled
                                />
                            </Grid>
                            {props.fieldData && props.fieldData['calculation_method'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            {props.fieldData['calculation_method']['current']}
                                        </FormLabel>
                                        <Select
                                            disabled={true}
                                            disableUnderline
                                            value={calculationMethod}
                                            onChange={handleCalculationMethod}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ color: theme.palette.text.grey }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            {props.fieldData['calculation_method']['drop_down_reference_value'] && props.fieldData['calculation_method']['drop_down_reference_value'].length > 0 &&
                                                props.fieldData['calculation_method']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }} >
                                                                {item.desc}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{
                                    padding: '20px 0px 15px 0px',
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required>
                                        {props.fieldData && props.fieldData['incentive_rate_type'] &&
                                            props.fieldData['incentive_rate_type']['current'] ?
                                            props.fieldData['incentive_rate_type']['current'] : 'Incentive Rate Type'
                                        }
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        disabled={true}
                                        value={incentiveRateType}
                                        onChange={handleIncentiveRateType}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ color: theme.palette.text.grey }}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData && props.fieldData['incentive_rate_type'] && props.fieldData['incentive_rate_type']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['incentive_rate_type']['drop_down_reference_value']
                                                .filter(item => !((calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference' || calculationMethod === 'Multi Axis' ||
                                                    calculationMethod === 'Tiered Growth' || calculationMethod === 'Price Point' || calculationMethod === 'External Calculation')
                                                    && item.key === 'Attribute') && !(calculationMethod != 'Multi Axis' && item.key === "Conditional Attribute"))
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}
                                                            disabled={
                                                                (calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference')
                                                                    && item.key === 'Attribute'
                                                                    ? true :
                                                                    false

                                                            }
                                                        >
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </div>
                            </Grid>

                            {(calculationMethod === 'Tiered Growth' || calculationMethod === 'Tiered Volume' || calculationMethod === 'Multi Axis') &&
                                props.fieldData && props.fieldData['tier_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            {props.fieldData['tier_type']['current'] ? props.fieldData['tier_type']['current'] : 'Tier Type'}
                                        </FormLabel>
                                        <Select
                                            disabled={!calculationMethod ? true : false}
                                            disableUnderline
                                            value={tierType}
                                            onChange={handleTierType}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            {props.fieldData && props.fieldData['tier_type'] && props.fieldData['tier_type']['drop_down_reference_value'] && props.fieldData['tier_type']['drop_down_reference_value'].length > 0 &&
                                                props.fieldData['tier_type']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {(calculationMethod === 'Tiered Volume' || calculationMethod === 'Tiered Growth' || calculationMethod === 'Multi Axis')
                                && props.fieldData && props.fieldData['tier_period'] ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['tier_period']['current'] ? props.fieldData['tier_period']['current'] : 'Tier Period'}
                                        twoline='true' onChange={handleTierPeriod} data={props.fieldData['tier_period']['drop_down_reference_value']} labelFormat={'desc'}
                                        prevalue={generalData && generalData.tier_period ? generalData.tier_period : ''}
                                    />
                                </Grid>
                                :
                                ''
                            }


                            {(calculationMethod === 'Tiered Growth' || calculationMethod === 'Tiered Volume' || calculationMethod === 'Multi Axis') &&
                                props.fieldData && props.fieldData['tier_evaluation_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            {props.fieldData['tier_evaluation_level']['current'] ?
                                                props.fieldData['tier_evaluation_level']['current'] : 'Tier Evaluation Level'}
                                        </FormLabel>

                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            multiple
                                            value={tierEvaluationLevel ? tierEvaluationLevel : []}
                                            style={{ maxHeight: "50px", width: '100%', textTransform: 'capitalize' }}
                                            input={<OutlinedInput sx={{ padding: '4px' }} />}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            onOpen={() => handleOpen()}
                                            onClose={() => setSelectOpen(false)}
                                            open={selectOpen}
                                            renderValue={(appType) => {
                                                var temp = []
                                                appType && appType.map((item, i) => {
                                                    if (tierEvaluationLevelLabel && tierEvaluationLevelLabel[item]) {
                                                        temp.push(tierEvaluationLevelLabel[item])
                                                    }
                                                    else {
                                                        temp.push(item.toString().replace(/_/g, ' '))
                                                    }
                                                })
                                                return temp.join(",")
                                            }}
                                            className={classes.multiSelect}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={allSelect} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {tierEvaluationLevelLabel && Object.entries(tierEvaluationLevelLabel).length > 0 &&
                                                Object.entries(tierEvaluationLevelLabel)
                                                    .map(([k, v]) => {
                                                        return (

                                                            <MenuItem onClick={(event) => handleSelectValues(event, k)} value={k} key={k} style={{ textTransform: 'capitalize' }}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={selected.indexOf(k) > -1} />
                                                                <ListItemText primary={v} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                            <MenuItem value=' ' disabled></MenuItem>
                                            <div className={classes.dropdownAction}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {calculationMethod === 'Quota Achievement' && props.fieldData && props.fieldData['quota_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <MultiSelectDropdown heading={props.fieldData['quota_level']['current']}
                                        listArray={props.fieldData['quota_level']['drop_down_reference_value'] &&
                                            props.fieldData['quota_level']['drop_down_reference_value'].map(item => item.key)}
                                        data={quotaLevel} id='quota_level' onChange={handleQuotaLevel} capitalize />
                                </Grid>
                            }

                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                props.fieldData && props.fieldData['commitment_achievement'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            {props.fieldData['commitment_achievement']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={target}
                                            onChange={handleTarget}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value={'No'} key={'No'} >No</MenuItem>
                                            <MenuItem value={'Yes - All'} key={'Yes - All'}>Yes - All</MenuItem>
                                            <MenuItem value={'Yes - Any'} key={'Yes - Any'}>Yes - Any</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                props.fieldData && props.fieldData['contract_dependency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            {props.fieldData['contract_dependency']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={contractDependency}
                                            onChange={(e) => handleContractDependency(e.target.value)}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                        >
                                            {props.fieldData['contract_dependency']['drop_down_reference_value'] && props.fieldData['contract_dependency']['drop_down_reference_value'].length > 0 &&
                                                props.fieldData['contract_dependency']['drop_down_reference_value']
                                                    .map(item => (
                                                        <MenuItem value={item.key} key={item.key} >
                                                            {item.key}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['calculation_rule_modifier'] && calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '0.2rem' }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData && props.fieldData['calculation_rule_modifier'] && props.fieldData['calculation_rule_modifier']['current'] ? props.fieldData['calculation_rule_modifier']['current'] : 'Calculation Rule Modifier'}
                                        placeholder={' '} twoline='true' {...props} onChange={(value) => handleCalcRuleModifier(value)}
                                        data={['Yes', 'No']} required={false} prevalue={generalData ? generalData.calculation_rule_modifier : ''}
                                    />
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && props.fieldData && props.fieldData['formula_exclusive'] &&
                                calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            {props.fieldData['formula_exclusive']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={exclusiveFormula}
                                            onChange={handleExclusiveFormula}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            <MenuItem value={false} key={false} >No</MenuItem>
                                            <MenuItem value={true} key={true}>Yes</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Point' &&
                                props.fieldData && props.fieldData['evaluate_sales_bundle'] && calculationMethod != 'Price Difference' &&
                                calculationMethod != 'External Calculation' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >

                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            {props.fieldData['evaluate_sales_bundle']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={evaluateSalesBundle}
                                            onChange={handleEvaluateSalesBundle}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            <MenuItem value="" > </MenuItem>
                                            {props.fieldData['evaluate_sales_bundle']['drop_down_reference_value'] && props.fieldData['evaluate_sales_bundle']['drop_down_reference_value'].length > 0
                                                && props.fieldData['evaluate_sales_bundle']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Fixed Amount' && calculationMethod != 'Price Difference' &&
                                props.fieldData['maximum_amount_level'] && calculationMethod != 'External Calculation' &&

                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            {props.fieldData['maximum_amount_level']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={maximumAmountLevel}
                                            onChange={handleMaximumAmountLevel}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            {/* <MenuItem value="">
                                                Contract
                                            </MenuItem> */}
                                            {props.fieldData['maximum_amount_level']['drop_down_reference_value'] &&
                                                props.fieldData['maximum_amount_level']['drop_down_reference_value'].length > 0
                                                && props.fieldData['maximum_amount_level']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.desc}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Fixed Amount' && calculationMethod != 'Price Difference' &&
                                calculationMethod != 'External Calculation' && props.fieldData && props.fieldData['maximum_amount_period'] ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '20px 0px 15px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            {props.fieldData['maximum_amount_period']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={maximumAmountPeriod}
                                            onChange={(e) => handleMaximumAmountPeriod(e.target.value)}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                        >
                                            {props.fieldData['maximum_amount_period']['drop_down_reference_value'] && props.fieldData['maximum_amount_period']['drop_down_reference_value'].length > 0 &&
                                                props.fieldData['maximum_amount_period']['drop_down_reference_value']
                                                    .filter(item => (!(maximumAmountLevel != 'customer_number' && item == 'Payment Period - Different Amount') && !((calculationMethod !== 'Tiered Volume' && calculationMethod !== 'Multi Axis' && calculationMethod !== 'Tiered Growth')
                                                        && item == 'Payment Period - Tier')))
                                                    .map(item => (
                                                        <MenuItem value={item.key} key={item.key} >
                                                            {item.key}
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                    </div>
                                </Grid>
                                :
                                null
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'Fixed Amount' &&
                                props.fieldData && props.fieldData['maximum_incentive_amount'] && calculationMethod != 'External Calculation' && maximumAmountPeriod !== 'Payment Period - Tier' && maximumAmountPeriod !== 'Payment Period - Different Amount' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['maximum_incentive_amount']['current']} twoline='true'
                                        onChange={handleIncentiveAmount} prevalue={generalData && generalData.maximum_incentive_amount ? generalData.maximum_incentive_amount : ''} style={{ marginTop: 5, height: '19px !important' }} />
                                </Grid>
                            }

                        </Grid>
                    </div>
                </form>
            </Card>
            <Card className={classes.bodyContainer} >
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-1.5rem' }}
                >
                    <CardHeader
                        title="ADDITIONAL DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container}>
                        <Grid container
                        //style={{ marginBottom: 16 }}
                        >
                            {props.fieldData && props.fieldData['external_description'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['external_description']['current']} placeholder=' '
                                        twoline='true' onChange={handleExternalDescription} ref={externalDescriptionRef}
                                        prevalue={generalData ? generalData.external_description : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_1'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_1']['drop_down_reference_value'] && props.fieldData['flex_fields_1']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                {props.fieldData['flex_fields_1']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields1}
                                                onChange={(e) => handleFlexFields1(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value="" > </MenuItem>
                                                {props.fieldData['flex_fields_1']['drop_down_reference_value'] && props.fieldData['flex_fields_1']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_1']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_1']['current']} twoline='true'
                                            onChange={handleFlexFields1} prevalue={generalData && generalData.flex_fields_1 ? generalData.flex_fields_1 : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_2'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_2']['drop_down_reference_value'] && props.fieldData['flex_fields_2']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                {props.fieldData['flex_fields_2']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields2}
                                                onChange={(e) => handleFlexFields2(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value="" > </MenuItem>
                                                {props.fieldData['flex_fields_2']['drop_down_reference_value'] && props.fieldData['flex_fields_2']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_2']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_2']['current']} twoline='true'
                                            onChange={handleFlexFields2} prevalue={generalData && generalData.flex_fields_2 ? generalData.flex_fields_2 : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_3'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_3']['drop_down_reference_value'] && props.fieldData['flex_fields_3']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                {props.fieldData['flex_fields_3']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields3}
                                                onChange={(e) => handleFlexFields3(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value="" > </MenuItem>
                                                {props.fieldData['flex_fields_3']['drop_down_reference_value'] && props.fieldData['flex_fields_3']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_3']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_3']['current']} twoline='true'
                                            onChange={handleFlexFields3} prevalue={generalData && generalData.flex_fields_3 ? generalData.flex_fields_3 : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['legacy_contract_id'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['legacy_contract_id']['current']} placeholder={' '} twoline='true'
                                        onChange={handleLegacyContractId} ref={legacyContractIdRef} prevalue={generalData ? generalData.legacy_contract_id : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['go_live_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['go_live_date']['current']} twoline='true'
                                        onChange={handleGoLiveDate} ref={goLiveDateRef}
                                        // prevalue={generalData && generalData.go_live_date != '0001-01-01T00:00:00Z' ? Moment(generalData.go_live_date) : ''}
                                        prevalue={goLiveDate} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>

            <AdminDataContainer />
        </div >
    );

});

const mapStateToProps = state => ({
    contractnumber: state.addContractData.contractNumber,
    dropdownTierData: state.addMultipleConfigurationData.tierEvaluationData,
});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefaultTier: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'tier_evaluation_level')),

    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HeaderFieldsContiner);