import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField, FormControl, ToggleButton, ToggleButtonGroup,
    Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Accordion,
    AccordionSummary,
    AccordionDetails, Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ExpandMore, ExpandLess, AddCircleOutline } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getPromoTypes, getBudgetingConfig, getMarketFilters, getModels } from '../../../../../../redux/actions';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NestedDropdown, PromoMultiSelect } from '../../../../Components'
import { index } from 'd3';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        paddingBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 15,
    },
    rightContainer: {
        // backgroundColor: '#F8F8F8'
    },
    leftContainer: {
        marginTop: '12px',
        padding: '0px 45px',
        backgroundColor: '#F8F8F8'

    },
    defineContainer: {
        marginTop: '12px',
        // padding: '0px 45px',
        backgroundColor: '#F8F8F8',
        maxHeight: 634,
        height: 634,
        overflowY: 'auto',
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,

    },
    chipContainer: {
        // paddingLeft: 30,
        display: 'flex',
        flexDirection: 'row',
        // paddingBottom: 10,
        backgroundColor: '#F8F8F8',
        padding: '20px 0px 20px 30px !important'
    },
    subContainer: {
        padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },

    searchBox: {
        backgroundColor: theme.palette.white
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        marginTop: 10,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        //width: '25%',
        //marginTop: -5,
        display: 'flex',
        flexDirection: 'column'
    },
    rightHeader: {
        width: '100%'
        // display: 'flex',
        // justifyContent: 'space-between'
    },
    chip: {
        backgroundColor: 'white',
        border: '1px solid #E2E2E2'
    }
}));
const DefineMarket = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [productLine, setProductLine] = React.useState([]);
    const [country, setCountry] = React.useState('USA');
    const [enableDesc, setEnableDesc] = React.useState(false);
    const [externalDesc, setExternalDesc] = React.useState('');
    const [stickerDesc, setStickerDesc] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [status, setStatus] = React.useState('pending');
    const [definedModels, setDefinedModels] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [modelYear, setModelYear] = React.useState('');
    const [checkedModel, setCheckedModel] = React.useState([]);
    const [displaySelected, setDisplaySelected] = React.useState(true);
    const [toggle, setToggle] = React.useState(true);
    const [selectedModels, setSelectedModels] = React.useState([]);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [checkedLoc, setCheckedLoc] = React.useState([]);
    const [region, setRegion] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [itemOn, setItemOn] = React.useState('');
    const [regionList, setRegionList] = React.useState([]);
    const [stickerModels, setStickerModels] = React.useState([]);
    const [defaultExpanded, setDefaultExpanded] = React.useState([]);
    const [loc, setLoc] = React.useState({});
    useEffect(() => {
        setStickerDesc(props.stickerName)
    }, [props.stickerName]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData)
        }
    }, [props.configData]);
    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {
            setModels(props.modelData)
            setDefinedModels(props.modelData)
        }
    }, [props.modelData]);
    useEffect(() => {
        if (filterData && filterData.length > 0 && definedModels) {
            setModels(definedModels.filter((item, i) => filterData.includes(item.category)))
        }
        else {
            setModels(definedModels)
        }
    }, [filterData]);
    useEffect(() => {
        if (props.locationData && props.locationData.length > 0) {
            var region = props.locationData.map((item, index) => item.category)
            setRegionList(region)
            setLocations(props.locationData)
        }
    }, [props.locationData]);
    useEffect(() => {
        if (props.defineMarket) {
            setSelectedLocItems(props.defineMarket['location'])
            setSelectedModelItems(props.defineMarket['model'])
        }
        else {
            setSelectedLocItems([])
            setSelectedModelItems([])
        }
    }, [props.defineMarket])
    useEffect(() => {
        console.log(props.defineMarketChild)
        if (props.defineMarketChild) {
            setSelectedLoc(props.defineMarketChild['location'])
            setStickerModels(props.defineMarketChild['model'])
            setDefaultExpanded(props.defineMarketChild['model'])
            setFilterData(props.defineMarketChild['model_year'])
            setModelYear(props.defineMarketChild['model_year'] ? props.defineMarketChild['model_year'][0] : '')
        }
        else {
            setSelectedLoc([])
            setStickerModels([])
            setFilterData([])
            setModelYear([])
        }
    }, [props.defineMarketChild])
    function handleModelYear(event) {
        setModelYear(event.target.value)
    }
    function handleRegion(event) {
        setRegion(event.target.value)
    }



    function handleCheckedData(selected, child, type) {
        setAnchorEl(false)
        if (type !== 'cancel') {
            // setSelectedModelItems(selected)
            setStickerModels(child)
            props.handleDefineMarket(selected, child, 'model')
            setAnchorEl(false)
            setDefaultExpanded(child)
            // if (selected && selectedLocItems)
            //     manipulateData(selected, selectedLocItems)

        }
    };
    function handleCheckedLoc(selected, child, type) {
        setAnchorloc(false)
        if (type !== 'cancel') {
            setSelectedLoc(child)
            setSelectedLocItems(selected)
            props.handleDefineMarket(selected, child, 'location')
            setAnchorEl(false)
            locManipulate(selected)
            // if (selectedModelItems && selected)
            //     manipulateData(selectedModelItems, selected)
        }
    };
    function locManipulate(data) {
        var temp = {}
        data && data.map(item => {
            temp = { ...temp, [item.category]: item }
        })
        setLoc(data)
    }
    function handleToggle(event) {
        setToggle(event.target.checked);

    };
    function handleDisplay(event) {
        setDisplaySelected(event.target.checked ? false : true);
    };
    function handleClose() {
        setOpen(false)
        setItemOn('')
    }
    function handleSubmit() {
        var loc = [];
        var locItems = [];
        var model = [];
        var modelItems = [];
        setOpen(false)
        if (displaySelected) {
            if (toggle) {
                const index = selectedModelItems.findIndex(item => JSON.stringify(item) === JSON.stringify(itemOn.relationship));
                // const index = selectedModelItems.findIndex(item => JSON.stringify(item.filter(([k, v]) => k != region)) === JSON.stringify(itemOn.relationship));
                modelItems = selectedModelItems.filter((item, i) => index != i)
                model = stickerModels.filter((item, i) => item != Object.values(itemOn.relationship).join("_"))
                setSelectedModelItems(modelItems)
                setStickerModels(model)
                //handleCheckedData(selectedModels, modelItems)
                props.handleDefineMarket(modelItems, model, 'model')
            }
            else {
                const index = selectedLocItems.findIndex(item => JSON.stringify(item) === JSON.stringify(itemOn.relationship));
                locItems = selectedLocItems.filter((item, i) => index != i)
                loc = selectedLoc.filter((item, i) => item != Object.values(itemOn.relationship).join("_"))
                setSelectedLocItems(locItems)
                setSelectedLoc(loc)
                props.handleDefineMarket(locItems, loc, 'location')
            }
        }
        else {
            if (toggle) {
                setSelectedModelItems([...selectedModelItems, itemOn.relationship])
                setStickerModels([...stickerModels, Object.values(itemOn.relationship).join("_")])
                props.handleDefineMarket([...selectedModelItems, itemOn.relationship], [...stickerModels, Object.values(itemOn.relationship).join("_")], 'model')
            }
            else {
                setSelectedLocItems([...selectedLocItems, itemOn.relationship])
                var loc = [...selectedLoc, Object.values(itemOn.relationship).join("_")]
                setSelectedLoc(loc)
                props.handleDefineMarket([...selectedLocItems, itemOn.relationship], loc, 'location')
            }
        }
        setItemOn('')
    }
    function handleChipItem(item) {
        setOpen(true)
        setItemOn(item)
    }
    const handleFilters = (value, field, type) => {
        setFilterData(value)
        if (value.length > 0)
            setModelYear(value[0])
        props.onChange(value, 'material_group1')
    }
    const handleClick = (type) => {
        if (type == 'model') {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
        }
        else {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };
    const handleAccordionChange = (panel, type, relationship) => (event, isExpanded) => {
        if (isExpanded) {
            if (displaySelected) {
                setDefaultExpanded(defaultExpanded.filter(item => item != Object.values(relationship).join("_")))
            }
            else {
                setDefaultExpanded([...defaultExpanded, Object.values(relationship).join("_")])
            }
        }
        else {
            if (displaySelected) {
                setDefaultExpanded([...defaultExpanded, Object.values(relationship).join("_")])

            }
            else {
                setDefaultExpanded(defaultExpanded.filter(item => item != Object.values(relationship).join("_")))
            }
        }
        setMarketExpanded(isExpanded ? [...marketExpanded, panel + "-" + type] : marketExpanded.filter(item => item != panel + "-" + type));
    };

    const renderNestedItems = (items) => {
        return items && items.filter((item, index) =>
            toggle ? displaySelected ? stickerModels && stickerModels.length > 0 ? stickerModels.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : '' :
                !(stickerModels.includes(Object.values(item.relationship).join("_").toString()) && stickerModels.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())))
                : displaySelected ? selectedLoc && selectedLoc.length > 0 ? selectedLoc.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : '' :
                    !(selectedLoc.includes(Object.values(item.relationship).join("_").toString()))
        )
            .map((item, index) => (
                <>

                    {item.type == 'material_group1' || item.type == 'region' ?
                        renderNestedItems(item.children)
                        :
                        item.children && item.children.length > 0 ? (
                            <div key={index} style={{ flexGrow: 1, width: '100%' }} >
                                <Accordion
                                    expanded={displaySelected ? !(defaultExpanded.includes(Object.values(item.relationship).join("_"))) : defaultExpanded.includes(Object.values(item.relationship).join("_"))}//marketExpanded.includes(item.category + "-" + item.type)
                                    onChange={handleAccordionChange(item.category, item.type, item.relationship)}
                                    style={{ margin: 0, borderBottom: "0.8px solid #E2E2E2", zindex: 1 }}
                                    fullWidth
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore color={marketExpanded.includes(item.category + "-" + item.type) ? 'primary' : 'black'} />}
                                        className={classes.accordionMenu}
                                        style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10 }}
                                    >
                                        <Typography variant='h4'
                                            color={'grey'}
                                        >
                                            {item.category}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                        className={(item.type == 'material_group3') && classes.chipContainer}
                                    >
                                        {renderNestedItems(item.children)}
                                    </AccordionDetails>
                                </Accordion>
                            </div >
                        ) :
                            displaySelected ?
                                (<div style={{ paddingLeft: 30, marginRight: 5 }}>
                                    <Chip
                                        label={item.category}
                                        variant="outlined"
                                        className={classes.chip}
                                        sx={{ backgroundColor: 'white' }}
                                        //onClick={handleClick}
                                        onDelete={() => handleChipItem(item)}

                                    />
                                </div>
                                )
                                :
                                (toggle ? (selectedModelItems.indexOf(item.relationship) < 0) :
                                    (selectedLocItems.indexOf(item.relationship) < 0)) && (
                                    <div style={{ paddingLeft: 30, marginRight: 5 }}>
                                        <Chip
                                            label={item.category}
                                            variant="outlined"
                                            onDelete={() => handleChipItem(item)}
                                            deleteIcon={<AddCircleOutline />}
                                            sx={{ backgroundColor: 'white' }}
                                        //onClick={handleClick}

                                        />
                                    </div>
                                )
                    }
                </>
            ));
    };
    return (
        <div className={classes.root}>
            <Grid container item spacing={1}>
                <Grid
                    item xs={12} md={12}
                    classes={{ root: classes.leftContainer }}
                >
                    <div>
                        <Typography variant='h3' style={{ marginBottom: '24px' }}>SELECT YOUR MARKET</Typography>

                        <div className={classes.filterBox} >
                            <Typography variant='h4' color='grey' style={{ marginRight: 12 }}>Filter</Typography>
                            <div className={classes.filterItem}>
                                <Typography variant='h4' color='grey'>Model Year</Typography>
                                <PromoMultiSelect id='model-year' onChange={(value) => handleFilters(value, 'material_group1', 'material_master')}
                                    options={props.modelYears ? props.modelYears : []} prevalue={filterData} />
                            </div>
                            <div className={classes.filterContainer}>
                                <div className={classes.filterItem}>
                                    <Typography variant='h4' color='grey'>Model Selection</Typography>
                                    <IconButton onClick={() => handleClick('model')}>{anchorEl ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                </div>
                                {anchorEl &&
                                    <NestedDropdown head='Model Selection' id={'model'} data={models} expand={expanded} onSubmit={handleCheckedData}
                                        prevalue={stickerModels} selected={selectedModelItems} type='model_filter' />}
                            </div>
                            <div className={classes.filterContainer}>
                                <div className={classes.filterItem}>
                                    <Typography variant='h4' color='grey'>Location Selection</Typography>
                                    <IconButton onClick={() => handleClick('location')}>{anchorloc ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                </div>
                                {anchorloc &&
                                    <NestedDropdown head='Location Selection' id={'locations'} data={locations} expand={expanded} onSubmit={handleCheckedLoc}
                                        prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter' />
                                }
                            </div>

                        </div>
                    </div>
                </Grid >
                <Grid
                    item xs={12} md={12}
                    className={classes.rightContainer}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                    </div>
                    <div className={classes.rightHeader}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <FormControlLabel
                                    value={displaySelected}
                                    control={<Checkbox checked={displaySelected ? false : true} />}
                                    label={<Typography variant='h4' color='grey'>{toggle ? 'Display Unselected Models' : 'Display Unselected States'}</Typography>}
                                    labelPlacement="end"
                                    onChange={handleDisplay}
                                />
                            </div>
                            <div >
                                <Stack direction="row" spacing={1} alignItems="center" style={{ height: 30 }}>
                                    <Typography variant='h4' color='black'>STATES</Typography>
                                    <Switch sx={{ m: 1 }} checked={toggle} className={classes.toggleButton}
                                        onChange={handleToggle} />
                                    <Typography variant='h4' color='black'>MODELS</Typography>
                                </Stack>
                            </div>
                        </div>
                        <div>
                            {toggle ?
                                <ToggleButtonGroup
                                    color="primary"
                                    value={modelYear}
                                    exclusive
                                    onChange={handleModelYear}
                                    aria-label="Platform"
                                    style={{ position: 'relative', zindex: -1 }}
                                >
                                    {props.country != 'CA' ? filterData && filterData.map(item =>
                                        <ToggleButton value={item}>{item}</ToggleButton>

                                    )
                                        : (
                                            <ToggleButton value={'Canada'}>Canada</ToggleButton>
                                        )}
                                </ToggleButtonGroup>
                                :
                                <ToggleButtonGroup
                                    color="primary"
                                    value={region}
                                    exclusive
                                    onChange={handleRegion}
                                    aria-label="Platform"
                                    style={{ position: 'relative', zindex: -1 }}
                                >
                                    {regionList && regionList.map(item =>
                                        <ToggleButton value={item}>{item}</ToggleButton>
                                    )}
                                </ToggleButtonGroup>
                            }
                        </div>
                        <div className={classes.defineContainer} style={{ position: 'relative', zindex: -1, backgroundColor: '#F8F8F8', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', paddingTop: !toggle ? 10 : 0 }} className={!toggle && classes.chipContainer}>
                                {/* {displaySelected ?
                                    toggle ?
                                        renderNestedItems(checkedModel.filter((item, index) => item.category == modelYear))
                                        :
                                        renderNestedItems(checkedLoc.filter((item, index) => item.category == region))
                                    : }*/
                                    toggle ?
                                        stickerModels && stickerModels.length > 0 &&
                                        renderNestedItems(models.filter((item, index) => item.category == modelYear))
                                        :
                                        selectedLoc && selectedLoc.length > 0 &&
                                        renderNestedItems(locations && locations.filter((item, index) => item.category == region))
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                    {displaySelected ? 'REMOVE FROM SELECTION ?' : 'ADD TO SELECTION ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Chip
                            label={itemOn.category}
                            variant="outlined"
                            className={classes.chip}
                            sx={{ backgroundColor: 'white' }}

                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSubmit}>yes</Button>
                </DialogActions>
            </Dialog>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionTypes: () => dispatch(getPromoTypes()),
        getMarketFilters: (table, data) => dispatch(getMarketFilters(table, data)),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DefineMarket);