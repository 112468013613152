import React, { useEffect } from 'react';
import {
    Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { createPromotionScenario, getMaterialFilters, getModels, getPrefillScenarioList, getPromoStickers, runCostingSimulation } from '../../../../../redux/actions';
import GridMain from './GridMain';
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
        height: '138vh'
    },

}));


const Grid = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    var mode = location.data && location.data.page ? location.data.page : 'add';
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [promoModelYearArray, setPromoModelYearArray] = React.useState([]);
    const [previousScenarios, setPreviousScenarios] = React.useState([]);
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [promoStickerName, setPromoStickerName] = React.useState([])
    const [promoModelFilter, setPromoModelFilter] = React.useState({});
    const [defineMarket, setDefineMarket] = React.useState({});
    const [prefillId, setPrefillId] = React.useState('');
    const [prefillData, setPrefillData] = React.useState([])
    const [promoData, setPromoData] = React.useState([])
    const [country, setCountry] = React.useState([]);
    const [colorPicker, setColorPicker] = React.useState({});
    const [models, setModels] = React.useState([]);
    const [promoLoc, setPromoLoc] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);
    const [ID, setID] = React.useState(0)

    useEffect(() => {
        const data = {
            table_name: "prefill_scenario"
        }
        props.getScenarioList(data);
        props.getFilters();
    }, []);


    useEffect(() => {
        if (props.scenarioList)
            setPreviousScenarios(props.scenarioList)

    }, [props.scenarioList]);

    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {
            setModels(props.modelData)
        }
    }, [props.modelData]);

    function getSelectedID(id, contry, productLine) {
        // console.log(id, contry, productLine)
        id.forEach((item) => {
            props.getStickers(productLine, contry, item)
        });
    }

    useEffect(() => {
        if (props.stickersData && props.stickersData.ID && !(promoStickerData.some(obj => obj.ID === props.stickersData.ID))) {
            setPromoStickerData([...promoStickerData, props.stickersData]);
        }
    }, [props.stickersData]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setPromoModelYearArray(props.filterData['material_master'][0]['material_group1'])
            setPromoDivisionArray(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);

    useEffect(() => {
        if (props.scenario && prefillId) {
            setDefineMarket(props.scenario.promotion_grids)
            setPromoModelFilter(props.scenario.promotion_display)
            setPrefillData(props.scenario)
            setPromoData(props.scenario)
            setID(props.scenario.ID)
            setCountry(props.scenario.country)

            handleCheckedLoc(props.scenario.promo_location?.location)
            if (props.scenario.defined_stickers) {
                getRandomColor(props.scenario.defined_stickers)
                getSelectedID(props.scenario.defined_stickers, props.scenario.country, props.scenario.product_lines)
            }
        }
        else {
            setDefineMarket([])
            setPromoModelFilter([])
            setPrefillData([])
            setPromoData([])
        }
    }, [props.scenario])

    useEffect(() => {
        if (promoData && promoData.material_group1 && promoData.material_group1.length > 0 && promoData.product_lines) {
            var model = {
                table_name: 'material_master',
                filter: {
                    material_group1: promoData.material_group1,
                    material_group: promoData.product_lines
                }
            }
            props.getFilterData('material_master', model)
        }
    }, [promoData && promoData.material_group1])

    useEffect(() => {
        if (defineMarket && Object.entries(defineMarket).length > 0 && promoData.promotion_start_date && promoData.promotion_end_date
            && promoData.promo_location && promoData.material_group1 && promoData.country && promoData.promotion_currency && promoData.product_lines) {
            var data = {
                "promotion_start_date": promoData.promotion_start_date,
                "promotion_end_date": promoData.promotion_end_date,
                "defined_stickers": defineMarket ? Object.keys(defineMarket) && Object.keys(defineMarket).map(Number) : [],
                "promotion_grids": defineMarket,
                "promo_location": promoData.promo_location,
                "material_group1": promoData.material_group1,
                "country": promoData.country,
                "promotion_currency": promoData.promotion_currency,
                "product_lines": promoData.product_lines
            }
            props.runSimulation(data)
        }
    }, [defineMarket, refresh, promoData.promotion_start_date, promoData.promotion_end_date, promoData.material_group1])

    function getRandomColor(stickers) {
        const letters = '0123456789ABCDEF';
        var temp = { ...colorPicker }
        stickers && stickers.filter(item => !(colorPicker && colorPicker[item])).map(item => {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            temp = { ...temp, [item]: color }
        }
        )
        setColorPicker(temp)
    }

    function handleCheckedLoc(selected) {
        // setSelectedLocItems(selected)
        const array2 = [];
        selected && selected.forEach(obj => {
            const { country, region, state } = obj;
            const existingCategoryA = array2.find(item => item.category === region);
            if (existingCategoryA) {
                existingCategoryA.children.push({ category: state, type: 'state', relationship: { country: country, region: region, state: state }, children: [] });

            } else {
                array2.push({
                    category: region, type: 'region',
                    relationship: { country: country, region: region },
                    children: [{
                        category: state, type: 'state',
                        relationship: { country: country, region: region, state: state },
                        children: []
                    }]
                });
            }
        });
        setPromoLoc([...array2])
    };

    function handlePrefill(id) {
        setPrefillId(id);
        if (id)
            props.fetchScenario(id, 'prefillGrid')
    }

    var scenarioData = {
        ...promoData,
        "country": promoData.country,
        "defined_stickers": defineMarket ? Object.keys(defineMarket) && Object.keys(defineMarket).map(Number) : [],
        "selected_stickers": promoStickerData && promoStickerData.map(item => item.ID),
        "promotion_grids": defineMarket,
        "promotion_display": promoModelFilter
    }
    function handleSubmit() {
        // console.log("id" + ID)
        // if (mode == 'edit') {
        var data = {
            ID: ID,
            ...scenarioData
        }

        props.onUpdate(data, ID, 'edit')
        // }
        // else
        // props.onSubmit(scenarioData, null, 'create')
    }

    function handleGrid(type) {
        if (type == 'refresh')
            setRefresh(refresh + 1)
        else if (type == 'return')
            history.push('/#')
        else
            handleSubmit()
    };

    // console.log(promoStickerData)
    // console.log(models)
    return (
        <>
            <div className={classes.root}>
                <GridMain
                    promoProductLines={promoDivisionArray}
                    data={previousScenarios}
                    prefill={handlePrefill}
                    country={promoData.country}
                    definedData={defineMarket}
                    stickers={promoStickerData}
                    colorPicker={colorPicker}
                    locations={promoLoc}
                    modelYears={promoData.material_group1}
                    modelData={models}
                    gridFilter={promoModelFilter}
                    currency={promoData.promotion_currency}
                    handleGrid={handleGrid}
                // handleDefine={handleDefine}
                >
                </GridMain>
            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        runSimulation: (data) => dispatch(runCostingSimulation(data)),
        getScenarioList: (data) => dispatch(getPrefillScenarioList(data)),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter')),
        onSubmit: (data, id, type) => dispatch(createPromotionScenario(data, id, type)),
        onUpdate: (data, id, type) => dispatch(createPromotionScenario(data, id, 'edit')),
        fetchScenario: (id, page) => dispatch(createPromotionScenario(null, id, 'fetch_id', page)),
        getStickers: (productType, country, id) => dispatch(getPromoStickers(productType, country, id)),
    }
}

const mapStateToProps = state => {
    return {
        scenario: state.promotionData.scenarioData,
        modelData: state.promotionData.modelFilters,
        scenarioList: state.promotionData.prefillScenarioList,
        filterData: state.promotionData.promoFilters,
        stickersData: state.promotionData.promoStickers,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Grid);