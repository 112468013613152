import React, { useEffect, forwardRef, useRef, useState, Fragment } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, TextField, Chip
} from '@mui/material';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import {
    getEligibilityRulesTableData, editEligibilityRules, getDefaultValuesAttributeValue, getDefaultValuesAllData,
    uploadEligibilityRules, getCustomerMasterData, getMaterialMasterData, getSupplierMasterData, getEmployeeData, getAttributeListData, fetchDropdownConfig
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { Publish, CloudUpload } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Tooltip from "@mui/material/Tooltip";
import ReplayIcon from '@mui/icons-material/Replay';
import { AllOtherAttributeMasterComponent, ChipInput, MultiSelectDropdownKeyVal, SearchDropdown } from '../../../../components/Inputs';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';

momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 50
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    formDiv: {
        height: 41,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    uploadIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        height: 36,
        display: 'flex'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const EligibilityRules = forwardRef((props) => {
    const theme = useTheme();
    const [currentApplication, setCurrentApplication] = React.useState(sessionStorage.getItem('application'));
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            },
        },
    };


    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [attributeName, setAttributeName] = React.useState([]);
    const [option, setOption] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);
    const [eligibility, setEligibility] = React.useState('Eligible');
    const [attributeValue, setAttributeValue] = React.useState({});
    const [editMode, setEditMode] = React.useState(false);
    const [eligibilityStore, setEligibilityStore] = React.useState([]);
    const [attributeNameEnableHelper, setAttributeNameEnableHelper] = React.useState(false);
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeNameObject, setAttributeNameObject] = React.useState([]);
    const [attributeNameValue, setAttributeNameValue] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [sequenceNumber, setSequenceNumber] = React.useState(1);
    const [idValue, setIdValue] = React.useState('');
    const [eligibilityTypeTier, setEligibilityTypeTier] = React.useState('');
    const inputFile = useRef(null)
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [ruleID, setRuleID] = React.useState(0);
    const [maximumAmount, setMaximumAmount] = React.useState('');
    const handleDrop = (event) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.uploadEligibilityRules(data, props.calculationMethod, sessionStorage.getItem('application'), props.sourceDataType);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
    }
    function uploadER() {
        inputFile.current.click();
    }
    useEffect(() => {
        if (props.uploadEligibilityRulesData && props.uploadEligibilityRulesData.data) {
            var x = props.uploadEligibilityRulesData.data;
            x.forEach(function (v) { delete v.ID });
            setEligibilityStore([...eligibilityStore, ...props.uploadEligibilityRulesData.data]);
            props.onChange([...eligibilityStore, ...props.uploadEligibilityRulesData.data])
        }
    }, [props.uploadEligibilityRulesData])


    const optionData = [
        { key: 'EQUAL', value: 'EQUAL' },
        { key: 'NOT EQUAL', value: 'NOT EQUAL' }
    ];
    const eligibiltyTypeData = ['Tier', 'Rebate', 'Both']
    const columns = [
        {
            field: 'type',
            title: 'Eligibility Type',
            type: 'string',
        },

        {
            field: 'option',
            title: 'Option',
            type: 'string',
        },
        {
            field: 'start_date',
            title: 'Start Date',

        },
        {
            field: 'end_date',
            title: 'End Date',

        },
        {
            field: 'sequence',
            title: 'Condition ID',
            type: 'string',
        },
        {
            field: 'maximum_amount',
            title: 'Max Amount',
            type: 'string',
        },
        {
            field: 'attribute_key_values[0].attribute_name',
            title: 'Attribute Name',
            type: 'string',
        },
        {
            field: 'attribute_key_values[0].attribute_value',
            title: 'Attribute Value',
            type: 'string',
        },
        {
            field: "Actions",
            title: "Actions",
            type: "string",
            editable: "never",
            sorting: false,

        }
    ];
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const editEligibilityRule = (rule) => {
        const index = eligibilityStore.indexOf(rule);
        if (rule.ID) {
            setIdValue(rule.ID);
        } else {
            setIdValue('');
        }
        if (rule.rule_id) {
            setRuleID(rule.rule_id);
        } else {
            setRuleID(0);
        }
        if (rule && rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_name) {
            var name = rule.attribute_key_values[0].attribute_name
            setAttributeName(rule.attribute_key_values[0].attribute_name);
            if (name == 'customer_number' || name === 'ship_to_party' || name === 'bill_to_party'
                || name === 'sold_to_party' || name === 'payer')
                setAttributeNameValue('customer_number')
            else
                if (name == 'employee_number' || name == 'supplier_number' || name == 'material_number')
                    setAttributeNameValue(name)
        }
        if (rule && rule.attribute_key_values && rule.attribute_key_values.length > 0) {
            var temp = {}
            var attr = []
            rule.attribute_key_values.map((item, i) => {
                attr.push(item.attribute_name)
                temp = { ...temp, [item.attribute_name]: item }
            })
            setAttributeValue(temp);
            setAttributeName(attr)
        }
        setAttributeNameEnableHelper(true)
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setOption(rule.option);
        setSequenceNumber(rule.sequence);
        setEffectiveStartDate(rule.effective_start_date);
        setEffectiveEndDate(rule.effective_end_date);
        setMaximumAmount(rule.maximum_amount)
        setEligibility(rule.enabled === true ? 'Eligible' : 'Not Eligible');
        // setAttributeValue(rule.attribute_value);
        setEligibilityTypeTier(rule.type);
    }
    function handleAttributename(value) {
        setAttributeValue('');
        setAttributeName(value)
    }
    function handleOption(event) {
        setOption(event.target.value);
    }
    function handleEffectiveStartDate(event) {
        if (event && event.isValid())
            setEffectiveStartDate(event.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setEffectiveStartDate(event);
    }
    function handleEffectiveEndDate(newValue) {
        if (newValue && newValue.isValid())
            setEffectiveEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setEffectiveEndDate(newValue)
    }

    function handleSubmit(id) {
        var data = {
            "option": option,
            "effective_start_date": effectiveStartDate,
            "effective_end_date": effectiveEndDate,
            "type": eligibilityTypeTier,
            "maximum_amount": maximumAmount ? Number(maximumAmount) : 0,
            "attribute_key_values": attributeValue && Object.values(attributeValue) ? Object.values(attributeValue) : []

        };
        if (sequenceNumber) {
            data.sequence = parseInt(sequenceNumber)
        } else {
            data.sequence = 0
        }
        if (idValue) {
            data.ID = idValue;
            data.rule_id = ruleID
        }
        if (id || id === 0) {
            var editedArray = [...eligibilityStore];
            editedArray[editIndex] = data;
            setEligibilityStore(editedArray);
            props.onChange(editedArray)
            localStorage.removeItem('editableRuleMode');

        } else {
            setEligibilityStore([...eligibilityStore, data]);
            props.onChange([...eligibilityStore, data])
        }

        handleRemove();

    }
    function handleRemove() {
        setEditMode(false);
        setAttributeValue({});
        setAttributeName([]);
        setEligibility('');
        if (effectiveStartDate != props.startDate)
            setEffectiveStartDate(null);
        if (effectiveEndDate != props.endDate)
            setEffectiveEndDate(null);
        setOption('');
        setSequenceNumber(1);
        setEligibilityTypeTier('');
        setIdValue('')
        setRuleID(0);
        setAttributeArray([]);
        setMaximumAmount('')
    }
    const [calcMethod, setCalcMethod] = React.useState('')
    useEffect(() => {
        if (props.data && props.data.eligibility_rules) {
            setEligibilityStore(props.data.eligibility_rules);
            setEffectiveStartDate(props.data.valid_from)
            setEffectiveEndDate(props.data.valid_to)
        }
        if (props.data && props.data.calculation_method) {
            setCalcMethod(props.data.calculation_method)
        }
        if (props.data && props.data.source_data_type) {
            props.getDefaultValuesAllData('attribute_name', props.data.source_data_type, sessionStorage.getItem('application'));
            props.onLoadingDefault(sessionStorage.getItem('application'), props.data.source_data_type);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.sourceDataType) {
            props.getDefaultValuesAllData('attribute_name', props.sourceDataType, sessionStorage.getItem('application'));
            props.onLoadingDefault(sessionStorage.getItem('application'), props.sourceDataType);
        }
    }, [props.sourceDataType])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records[0]) {
            setAttributeNameData(props.dropdownData.records[0].label_names);
            setAttributeNameObject(props.dropdownData.records[0])
        }
    }, [props.dropdownData])
    const handleDeleteLocally = (rule) => {
        const index = eligibilityStore.indexOf(rule);
        var eligibilityData = eligibilityStore.filter((item, i) => i !== index)
        setEligibilityStore(eligibilityData);
        props.onChange(eligibilityData)
    }
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    useEffect(() => {
        var tempObj = {};
        if (props.dropdownData && props.dropdownData.records && props.formFieldsAllData && props.formFieldsAllData.records) {
            props.dropdownData.records.map((item1) => {
                if (item1.field_id === 'attribute_name') {
                    item1.drop_down_value_keys.map((item3) => {
                        props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.map((item4) => {
                            if (item3 === item4.field_id) {
                                tempObj[item4.field_id] = item4.name;
                            }
                        })
                    })
                    setKeyValuePairObject(tempObj);
                }
            })
        }
        setKeyValuePairObject(tempObj);

    }, [props.formFieldsAllData, props.dropdownData]);

    useEffect(() => {
        if (props.startDate)
            setEffectiveStartDate(props.startDate)
        if (props.endDate)
            setEffectiveEndDate(props.endDate)

    }, [props.startDate, props.endDate])

    //add operation
    const [attributeArray, setAttributeArray] = React.useState([]);

    const handleAttributeValueFA = (e, field, type) => {
        var val = '';
        // if (type === 'outlined') {
        //     val = e.target.value
        // }
        if (type === 'attr') {
            val = e;
            if (e && e.length > 0) {
                var index = e.indexOf('-');
                if (index != -1)
                    val = e.substring(0, index - 1)
            }
        }
        else {
            val = e;
        }
        setAttributeValue(prevState => ({
            ...prevState,
            [field]: {
                "attribute_name": field,
                "attribute_value": val,
                "attribute_type": 'Attribute'
            }
        }))
    }

    return (
        <div>
            {props.mode != 'view' &&
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={1} >
                                    {/*  for tear calculation */}
                                    <Grid
                                        item
                                        md={(props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth' || props.calculationMethod === 'Multi Axis') ? 2.5 : 1.5}
                                        xs={12}
                                    >
                                        <Grid container spacing={1} >
                                            {((props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth' || props.calculationMethod === 'Multi Axis')
                                            ) &&

                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv} required>
                                                            Eligibility Type
                                                        </FormLabel>
                                                        <Select
                                                            value={eligibilityTypeTier}
                                                            onChange={(e) => setEligibilityTypeTier(e.target.value)}
                                                            displayEmpty
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {eligibiltyTypeData
                                                                .map(item => {
                                                                    return (
                                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>

                                                    </div>
                                                    {/* <Typography variant='h6'>A brief description corresponding to the selected attribute.</Typography> */}
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                md={(props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth' || props.calculationMethod === 'Multi Axis') ? 6 : 12}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                        Attribute Name
                                                    </FormLabel>
                                                    <div style={{ marginTop: -25 }}>
                                                        <MultiSelectDropdownKeyVal capitalize={true} listArray={attributeNameData} data={attributeName}
                                                            id={'attribute'} onChange={(value) => handleAttributename(value)} format='object' />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                Option
                                            </FormLabel>
                                            <Select
                                                value={option}
                                                onChange={handleOption}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value="" style={{ height: 30 }}>
                                                </MenuItem>
                                                {optionData.map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} disabled={item.disabled}>
                                                            {item.value}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    {attributeName && attributeName.length > 0 &&
                                        attributeName.map(item => (
                                            <Grid item md={4} xs={12}>
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv} required>
                                                        {attributeNameData && attributeNameData[item]}
                                                    </FormLabel>
                                                    {(item === 'sold_to_party'
                                                        || item === 'ship_to_party'
                                                        || item === 'payer'
                                                        || item === 'bill_to_party'
                                                        || item === 'customer_number')
                                                        ?
                                                        <div style={{ marginTop: -5 }}>

                                                            <SearchDropdown id='customer_single'
                                                                table={'customer_master'}
                                                                keyName={'customer_number'}
                                                                description={true}
                                                                onChange={(value) => handleAttributeValueFA(value, item)}
                                                                prevalue={attributeValue && attributeValue[item]?.attribute_value}
                                                                multiple
                                                            />
                                                        </div>
                                                        :
                                                        item === 'material_number' ?
                                                            <div style={{ marginTop: -5 }}>
                                                                <SearchDropdown id='material_single'
                                                                    table={'material_master'}
                                                                    keyName={'material_number'}
                                                                    description={true}
                                                                    onChange={(value) => handleAttributeValueFA(value, item)}
                                                                    prevalue={attributeValue && attributeValue[item]?.attribute_value}
                                                                    multiple
                                                                />

                                                            </div>
                                                            :
                                                            item === 'supplier_number' ?
                                                                <div style={{ marginTop: -5 }} >
                                                                    <SearchDropdown id='supplier_single'
                                                                        table={'supplier_master'}
                                                                        keyName={'supplier_number'}
                                                                        description={true}
                                                                        onChange={(value) => handleAttributeValueFA(value, item)}
                                                                        prevalue={attributeValue && attributeValue[item]?.attribute_value}
                                                                        multiple
                                                                    />
                                                                </div>
                                                                :
                                                                item === 'employee_number' ?
                                                                    <div style={{ marginTop: -5 }} >
                                                                        <SearchDropdown id='employee_single'
                                                                            table={'employee_master'}
                                                                            keyName={'employee_number'}
                                                                            description={true}
                                                                            onChange={(value) => handleAttributeValueFA(value, item)}
                                                                            prevalue={attributeValue && attributeValue[item]?.attribute_value}
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                    : item === 'customer_chain' || item === 'customer_group' || item === 'material_group' || item === 'product_hierarchy' || item === 'company_code' || item === 'sales_org' || item === 'supplier_group' ?
                                                                        <div style={{ marginTop: -10 }}>
                                                                            <AllOtherAttributeMasterComponent attributeName={item} value={attributeValue && attributeValue[item]?.attribute_value} onChange={(e) => handleAttributeValueFA(e, item, 'attr')} prevalue={attributeValue && attributeValue[item]?.attribute_value} withOutLabel={true} id={item} multiple />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <ChipInput data={attributeValue && attributeValue[item]?.attribute_value} onChange={(e) => handleAttributeValueFA(e, item)} />
                                                                        </div>
                                                    }
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                        Start Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={effectiveStartDate}
                                                                onChange={date => handleEffectiveStartDate(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={props.startDate ? props.startDate : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                        End Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={effectiveEndDate}
                                                                onChange={date => handleEffectiveEndDate(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid
                                        item
                                        md={5}
                                        xs={12}
                                    >
                                        <Grid container spacing={1}> */}
                                    <Grid
                                        item
                                        md={1}
                                        style={{
                                            width: '100%',
                                            display: (currentApplication === 'Customer Chargeback' || currentApplication === 'Pricing' ||
                                                currentApplication === 'Supplier Chargeback') ? 'none' : null
                                        }}
                                    >
                                        <Tooltip title='Same Condition ID = OR Different Condition ID = AND'>
                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv}>
                                                Condition ID
                                            </FormLabel>
                                        </Tooltip>
                                        <OutlinedInput
                                            inputProps={{
                                                type: 'number'
                                            }}
                                            value={sequenceNumber}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setSequenceNumber(e.target.value)}
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        md={9}
                                    >
                                        <Grid container spacing={1} > */}

                                    {((props.maximumAmountPeriod == 'Payment Period - Different Amount') || maximumAmount != 0) &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv}>
                                                    Max Amt
                                                </FormLabel>
                                                <OutlinedInput
                                                    inputProps={{
                                                        type: 'number'
                                                    }}
                                                    value={maximumAmount}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={e => setMaximumAmount(e.target.value)}
                                                />
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item  //md={5}
                                        classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingBottom: 15, display: 'flex', marginTop: "-0.1rem" }} >
                                            <FormLabel></FormLabel>
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    // disabled={attributeName.length == 0 || !option || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (Moment(effectiveStartDate) >= Moment(props.startDate))) || !(attributeValue && Object.entries(attributeValue).length > 0) ? true : false}
                                                    disbaled={(attributeName && option && effectiveStartDate && effectiveEndDate && sequenceNumber)
                                                        || ((props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Multi Axis' || props.calculationMethod === 'Tiered Growth') &&
                                                            eligibilityTypeTier && attributeName && option && effectiveStartDate && effectiveEndDate && sequenceNumber)
                                                        ? false : true}
                                                    onClick={() => handleSubmit(editIndex)}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={
                                                        // attributeName.length == 0
                                                        // || !option
                                                        // || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (Moment(effectiveStartDate) >= Moment(props.startDate)))
                                                        // || !(attributeValue && Object.entries(attributeValue).length > 0)
                                                        // || 
                                                        (//(props.calculationMethod === 'Flat Rate' || props.calculationMethod === 'Fixed Amount' || props.calculationMethod === 'Quota Achievement' || props.calculationMethod === 'Price Point' || props.calculationMethod === 'External Calcualtion') && 
                                                            attributeName && option && effectiveStartDate && effectiveEndDate && sequenceNumber)
                                                            || ((props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Multi Axis' || props.calculationMethod === 'Tiered Growth') &&
                                                                eligibilityTypeTier && attributeName && option && effectiveStartDate && effectiveEndDate && sequenceNumber)
                                                            ? false : true}
                                                    onClick={() => handleSubmit('')}
                                                >
                                                </Button>
                                            }
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                                style={{ marginLeft: 10 }}
                                            >
                                            </Button>
                                            <input type='file'
                                                accept=".xlsx, .xls, .csv"
                                                id='file' ref={inputFile} style={{ display: 'none' }}
                                                onChange={(e) => handleDrop(e)} />
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<CloudUpload />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.uploadIconContainer
                                                }}
                                                onClick={() => uploadER()}
                                                style={{ marginLeft: 10 }}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* </Grid>
                                </Grid> */}
                                {/* </Grid>
                                </Grid> */}
                            </div>
                        </form>
                    </Card>
                </div >
            }
            <Card style={{ marginTop: '-1rem' }}>
                <div className={classes.row} style={{ marginTop: '-1rem' }} >
                    <CardHeader
                        title="ELIGIBILITY RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2} style={{ marginTop: '-0.9rem' }} >
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {eligibilityStore.length > 0 ?

                                //{(props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth' || props.calculationMethod === 'Multi Axis') ?
                                < MaterialTable
                                    columns={(props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth' || props.calculationMethod === 'Multi Axis') ?
                                        columns.filter(item => props.mode == 'view' ? item.field != 'Actions' : item) : columns.filter(item => (props.mode == 'view' ? item.field != 'Actions' && item.field != 'type' : item.field != 'type'))}
                                    data={eligibilityStore}
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    enablePagination={false}
                                    enableRowVirtualization
                                    style={{ marginTop: '-1rem' }}
                                    options={{
                                        maxBodyHeight: '80vh',
                                        search: false,
                                        paging: false,
                                        filtering: true,
                                        emptyRowsWhenPaging: false,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,

                                    }}
                                    components={{
                                        Toolbar: (props) => (
                                            <div
                                                style={{
                                                    height: "0px",
                                                }}
                                            >
                                            </div>
                                        ),
                                        Row: ({ data, index }) => {
                                            var l = data['attribute_key_values'] ? (data['attribute_key_values'].length) + 1 : 1
                                            return (
                                                <Fragment >
                                                    <TableRow style={{ backgroundColor: index % 2 != 0 ? 'rgba(242, 242, 242, 0.3)' : 'none', marginTop: 50 }}>
                                                        {(props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth' || props.calculationMethod === 'Multi Axis') && <TableCell align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                            {data.type}
                                                        </TableCell>}
                                                        <TableCell align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                            {data.option}
                                                        </TableCell>
                                                        <TableCell align='center' rowSpan={l}>{Moment.utc(data.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center' rowSpan={l}>{Moment.utc(data.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                            {data.sequence}
                                                        </TableCell>
                                                        <TableCell align='center' rowSpan={l}>{data.maximum_amount && data.maximum_amount != 0 ?
                                                            parseFloat(
                                                                data.maximum_amount
                                                            ).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }) : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                    {data['attribute_key_values'] && data['attribute_key_values'].map((item, index) => {
                                                        return (
                                                            <StyledTableRow>
                                                                <TableCell align='center' style={{ textTransform: 'capitalize', borderTop: '2px solid #ddd' }}  >
                                                                    {item.attribute_name ? attributeNameData && attributeNameData[item.attribute_name] ? attributeNameData[item.attribute_name] : item.attribute_name.replace(/_/g, ' ') : ''}
                                                                </TableCell>
                                                                <TableCell align='center' style={{ textTransform: 'capitalize', borderTop: '2px solid #ddd' }}  >
                                                                    {item.attribute_value && typeof (item.attribute_value) !== 'string' ? item.attribute_value/*.join(",")*/.toString() : item.attribute_value}
                                                                </TableCell>
                                                                {index == 0 && props.mode != 'view' &&
                                                                    <TableCell rowSpan={l} >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <IconButton
                                                                                onClick={() => editEligibilityRule(data, index)}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() => handleDeleteLocally(data)}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                }
                                                            </StyledTableRow>
                                                        );
                                                    })
                                                    }


                                                </Fragment>
                                            );
                                        }

                                    }}
                                />

                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

});

const mapDispatchToProps = dispatch => {
    return {
        // onEligibilityRulesEdit: (data, id) => dispatch(editEligibilityRules(data, id)),
        //onCheckEligibilityRulesTable: () => dispatch(getEligibilityRulesTableData(localStorage.getItem('currentAddContractID'))),
        uploadEligibilityRules: (file, calculationMethod, appType, sourceDataType) => dispatch(uploadEligibilityRules(file, calculationMethod, appType, sourceDataType)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValuesAttributeValue(applicationType, sourceDataType, 'on', 'attribute_name')),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        //getAttributeListData: (data) => dispatch(getAttributeListData(data)),
    }
}

const mapStateToProps = state => {
    return {
        tableData: state.addContractData.eligibilityRulesData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationDataAV,
        //data: state.addContractData.contractGeneralData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        uploadEligibilityRulesData: state.dataSetupData.uploadEligibilityRulesData,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityRules);
