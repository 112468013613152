import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, TextField,
    Card, OutlinedInput, FormLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addPricingBatchJobSetup, getDefaultValues, getPricingBatchJobDetail, getPriceCalForList, getIntegrationSetupData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Link } from 'react-router-dom';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../../components/Inputs";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        marginTop: 20

    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 1,
        width: '100%',
        paddingLeft: 10,
        borderRadius: '3px'
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 1,
        height: 34,
        borderRadius: '3px',
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
}));

const EditTransactionDataBatchJobSetup = props => {
    const classes = useStyles();
    const [batchSetupID, setBatchSetupID] = React.useState(0);
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [customerNumber, setCustomerNumber] = useState([]);
    const [materialNumber, setMaterialNumber] = useState([]);
    const [supplierNumber, setSupplierNumber] = useState([]);
    const [employeeNumber, setEmployeeNumber] = useState([]);
    const [materialGroup, setMaterialGroup] = React.useState([]);
    const [customerGroup, setCustomerGroup] = React.useState([]);
    const [transactionType, setTransactionType] = React.useState('');
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');
    const [packetSize, setPacketSize] = React.useState('');
    const history = useHistory();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        if (props.batchJobDetail && props.batchJobDetail.app_type) {
            setBatchSetupID(props.batchJobDetail.ID)
            setBatchJobType(props.batchJobDetail.batch_job_type);
            setBatchJobName(props.batchJobDetail.batch_job_name);
            setPlannedStartDate(props.batchJobDetail.planned_start_date);
            setFrequency(props.batchJobDetail.frequency);
            setPlannedStartTime(props.batchJobDetail.planned_start_time)
            setPlannedDateType(props.batchJobDetail.planned_date_type)
            setPlannedStartCalendar(props.batchJobDetail.planned_start_calendar)
            if (props.batchJobDetail && props.batchJobDetail.batch_filter_criteria && props.batchJobDetail.batch_filter_criteria[0] && props.batchJobDetail.batch_filter_criteria[0].filters != null) {
                var data = []
                data.push(props.batchJobDetail.batch_filter_criteria[0].filters)
                if (data.length > 0) {
                    data.map(v => {
                        setCustomerNumber([...customerNumber, v.customer_number])
                        setMaterialNumber([...materialNumber, v.material_number])
                        setBillingDateStart(Moment.utc(v.billing_start_date).local())
                        setBillingDateEnd(Moment.utc(v.billing_end_date).local())
                        setRadioGroupValue(v.records)
                        setPacketSize(v.packet_size)
                        setTransactionType(v.transaction_type)
                        setCustomerGroup([...customerGroup, v.customer_group])
                        setMaterialGroup([...materialGroup, v.material_group])
                        setSupplierNumber([...supplierNumber, v.supplier_number])
                        setEmployeeNumber([...employeeNumber, v.employee_number])
                    })
                }
            }
        }
    }, [props.batchJobDetail])

    useEffect(() => {
        props.getConfigData(1, 10);
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getBatchJobDetail(appId);
        props.onLoadingDefault("Customer Rebate");

    }, []);
    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'accrual_calendar') {
                    setPlannedStartCalendarArray(item)
                }
            })
        }
    }, [props.dropdownData])

    useEffect(() => {
        if (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time') {
            setPlannedDateType('static');
        }
        else {
            setPlannedDateType('');
        }
    }, [frequency])

    const BatchJobTypesArray = [
        'Build Sales Data'
    ];
    const frequencyTypesArray = [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue);
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue);
    }
    function handleMaterialNumber(newValue) {
        setMaterialNumber(newValue);
    }
    function handleEmployeeNumber(newValue) {
        setEmployeeNumber(newValue);
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue);
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup(newValue);
    }
    function handleCustomerGroup(newValue) {
        setCustomerGroup(newValue);
    }


    function handleDeleteChip(value, index, field) {
        if (field == 'Customer Number') {
            var deletedCustomerNumber = customerNumber.filter(item => item !== value)
            setCustomerNumber(deletedCustomerNumber)
        }
        if (field == 'Employee Number') {
            var deletedEmployeeNumber = employeeNumber.filter(item => item !== value)
            setEmployeeNumber(deletedEmployeeNumber)
        }
        if (field == 'Supplier Number') {
            var deletedSupplierNumber = supplierNumber.filter(item => item !== value)
            setSupplierNumber(deletedSupplierNumber)
        }
        if (field == 'Material Group') {
            var deletedMaterialGroup = materialGroup.filter(item => item !== value)
            setMaterialGroup(deletedMaterialGroup)
        }
        if (field == 'Customer Group') {
            var deletedCustomerGroup = customerGroup.filter(item => item !== value)
            setCustomerGroup(deletedCustomerGroup)
        }
        else {
            var deletedMaterialNumber = materialNumber.filter(item => item !== value)
            setMaterialNumber(deletedMaterialNumber)
        }

    }
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleTransactionType = (value) => {
        setTransactionType(value);
    }
    function handleContinue() {
        setOpen(false);
    }

    function handleClear() {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setSupplierNumber([]);
        setEmployeeNumber([]);
        setMaterialGroup([]);
        setCustomerGroup([]);
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setTransactionType();
    }
    function handleClearAll() {
        handleClear();
    }
    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value.concat(":00"))
    }
    function handleOnSubmit() {

        var batchJobFilter = [{
            "billing_start_date": billingDateStart ? Moment.utc(billingDateStart).format('YYYY-MM-DD') : '',
            "billing_end_date": billingDateEnd ? Moment.utc(billingDateEnd).format('YYYY-MM-DD') : '',
            "customer_number": customerNumber.length > 1 ? customerNumber : customerNumber.toString(),
            "material_number": materialNumber.length > 1 ? materialNumber : materialNumber.toString(),
            "records": radioGroupValue ? radioGroupValue : '',
            "packet_size": packetSize,
            "transaction_type": transactionType,
            "customer_group": customerGroup.length > 1 ? customerGroup : customerGroup.toString(),
            'material_group': materialGroup.length > 1 ? materialGroup : materialGroup.toString(),
            "supplier_number": supplierNumber.length > 1 ? supplierNumber : supplierNumber.toString(),
            "employee_number": employeeNumber.length > 1 ? employeeNumber : employeeNumber.toString(),
        }]
        var data = {
            "app_type": "Sales Data",
            "batch_job_type": batchJobType,
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            // "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ'),
            // "planned_start_calendar": plannedStartCalendar,
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,
            "batch_filter_criteria": batchJobFilter
        }
        if (plannedDateType == 'dynamic') {
            data["planned_start_calendar"] = plannedStartCalendar
        } else {
            data["planned_start_date"] = plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ')
        }
        props.onSubmit(data, 'edit', batchSetupID);

    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/transaction-data-batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'none' : 'flex') }}>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            Transaction Type
                                        </FormLabel>
                                        <Select
                                            value={transactionType}
                                            onChange={(e) => handleTransactionType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }} >
                                            <MenuItem value={'INV'} key={'INV'}>
                                                INV
                                            </MenuItem>
                                            <MenuItem value={'SO'} key={'SO'}>
                                                SO
                                            </MenuItem>
                                            <MenuItem value={'DEL'} key={'DEL'}>
                                                DEL
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                {transactionType === 'INV' &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                    >
                                        <div style={{ display: 'flex' }}>

                                            <div className={classes.contractContiner}>
                                                <FormLabel >Billing Date</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 1,
                                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={billingDateStart}
                                                            onChange={handleBillingDateStart}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                            <div className={classes.contractContiner}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 34,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%',
                                                                    marginLeft: 10
                                                                }
                                                            }}
                                                            value={billingDateEnd}
                                                            onChange={handleBillingDateEnd}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={billingDateStart ? billingDateStart : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                    </Grid>
                                }
                                {transactionType === 'SO' &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}

                                    >
                                        <div style={{ display: 'flex' }}>

                                            <div className={classes.contractContiner}>
                                                <FormLabel >Sales Order Date</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 1,
                                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={billingDateStart}
                                                            onChange={handleBillingDateStart}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                            <div className={classes.contractContiner}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 34,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%',
                                                                    marginLeft: 10
                                                                }
                                                            }}
                                                            value={billingDateEnd}
                                                            onChange={handleBillingDateEnd}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={billingDateStart ? billingDateStart : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                    </Grid>
                                }
                                {transactionType === 'DEL' &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}

                                    >
                                        <div style={{ display: 'flex' }}>

                                            <div className={classes.contractContiner}>
                                                <FormLabel >Delivery Date</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 1,
                                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={billingDateStart}
                                                            onChange={handleBillingDateStart}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                            <div className={classes.contractContiner}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 34,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%',
                                                                    marginLeft: 10
                                                                }
                                                            }}
                                                            value={billingDateEnd}
                                                            onChange={handleBillingDateEnd}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={billingDateStart ? billingDateStart : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "1px" }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} >
                                        Packet Size
                                    </FormLabel>
                                    <OutlinedInput
                                        type={'number'}
                                        value={packetSize}
                                        // classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={(e) => setPacketSize(e.target.value)}
                                    >
                                    </OutlinedInput>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginLeft: "-25px" }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            Processing Type
                                        </FormLabel>
                                        <Select
                                            value={radioGroupValue}
                                            onChange={(e) => handleRadioGroup(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }} >
                                            <MenuItem value={'non-processed'} key={'non-processed'}>
                                                Process New Records
                                            </MenuItem>
                                            <MenuItem value={'all'} key={'all'}>
                                                Process All Records
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "1px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Customer Number </Typography>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Material Number</Typography>
                                    <ChipInput data={materialNumber} onChange={handleMaterialNumber} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Supplier Number </Typography>
                                    <ChipInput data={supplierNumber} onChange={handleSupplierNumber} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Employee Number </Typography>
                                    <ChipInput data={employeeNumber} onChange={handleEmployeeNumber} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Customer Group </Typography>
                                    <ChipInput data={customerGroup} onChange={handleCustomerGroup} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Material Group </Typography>
                                    <ChipInput data={materialGroup} onChange={handleMaterialGroup} /> */}
                                {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={materialGroup}
                                        onAdd={(chips) => handleMaterialGroup(chips)}
                                        onDelete={(chip, index) => handleDeleteChip(chip, index, 'Material Group')}
                                        disableUnderline={true}
                                    /> */}
                                {/* </Grid> */}
                                {/* <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                        <FormControlLabel
                                            value="non-processed"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.fontSetting }} > Process New Records </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                        <FormControlLabel
                                            value="all"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.fontSetting }} >Process All Records </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                    </RadioGroup>
                                </Grid> */}
                            </Grid>
                        </div>
                    </form>
                </Card >

                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Type
                                            </FormLabel>
                                            <Select
                                                value={batchJobType}
                                                onChange={(e) => setBatchJobType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {BatchJobTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {frequencyTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>


                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={batchJobName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setBatchJobName(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency || (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time')}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>

                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px',
                                                            borderRadius: '3px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 1,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                    .filter(item => item != 'Manual')
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>

                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>

                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                //className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%', borderRadius: '3px' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>


                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/transaction-data-batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={!batchJobType || !batchJobName || !plannedStartTime || !frequency || !plannedDateType ? true
                                : plannedStartDate || plannedStartCalendar ? false : true
                            }
                        >
                            Continue

                        </Button>
                    </DialogActions>
                </Dialog>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>


                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}

                        disabled={batchJobType === 'Build Sales Data' && transactionType && radioGroupValue ? false : true}
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );


};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(addPricingBatchJobSetup(data, type, id, 'Salse Data')),
        getBatchJobDetail: (id) => dispatch(getPricingBatchJobDetail(id)),
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        batchJobDetail: state.customerData.pricingBatchJobDetail,
        configData: state.pricingData.priceCalForListData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionDataBatchJobSetup);